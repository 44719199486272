import * as types from "../../constant/typeAction";

export const initialState = {
    listOfNotify: [],
};

const lstErrorCommon = ['ERROR_REQUEST', 'ERROR_SYSTEM']
const statusCodeCommon = [403, 500];

// 403 - request faled - other error
const _handlePushError = (state, action) => {

    const { error } = action.payload;
    const { dataError } = error;

    if (typeof (dataError) === "object"){
        const { error: { errorType, status } } = dataError;
        
        const lastItemOfQueue = state.listOfNotify[0];
        if (lastItemOfQueue) {
            const { type } = lastItemOfQueue;
            if (type === 'ERROR'){
                const { dataError } = lastItemOfQueue;
                if (typeof (dataError) === "object") {
                    const { 
                        error: { 
                            errorType: errorTypeLastItemQueue,
                            status: statusLastItemQueue
                        }
                    } = dataError;
                    if (lstErrorCommon.includes(errorType) && errorType === errorTypeLastItemQueue){
                        return state;
                    }

                    if (!!status && status === statusLastItemQueue && statusCodeCommon.includes(status)){
                        return state;
                    }
                }
            }
        }
    }

    return {
        listOfNotify: [
            error,
            ...state.listOfNotify
        ]
    }
}

export default function notifyReducer(state = initialState, action) {
    switch (action.type) {
        case types.PUSH_ERROR:
            return _handlePushError(state, action)
        case types.PUSH_SUCCESS:
            return {
                listOfNotify: [
                    action.payload.success,
                    ...state.listOfNotify
                ]
            }
        case types.PUSH_INFO:
            return {
                listOfNotify: [
                    action.payload.info,
                    ...state.listOfNotify
                ]
            }
        case types.PUSH_WARNING:
            return {
                listOfNotify: [
                    action.payload.warning,
                    ...state.listOfNotify
                ]
            }
        case types.POP_NOTIFY:
            const newListOfNotify = 
                state.listOfNotify.slice(0, state.listOfNotify.length - 1);
            return {
                listOfNotify: newListOfNotify
            }
        default:
            return state;
    }
}
