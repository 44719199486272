import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-direction: column;
    .title{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 128%;
        color: #959595;
        margin: 0 0 10px 10px;
        img{
            margin-left: 5px;
        }
    }
    .value{
        width: 100%;
        height: 48px;
        background-color: #FFFFFF;
        border: 1px solid #C6D5EB;
        box-sizing: border-box;
        border-radius: 3px;    
        display: flex;
        align-items: center;
        padding: 0 10px;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        color: #212121;        
        ::placeholder{
            color: #959595;
        }
        &.disabled{            
            background-color: #ECF0F6;
            ::placeholder{
            color: #212121;
        }
        }               
    }

`
export { Bound }