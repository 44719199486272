import styled, { css } from 'styled-components';

const Bound = styled.div`
    display: flex;
    width: 322px;
    align-items: center;
    /* justify-content: space-between; */
    max-width: 322px;
    border-radius: 4px;
    padding: 12px 0;
    font-family: "UTM-Avo";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: rgba(0, 0, 0, 0.65);
    animation-name: animatetop;
    animation-duration: 1s;
    position: fixed;
    z-index: 100;
    .ic-detail {
        margin-left: 26px;
    }

    .content {
        margin-left: 16px;
        flex-grow: 1;
    }

    .btn-close {
        margin: 0 17.5px;

        :hover {
            cursor: pointer;
        }
    }

    ${props => css`${props.specificStyles}`}
    ${props => props.extendStyles}

    @keyframes animatetop {
        from {top: -300px; opacity: 0}
        to {top: 0; opacity: 1}
    }
`;

export {
    Bound
}