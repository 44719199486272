import React, { useState, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'

// style
import { Bound } from "./index.styles";

// tools
import { convertDateTime, buildMoney, buildValueScope, enCodeSTK } from '../../../../../../helper/tools';

// data
import { dataBank } from '../../../../../../data/historyData';
//constant
import * as keys from '../../../../../../constant/key'
// control
// import ModalControl from '../../../../../Control/ModalControl';
// import TooltipCustomControl from '../../../../../Control/TooltipCustomControl';


const ItemHistoryTable = ({ data }) => {
    const { BillNumber, CreateTime, FacepayNumber, Amount, ToBankAccount, ToBankCode, ShopName } = data;

    let userDataLocal = JSON.parse(localStorage.getItem(keys.FACEPAY_USER_DATA));
    // const refTooltipBankCode = useRef(null);

    // const [showBank, setShowBank] = useState(false)

    const iconBank = dataBank.find(item => item.BankCode === ToBankCode)

    const onError = () => {
        // e.target.src = avatarDefault;
    }

    return (
        <Bound className="item-table"
            gridTemplateColumnsContent={
                userDataLocal && 
                // userDataLocal.Role === keys.STORE_MANAGER && 
                [keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role) && 
                "1fr 260px 220px 160px 190px"
            }>
            <p
                className="item"
                data-toogle="tooltip"
                title={
                    BillNumber ?
                        `#${BillNumber}`
                        : "Invalid Identity"
                }
            >
                {
                    BillNumber ?
                        `#${BillNumber}`
                        : "-"
                }
            </p>
            <p className="item">{
                CreateTime ?
                    convertDateTime(CreateTime, 'DD/MM/YYYY HH:mm')
                    : "-"
            }</p>
            <p
                className="item"
                data-toogle="tooltip"
                title={
                    FacepayNumber ?
                        `#${FacepayNumber}`
                        : "Invalid Identity"
                }
            >
                {
                    FacepayNumber ?
                        `#${FacepayNumber}`
                        : "-"
                }
            </p>
            <p className="item">{
                Amount ?
                    buildMoney(Amount) + ' VND'
                    : "-"
            }</p>
            <p className="item">{
                Amount ?
                    buildValueScope(Amount)
                    : "-"
            }</p>
            {
                userDataLocal && userDataLocal.Role === keys.CHAIN_MANAGER &&
                <Fragment>
                    <div className="name-store" data-toogle="tooltip" title={ShopName ? ShopName : "Invalid Identity"}>
                        {ShopName ? ShopName : "-"}
                    </div>
                    <div className="icon-bank">
                        {iconBank ?
                            <img onError={onError} src={iconBank.BankImage} alt="ic_bank" />
                            : ""
                        }
                    </div>
                    <div className="block-bankcode">
                        <p className="block-bankcode__stk-tooltip"
                        // ref={refTooltipBankCode}
                        // onMouseEnter={() => { setShowBank(true) }}
                        // onMouseLeave={() => { setShowBank(false) }}
                        >
                            {
                                ToBankAccount ?
                                    enCodeSTK(ToBankAccount)
                                    : "-"
                            }
                        </p>
                    </div>
                </Fragment>
            }
        </Bound>
    )
}

ItemHistoryTable.propTypes = {
    data: PropTypes.object.isRequired
}

export default React.memo(ItemHistoryTable);
