import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'

// styles
import { Bound } from './index.styles'

// component
import ModalControl from '../ModalControl'
import LstDropDown from './LstDropDown'

const optionDropdown = {
    HISTORY: "HISTORY",
    CUSTOM: "CUSTOM",
}

const stylesOption = {
    [optionDropdown.HISTORY]: `
        background-color: #FFFFFF;
        border: 1px solid #C6D5EB;
        box-sizing: border-box;
        border-radius: 6px;
    `,
    [optionDropdown.CUSTOM]: ``
}

const DropdownControl = ({ isTitle, titleDropDown, option, stylesParent, stylesListDropdown, stylesItemDropdown, data, handleChoice, icon, value, width }) => {
    const [isShow, setIsShow] = useState(false);
    // const [extendStyles, setExtendStyles] = useState('');
    const isClickOutsiteRef = useRef(false);
    const dropDownRef = useRef(null);

    const _handleShowDropdown = () => {
        setIsShow(!isShow);
    }

    const handleClick = useCallback(() => {
        if (isClickOutsiteRef.current === false)
            setTimeout(() => {
                setIsShow(false)
            }, 0);
    }, []);


    useEffect(() => {
        if (isShow)
            document.addEventListener("click", handleClick, false);
        return () => document.removeEventListener("click", handleClick, false);
    }, [handleClick, isShow]);


    // useEffect(() => {
    //     const dropDown = dropDownRef.current;
    //     if (!dropDown) return;

    //     const styles = {}

    //     const dimensions = dropDown.getBoundingClientRect();

    //     styles.left = dimensions.left + (dimensions.width / 2) - width/2;
    //     styles.left = Math.min(styles.left, document.body.clientWidth);
    //     styles.left += "px";
    //     styles.top = dimensions.top + dimensions.height + 5;
    //     if (dimensions.top < (window.innerHeight / 4) * 3) { 
    //         styles.top = dimensions.top + dimensions.height + 5;
    //     } else {
    //         styles.bottom = (window.innerHeight - dimensions.top) - 10 + "px";
    //     }
    //     styles.top += "px";
    //     if(styles.bottom) 
    //         delete styles.top;
    //     const stylesToString = Object.keys(styles)
    //         .reduce((str, item) =>
    //             `${str}
    //         ${item}: ${styles[item]};
    //         `, ``)
    //     setExtendStyles(stylesToString)
    // }, [isShow])

    return (
        <Bound
            className="parent"
            specificStyles={stylesOption[option]}
            stylesParent={stylesParent}
            onClick={_handleShowDropdown}
            ref={dropDownRef}
            onMouseEnter={() => isClickOutsiteRef.current = true}
            onMouseLeave={() => isClickOutsiteRef.current = false}
        >
            {
                isTitle &&
                <p className="title">{titleDropDown}</p>
            }
            <div className="content">
                <p className="default-value">{
                    value || "value"}
                </p>
                {
                    icon && <img className="icon" src={icon} alt="icon" />
                }
                {
                    isShow &&
                    // <ModalControl>
                    <LstDropDown
                        stylesListDropdown={stylesListDropdown}
                        data={data}
                        valueDefault={value}
                        // extendStyles={extendStyles}
                        handleChoice={handleChoice}
                        handleBlur={handleClick}
                        stylesItemDropdown={stylesItemDropdown}
                    />
                    // </ModalControl>
                }
            </div>
        </Bound>
    )
}

DropdownControl.defaultProps = {
    option: optionDropdown.CUSTOM
}

DropdownControl.propTypes = {
    option: PropTypes.oneOf(Object.values(optionDropdown)),
    stylesParent: PropTypes.any,
    stylesListDropdown: PropTypes.any,
    stylesItemDropdown: PropTypes.any,
    data: PropTypes.array.isRequired,
    handleChoice: PropTypes.func.isRequired,
    icon: PropTypes.string,
    value: PropTypes.string,
}

export default React.memo(DropdownControl);
