import styled, { css } from "styled-components"

const Bound = styled.div`
    display: flex;
    align-items: center;
    /* height: 36px; */
    width: 100%;
    /* justify-content: space-between; */
    padding: 25px 10px;
    .title-history {
        font-family: "SVN-Gilroy";
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 115%;
        color: #051321;
        /* margin: 0 0 0 10px; */
    }

    .content-filter {
        margin: 0 0 0 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;

        .label-input {
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            align-items: center;
            text-align: right;
            letter-spacing: 0.05px;
            color: #051321;
            &:nth-child(1) {
                margin: 0 16px 0 16px;
            }
            &:nth-child(3) {
                margin: 0 16px 0 30px;
            }
            &:nth-child(5) {
                margin: 0 16px 0 30px;
            }
        }

        /* Dropdown */

        
        /* end dropdown */
    }

    .ic-download{
        margin: 0 0 0 27px;
        border-radius: 6px;
        div{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border: 1px solid #C6D5EB;
            box-sizing: border-box;
            border-radius: 6px;
            cursor: pointer;
            img{
                width: 16px;
                object-fit: contain;
            }
        }
        &:hover{
            background-color: #ECF4FF;
        }
    }

    /* .btn-reload{
        margin: 0 10px 0 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border: 1px solid #C6D5EB;
        box-sizing: border-box;
        border-radius: 6px;
        cursor: pointer;
        img{
            width: 16px;
            object-fit: contain;
        }
        &:hover{
            background-color: #ECF4FF;
        }
    } */
`

const styleInput = css`
    width: 120px;
    height: 36px;
    background-color: #FFFFFF;
    border: 1px solid #C6D5EB;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.05px;
    color: #051321;
    outline: none;
    &::placeholder{
        color: #C6D5EB;
    }
    &:focus{
        border: 2px solid #1279DA;
    }
`


const stylesParentDropsDown = css`
    &.parent{
        width: 120px;
        height: 36px;
        background-color: #FFFFFF;
        border: 1px solid #C6D5EB;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 0 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        position: relative;
        .default-value{
            font-weight: normal;
            font-size: 14px;
            color: #051321;
        }
        .icon{
            position: absolute;
            top: 55%;
            right: 0;
            transform:translate(-10px, -55%);
        }
        
    }
`;

const stylesListDown = css`
    &.listDropdown {
        width: 120px;
        /* height: ${props => props.heightListDropdown || "108px"}; */
        overflow: auto;
        position: absolute;
        top: 38px;
        right: 0;
        background-color: #FFFFFF;
        /* border: 1px solid #C6D5EB; */
        box-sizing: border-box;
        border-radius: 6px;
        z-index: 10;
        &::-webkit-scrollbar {
            width: 9px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #E2E2E2;
            background-clip: padding-box;
            border-radius: 20px;
            border: 3px solid white;
        }
        .itemDropdown{
            width: 100%;
            height: 36px;
            /* border-radius: 6px; */
            background-color: #F3F3F3;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 0 0 20px;
            transition: .1s all ease-in-out;
            &:hover{
                background-color: #f1f5fa;
            }
            &.active{
                color: #0075FF;
            }
        }
    }
`;

export { Bound, styleInput, stylesParentDropsDown, stylesListDown };