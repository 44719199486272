import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Bound } from "./index.styles";
//component
import ItemUser from "./ItemUser";
import PagingV2Control from "../../../Control/PagingV2Control";
//constant
import * as keys from "../../../../constant/key";
import * as types from "../../../../constant/typeAction";
//action
import { getFilterPOS } from "../../../../redux/actions/posActions";
import {
  changePageSize,
  changePageNumber,
} from "../../../../redux/actions/pagingAction";

const heightTableItem = 54;
let pageSizeLocal = 10;

const POSPageList = () => {
  // const userDataLocal = JSON.parse(
  //   localStorage.getItem(keys.FACEPAY_USER_DATA)
  // );

  const history = useHistory();
  const [listPOS, setListPOS] = useState([]);
  const [heightPos, setHeightPos] = useState(0);
  const [total, setTotal] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [userDataLocal, setUserDataLocal] = useState({})

  const dispatch = useDispatch();

  const { posReducer, pagingReducer, systemReducer, shopReducer, userReducer } = useSelector(
    (state) => ({
      posReducer: state.posReducer,
      pagingReducer: state.pagingReducer,
      systemReducer: state.systemReducer,
      shopReducer: state.shopReducer,
      userReducer: state.userReducer,
    })
  );

  useEffect(() => {
    if (!userReducer.loginResult) return;
    setUserDataLocal(userReducer.loginResult)
  }, [userReducer.loginResult])

  useEffect(() => {
    // if (userDataLocal.Role === keys.STORE_MANAGER) {
    if ([keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role)) {
      if (!posReducer) return;
      if (!posReducer.listPos) return;
    }
    setListPOS(posReducer.listPos);
    setTotal(posReducer.total);
  }, [posReducer.listPos]);

  useEffect(() => {
    if (!posReducer.data) return;
    if (!Object.keys(userDataLocal).length) return;
    if (posReducer.type === types.REMOVE_POS) {
      let listShopID = [];
      // if (userDataLocal.Role === keys.STORE_MANAGER) {
      if ([keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role)) {
        listShopID = [shopReducer.data.Shops[0].ID];
      } else if (userDataLocal.Role === keys.CHAIN_MANAGER) {
        listShopID = posReducer.shopFilter.listShopID
          ? posReducer.shopFilter.listShopID
          : shopReducer.data.Shops.map((item) => item.ID);
      }

      dispatch(
        getFilterPOS(
          pagingReducer.pos_list.pageSize,
          pagingReducer.pos_list.pageNumber,
          listShopID,
          history
        )
      );
    }
  }, [JSON.stringify(posReducer.data), JSON.stringify(posReducer.shopFilter)], userDataLocal.Role);

  useEffect(() => {
    if (!pagingReducer) return;
    if (!pagingReducer.pos_list) return;
    if (!shopReducer.data) return;
    if (!Object.keys(userDataLocal).length) return;

    let height = pagingReducer.pos_list.itemDisplay * heightTableItem;
    if (height !== heightPos) setHeightPos(height);
    if (pagingReducer.pos_list.pageSize !== pageSizeLocal) {
      pageSizeLocal = pagingReducer.pos_list.pageSize;
    }
    if (pagingReducer.pos_list.pageNumber !== pageNum) {
      setPageNum(pagingReducer.pos_list.pageNumber);
    }

    let listShopID = [];
    // if (userDataLocal.Role === keys.STORE_MANAGER) {
    if ([keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role)) {
      listShopID = [shopReducer.data.Shops[0].ID];
    } else if (userDataLocal.Role === keys.CHAIN_MANAGER) {
      if (!posReducer.shopFilter.listShopID) return;
      listShopID = posReducer.shopFilter.listShopID
        ? posReducer.shopFilter.listShopID
        : shopReducer.data.Shops.map((item) => item.ID);
    }

    dispatch(
      getFilterPOS(
        pagingReducer.pos_list.pageSize,
        pagingReducer.pos_list.pageNumber,
        listShopID,
        history
      )
    );
  }, [
    JSON.stringify(pagingReducer),
    JSON.stringify(shopReducer.data),
    JSON.stringify(posReducer.shopFilter),
    userDataLocal.Role
  ]);

  const onChangePage = (page) => {
    if (!page) return;
    dispatch(changePageNumber(page, pagingReducer.pos_list.type));
    dispatch(changePageNumber(page, pagingReducer.pos_grid.type));
  };

  const onChangePageSize = (size) => {
    if (!size) return;
    dispatch(changePageSize(size.type, pagingReducer.pos_list.type));
    dispatch(changePageSize(size.type, pagingReducer.pos_grid.type));
    onChangePage(1);
  };

  const renderTableContent = () => {
    if (listPOS && listPOS.length > 0) {
      return (
        listPOS && listPOS.map((item, i) => <ItemUser key={i} data={item} />)
      );
    }
  };

  const auto_HotReload_Offline_To_Online = useCallback(() => {
    const { status, countSwitch } = systemReducer;

    if (status === types.WEB_IS_OFFLINE) {
      dispatch({
        type: types.POS_PAGE_LIST_OFFLINE,
      });
      return;
    }

    if (
      status === types.WEB_IS_ONLINE &&
      countSwitch > 0 &&
      posReducer.type === types.POS_PAGE_LIST_OFFLINE
    ) {
      if (posReducer.type === types.GET_FILTER_POS_START) return;
      if (!Object.keys(userDataLocal).length) return;

      let listShopID = [];
      // if (userDataLocal.Role === keys.STORE_MANAGER) {
      if ([keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role)) {
        listShopID = [shopReducer.data.Shops[0].ID];
      } else if (userDataLocal.Role === keys.CHAIN_MANAGER) {
        listShopID = posReducer.shopFilter.listShopID
          ? posReducer.shopFilter.listShopID
          : shopReducer.data.Shops.map((item) => item.ID);
      }

      dispatch(
        getFilterPOS(
          pagingReducer.pos_list.pageSize,
          pagingReducer.pos_list.pageNumber,
          listShopID,
          history
        )
      );
    }
  }, [systemReducer, userDataLocal.Role]);
  useEffect(() => {
    auto_HotReload_Offline_To_Online();
  }, [auto_HotReload_Offline_To_Online]);

  return (
    <Bound
      gridTemplateColumnsHeader={
        userDataLocal &&
        // userDataLocal.Role === keys.STORE_MANAGER &&
        [keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role) &&
        "160px 1fr 190px 185px 145px 160px"
      }
    >
      <div className="header-list-container">
        <div className="title code-bill">Mã thiết bị</div>
        <div className="title">Tên thiết bị</div>
        <div className="title">Trạng thái</div>
        <div className="title">Ngày thiết lập</div>
        <div className="title">Hoạt động cuối</div>
        {userDataLocal &&
          // userDataLocal.Role === keys.STORE_MANAGER ? (
          [keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role) ? (
            <div className="title last-activity">Thao tác</div>
          ) : (
            <div className="title last-activity-shop">Cửa hàng</div>
          )}
      </div>
      <div className="content-list-container">
        {
          listPOS && listPOS.length > 0 ?
            (
              <div
                className="pos-list-scroll"
                style={{ height: `${heightPos}px` }}
                id="table"
              >
                {renderTableContent()}
              </div>
            )
            :
            (
              <div className="no-data">
                Chưa kết nối thiết bị nào
              </div>
            )
        }
      </div>
      <PagingV2Control
        margin="20px 0"
        total={total}
        pageNum={pageNum}
        size={pagingReducer.pos_list.pageSize}
        onChangePage={(page) => onChangePage(page)}
        onChangePageSize={(size) => onChangePageSize(size)}
      />
    </Bound>
  );
};

export default POSPageList;
