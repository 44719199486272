export const FACEPAY_LOGIN_DATA = 'FACEPAY_LOGIN_DATA'
export const FACEPAY_USER_DATA = 'FACEPAY_USER_DATA'
export const FACEPAY_DISPLAY_CONFIG = 'FACEPAY_DISPLAY_CONFIG'

export const HISTORY_LIST = 'HISTORY_LIST'
export const POS_GRID = 'POS_GRID'
export const POS_LIST = 'POS_LIST'

export const CHAIN_MANAGER = 'CM'
export const STORE_MANAGER = 'SM'
export const WUM = 'WUM'

export const TYPEDATE = {
    DAY: 'Day',
    MONTH: 'Month',
    QUARTER: 'Quarter'
}
