import React from 'react'

const IcUpload = (props) => {
    return (
        <svg {...props} width="67" height="67" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#ic-upload)">
                <path d="M25.6171 29.1513C21.3369 29.1513 17.8547 32.6336 17.8547 36.9138V59.0368C17.8547 63.3171 21.3369 66.7993 25.617 66.7993L40.3657 66.7996C44.6459 66.7996 48.1282 63.3173 48.1282 59.0371V40.2244L37.3965 29.1513H25.6171ZM40.3658 61.6246L25.617 61.6243C24.1903 61.6243 23.0295 60.4636 23.0295 59.0368V36.9138C23.0295 35.4871 24.1903 34.3263 25.617 34.3263H34.2851V39.6941C34.2851 41.5168 35.7627 42.9944 37.5854 42.9944H42.9532V59.0371C42.9533 60.4638 41.7926 61.6246 40.3658 61.6246ZM66.2407 34.7144C66.2407 42.0432 60.5158 48.0589 53.3033 48.5271V43.335C57.6588 42.8808 61.0657 39.1882 61.0657 34.7144C61.0657 29.9348 57.1773 26.0463 52.3977 26.0463H48.6458V21.5182C48.6458 12.8151 41.5653 5.73455 32.8621 5.73455C24.7393 5.73455 17.9897 11.8179 17.162 19.8849L16.9421 22.028L14.7944 22.1999C9.32836 22.6375 5.17596 27.282 5.17596 32.7737C5.17596 37.5903 8.27448 41.6647 12.6797 42.9546V48.2748C5.39434 46.8791 0.000976562 40.4598 0.000976562 32.7737C0.000976562 28.7803 1.3664 24.9694 4.08054 22.0431C6.30216 19.6479 9.17402 18.0206 12.32 17.3441C13.2005 12.9919 15.4493 9.0195 18.7772 5.99783C22.6394 2.491 27.6415 0.55957 32.8621 0.55957C44.2262 0.55957 53.5069 9.65162 53.813 20.9432C60.7841 21.6541 66.2407 27.5589 66.2407 34.7144Z" fill="#BABABA" />
            </g>
            <defs>
                <clipPath id="ic-upload">
                    <rect width="66.24" height="66.24" fill="white" transform="translate(0 0.55957)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default IcUpload
