import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types'

// component
import AlertPopup from '../../../Popup/AlertPopup'

// action
import { logout } from '../../../../redux/actions/userAction'

// styles
import { Error403Wrapper } from './index.styles'

// type
// import { LOGIN_USER, LOGIN_USER_ERROR } from '../../../../constant/typeAction';
import {
    FACEPAY_USER_DATA,
    // FACEPAY_DISPLAY_CONFIG
} from '../../../../constant/key';

const alertPopupExtendStyles = `
    margin: 33px auto 0 auto;
    left: 50%;
    transform: translate(-50%, 0);
    width: 540px;
    max-width: unset;

    .timer {
        color: #051321;
        font-weight: bold;
        font-size: 16px;
        margin-right: 35px;
    }
`

const Error403 = ({ closePopup, autoClosePopup }) => {
    const history = useHistory()
    const dispatch = useDispatch();
    // const [second, setSecond] = useState(10);

    const { userReducer } = useSelector((state) => ({
        userReducer: state.userReducer
    }));

    const second = useRef(10);
    const [secondComponent, setSecondComponent] = useState(<div className="timer">10s</div>);

    useEffect(() => {
        const dataLogin = localStorage.getItem(FACEPAY_USER_DATA);
        if (!dataLogin) {
            // debugger;
            closePopup();
            dispatch(logout(history));
            return;
        }

        const objDataLogin = JSON.parse(dataLogin);
        if (objDataLogin.isLogedOut) {
            // debugger;
            closePopup();
            dispatch(logout(history));
            return;
        }
        // debugger;
        objDataLogin.isLogedOut = true;
        localStorage.setItem(FACEPAY_USER_DATA, JSON.stringify(objDataLogin));

        let time = null;
        const timeout = setInterval(() => {
            second.current = second.current - 1;
            setSecondComponent(<div className="timer">{second.current}s</div>);
            if (second.current === 0) {
                clearInterval(timeout);
                time = setTimeout(() => {
                    closePopup();
                    dispatch(logout(history));
                }, 500)
            }
        }, 1000)

        return () => {
            clearInterval(timeout)
            clearTimeout(time)
        }
    }, [])

    // useEffect(() => {
    //     if([LOGIN_USER, LOGIN_USER_ERROR].includes(userReducer.type)){
    //         closePopup();
    //     }
    // }, [userReducer.type]);

    return (
        <Error403Wrapper>
            <AlertPopup
                type="WARNING"
                // handleClickBtn={closePopup}
                extendStyles={alertPopupExtendStyles}
                btn={secondComponent}
            >
                <div>Tài khoản đã đăng nhập từ thiết bị khác hoặc quá thời</div>
                <div>gian hoạt động, bạn vui lòng đăng nhập lại nhé !</div>
            </AlertPopup>
        </Error403Wrapper>
    );
}

// const memoError403 = React.useMemo(Error403);
// memoError403.displayName = "memoError403";


Error403.propTypes = {
    closePopup: PropTypes.func.isRequired,
    autoClosePopup: PropTypes.any
}

export default Error403;
