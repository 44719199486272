import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Bound, styleDropdownParent, stylesListDropdown } from "./index.styles";

// images
import icon_dropdown from "../../../images/ic_arrow_down.svg";
//component
import DropdownControl from "../DropdownControl";
//tools
import { formatNumber } from "../../../helper/formatNumber";
import { useSelector } from "react-redux";

const itemPerPageArr = [
  {
    name: "10",
    type: 10,
  },
  {
    name: "30",
    type: 30,
  },
  {
    name: "60",
    type: 60,
  },
];

const PagingV2Control = (props) => {
  const { pageNum, total, size, margin } = props;
  const { onChangePage, onChangePageSize } = props;

  const { systemReducer } = useSelector((state) => ({
    systemReducer: state.systemReducer,
  }));

  const [pageSize, setPageSize] = useState(itemPerPageArr[0]);
  const [pageTotal, setPageTotal] = useState(total);
  // const [widthPaging, setWidthPaging] = useState(120)
  const [state, setState] = useState({
    start: 0,
    end: 0,
  });

  useEffect(() => {
    if (!total) {
      setPageTotal(0);
      let start = 0;
      let end = 0;
      setState({ start, end });
      return;
    } else if (total !== pageTotal) {
      setPageTotal(total);
    }
    if (size !== pageSize.type) {
      let findSize = itemPerPageArr.find((item) => item.type === size);
      if (findSize) setPageSize(findSize);
    }
    let pageSizeChoice = size;
    let delta = pageSizeChoice * pageNum;
    let start = delta - pageSizeChoice + 1;
    let end = delta;
    if (end > total) {
      end = total;
    }
    setState({ start, end });
    // let width = 120
    // if(start >= 100 && start < 1000){
    //     width = 130
    // }
    // else if(start >= 1000){
    //     width = 140
    // }
    // if(width !== widthPaging){
    //     setWidthPaging(width)
    // }
  }, [pageNum, size, total]);

  const onClickPreviousPage = () => {
    if (!onChangePage) return;
    if (pageNum === 1) return;
    onChangePage(pageNum - 1);
    _autoScrollTop();
  };

  const onClickNextPage = () => {
    if (!onChangePage) return;
    let pageSizeChoice = pageSize.type;
    let delta = pageSizeChoice * pageNum;
    if (delta >= total) return;
    onChangePage(pageNum + 1);
    _autoScrollTop();
  };

  const _autoScrollTop = () => {
    document.getElementById("table").scrollTop = 0;
  };

  const onSelectPageSize = (size) => {
    if (!onChangePageSize) return;
    onChangePageSize(size);
    setPageSize(size);
    _autoScrollTop();
  };

  return (
    <Bound style={{ margin: margin ? margin : "unset" }}>
      <p className="label-input">Số lượng</p>
      <DropdownControl
        data={itemPerPageArr}
        handleChoice={(size) => onSelectPageSize(size)}
        stylesParent={styleDropdownParent}
        stylesListDropdown={stylesListDropdown}
        icon={icon_dropdown}
        value={pageSize.name}
        width={50}
      />
      <p
        className="paging-container"
        // style={{width: `${widthPaging}px`}}
      >
        {formatNumber(state.start)}-{formatNumber(state.end)} trên tổng{" "}
        {formatNumber(pageTotal)}
      </p>
      <div
        className="btn-previous"
        style={
          systemReducer.status === "WEB_IS_OFFLINE"
            ? { opacity: 0.3, cursor: "unset" }
            : pageNum === 1
            ? { opacity: 0.3, cursor: "unset" }
            : {}
        }
        onClick={
          systemReducer.status === "WEB_IS_OFFLINE"
            ? null
            : () => onClickPreviousPage()
        }
      />
      <div
        className="btn-next"
        style={
          systemReducer.status === "WEB_IS_OFFLINE"
            ? { opacity: 0.3, cursor: "unset" }
            : state.end >= total
            ? { opacity: 0.3, cursor: "unset" }
            : {}
        }
        onClick={
          systemReducer.status === "WEB_IS_OFFLINE"
            ? null
            : () => onClickNextPage()
        }
      />
    </Bound>
  );
};

PagingV2Control.propTypes = {
  pageNum: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
};

export default PagingV2Control;
