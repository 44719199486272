import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux"

// styles
import {
    Bound, styleInput,
    // stylesParentDropsDown, stylesListDown 
} from "./index.styles"

// tools
import { RemoveAccents, clearFirstString } from '../../../../../helper/tools';

// custom hook
import useDebounce from '../../../../../helper/useDebounce';

// data
import { dataHeader } from "../../../../../data/historyData"

// control
import InputTextHistoryControl from '../../../../Control/InputTextHistoryControl';
// import DropdownControl from '../../../../Control/DropdownControl';

// host
import { host } from '../../../../../host/host';

// actions
import { changeFieldsFilter } from '../../../../../redux/actions/statisticAction';

//component
import IcDownLoad from '../../../../../images/component/IcDownLoad'
import { WEB_IS_OFFLINE, GET_EXPORT_EXCEL_ERROR } from '../../../../../constant/typeAction';
import { pushError } from '../../../../../redux/actions/notifyAction';
import { FACEPAY_USER_DATA, STORE_MANAGER, CHAIN_MANAGER, WUM } from '../../../../../constant/key';

const timeDebounce = 800;

const HeaderHistory = () => {

    const userDataLocal = JSON.parse(localStorage.getItem(FACEPAY_USER_DATA));

    const { statisticReducer, shopReducer, systemReducer } = useSelector(state => ({
        statisticReducer: state.statisticReducer,
        shopReducer: state.shopReducer,
        systemReducer: state.systemReducer
    }))

    const dispatch = useDispatch();

    const billCodeRedux = statisticReducer.fieldsFilter.billCode;
    const facepayNumberRedux = statisticReducer.fieldsFilter.facepayNumber;
    const dataReduxDropdown = dataHeader.find(item => item.type === statisticReducer.fieldsFilter.compareAmount);

    const [valueBillCode, setValueBillCode] = useState(billCodeRedux ? `#${billCodeRedux}` : '');
    const [valueFacepayNumber, setValueFacepayNumber] = useState(facepayNumberRedux ? `#${facepayNumberRedux}` : '');
    // const [nameDropdown, setNameDropdown] = useState(dataReduxDropdown.name ? dataReduxDropdown.name : dataHeader[0].name);
    const [dataDropDown, setDataDropdown] = useState(dataReduxDropdown ? dataReduxDropdown : dataHeader[0]);

    const debouncedValueBillCode = useDebounce(valueBillCode, timeDebounce);
    const debouncedValueFacepayNumber = useDebounce(valueFacepayNumber, timeDebounce);

    const _onChangeBillCode = e => {
        e.preventDefault();
        const { value } = e.target;

        // Check ki tu dac biet
        // let regSpecialString = /[!@$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]/;
        // let checkString = regSpecialString.test(value);
        // if (checkString) return;

        // Check khoang trang
        const checkWhiteSpace = value.indexOf(" ") >= 0 ? true : false;
        if (checkWhiteSpace) return;

        // Check remove ki tu tieng Viet
        const newValue = RemoveAccents(value);

        // Check khong nhap duoc ki tu "#" 2 lan
        // if (newValue.length >= 2) {
        //     let regSpecialString = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]/;
        //     const checkValue = newValue;
        //     let checkString = regSpecialString.test(clearFirstString(checkValue, "#"));
        //     if (checkString) return;
        // }

        // Check ki tu "#" khi setValue
        if (newValue.charAt(0) === "#")
            setValueBillCode(newValue)
        else {
            if (value === "")
                setValueBillCode("")
            else
                setValueBillCode(`#${newValue}`)
        }
    }

    const _onChangeFacepayNumber = e => {
        e.preventDefault();
        const { value } = e.target;

        // Check ki tu dac biet
        // let regSpecialString = /[!@$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]/;
        // let checkString = regSpecialString.test(value);
        // if (checkString) return;

        // Check khoang trang
        const checkWhiteSpace = value.indexOf(" ") >= 0 ? true : false;
        if (checkWhiteSpace) return;

        // Check remove ki tu tieng Viet
        const newValue = RemoveAccents(value);

        // Check khong nhap duoc ki tu "#" 2 lan
        // if (newValue.length >= 2) {
        //     let regSpecialString = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]/;
        //     const checkValue = newValue;
        //     let checkString = regSpecialString.test(clearFirstString(checkValue, "#"));
        //     if (checkString) return;
        // }

        // Check ki tu "#" khi setValue
        if (newValue.charAt(0) === "#")
            setValueFacepayNumber(newValue)
        else {
            if (value === "")
                setValueFacepayNumber("")
            else
                setValueFacepayNumber(`#${newValue}`)
        }
    }


    const _onKeyDownValue = (e, type) => {
        if (e.keyCode === 8) {
            switch (type) {
                case "valueBillCode":
                    if ((valueBillCode.length === 2 || valueBillCode.length === 1) && valueBillCode.charAt(0) === "#")
                        setValueBillCode("")
                    break;

                case "valueFacepayNumber":
                    if ((valueFacepayNumber.length === 2 || valueFacepayNumber.length === 1) && valueFacepayNumber.charAt(0) === "#")
                        setValueFacepayNumber("")
                    break;

                default:
                    break;
            }
        }
    }

    // const _handleChoiceItemDropdown = dataDropDown => {
    //     if (dataDropDown) {
    //         setNameDropdown(dataDropDown.name);
    //         setDataDropdown(dataDropDown);
    //         const compareAmount = dataDropDown.type;
    //         dispatch(changeFieldsFilter(
    //             clearFirstString(valueBillCode, "#"), clearFirstString(valueFacepayNumber, "#"), compareAmount, 1
    //         ))
    //     }
    // }

    const callDebouncValueBillCode = useCallback(() => {
        if (!shopReducer.data) return
        if (debouncedValueBillCode === "#") return;
        const compareAmount = dataDropDown.type;
        dispatch(changeFieldsFilter(
            clearFirstString(debouncedValueBillCode, "#"), clearFirstString(valueFacepayNumber, "#"), compareAmount, 1
        ))

    }, [debouncedValueBillCode, shopReducer.data])

    useEffect(() => {
        callDebouncValueBillCode()
    }, [callDebouncValueBillCode])

    const callDebouncValueFacepayNumber = useCallback(() => {
        if (!shopReducer.data) return
        if (debouncedValueFacepayNumber === "#") return;
        const compareAmount = dataDropDown.type;
        dispatch(changeFieldsFilter(
            clearFirstString(valueBillCode, "#"), clearFirstString(debouncedValueFacepayNumber, "#"), compareAmount, 1
        ))
    }, [debouncedValueFacepayNumber, shopReducer.data])

    useEffect(() => {
        callDebouncValueFacepayNumber()
    }, [callDebouncValueFacepayNumber])


    const _exportFileExcel = () => {
        if (systemReducer.status === WEB_IS_OFFLINE) {
            dispatch(pushError({
                type: GET_EXPORT_EXCEL_ERROR,
                error: { errorType: "ERROR_REQUEST" }
            }))
            return;
        }
        // const { timeFilter, fieldsFilter } = statisticReducer;
        // const FromDate = timeFilter.timeBefore.toISOString();
        // const ToDate = timeFilter.timeAfter.toISOString();
        // const BillNumber = fieldsFilter.billCode;
        // const FacepayNumber = fieldsFilter.facepayNumber;

        let listShopID = [];
        // if (userDataLocal.Role === STORE_MANAGER) {
        if ([WUM, STORE_MANAGER].includes(userDataLocal.Role)) {
            listShopID = [shopReducer.data.Shops[0].ID]
        } else if (userDataLocal.Role === CHAIN_MANAGER) {
            listShopID = statisticReducer.shopFilter.listShopID 
                ? statisticReducer.shopFilter.listShopID 
                : shopReducer.data.Shops.map(item => item.ID)
        }

        // let href = `${host}trans/filterExport?FromDate=${FromDate}&ToDate=${ToDate}&BillNumber=${BillNumber}&FacepayNumber=${FacepayNumber}&Shops=${listShopID}`
        let href = `${host}trans/filterExport?Shops=${listShopID}`
        return window.location.href = href
    }


    return (
        <Bound heightListDropdown={dataHeader.length > 4 ? "144px" : "auto"}>
            <p className="title-history">
                Lịch sử thanh toán
            </p>
            <div className="content-filter">
                <p className="label-input">Mã đơn hàng</p>
                <InputTextHistoryControl
                    extendStyles={styleInput}
                    value={valueBillCode}
                    placeholder="#"
                    onChange={_onChangeBillCode}
                    type="text"
                    onKeyDown={(e) => _onKeyDownValue(e, "valueBillCode")}
                />
                <p className="label-input">Mã giao dịch</p>
                <InputTextHistoryControl
                    extendStyles={styleInput}
                    value={valueFacepayNumber}
                    placeholder="#"
                    onChange={_onChangeFacepayNumber}
                    type="text"
                    onKeyDown={(e) => _onKeyDownValue(e, "valueFacepayNumber")}
                />

                <a className="ic-download" href="#javascript" onClick={() => _exportFileExcel()}>
                    <div data-toggle="tooltip" title="Export">
                        <IcDownLoad />
                    </div>
                </a>
            </div>
        </Bound >
    );
}

export default React.memo(HeaderHistory);
