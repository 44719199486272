import styled, { css } from "styled-components";

const Bound = styled.div`
    padding: 20px 20px 20px 25px;
    display: grid;
    height: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 33px repeat(5, 73px) 1fr 1fr 60px;
    grid-gap: 20px;
    .logo-shop{
        grid-row: 1 / span 3;
        padding-left: 10px;
    }
    .empty{
        background-color: unset;
        grid-column: 2 / span 1;
        grid-row: 1 / span 1;
    }
    .shop-address{
        grid-column: 1 / span 2;
    }

    .errors {
        grid-column: 1 / span 2;
        color: #F03B3B;
        font-size: 12px;
        line-height: 170%;
    }

    .terms{
        grid-column: 1 / span 2;
        padding: 20px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        .input-wrapper{
            display: flex;
            align-items: center;            
            justify-content: center;
            width: 24px;
            height: 24px;          
            input[type=checkbox] {
                position: relative;
                cursor: pointer;
                width: 100%;
                height: 100%;
            }
            input[type=checkbox]:before {
                content: "";
                display: block;
                position: absolute;
                width: 24px;
                height: 24px;
                top: 0;
                left: 0;
                /* border: 1px solid #C6D5EB; */
                box-sizing: border-box;
                border-radius: 3px;
                /* background-color: #FFFFFF; */
            }
            input[type=checkbox]:checked:before {
                content: "";
                display: block;
                position: absolute;
                width: 24px;
                height: 24px;
                top: 0;
                left: 0;                
                background-color:#1E80EF;
            }
            input[type=checkbox]:checked:after {
                content: "";
                display: block;
                width: 6px;
                height: 10px;
                border: 1px solid #FFFFFF;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                position: absolute;
                top: 5px;
                left: 9px;
            }
        }
        .terms-and-condition{
            font-family: 'UTM-Avo';
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 150%;
            color: #212121;
            margin: 0 0 0 10px;
            a{
                color:#1279DA;
                margin: 0 5px;
                cursor: pointer;
            }
        }
    }
    .button-container{        
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .btn-reg.disabled {
            opacity: 0.3;
        }
    }
`
const styleDropdownParent = css`    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title{
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 128%;
        color: #959595;
        margin: 0 0 0 10px;
    }
    .content{
        width: 307px;
        height: 48px;
        background: #FFFFFF;
        border: 1px solid #C6D5EB;
        box-sizing: border-box;
        border-radius: 3px;
        position: relative;
        .default-value{
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            padding: 0 10px;
            font-weight: normal;
            font-size: 13px;
            color: #000000;        
        }
        .icon{
            position: absolute;
            top: 55%;
            right: 0;
            transform:translate(-10px, -55%);
        }
    }

`
const stylesListDropdown = css`    
    &.listDropdown{
        width: 305px;
        height: max-content;
        position: absolute;
        right: 0;
        top: 50px;
        background-color: #FFFFFF;
        box-sizing: border-box;
        z-index: 10;
        display: flex;
        flex-direction: column;
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        &::-webkit-scrollbar {
            width: 9px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #E2E2E2;
            background-clip: padding-box;
            border-radius: 20px;
            border: 3px solid white;
        }
        .itemDropdown{
            width: 100%;
            height: 36px;
            display: flex;
            flex-direction:column;
            justify-content: center;
            padding: 0 20px 0 18px;
            transition: .1s all ease-in-out;
            font-family: 'UTM-Avo';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            letter-spacing: 0.05px;
            color: #222222;;
            &:hover{
                background-color: #f1f5fa;
            }
            &.active{
                color: #212121;
            }
        }

    }


`
const styleButtonCancel = css`
    width: 256px;
    height: 60px;
    border: 1px solid #1279DA;
    box-sizing: border-box;
    border-radius: 50px;
    background: #FFFFFF;
    font-family: 'UTM-Avo';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.05px;
    color: #1279DA;
    text-align: center;
`
const styleButtonRegister = css`
    width: 256px;
    height: 60px;
    border: 1px solid #1279DA;
    box-sizing: border-box;
    border-radius: 50px;
    background: #1279DA;
    font-family: 'UTM-Avo';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.05px;
    color: #FFFFFF;
    text-align: center;

`

export { Bound, styleButtonCancel, styleButtonRegister, styleDropdownParent, stylesListDropdown }