import styled from "styled-components";

const Bound = styled.div`
    font-family: UTM-Avo;
    font-style: normal;
    width: 100%;
    height: 100%;
    ${props => props.BoundStyles}
`;

const TooltipDefault = styled.div`
    border-radius: 5px;
    background: #FFFFFF;
    width: fit-content;
    height: auto;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
    background-color: black;
    color: #fff;
    position: absolute;
    ${props => props.positionShowTooltip}
`

const TooltipBankCode = styled.div`
    border-radius: 10px;
    background: #FFFFFF;
    width: 131px;
    height: 55px;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.2);
    img{
        width: 100%;
        height: 100%;
        object-fit: content;
    }
    position: absolute;
    ${props => props.positionShowTooltip}
`

export { Bound, TooltipDefault, TooltipBankCode }