import React from 'react';
import { Link } from "react-router-dom";
//image
import logo_weedigital from '../../images/logo_weedigital.svg'
//mockData
import { footerData } from '../../data/footerData'
//styles
import { Bound } from './index.styles'
//component
import IcLogoWeeDigital from '../../images/component/IcLogoWeeDigital';


const Footer = (props) => {
    let footerMenu = footerData

    const showPopup = () => {

    }
    const renderMenu = () => {
        return footerMenu.map((data, i) => {
            return <Link key={i} to={data.path} onClick={() => showPopup()} >{data.value}</Link>
        })
    }

    return (
        <Bound>
            <div id='menu-list'>
                {renderMenu()}
            </div>
            <div id='wee-product'>
                <p>Một sản phẩm của </p>
                {/* <img src={logo_weedigital} alt='logo weedigital'></img> */}
                <IcLogoWeeDigital />
            </div>
        </Bound>
    );
}

export default Footer;
