import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import QRCode from "qrcode.react";

// key
import { FACEPAY_USER_DATA } from '../../../../constant/key';

// styles
import { Bound, StyleShowZoomQRCode } from "./index.styles"

// images
import ic_zoom from "../../../../images/ic_zoom.svg"
import ic_close from "../../../../images/ic_close.svg"

// constant
import { REGISTER_POS, WEB_IS_ONLINE, WEB_IS_OFFLINE, REGISTER_POS_START, POS_PAGE_ADD_NEW_OFFLINE } from '../../../../constant/typeAction';

// components
import ModalControl from '../../../Control/ModalControl';
import IcDeviceAddPos from '../../../../images/component/IcDeviceAddPos';
import IcQrFail from '../../../../images/component/IcQrFail';
import IcReloadQrCode from '../../../../images/component/IcReloadQrCode';

// actions
import { register, testSocketAction, getFilterPOS } from '../../../../redux/actions/posActions';
import { logout } from '../../../../redux/actions/userAction';
import IcAddDevice from '../../../../images/component/IcAddDevice';


let variableTimeout = null;
// const heightTableTitle = 48;
// const heightTableItem = 54;
// const heightPagingControl = 76;

const POSPageAddNew = () => {
    // const start = new Date();

    const userDataLocal = JSON.parse(localStorage.getItem(FACEPAY_USER_DATA));

    const [dataRegisterPOS, setDataRegisterPOS] = useState(null);
    const [keyGenQR, setKeyGenQR] = useState("");
    const [expiredKeyQR, setExpiredKeyQR] = useState(true);
    const [showZoom, setShowZoom] = useState(false);
    const [countDownTimeExpired, setCountDownTimeExpired] = useState(0)
    const [checkError, setCheckError] = useState(false)
    const isClickOutsiteRef = useRef(false);

    const { posReducer, shopReducer, systemReducer, notifyReducer, pagingReducer } = useSelector(state => ({
        posReducer: state.posReducer,
        shopReducer: state.shopReducer,
        systemReducer: state.systemReducer,
        notifyReducer: state.notifyReducer,
        pagingReducer: state.pagingReducer,
    }));

    const dispatch = useDispatch();
    const history = useHistory();

    const _reloadQrCode = () => {
        if (!userDataLocal || !shopReducer.data) {
            dispatch(logout(history))
        }
        const shopID = shopReducer.data.Shops[0].ID;
        dispatch(register(userDataLocal.UID, shopID))
    }

    useEffect(() => {
        if (!userDataLocal || !shopReducer.data) {
            // dispatch(logout(history))
            return;
        }
        const shopID = shopReducer.data.Shops[0].ID;
        if (userDataLocal && shopID) {
            dispatch(register(userDataLocal.UID, shopID))
            let listShopID = [shopReducer.data.Shops[0].ID];

            dispatch(
                getFilterPOS(
                    pagingReducer.pos_grid.pageSize,
                    1,
                    listShopID,
                    history
                )
            );
        }
        // setTimeout(() => {
        //     dispatch(testSocketAction("POSD"))
        // }, 5000);
    }, [shopReducer.data])

    useEffect(() => {
        if (notifyReducer.listOfNotify.length > 0) {
            setCheckError(true)
        }
        else {
            setCheckError(false)
        }
    }, [notifyReducer])


    useEffect(() => {
        if (posReducer.data && posReducer.type === REGISTER_POS && Object.keys(posReducer.data).length > 0) {
            setKeyGenQR(posReducer.data.ConnectQR);
            setDataRegisterPOS(posReducer.data);
            setExpiredKeyQR(false);
        }
    }, [posReducer.data])

    useEffect(() => {
        if (dataRegisterPOS) {
            setCountDownTimeExpired(dataRegisterPOS.TimeOut * 1000)
        }
        return () => clearTimeout(variableTimeout)

    }, [dataRegisterPOS])

    useEffect(() => {
        return () => clearTimeout(variableTimeout)
    }, [])


    const handleClick = useCallback(() => {
        if (isClickOutsiteRef.current === false)
            setTimeout(() => {
                setShowZoom(false)
            }, 0);
    }, []);

    useEffect(() => {

        if (checkError) return;

        if (showZoom)
            document.addEventListener("click", handleClick, false);
        return () => {
            if (checkError) return;
            document.removeEventListener("click", handleClick, false);
        }
    }, [handleClick, showZoom, checkError]);

    useEffect(() => {
        let intervalExpired = null;
        if (countDownTimeExpired > 0) {
            intervalExpired = setInterval(async () => {
                await setCountDownTimeExpired(second => second - 1000);
            }, 1000);
        } else if (countDownTimeExpired === 0) {
            setExpiredKeyQR(true);
        }
        return () => clearInterval(intervalExpired);
    }, [countDownTimeExpired])


    const auto_HotReload_Offline_To_Online = useCallback(
        () => {
            const { status, countSwitch } = systemReducer;

            if (status === WEB_IS_OFFLINE) {
                dispatch({
                    type: POS_PAGE_ADD_NEW_OFFLINE
                })
                return;
            }

            if ((status === WEB_IS_ONLINE && countSwitch > 0 && posReducer.type === POS_PAGE_ADD_NEW_OFFLINE)) {
                if (posReducer.type === REGISTER_POS_START) return
                const shopID = shopReducer.data.Shops[0].ID;
                if (userDataLocal && shopID) {
                    dispatch(register(userDataLocal.UID, shopID))
                }
            }
        },
        [systemReducer],
    )
    useEffect(() => {
        auto_HotReload_Offline_To_Online()
    }, [auto_HotReload_Offline_To_Online])


    return (
        <Bound>
            <div className="device">
                {/* <IcDeviceAddPos /> */}
                <IcAddDevice />
            </div>

            <div className="wrap-qrcode">
                <div className="qr-container">
                    {
                        expiredKeyQR ?
                            <div className="qr-container__expired">
                                {/* <img src={icon_qr_fail} alt="fail-qr-code" /> */}
                                <IcQrFail />
                                <p className="title">Mã đã hết hạn</p>
                                <p className="reload" onClick={_reloadQrCode}>
                                    {/* <img src={reload_qrcode} alt="reload" /> */}
                                    <IcReloadQrCode />
                                    &nbsp; Tải lại
                                </p>
                            </div>
                            :
                            <React.Fragment>
                                <QRCode
                                    value={keyGenQR}
                                    id="qr-code-id"
                                    className="qr-container__block-qr"
                                    renderAs="svg"
                                    size={288}
                                    level="Q"
                                    onClick={() => setShowZoom(true)}
                                />
                                <div className="qr-container__zoom-qr">
                                    <p
                                        onClick={() => setShowZoom(true)}>
                                        <img src={ic_zoom} alt="ic_zoom" />
                                            &nbsp; Phóng lớn
                                    </p>
                                </div>
                            </React.Fragment>
                    }
                </div>
                <p className="title">Quét mã để kết nối thiết bị</p>
                {
                    !expiredKeyQR &&
                    <div className="countdown-timer first">
                        <p>
                            Mã QR sẽ hết hạn sau:
                            </p>
                        &nbsp;
                            <span>
                            {countDownTimeExpired / 1000}s
                        </span>
                    </div>

                }
            </div>

            {
                showZoom &&
                <ModalControl>
                    <StyleShowZoomQRCode>
                        <div className="ic_close" onClick={() => setShowZoom(false)}>
                            <img src={ic_close} alt="ic_close" />
                        </div>
                        {
                            expiredKeyQR ?
                                <div className="qr-container__expired"
                                    onMouseEnter={() => checkError ? null : isClickOutsiteRef.current = true}
                                    onMouseLeave={() => checkError ? null : isClickOutsiteRef.current = false}
                                >
                                    <div className="background">
                                        {/* <img className="fail" src={icon_qr_fail} alt="fail-qr-code" /> */}
                                        <IcQrFail />
                                        <p className="title">Mã đã hết hạn</p>
                                        <p className="reload" onClick={_reloadQrCode}>
                                            {/* <img src={reload_qrcode} alt="reload" /> */}
                                            <IcReloadQrCode />
                                            &nbsp; Tải lại
                                        </p>
                                    </div>

                                </div>
                                :
                                <QRCode
                                    value={keyGenQR}
                                    id="qr-code-id"
                                    className="qr-container"
                                    renderAs="svg"
                                    size={288}
                                    level="Q"
                                    onMouseEnter={() => isClickOutsiteRef.current = true}
                                    onMouseLeave={() => isClickOutsiteRef.current = false}
                                />
                        }
                        <div className="countdown-timer last">
                            <p>
                                Mã QR sẽ hết hạn sau:
                            </p>
                            &nbsp;
                            <span>
                                {countDownTimeExpired / 1000}s
                            </span>
                        </div>
                    </StyleShowZoomQRCode>
                </ModalControl>
            }
        </Bound>
    )
}


export default React.memo(POSPageAddNew)
