export const HelpsData = [
    {
        id: 0,
        question: 'Facepay là gì ?',
        answer: [
            'Facepay là giải pháp thanh toán cho phép khách hàng của bạn thanh toán hoá đơn ngay tại cửa hàng của bạn dựa trên khuôn mặt của chính họ. Và bất cứ nơi nào khách hàng thấy dấu hiệu nhận diện của Facepay, họ chỉ cần quét khuôn mặt để thanh toán ngay!',
            
        ]
    },
    {
        id: 1,
        question: 'Sử dụng Facepay có an toàn không ?',
        answer: [
            'Facepay sử dụng thuật toán nhận diện khuôn mặt được phát triển độc quyền bởi công ty Wee Digital. Các khoản thanh toán được mã hóa kỹ thuật số, cần được xác thực và sự chấp thuận của khách hàng để hoàn tất. Điều đó có nghĩa là không có giao dịch nào có thể được bắt đầu trừ khi cả bạn và khách hàng đồng ý với giao dịch đó.',
        ]
    },
    {
        id: 2,
        question: 'Làm thế nào để đăng ký sử dụng Facepay ?',
        answer: [
            'B1. Để đăng ký sử dụng Facepay bạn cần các điều kiện sau:',
            '- Là chủ một cửa hàng, cơ sở hoạt động kinh doanh ',
            '- Có tài khoản Wee App và đã kết nối ngân hàng (http://wee.vn)',
            'B2. Truy cập http://pos.facepay.vn sau đó đăng nhập vào hệ thống bằng cách quét mã QR trên ứng dụng Wee.',
            'B3. Bạn cung cấp các thông tin về cửa hàng của mình để tiến hành đăng ký. Chúng tôi sẽ liên hệ với bạn ngay khi nhận được yêu cầu trong thời gian sớm nhất.'
        ]
    },
    {
        id: 3,
        question: 'Hệ thống Facepay hiện tại liên kết những ngân hàng nào ?',
        answer: [
            'Hiện tại Facepay đã kết nối với ABBank.',
            'Chúng tôi luôn nỗ lực mở rộng kết nối với các ngân hàng để hỗ trợ điều kiện tốt nhất trong công việc kinh doanh của bạn!',
        ]
    },
    {
        id: 4,
        question: 'Làm thế nào để kết nối thiết bị POS ?',
        answer: [
            'Để kết nối với thiết bị POS bạn cần thực hiện các bước sau:',
            '+ Sau khi kết nối bạn vào danh sách thiết bị và ấn “thêm”',
            '+ Trên màn hình thêm sẽ có một mã quét QR xuất hiện',
            '+ Mở thiết bị POS lên, bạn cần cấp một số quyền để thiết bị POS hoạt động',
            '+ Ấn “quét thẻ QR” trên máy POS và quét mã trên màn hình Web để 2 thiết bị ghép nối với nhau.',
            '+ Đặt tên thiết bị và định vị thiết bị, ở bước này bạn cần thực hiện giữ 2 thiết bị ghép gần nhau để tránh sai số vị trí và đảm bảo hoạt động của 2 thiết bị.',
            '+ Khi kết nối thành công bạn sẽ thấy thiết bị xuất hiện trên danh sách.',
        ]
    },
    {
        id: 5,
        question: 'Tại sao tôi cần phải định vị thiết bị POS ?',
        answer: [
            'Việc định vị thiết bị là một phần trong quá trình bảo mật giúp chúng tôi kiểm soát giao dịch, qua đó làm giảm thiểu rủi ro thanh toán gian lận xuống mức thấp nhất.',
        ]
    },
    {
        id: 6,
        question: 'Tôi muốn theo dõi toàn bộ giao dịch thanh toán Facepay qua cửa hàng thì làm thế nào ?',
        answer: [
            'Sau khi đăng nhập tài khoản ở giao diện màn hình thống kê, bạn có thể nhìn thấy toàn bộ các thanh toán của cửa hàng trong ngày, bao gồm tổng doanh thu, biểu đồ doanh thu trong tháng, chi tiết từng giao dịch đã thực hiện.',
        ]
    },
    {
        id: 7,
        question: 'Làm thế nào để thêm quảng cáo vào máy POS ?',
        answer: [
            'Với các thiết bị POS đã kết nối thành công bạn có thể thiết lập các banner quảng cáo. Các bước làm như sau:',
            '+ Trên banner bạn chọn “Cài đặt quảng cáo.”',
            '+ Chọn thiết bị mà bạn muốn thao tác.',
            '+ Trong giao diện bạn sẽ thấy phần xem trước các banner, danh sách các banner, thêm mới xóa sửa.',
            '+ Số lượng banner được giới hạn số lượng 5',
            '+ Bạn có thể kéo thả các banner hiện tại để sắp xếp vị trí hiển thị theo mong muốn.',
            '+ Để thêm bạn chọn banner muốn theo theo kích cỡ hoặc tỉ lệ yêu cầu của hệ thống, sau đó nhập các thông tin yêu cầu và ấn “thêm”.',
        ]
    },
    

]