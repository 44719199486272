import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

// component
import DragAndDrop from '../DragAndDrop'

// img
import IcUpload from "../../../images/component/IcUpload"
import IcDelRedBgPink from "../../../images/component/IcDelRedBgPink"

// styles
import { DropArea, WrapImg } from './index.styles'

// action
import { pushError, pushWarning } from '../../../redux/actions/notifyAction'
// import { pushError, pushWarning } from '../../../redux/notify/notifyAction'

const DropOrChooseLogo = ({ file, handleDropFiles, handleReturnError }) => {

    const dispatch = useDispatch();
    const inputRef = useRef(null);

    const [isErrorImg, setIsErrorImg] = useState(false)

    useEffect(() => {
        const inputFile = inputRef.current = document.createElement('input');
        inputFile.type = 'file';
        inputFile.onchange = (e) => { 
            handleCheckMatchImg(e.target.files) 
        }
    }, [])

    const handleChooseFile = () => {
        if (!inputRef) {
            return;
        }

        inputRef.current.click()
    }

    const checkMatchSize = (file) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = function() {
                const isMatchRatio = this.width === this.height;
                resolve(isMatchRatio)
            }

            const reader = new FileReader();
            reader.onload = function() {
                img.src = reader.result;
            }
            reader.readAsDataURL(file);
        })
    }

    const handleCheckMatchImg = async (files) => {
        if(files.length !== 1){
            // dispatch(pushError({
            //     type: 'ERROR_LOAD_IMAGE',
            //     error: 'Vui lòng chọn 1 file ảnh có định dạng png hoặc jpg'
            // }))
            setIsErrorImg(true)
            handleReturnError('Loại file logo thương hiệu không được hỗ trợ!')
            inputRef.current.value = '';
            return
        }

        const file = files[0]
        const { type } = file
        const [typeFile, subTypeFile] = type.split("/")
        const arrayTypeMatch = ["image"]
        const arraySubTypeMatch = ["png", "jpg", "jpeg"]
        if (!arrayTypeMatch.includes(typeFile) || !arraySubTypeMatch.includes(subTypeFile)){
            // dispatch(pushError({
            //     type: 'ERROR_LOAD_IMAGE',
            //     error: 'Vui lòng chọn 1 file ảnh có định dạng png hoặc jpg'
            // }))
            setIsErrorImg(true)
            handleReturnError('Loại file logo thương hiệu không được hỗ trợ!')
            inputRef.current.value = '';
            return;
        }

        // const isMatchSize = await checkMatchSize(file)
        // if(!isMatchSize){
        //     dispatch(pushWarning({
        //         type: 'WARNING_LOAD_IMAGE',
        //         data: 'File không đúng kích thước hoặc tỉ lệ yêu cầu.'
        //     }))
        //     // return;
        // }
        setIsErrorImg(false)
        handleDropFiles(files)
        inputRef.current.value = '';
    }

    return ( 
        file?
            <WrapImg>
                <IcDelRedBgPink 
                    className="delete-img" 
                    onClick={() => handleDropFiles("")}
                />
                <img src={file} alt="logo" />
            </WrapImg>
            :
            <DragAndDrop handleDropFiles={handleCheckMatchImg}>
                <DropArea isError={isErrorImg}>
                    <IcUpload />
                    <div className="drag-logo">Kéo thả logo</div>
                    <div className="choose-local">hoặc <span onClick={handleChooseFile}>chọn từ máy tính</span> loại tệp tin png, jpg</div>
                    <div className="note">
                        <div>(*) Tỉ lệ hình ảnh khuyến khích 1:1</div>
                        <div>Ví dụ: kích thước logo 300x300 pixels</div>
                    </div>
                </DropArea>
            </DragAndDrop>
    )
}

export default React.memo(DropOrChooseLogo)
