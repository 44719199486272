import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//styles
import { Bound } from "./index.styles";

//component
import StatisticFilter from "./StatisticFilter";
import Merchant from "./Merchant";
import Chart from "./Chart/Chart";
import History from "./History";
import { changeFieldsFilter } from "../../../redux/actions/statisticAction";

let bound = null;
let header = null;

const StatisticPage = () => {
    const { statisticReducer } = useSelector((state) => ({
        statisticReducer: state.statisticReducer,
    }));

    const dispatch = useDispatch();
    useEffect(() => {
        bound = document.getElementById("history-bound-id");
        // header = document.getElementById('header-id')
        setTimeout(() => {
            let bound = document.getElementById("statistic-container-id");
            if (bound) {
                bound.style.height = "100%";
            }
        }, 200);
        const {fieldsFilter}=statisticReducer
                return () => {
                    const billNumber = fieldsFilter.billCode;
                    const facepayNumber = fieldsFilter.facepayNumber;
                    const compareAmount = fieldsFilter.compareAmount;
                    dispatch(changeFieldsFilter(billNumber, facepayNumber, compareAmount, 1, 0));
                }
    }, []);

    // const onScrollBound = () => {
    //     if(!bound || !header) return;
    //     let scrollTop = bound.scrollTop
    //     if(bound.scrollTop > 100) scrollTop = 100
    //     let delta = scrollTop / 100
    //     header.style.borderColor = `rgba(198, 213, 235, ${delta})`
    // }
    return (
        <Bound
            id="history-bound-id"
        // onScroll={()=>onScrollBound()}
        >
            <div className="statistic-container" id="statistic-container-id">
                <StatisticFilter />
                <Merchant />
                <Chart />
                <History />
            </div>
        </Bound>
    );
};

export default StatisticPage;
