import styled from 'styled-components'

import ic_arrow_down from "../../images/ic_arrow_down.svg";

const Bound = styled.div`
    display: flex;
    grid-column: 2 / span 1;
    width: 1110px;
    height: 128px;
    /* border-bottom: 1px solid rgba(198, 213, 235, 0); */
    transition: border-bottom-width 400ms;
    position: relative;
    ::after,::before{
        content: '';
        width: 20px;
        height: 20px;
        bottom: -20px;
        position: absolute;
        background-color: transparent;
        box-sizing: content-box;
        z-index:1;
    }
    ::before{
        left: 0;
        border-top-left-radius: 20px;
        box-shadow: -5px -5px 0 5px #fafafa;
    }
    ::after{
        right: 0;
        border-top-right-radius: 20px;
        box-shadow: 5px -5px 0 5px #fafafa;
    }
    /* &.sticky {
        position: fixed;
        width: 1110px;
        background-color: #ffffff;
        top: 0;

        & + div {
            margin-top: 100px;
        }
    } */
    
    img.logo-facepay {
        height: 48px;
        margin-top: 45px;
        :hover {
            cursor: pointer;
        }
    }

    .menu {
        display: flex;
        margin-left: 29px;
        padding-bottom: 7px;
        flex: 1;
        height: fit-content;
        /* align-self:center; */
        margin-top: 45px;
        align-items:center;
        overflow:hidden;
        & > * {
            width: fit-content;
            margin-left: 34px;
            text-decoration: none;
            font-family: "UTM-Avo";
            font-weight: normal;
            font-size: 14px;
            line-height: 150%;
            color: #051321;
            position: relative;
            user-select: none;
            /* transition-property: color, font-size;
            transition-duration: 400ms; */
        }

        /* a:first-child {
            width: fit-content;
        } */
        
        a::before {
            content: '';
            position: absolute;
            width: 30px;
            height: 3px;
            top: calc(100% + 10px);
            background-color: #1279da;
            border-radius: 4px 4px 0px 0px;
            transition: top linear 0.2s;
        }

        a.active {
            color: #1279da;
            font-weight: bold;
            font-size: 15px;
        }

        a.active::before {
            top: calc(100% + 3px);
        }
    }

    .user-login {
        display: flex;
        flex-direction: row;
        /* align-items: center; */
        margin-top: 45px;
        .block-info-user {
            .shop-address, .shop-phone {
                display: flex;
                align-items: center;
                margin: 0 0 6px 0;
                justify-content: flex-end;
                svg{
                    width: 14px;
                    height: 14px;
                    object-fit:contain;
                    margin: 0 0 0 12px;
                }
                p{
                    font-family: 'UTM-Avo';
                    font-style: normal;
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 170%;
                    color: #051321;
                    overflow: hidden;
                    width: 400px;
                    text-align: end;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                }
            }
        }
        .block-logout {
            display: flex;
            /* align-items: center; */
            justify-content: center;
            /* margin-top: 40px; */
            cursor: pointer;
            margin: 0 0 0 38px;
            font-family: 'UTM-Avo';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            color: #212121;
            svg {
                margin: 5px 0 0 0;
            }
            z-index: 100;
        }

        /* p {
            font-family: "UTM-Avo";
            font-size: 14px;
            line-height: 170%;
            text-transform: capitalize;
            color: #051321;
        }
        .ic-user-login {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 2px solid #ffffff;
            box-sizing: border-box;
            margin-left: 16px;
            object-fit: cover;
        }
        .logout-block {
            width: 12px;
            height: 12px;
            position: relative;
            box-sizing: content-box;
            margin: 0 10px;
            background-image: url("${ic_arrow_down}");
            background-size: 100% 100%;
            cursor: pointer;
        } */
    }
`

export {
    Bound
}