import Axios from "axios"

import * as types from '../../constant/typeAction';

import { host } from '../../host/host'

import setAuth from '../../helper/setAuth';
import * as keys from '../../constant/key'

// action
import { pushError } from "./notifyAction";

// tools
import getError from "../../helper/getErrorServices";

const apiCheckStatus = `${host}web-mpos/checkStatus?id=`
const apiGetReasonLock = `${host}backoffice/get-reasons`

export const checkStatus = (history) => async (dispatch, getState) => {
    const store = getState();
    const { UID } = store?.userReducer?.loginResult
    if(!UID) return;
    try {
        const res = await Axios.get(apiCheckStatus + UID)
        const { data } = res;
        // console.log(data)
        const isSuccess = data.Code === 0;
        if(isSuccess){
            // if(data.IsLock){
                // history.push('/main/blocked-account')
                // dispatch(pushError({
                //     type: 'ACCOUNT_WAS_BLOCKED',
                //     error: {
                //         data: {
                            
                //         }
                //     }
                // }))
                dispatch({
                    type: types.ACCOUNT_BLOCKED,
                    payload: {
                        isBlocked: data.IsLock,
                        reasonBlocked: {
                            lockReasonID: data.LockReasonID,
                            lockedReason: data.LockedReason
                        }
                    }
                })
                return
            // }
            // history.push('/main/statistic')
        }
    }
    catch(err){
        console.dir(err)
    }
}

export const getReasonsLock = () => async dispatch => {
    const dataRequest = JSON.stringify({
        Type: 'LOCK'
    })

    try {
        const res = await Axios.post(apiGetReasonLock, dataRequest)
        const { data } = res;
        const isSuccess = data.Code === 0;
        if(isSuccess){
            const listReasons = data.Reasons || []
            dispatch({
                type: types.GET_LIST_REASON_LOCK,
                payload: {
                    listReasons
                }
            })
        }
        else{
            // console.dir(data)
            dispatch(pushError({
                type: types.GET_LIST_REASON_LOCK_ERR,
                error: data
            }))
        }
    }
    catch(err){
        // console.dir(err)
        dispatch(pushError({
            type: types.GET_LIST_REASON_LOCK_ERR,
            error: getError(err)
        }))
    }
}

export const login = (email, password) => async dispatch => {
    const queryDataFormat = JSON.stringify({
        Email:      email,
        Pwd:        password
    })
    const hostLogin = host + 'web/login'
    try {
        const res = await Axios.post(hostLogin, queryDataFormat)
        console.log(res);
        const {data} = res;
        if(data && data.Code === 0){
            setAuth(data)
            let dataLogin = { 
                UID: data.UID, 
                LinkedStore: data.LinkedStore, 
                LinkPos: data.LinkPos,
                Role: data.Role,
                FullName: 'Family Mart',
            };
            localStorage.setItem(keys.FACEPAY_USER_DATA, JSON.stringify(dataLogin))
            dispatch(dispatchLogin(data))
        }
        else{
            dispatch({
                type: types.LOGIN_USER_ERROR,
                err: ''
            })
            // dispatch(pushError({
            //     type: types.LOGIN_USER_ERROR,
            //     error: data
            // }))
        }
    }
    catch(err){
        // debugger;
        
        dispatch(pushError({
            type: types.LOGIN_USER_ERROR,
            error: getError(err)
        }))
        dispatch({
            type: types.LOGIN_USER_ERROR,
            err
        })
    }
}

export const login_mPOS = (clientID, clientSecret) => async dispatch => {
    const queryDataFormat = JSON.stringify({
        ClientID:       clientID,
        ClientSecret:   clientSecret
    })
    const hostLogin = `${host}web-mpos/login`
    try {
        const res = await Axios.post(hostLogin, queryDataFormat)
        console.log(res);
        const {data} = res;
        if(data && data.Code === 0){
            setAuth(data)
            let dataLogin = { 
                UID: data.UID, 
                LinkedStore: data.LinkedStore, 
                LinkPos: data.LinkPos,
                Role: data.Role,
                FullName: data.FullName || 'Family Mart',
                IdentityNumber: data.IdentityNumber || '',
                PhoneNumber: data.PhoneNumber || ''
            };
            localStorage.setItem(keys.FACEPAY_USER_DATA, JSON.stringify(dataLogin))
            dispatch(dispatchLogin(data))
        }
        else{
            // dispatch({
            //     type: types.LOGIN_USER_ERROR,
            //     err: ''
            // })
            dispatch(pushError({
                type: types.LOGIN_USER_ERROR,
                error: data
            }))
        }
    }
    catch(err){
        dispatch(pushError({
            type: types.LOGIN_USER_ERROR,
            error: getError(err)
        }))
        dispatch({
            type: types.LOGIN_USER_ERROR,
            err
        })
    }
}

export const dispatchLogin = (data) => ({
    type: types.LOGIN_USER,
    data: data
})

export const logout = (history) => async dispatch => {
    dispatch({
        type: types.LOGOUT_USER,
        data: {Code:0}
    })
    localStorage.removeItem(keys.FACEPAY_USER_DATA)
    localStorage.removeItem(keys.FACEPAY_DISPLAY_CONFIG)
    history.replace('/login')
}

export const getUser = (uID, isStore) => async dispatch => {
    const queryDataFormat = JSON.stringify({
        UID:   uID,
    })
    const hostGetUSer = host + 'user/get'
    try {
        const res = await Axios.post(hostGetUSer, queryDataFormat)
        const {data} = res;
        if(data && data.Code === 0){
            if(isStore){
                let dataLogin = { 
                    UID: data.UID, 
                    LinkedStore: data.LinkedStore, 
                    LinkPos: data.LinkPos,
                    FullName: 'Family Mart'
                };
                localStorage.setItem(keys.FACEPAY_USER_DATA, JSON.stringify(dataLogin))
            }
            data.type=types.GET_USER
            dispatch({
                type: types.GET_USER,
                data: data
            })
        }
        else{
            dispatch({
                type: types.GET_USER_ERROR,
                err: ''
            })
        }
    }
    catch(err){
        dispatch({
            type: types.GET_USER_ERROR,
            err
        })
    }
}

export const generate_mPOS_qr = () => async dispatch => {
    const hostLogin = host + 'generate-mpos-qr'
    try {
        const res = await Axios.get(hostLogin)
        console.log(res);
        const {data} = res;
        if(data && data.Code === 0){
            dispatch({
                type: types.GENERATE_MPOS_QR,
                data: data
            })
        }
        else{
            dispatch({
                type: types.GENERATE_MPOS_QR_ERROR,
                err: ''
            })
        }
    }
    catch(err){
        // debugger;
        dispatch(pushError({
            type: types.GENERATE_MPOS_QR_ERROR,
            error: getError(err)
        }))
        dispatch({
            type: types.GENERATE_MPOS_QR_ERROR,
            err
        })
    }
}

export const loginNULL = () => async dispatch => {
    dispatch({
        type: types.LOGIN_USER_NULL,
    })
}

export const getUserNULL = () => async dispatch => {
    dispatch({
        type: types.GET_USER_NULL,
    })
}