import * as types from "../../constant/typeAction";
import moment from "moment";
import { TYPEDATE } from "../../constant/key";
export const initialState = {
    fieldsFilter: {
        billCode: "",
        facepayNumber: "",
        compareAmount: 0,
        pageNum: 1,
        countChange: 0
    },
    timeFilter: {
        timeBefore: moment().startOf("month"),
        timeAfter: moment().endOf("day")
    },
    shopFilter: {
        dataDropdown: null,
        listShopID: null
    },
    dataSummary: null,
    dataTotal: null,
    dataFilter: null,
    type: ""
};

export default function statisticReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_STATISTIC_TRANSACTION_TOTAL_START:
        case types.GET_STATISTIC_TRANSACTION_SUMMARY_START:
        case types.GET_STATISTIC_TRANSACTION_FILTER_START:
            return {
                ...state,
                type: action.type
            };
        case types.GET_STATISTIC_TRANSACTION_SUMMARY_SUCCESS:
            return {
                ...state,
                dataSummary: {
                    ...action.data,
                    [TYPEDATE.DAY]: action.data.Day,
                    [TYPEDATE.MONTH]: action.data.Month,
                    [TYPEDATE.QUARTER]: action.data.Quarter
                },
                type: action.type
            };
        case types.GET_STATISTIC_TRANSACTION_TOTAL_SUCCESS:
            return {
                ...state,
                dataTotal: action.data,
                type: action.type
            };
        case types.GET_STATISTIC_TRANSACTION_FILTER_SUCCESS:
            return {
                ...state,
                dataFilter: action.data,
                type: action.type
            };
        case types.GET_STATISTIC_TRANSACTION_FILTER_ERROR:
        case types.GET_STATISTIC_TRANSACTION_SUMMARY_ERROR:
        case types.GET_STATISTIC_TRANSACTION_TOTAL_ERROR:
            return {
                ...state,
                err: action.err,
                type: action.type
            };
        case types.CHANGE_TIME_FILTER:
            return {
                ...state,
                timeFilter: {
                    ...state.timeFilter,
                    timeBefore: action.data.timeBefore,
                    timeAfter: action.data.timeAfter
                }
            };

        case types.CHANGE_FIELDS_FILTER:
            return {
                ...state,
                fieldsFilter: {
                    ...state.localFilter,
                    billCode: action.data.billCode,
                    facepayNumber: action.data.facepayNumber,
                    compareAmount: action.data.compareAmount,
                    pageNum: action.data.pageNum,
                    countChange: action.data.countChange >= 0 ? action.data.countChange : state.fieldsFilter.countChange + 1,
                },
            }

        case types.RELOAD_GET_ALL_FILTER:
        case types.STATISTIC_PAGE_OFFLINE:
            return {
                ...state,
                type: action.type
            }

        case types.DROPDOWN_SHOP_FILTER_STATISTIC:
            return {
                ...state,
                shopFilter: {
                    ...state.shopFilter,
                    dataDropdown: action.dataDropdown ? action.dataDropdown : null,
                    listShopID: action.listShopID ? action.listShopID : null
                },
                type: action.type
            }

        default:
            return state;
    }
}
