import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react'
import { useDispatch } from 'react-redux';
//image
import pos_device_on from '../../../../images/pos_device_on.svg'
import pos_device_off from '../../../../images/pos_device_off.svg'
import ic_location from '../../../../images/ic_location.svg'
import ic_calendar from '../../../../images/ic_calendar.svg'
import ic_last_active from '../../../../images/ic_last_active.svg'
import ic_more_option from '../../../../images/ic_more_option.svg'
//helpers
import * as tools from "../../../../helper/tools";
import * as keys from "../../../../constant/key"
// styles
import { Bound } from "./index.styles"
import MoreOptionPopUp from '../../../Popup/MoreOptionPopUp';
import ModalControl from '../../../Control/ModalControl';
//component
import IcMoreOption from '../../../../images/component/IcMoreOption';
import IcCalenda from '../../../../images/component/IcCalenda';
import IcLastActive from '../../../../images/component/IcLastActive';
import IcPosDeviceOn from '../../../../images/component/IcPosDeviceOn';
import IcPosDeviceOff from '../../../../images/component/IcPosDeviceOff';
import IcPhoneOn from '../../../../images/component/IcPhoneOn';
import IcPhoneOff from '../../../../images/component/IcPhoneOff';

const extendStylesModal = `
    width: 100%;
    height: 100%;
`;

const POSDeviceItem = (props) => {
    const [isShowMoreOptionPopup, setIsShowMoreOptionPopup] = useState(false);
    const btnMoreRef = useRef(null);

    let userDataLocal = JSON.parse(localStorage.getItem(keys.FACEPAY_USER_DATA));

    const { posData, index } = props


    const dispatch = useDispatch();

    const toggleMoreOption = useCallback(() => {
        setIsShowMoreOptionPopup(!isShowMoreOptionPopup)
    }, [btnMoreRef, isShowMoreOptionPopup])

    const _handleClickMore = (e) => {
        toggleMoreOption()
    }

    const _handleBlurPopup = (e) => {
        // e.preventDefault();
        // e.stopImmediatePropagation();
        toggleMoreOption()
    }

    return (
        <Bound>
            <div className="pos-img-container">
                <div className={"pos-img"}>
                    {
                        posData.IsOnline === true ?
                            <IcPosDeviceOn/>
                            // <IcPhoneOn />
                            :
                            <IcPosDeviceOff/>
                            // <IcPhoneOff />
                    }
                </div>
                <div
                    className={
                        posData.IsOnline === true
                            ? "pos-status status-on"
                            : "pos-status status-off"
                    }
                >
                    <div
                        className={
                            posData.IsOnline === true ? "light on" : "light off"
                        }
                    ></div>
                    <p>{posData.IsOnline === true ? "ONLINE" : "OFFLINE"}</p>
                </div>
            </div>
            <div className="pos-content-container">
                <p className="pos-name" data-toggle="tooltip" title={posData.Name}>{posData.Name}</p>
                {/* <div className="pos-line">
                    <img src={ic_location} alt="location" />
                    <p className="title">Vị trí:</p>
                    <p className="location">
                        {posData.Lat && posData.Lng
                            ? tools.deg_to_dms(posData.Lat, false) +
                            " " +
                            tools.deg_to_dms(posData.Lng, true)
                            : ""}
                    </p>
                </div> */}
                <div className="pos-line">
                    {/* <img src={ic_calendar} alt="calendar" /> */}
                    <IcCalenda className="icon-image" />
                    <p className="title">Ngày thiết lập:</p>
                    <p className="content">
                        {posData.CreateTime &&
                            tools.convertDateTime(
                                posData.CreateTime,
                                "DD/MM/YYYY, HH:mm"
                            )}
                    </p>
                </div>
                <div className="pos-line">
                    {/* <img src={ic_last_active} alt="last active" /> */}
                    <IcLastActive className="icon-image" />
                    <p className="title">Hoạt động gần nhất:</p>
                    <p className="content">
                        {posData.UpdateTime &&
                            tools.convertDateTime(
                                posData.UpdateTime,
                                "DD/MM/YYYY, HH:mm"
                            )}
                    </p>
                </div>
            </div>
            
            
            {
                userDataLocal && 
                // userDataLocal.Role === keys.STORE_MANAGER &&
                [keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role) &&
                    <Fragment>
                    <div
                        className="more-option-button"
                        onClick={_handleClickMore}
                        ref={btnMoreRef}>
                        <IcMoreOption />
                    </div>
                    {
                        isShowMoreOptionPopup &&
                        <ModalControl extendStyles={extendStylesModal}>
                            <MoreOptionPopUp
                                deviceItem={posData}
                                btnMoreRef={btnMoreRef}
                                handleBlur={_handleBlurPopup}
                            />
                        </ModalControl>
                    }
                    </Fragment>
            }

        </Bound>
    )
}

const memoPostDeviceItem = React.memo(POSDeviceItem);
memoPostDeviceItem.displayName = 'memoPostDeviceItem'

export default memoPostDeviceItem;

