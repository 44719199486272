import React from 'react'

const IcSaleManage = (props) => {
    return <svg {...props} width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.51813 23.5349H3.23227C1.44997 23.5349 0 24.9851 0 26.7674V29.121C0 29.6065 0.393448 29.9999 0.878906 29.9999H4.66942C4.57169 29.7248 4.51813 29.4291 4.51813 29.121V23.5349Z" fill="white" />
    <path d="M10.794 18.8281H9.50838C7.72607 18.8281 6.27588 20.2783 6.27588 22.0606V29.1212C6.27588 29.6066 6.66933 30.0001 7.15479 30.0001H10.9453C10.8478 29.725 10.794 29.4292 10.794 29.1212V18.8281Z" fill="white" />
    <path d="M29.8274 8.82843L23.5512 0.355682C23.3852 0.132065 23.1234 0 22.8448 0C22.5663 0 22.3042 0.132065 22.1385 0.355682L15.8626 8.82843C15.665 9.09508 15.6346 9.45053 15.7838 9.74693C15.9333 10.0433 16.2368 10.2306 16.5689 10.2306H18.828V29.1211C18.828 29.6066 19.2214 30 19.7069 30H25.9828C26.4683 30 26.8617 29.6066 26.8617 29.1211V10.2306H29.121C29.4529 10.2306 29.7566 10.0433 29.9059 9.74693C30.0553 9.45053 30.0249 9.09508 29.8274 8.82843Z" fill="white" />
    <path d="M17.0701 14.1211H15.7843C14.002 14.1211 12.5518 15.5711 12.5518 17.3534V29.1211C12.5518 29.6066 12.9454 30 13.4307 30H17.2212C17.1237 29.7249 17.0701 29.4292 17.0701 29.1211V14.1211Z" fill="white" />
  </svg>
}

export default IcSaleManage
