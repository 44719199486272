import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'antd/dist/antd.css'; // antd styles

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import configureStore from './configureStore';
// import axios from "axios";

// constant
import { FACEPAY_USER_DATA } from './constant/key';
import { WEB_IS_ONLINE, WEB_IS_OFFLINE } from './constant/typeAction';

// component
// import DebugRouter from './ui/Control/DebugerRouter';

const facePayLoginData = localStorage.getItem(FACEPAY_USER_DATA);
let initialState = {};
if (facePayLoginData) {
  const loginResult = JSON.parse(facePayLoginData)
  initialState = {
    userReducer: {
      data: [],
      loginResult
    }
  }
}
const store = configureStore(initialState);

window.addEventListener("online", () => {
  if (store.getState().systemReducer.status === WEB_IS_ONLINE) return;
  store.dispatch({
    type: WEB_IS_ONLINE,
    status: WEB_IS_ONLINE
  })
}, false);

window.addEventListener("offline", () => {
  if (store.getState().systemReducer.status === WEB_IS_OFFLINE) return;
  store.dispatch({
    type: WEB_IS_OFFLINE,
    status: WEB_IS_OFFLINE
  })
}, false);

ReactDOM.render(
  <Provider store={store}>
    {/* <DebugRouter> */}
      <BrowserRouter>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </BrowserRouter>
    {/* </DebugRouter> */}
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
