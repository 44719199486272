import React, { useEffect, useState, useCallback } from 'react'

// component
import AlertPopup from '../../../Popup/AlertPopup';
import ErrorConnect from '../ErrorConnect';


const alertPopupInvalidMoneyStyles = `
    margin: 33px auto 0 auto;
    left: 50%;
    transform: translate(-50%, 0);
    width: unset;
    max-width: unset;
`

const ErrorRemoveDeviceFollowActionType = ({ error, closePopup, autoClosePopup }) => {
    const [isAutoClosePopup, setIsAutoClosePopup] = useState(false)
    const [component, setComponent] = useState(null);

    useEffect(() => {
        _handleRender();
    }, [])

    useEffect(() => {
        if (isAutoClosePopup) {
            autoClosePopup(3000);
            setIsAutoClosePopup(false)
        }
    }, [isAutoClosePopup])

    const _handleRenderFollowCode = useCallback(
        () => {
            switch (error.Code) {
                case 2:
                    setIsAutoClosePopup(true)
                    setComponent(
                        <AlertPopup
                            type="ERROR"
                            handleClickBtn={closePopup}
                            extendStyles={alertPopupInvalidMoneyStyles}
                        >
                            <div>Thiết bị không tồn tại!</div>
                        </AlertPopup>
                    )
                    break;
                default:
                    setIsAutoClosePopup(true)
                    setComponent(
                        <AlertPopup
                            type="ERROR"
                            handleClickBtn={closePopup}
                            extendStyles={alertPopupInvalidMoneyStyles}
                        >
                            <div>Lỗi hệ thống. Code {error.Code}</div>
                        </AlertPopup>
                    );
                    break;
            }
        },
        [],
    )

    const _handleRenderFollowTypeError = useCallback(
        () => {
            switch (error.type) {
                default:
                    // eslint-disable-next-line no-undef
                    if(process.env.NODE_ENV === "development")
                        throw Error('Invalid type or type is not defined to use this modal!')
                    else {
                        setComponent(
                            <ErrorConnect closePopup={closePopup}>
                                <div>Không thể kết nối được với hệ thống.</div>
                                <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                            </ErrorConnect>
                        )
                        break;
                    }
            }
        },
        [],
    )

    const _handleRender = useCallback(() => {
        if (error.Code) {
            _handleRenderFollowCode();
            return;
        }
        _handleRenderFollowTypeError()
    }, [])

    return component
}

export default ErrorRemoveDeviceFollowActionType
