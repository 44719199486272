import React from 'react'
import ErrorConnect from '../ErrorConnect';

const ErrorCreateStore = ({ closePopup, autoClosePopup, error }) => {
    const { ErrCode } = error;
    if(ErrCode !== "account.locked") {
        if (process.env.NODE_ENV === "development")
            throw Error("Invalid type to use modal!");
        else {
            return <ErrorConnect closePopup={closePopup}>
                <div>Không thể kết nối được với hệ thống.</div>
                <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>s
            </ErrorConnect>
        }
    }
    return (
        <ErrorConnect closePopup={closePopup}>
            <div>Tài khoản đã bị khóa</div>
        </ErrorConnect>
    )
}

export default ErrorCreateStore
