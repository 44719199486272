import React from 'react'

// component
import AlertPopup from '../../../Popup/AlertPopup'
import ButtonControl from '../../../Control/ButtonControl'

const btnExtendStyles = `
    padding: 6px 34px;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 128.91%;
    border-radius: 6px;
    margin-right: 37px;
`
const alertPopupExtendStyles = `
    width: 540px;
    max-width: 540px;
    background-color: #FFEBEA;
    border: 1px solid #FF3B30;
    margin: 33px auto 0 auto;
    left: 50%;
    transform: translate(-50%, 0);
`

const ErrorConnect = ({ closePopup, children }) => {
    const btn = (
        <ButtonControl 
            type="ERROR" 
            extendStyles={btnExtendStyles}
            handleClick={closePopup}
        >
            OK
        </ButtonControl>
    )

    return (
        <AlertPopup type="ERROR" btn={btn} extendStyles={alertPopupExtendStyles}>
            {children}
        </AlertPopup>
    )
}

const MemoErrorConnect = React.memo(ErrorConnect)
MemoErrorConnect.displayName = 'MemoErrorConnect'

export default MemoErrorConnect
