export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR"
export const LOGIN_USER_NULL = "LOGIN_USER_NULL"

export const ACCOUNT_BLOCKED = "ACCOUNT_BLOCKED"

export const GET_LIST_REASON_LOCK = "GET_LIST_REASON_LOCK"
export const GET_LIST_REASON_LOCK_ERR = "GET_LIST_REASON_LOCK_ERR"

export const GENERATE_MPOS_QR = "GENERATE_MPOS_QR"
export const GENERATE_MPOS_QR_ERROR = "GENERATE_MPOS_QR_ERROR"

export const LOGOUT_USER = "LOGOUT_USER"

export const GET_USER = "GET_USER"
export const GET_USER_ERROR = "GET_USER_ERROR"
export const GET_USER_NULL = "GET_USER_NULL"

export const NEW_SHOP = "NEW_SHOP"
export const NEW_SHOP_ERROR = "NEW_SHOP_ERROR"
export const TEMP_SHOP = "TEMP_SHOP"
export const CLEAR_TEMP_SHOP = "CLEAR_TEMP_SHOP"

export const GET_SHOP_START = "GET_SHOP_START"
export const GET_SHOP_SUCCESS = "GET_SHOP_SUCCESS"
export const GET_SHOP_ERROR = "GET_SHOP_ERROR"

export const GET_FILTER_SHOP_START = "GET_FILTER_SHOP_START"
export const GET_FILTER_SHOP_SUCCESS = "GET_FILTER_SHOP_SUCCESS"
export const GET_FILTER_SHOP_ERROR = "GET_FILTER_SHOP_ERROR"

export const CREATE_STORE = 'CREATE_STORE'
export const CREATE_STORE_ERR = 'CREATE_STORE_ERR'

export const SET_DATA_CREATE_SHOP = 'SET_DATA_CREATE_SHOP'

export const GET_FILTER_POS_START = "GET_FILTER_POS_START"
export const GET_FILTER_POS = "GET_FILTER_POS"
export const GET_FILTER_POS_ERROR = "GET_FILTER_POS_ERROR"

export const REGISTER_POS_START = "REGISTER_POS_START"
export const REGISTER_POS = "REGISTER_POS"
export const REGISTER_POS_ERROR = "REGISTER_POS_ERROR"

export const GET_EXPORT_EXCEL_ERROR = "GET_EXPORT_EXCEL_ERROR"

export const PAYMENT_REQUEST = "PAYMENT_REQUEST"
export const PAYMENT_REQUEST_ERROR = "PAYMENT_REQUEST_ERROR"
export const CLEAR_PAYMENT_REQUEST = "CLEAR_PAYMENT_REQUEST"

export const REMOVE_POS = "REMOVE_POS"
export const REMOVE_POS_ERROR = "REMOVE_POS_ERROR"

export const CHANGE_STATUS_POS = "CHANGE_STATUS_POS"
export const CHANGE_STATUS_POS_ERR = "CHANGE_STATUS_POS_ERR"

export const GET_STATISTIC_TRANSACTION_TOTAL_START = "GET_STATISTIC_TRANSACTION_TOTAL_START"
export const GET_STATISTIC_TRANSACTION_TOTAL_SUCCESS = "GET_STATISTIC_TRANSACTION_TOTAL_SUCCESS"
export const GET_STATISTIC_TRANSACTION_TOTAL_ERROR = "GET_STATISTIC_TRANSACTION_TOTAL_ERROR"

export const GET_STATISTIC_TRANSACTION_SUMMARY_START = "GET_STATISTIC_TRANSACTION_SUMMARY_START"
export const GET_STATISTIC_TRANSACTION_SUMMARY_SUCCESS = "GET_STATISTIC_TRANSACTION_SUMMARY_SUCCESS"
export const GET_STATISTIC_TRANSACTION_SUMMARY_ERROR = "GET_STATISTIC_TRANSACTION_SUMMARY_ERROR"

export const GET_STATISTIC_TRANSACTION_FILTER_START = "GET_STATISTIC_TRANSACTION_FILTER_START"
export const GET_STATISTIC_TRANSACTION_FILTER_SUCCESS = "GET_STATISTIC_TRANSACTION_FILTER_SUCCESS"
export const GET_STATISTIC_TRANSACTION_FILTER_ERROR = "GET_STATISTIC_TRANSACTION_FILTER_ERROR"

export const CHANGE_TIME_FILTER = "CHANGE_TIME_FILTER"
export const CHANGE_FIELDS_FILTER = "CHANGE_FIELDS_FILTER"

export const RELOAD_GET_ALL_FILTER = "RELOAD_GET_ALL_FILTER"

//Avertisement
export const GET_ALL_ADVERTISEMENT = "GET_ALL_ADVERTISEMENT"
export const GET_ALL_ADVERTISEMENT_ERR = "GET_ALL_ADVERTISEMENT_ERR"

export const ADD_ADVERTISEMENT = "ADD_ADVERTISEMENT"
export const ADD_ADVERTISEMENT_ERR = "ADD_ADVERTISEMENT_ERR"

export const SAVE_SUCCESS_ADVERTISEMENT = "SAVE_SUCCESS_ADVERTISEMENT"
export const SAVE_ADVERTISEMENT_ERR = "SAVE_ADVERTISEMENT_ERR"

export const EDIT_ADVERTISEMENT = "EDIT_ADVERTISEMENT"
export const EDIT_SUCCESS_ADVERTISEMENT = "EDIT_SUCCESS_ADVERTISEMENT"
export const DELETE_ADVERTISEMENT = "DELETE_ADVERTISEMENT"
export const SWITCH_ADVERTISEMENT = "SWITCH_ADVERTISEMENT"
export const REMOVE_BANNER = "REMOVE_BANNER"
//router
export const SWITCH_ROUTER = "SWITCH_ROUTER"

//scroll
export const SCROLL = "SCROLL"

// popup
export const OPEN_POPUPLV1 = "OPEN_POPUPLV1"
export const OPEN_POPUPLV2 = "OPEN_POPUPLV2"
export const CLOSE_POPUPLV1 = "CLOSE_POPUPLV1"
export const CLOSE_POPUPLV2 = "CLOSE_POPUPLV2"
export const RESET_POPUP = "RESET_POPUP"

// notify
export const PUSH_ERROR = "PUSH_ERROR"
export const PUSH_SUCCESS = "PUSH_SUCCESS"
export const PUSH_INFO = "PUSH_INFO"
export const PUSH_WARNING = "PUSH_WARNING"
export const POP_NOTIFY = "POP_NOTIFY"

//paging
export const CHANGE_PAGE_SIZE = 'CHANGE_PAGE_SIZE'
export const CHANGE_PAGE_DISPLAY_ITEM = 'CHANGE_PAGE_DISPLAY_ITEM'
export const CHANGE_PAGE_NUMBER = 'CHANGE_PAGE_NUMBER'

// Socket
export const POSCT_HAS_CHANGE_DEVICE = "POSCT_HAS_CHANGE_DEVICE"

// Web is online / offline
export const WEB_IS_ONLINE = "WEB_IS_ONLINE"
export const WEB_IS_OFFLINE = "WEB_IS_OFFLINE"

// isLoadingPage
export const ACTIVE_LOADING_PAGE = "ACTIVE_LOADING_PAGE"
export const DEACTIVE_LOADING_PAGE = "DEACTIVE_LOADING_PAGE"

// Dropdown Filter Chain Manager
export const DROPDOWN_SHOP_FILTER_STATISTIC = "DROPDOWN_SHOP_FILTER_STATISTIC"
export const DROPDOWN_SHOP_FILTER_POS = "DROPDOWN_SHOP_FILTER_POS"

//  Page Offline
export const STATISTIC_PAGE_OFFLINE = "STATISTIC_PAGE_OFFLINE"
export const POS_PAGE_ADD_NEW_OFFLINE = "POS_PAGE_ADD_NEW_OFFLINE"
export const POS_PAGE_GRID_OFFLINE = "POS_PAGE_GRID_OFFLINE"
export const POS_PAGE_LIST_OFFLINE = "POS_PAGE_LIST_OFFLINE"