export const TermsAnConditionData = [
    {
        title: 'Thỏa thuận sử dụng',
        content: [
            'Vui lòng đọc kỹ các thỏa thuận này. Bằng cách sử dụng Dịch vụ, quý khách đồng ý rằng quý khách đã đọc, hiểu, chấp nhận và đồng ý với các Thỏa thuận sử dụng.',
            'Nếu quý khách không đồng ý, hoặc không thuộc phạm vi điều chỉnh của các Thỏa thuận sử dụng của Dịch vụ và mong muốn không tiếp tục sử dụng Dịch vụ, vui lòng không tiếp tục sử dụng Ứng dụng thanh toán Wee và Dịch vụ.',
            'Các Thỏa thuận sử dụng được quy định trong tài liệu này cấu thành một thỏa thuận pháp lý giữa quý khách và Công ty trách nhiệm hữu hạn Wee Digital (Công ty).',
        ],
    },
    {
        title: 'Điều 1: Giải thích từ ngữ',
        content: [
            '1.1. Thỏa thuận sử dụng Dịch vụ Wee (gọi tắt là "Thỏa thuận") là tài liệu thỏa thuận giữa Bạn / Người sử dụng Dịch vụ Wee (gọi là "Người dùng") và Công ty trách nhiệm hữu hạn Wee Digital (gọi là "Công ty"), quy định các điều khoản, điều kiện, quyền và nghĩa vụ của mỗi bên áp dụng khi người dùng sử dụng Dịch vụ Wee của Công ty.',
            '1.2. Dịch vụ Wee (gọi tắt là "Dịch vụ") là các dịch vụ do Công ty cung cấp trên Ứng dụng thanh toán Wee nhằm giúp Người dùng lưu trữ và thực hiện dịch vụ thanh toán trực tuyến cho hàng hóa, sản phẩm, dịch vụ thay thế cho hình thức thanh toán bằng tiền mặt.',
            '1.3. Giao dịch Wee (gọi tắt là “Giao dịch”) là các giao dịch có thể được thực hiện thông qua Ứng dụng thanh toán Wee vào từng thời điểm, bao gồm (nhưng không giới hạn) các giao dịch sau đây:',
            '1.3.1. Chuyển tiền, nhận tiền, chia tiền, tặng tiền trên Ứng dụng thanh toán Wee.',
            '1.3.2. Mua thẻ điện thoại, nạp tiền trực tiếp vào tài khoản điện thoại, và/hoặc các thẻ và tài khoản sử dụng các tiện ích giải trí, học thuật khác được bổ sung, phát triển trong từng thời điểm.',
            '1.3.3. Thanh toán hóa đơn dịch vụ tiện ích sinh hoạt: điện, nước, truyền hình cáp, internet…',
            '1.3.4. Mua, thanh toán các giao dịch/dịch vụ khác, như: vé máy bay, vé xe, vé xem phim…',
            '1.4. Dịch vụ Wee FacePay (gọi tắt là “FacePay”) là dịch vụ thanh toán các giao dịch bằng khuôn mặt do Công ty cung cấp trên các thiết bị đặt tại Bên thứ ba (nhà hàng, siêu thị, cửa hàng tiện lợi…) nhằm giúp Người dùng thực hiện thanh toán các giao dịch hàng hóa, sản phẩm, dịch vụ thay thế cho hình thức thanh toán bằng tiền mặt.',
            'Để sử dụng FacePay, Người dùng phải có Tài khoản Ứng dụng thanh toán Wee. Khi chúng tôi dùng “Dịch vụ” trong tài liệu này, nghĩa là đã bao gồm cả “FacePay”. Khi chúng tôi dùng “Giao dịch” trong tài liệu này, nghĩa là đã bao gồm “giao dịch bằng FacePay”.',
            '1.5. Tài khoản Ứng dụng thanh toán Wee (gọi tắt là “Tài khoản”): là tài khoản định danh do Công ty phát hành trên nền tảng di động tích hợp các thông tin về Người dùng như Khuôn mặt, Giấy tờ tùy thân, Giấy tờ pháp lý, Tài khoản ngân hàng liên kết…',
            '1.6. Ứng dụng thanh toán Wee (gọi tắt là “Ứng dụng”): là ứng dụng có sẵn bằng cách tải về trên các thiết bị công nghệ cho phép Người dùng truy cập để sử dụng Dịch vụ thanh toán Wee.',
            '1.7. Tài khoản ngân hàng liên kết (gọi tắt là “Tài khoản liên kết”): là tài khoản ngân hàng của Người dùng tại một ngân hàng nào đó dùng để liên kết với Tài khoản Wee của Người dùng đó.',
            '1.8. Người dùng: là tổ chức, cá nhân có đăng ký và sử dụng Ứng dụng thanh toán Wee.',
            '1.9. Nhà cung cấp: là các cá nhân, tổ chức cung cấp hàng hóa, dịch vụ sử dụng nền tảng công nghệ trực tuyến, đồng ý với Thỏa thuận sử dụng dịch vụ thu hộ với Công ty, ủy quyền cho Công ty thực hiện thu hộ các khoản tiền thanh toán từ Người dùng.',
            '1.10. Giấy tờ tùy thân: là bất cứ giấy tờ nào chứng minh nhân thân của một người, bao gồm (a) chứng minh nhân dân (“CMND”), (b) Căn cước công dân (“CCCD”), (c) Hộ chiếu và/hoặc (d) bất cứ loại giấy tờ tùy thân nào khác cơ quan thẩm quyền của Việt Nam hoặc quốc gia/vùng lãnh thổ được Việt Nam thừa nhận cấp, còn hiệu lực.',
            '1.11. Giấy tờ pháp lý: là bất cứ giấy tờ nào chứng minh tư cách pháp lý của một tổ chức, bao gồm: (a) Giấy chứng nhận đăng ký kinh doanh/doanh nghiệp, (b) Giấy chứng nhận đầu tư, (c) Quyết định thành lập và/hoặc (d) bất cứ loại giấy tờ pháp lý nào khác ghi nhận tư cách pháp lý của một tổ chức, kể cả có hay không có tư cách pháp nhân.',
            '1.12. Ngày làm việc: Là các ngày từ thứ hai đến thứ sáu mà Công ty làm việc bình thường; trừ các ngày nghỉ, lễ, tết theo quy định pháp luật.',
            '1.13. Đơn vị tiền tệ quy định sử dụng trên Ứng dụng, Tài khoản và các Dịch vụ là Đồng Việt Nam (VND).',
        ],
    },
    {
        title: 'Điều 2: Đăng ký và sử dụng dịch vụ',
        content: [
            '2.1. Trước khi đăng ký sử dụng Dịch vụ, Người dùng cần đọc, đồng ý và chấp nhận tất cả các quy định trong Thỏa thuận này và/hoặc các quy định, chính sách, và các thỏa thuận liên quan khác trong quá trình sử dụng Dịch vụ.',
            '2.2. Khi đã sử dụng Dịch vụ, Người dùng được hiểu là đã hoàn toàn tự nguyện đồng ý, chấp nhận cũng như tuân thủ tất cả các quy định trong Thỏa thuận này và/hoặc các quy định, chính sách, và các thỏa thuận liên quan khác trong quá trình sử dụng Dịch vụ.',
            '2.3. Trong quá trình sử dụng Dịch vụ, Người dùng đồng ý cung cấp đầy đủ, chính xác thông tin về họ tên, địa chỉ liên lạc, giấy tờ tùy thân hợp lệ, số điện thoại liên hệ, email… và chịu trách nhiệm về tính chính xác, trung thực các thông tin mà Người dùng đã cung cấp.',
            '2.4. Nếu phát sinh rủi ro, khiếu nại trong quá trình sử dụng Dịch vụ, Công ty căn cứ vào những thông tin mà Người dùng đã cung cấp để tiếp nhận và giải quyết. Mọi trường hợp cung cấp không chính xác, có sai sót dẫn đến trường hợp Người dùng không được hỗ trợ hoặc giải quyết cho các khiếu nại liên quan, Công ty không chịu trách nhiệm. Người dùng phải tự chịu trách nhiệm về những rủi ro, khiếu nại không được giải quyết vì việc cung cấp thông tin thiếu, không đầy đủ hoặc không chính xác của mình trong quá trình sử dụng Dịch vụ.',
        ],
    },
    {
        title: 'Điều 3: Thay đổi nội dung các thỏa thuận',
        content: [
            '3.1. Công ty có thể sửa đổi, bổ sung nội dung Thỏa thuận này vào bất cứ thời gian nào bằng cách gửi thông báo thay đổi cho Người dùng.',
            '3.2. Người dùng được xem là đã nhận được thông báo nếu:',
            '3.2.1. Các thông báo này đã được đưa lên website chính thức của Công ty cho Dịch vụ tại địa chỉ: wee.vn; hoặc',
            '3.2.2. Các thông báo này đã được gửi tới địa chỉ thư điện tử (email) mà Người dùng đăng ký khi bắt đầu quá trình sử dụng Dịch vụ; hoặc',
            '3.2.3. Được gửi tới số điện thoại Người dùng đăng ký sử dụng Dịch vụ; hoặc',
            '3.2.4. Được gửi, thông báo, cập nhật qua Ứng dụng.',
            '3.3. Trường hợp đối với những thay đổi như: (a) tăng trách nhiệm và nghĩa vụ của Người dùng, (b) hạn chế bớt các dịch vụ đang cung cấp trên Ứng dụng Wee, hoặc (c) giảm hạn mức giao dịch, trừ khi những thay đổi này phải thực hiện ngay vì yêu cầu an ninh hoặc theo quy định của pháp luật, Công ty sẽ thông báo cho Người dùng ít nhất 10 (mười) ngày làm việc trước ngày có hiệu lực của những thay đổi này.',
            '3.4. Nếu Người dùng không đồng ý với nội dung thay đổi thì có thể chấm dứt sử dụng Dịch vụ. Trường hợp Người dùng tiếp tục sử dụng Dịch vụ, thì được hiểu Người dùng đã hoàn toàn tự nguyện đồng ý, chấp nhận cũng như tuân thủ các nội dung thay đổi đó.',
        ],
    },
    {
        title: 'Điều 4: Sử dụng dịch vụ và Phí dịch vụ',
        content: [
            '4.1. Khi Người dùng chủ động thực hiện Giao dịch thì hàng hóa, sản phẩm, dịch vụ được xác định là được bàn giao ngay cho Người dùng khi Giao dịch được thực hiện thành công.',
            'Công ty không có trách nhiệm hoàn trả và / hoặc đổi lại hàng hóa, sản phẩm, dịch vụ này, trừ trường hợp phát sinh lỗi kỹ thuật từ Ứng dụng và / hoặc hàng hóa, sản phẩm, dịch vụ bị lỗi từ phía Nhà cung cấp dẫn đến việc hàng hóa, sản phẩm, dịch vụ không sử dụng được. Công ty sẽ tiếp nhận, xác minh và xử lý đối với các trường hợp lỗi như quy định tại khoản này cho Người dùng theo quy định của Công ty và/hoặc Nhà cung cấp trong từng thời điểm.',
            'Trong mọi trường hợp Người dùng không nhận được hàng hóa, sản phẩm, dịch vụ mặc dù đã thanh toán thành công và nguyên nhân được xem là lỗi từ Giao dịch, Công ty sẽ hoàn lại khoản tiền mà Người dùng đã thanh toán cho Giao dịch bị lỗi đó vào Tài khoản liên kết.',
            '4.2. Phí sử dụng Dịch vụ được Công ty công bố cho từng thời kỳ, tùy theo đặc điểm của từng loại Dịch vụ.',
            '4.3. Trong phạm vi cho phép của pháp luật, Người dùng ủy quyền cho Công ty được quyền tự động trích nợ phí sử dụng Dịch vụ vào Tài khoản liên kết và / hoặc trên các phương tiện thanh toán khác có kết nối với Ứng dụng để thanh toán phí sử dụng Dịch vụ này.',
            '4.4. Phí dịch vụ có thể được thu trước, sau hay ngay khi phát sinh Giao dịch tùy vào từng loại Dịch vụ mà Công ty cung cấp. Đối với hình thức thu phí dịch vụ trước, Người dùng có thể không được hoàn trả lại phần phí chưa sử dụng trong trường hợp Người dùng không có nhu cầu sử dụng tiếp Dịch vụ, Người dùng có những thay đổi về thông tin nhận diện cá nhân dẫn đến việc không thể sử dụng tiếp khoản tiền chưa sử dụng.',
        ],
    },
    {
        title: 'Điều 5: Quyền và nghĩa vụ của Người dùng',
        content: [
            '5.1. Quyền của Người dùng',
            '5.1.1. Người dùng được quyền sử dụng các Dịch vụ, thực hiện các Giao dịch hợp pháp, hợp lệ, được Công ty tạo mọi điều kiện để sử dụng Ứng dịch, Tài khoản, Dịch vụ một cách an toàn và hiệu quả nhất.',
            '5.1.2. Người dùng được yêu cầu Công ty đóng hoặc tạm khóa Tài khoản của mình khi cần thiết, trừ trường hợp các yêu cầu này thuộc phạm vi từ chối của Công ty.',
            '5.1.3. Người dùng được tự hạch toán, theo dõi các biến động Tài khoản, được yêu cầu cung cấp thông tin về các Giao dịch trên Tài khoản của mình.',
            '5.1.4. Người dùng được quyền thay đổi, bổ sung thông tin Tài khoản, mật khẩu đặt lệnh, giấy tờ tùy thân hoặc giấy tờ pháp lý, email, số điện thoại gắn với Tài khoản bằng việc xác nhận với Công ty thông qua văn bản đề nghị thay đổi thông tin với các lý do cụ thể và các tài liệu chứng minh hợp lệ.',
            '5.1.5. Người dùng có thể chấm dứt sử dụng Dịch vụ vào bất kỳ lúc nào sau khi đã gửi cho Công ty văn bản yêu cầu ngừng sử dụng dịch vụ theo mẫu mà Công ty cung cấp. Sau khi chấm dứt sử dụng Dịch vụ, Người dùng không thể tiếp tục sử dụng Dịch vụ.',
            '5.2. Nghĩa vụ của Người dùng',
            '5.2.1. Cung cấp thông tin đúng và đầy đủ, tuân thủ các thủ tục đăng ký, trình tự giao dịch và các hướng dẫn của Công ty.',
            '5.2.2. Sử dụng Ứng dụng đúng mục đích và tuân thủ các cảnh báo của Công ty về phương thức bảo mật tài khoản nhằm hạn chế rủi ro ở mức cao nhất.',
            '5.2.3. Phối hợp với Công ty và các cơ quan chức năng khi xảy ra tranh chấp, bị gian lận, khiếu nại liên quan đến Tài khoản.',
            '5.2.4. Lưu trữ các thông tin Giao dịch, hóa đơn trực tuyến và các chứng từ liên quan đến giao dịch để làm căn cứ đối chiếu khi cần thiết.',
            '5.2.5. Khi đồng ý sử dụng Dịch vụ và chấp thuận thanh toán cho hàng hóa, sản phẩm, dịch vụ cung ứng tại Ứng dụng và FacePay có nghĩa Người dùng đã hiểu rõ về hàng hóa, sản phẩm hoặc dịch vụ. Công ty không chịu trách nhiệm cho bất kỳ trường hợp nào do những nhầm lẫn của Người dùng khi chọn sai hàng hóa, sản phẩm, dịch vụ, chọn nhầm số tiền v.v…',
            '5.2.6. Khi Giao dịch được thực hiện thông qua một đơn vị trung gian (ngân hàng, tổ chức tín dụng, đơn vị phát hành thẻ v.v.), Người dùng có trách nhiệm tuân thủ các quy định của đơn vị trung gian này, nếu có. Mọi vấn đề phát sinh về nghĩa vụ và quyền lợi của Người dùng và đơn vị trung gian này nằm ngoài sự kiểm soát và trách nhiệm của Công ty nên Công ty sẽ được miễn trách nhiệm trong trường hợp phát sinh khiếu nại của Người dùng đối với phạm vi công việc do hoặc thuộc trách nhiệm của đơn vị trung gian cung cấp.',
            '5.2.7. Nếu Người dùng cho rằng đã xảy ra một gian lận, sai sót trong khi thực hiện Dịch vụ, bao gồm nhưng không giới hạn:',
            'a) Bất kỳ chậm chễ hoặc sai sót nào trong việc xử lý chỉ dẫn thanh toán của Người dùng; hoặc',
            'b) Tài khoản của Người dùng phát sinh giao dịch mà không do Người dùng thực hiện; hoặc',
            'c) Mật khẩu đặt lệnh và/hoặc thông tin của Người dùng đã đăng ký trên hệ thống Công ty bị tiết lộ cho Bên thứ ba; hoặc',
            'd) Có sự truy cập không hợp lệ vào tài khoản của Người dùng; hoặc',
            'e) Các lỗi khác mà Người dùng phát hiện được xuất phát từ hệ thống của Công ty.',
            'Ngay khi phát hiện bất cứ tình huống nào nêu trên, Người dùng cần ngay lập tức liên hệ trực tiếp với bộ phận hỗ trợ của Công ty để được kiểm tra và xử lý.',
            '5.2.8. Người dùng chịu trách nhiệm trong việc áp dụng mọi biện pháp hợp lý nhằm đảm bảo an toàn, đảm bảo tính tương thích cho các loại máy móc, thiết bị kết nối, phần mềm hệ thống, phần mềm ứng dụng… do Người dùng sử dụng khi kết nối, truy cập vào Tài khoản nhằm kiểm soát, phòng ngừa và ngăn chặn việc sử dụng hoặc truy cập trái phép Tài khoản của mình.',
            '5.2.9. Người dùng có trách nhiệm trong việc đảm bảo an toàn và bí mật đối với bất kỳ thông tin cá nhân, mật khẩu truy cập, mật khẩu đặt lệnh và các yếu tố định danh khác mà Người dùng sử dụng tại Dịch vụ. Người dùng hoàn toàn chịu trách nhiệm trong trường hợp các thông tin này bị mất, lợi dụng, tiết lộ cho người thứ ba và sẽ chịu mọi rủi ro, thiệt hại gây ra do việc sử dụng trái phép của người thứ ba đó.',
            '5.2.10. Người dùng cam kết ủy quyền cho Công ty được tự động trích nợ trong các phương tiện thanh toán có kết nối với Tài khoản mà Người dùng đã kết nối (thẻ tín dụng, thẻ ghi nợ, tài khoản ngân hàng, thẻ trả trước, v.v..) để thanh toán cho các giao dịch mua hàng hóa, dịch vụ với các nhà cung cấp mà Người dùng lựa chọn mua hàng hóa và / hoặc sử dụng dịch vụ. Việc thanh toán này chỉ được thực hiện theo yêu cầu của Người dùng hoặc theo sự thỏa thuận của chính Người dùng với nhà cung cấp.',
            '5.2.11. Người dùng có trách nhiệm hoàn lại theo bất cứ hình thức nào phù hợp và thuận tiện cho Công ty, Ngân hàng liên kết, Nhà cung cấp và / hoặc bất cứ Bên thứ ba nào khác trong mọi trường hợp Tài khoản của Người dùng được ghi nhận một giá trị lớn hơn giá trị thực tế mà Người dùng nhận từ Người dùng khác và / hoặc có được bằng bất cứ hình thức hợp pháp nào khác mà Người dùng có thể chứng minh được, dù với bất cứ lý do gì. Công ty bảo lưu quyền được yêu cầu Người dùng hoàn lại giá trị chênh lệch hơn trong toàn thời gian Người dùng sử dụng Dịch vụ và cho đến khi khoản giá trị chênh lệch được hoàn về cho Công ty và / hoặc Bên thứ ba nào khác là chủ sở hữu hợp pháp của khoản tiền đó.',
            '5.2.12. Đối với các trường hợp Người dùng chưa nhận được sản phẩm, dịch vụ nhưng đã bị trừ tiền, Công ty sẽ thông báo với Người dùng tối đa 03 lần. Nếu Người dùng không thực hiện xác nhận, Công ty sẽ không giải quyết trong mọi trường hợp.Sau 60(sáu mươi) ngày, Công ty không giải quyết bất kỳ khiếu nại nào phát sinh từ các trường hợp này.'
        ],
    },
    {
        title: 'Điều 6: Quyền và nghĩa vụ của Công ty',
        content: [
            '6.1. Quyền của Công ty',
            '6.1.1. Nếu Công ty có lý do cho rằng Người dùng vi phạm các hành vi cấm quy định tại Điều 7, Công ty sẽ có toàn quyền thực hiện những biện pháp cần thiết, hợp pháp để bảo vệ Công ty và / hoặc Người dùng khác hoặc Bên thứ ba có liên quan. Những hành động Công ty có thể thực hiện được bao gồm nhưng không giới hạn:',
            'a) Công ty có thể đóng, tạm ngừng hoặc giới hạn truy nhập của Người dùng vào Tài khoản và Dịch vụ mà Công ty cung cấp;',
            'b) Công ty có thể liên hệ với Nhà cung cấp / người Mua mà Người dùng giao dịch, liên hệ với ngân hàng của Người dùng, với Người dùng khác hoặc liên hệ tới Bên thứ ba để cảnh báo hoặc lưu ý tới hành động của Người dùng;',
            'c) Công ty có quyền từ chối việc thực hiện Dịch vụ cho các Giao dịch, trả thưởng cho các Tài khoản mà Công ty nhận được tố cáo gian lận, trục lợi từ một bên thứ ba và các tố cáo này là hợp lệ và có cơ sở. Đồng thời, Công ty có thể phối hợp và cung cấp thông tin cho cơ quan chức năng và bên thứ ba để xử lý các hành vi gian lận, lừa đảo, trục lợi và / hoặc vi phạm pháp luật.',
            '6.1.2. Công ty không chịu trách nhiệm đối với những thiệt hại, tổn thất của Người dùng phát sinh trong quá trình sử dụng Dịch vụ, trừ phi những thiệt hại, tổn thất này là do lỗi cố ý của Công ty.',
            '6.1.3. Công ty không chịu trách nhiệm đối với những thiệt hại trực tiếp hoặc gián tiếp mà Người dùng phải chịu phát sinh từ hoặc do:',
            'a) Việc sử dụng Dịch vụ hoặc tiếp cận các thông tin mà Dịch vụ cung ứng của những người được Người dùng ủy quyền; hoặc',
            'b) Việc Người dùng để mất, thất lạc, mất cắp thiết bị di động dùng để đăng nhập Tài khoản, lộ mật khẩu đặt lệnh và / hoặc các yếu tố định danh khác mà Công ty cung cấp dẫn đến người khác dùng những thông tin này để sử dụng Tài khoản hoặc Dịch vụ hoặc tiếp cận những thông tin từ Dịch vụ cung ứng; hoặc',
            'c) Sự ngắt quãng, trì hoãn, chậm trễ, tình trạng không sẵn sàng sử dụng hoặc bất kỳ sự cố nào xảy ra trong quá trình cung cấp Dịch vụ do các nguyên nhân ngoài khả năng kiểm soát hợp lý của Công ty, bao gồm nhưng không giới hạn ở tình trạng gián đoạn do Dịch vụ cần được nâng cấp, sửa chữa, lỗi đường truyền của nhà cung cấp dịch vụ Internet, lỗi gián đoạn do nhà cung cấp hệ thống kỹ thuật, cơ sở hạ tầng khác; hoặc',
            'd) Bất cứ sự kiện hoặc tình huống bất khả kháng nào bao gồm nhưng không giới hạn bởi thiên tai, đình công, hoặc các yêu cầu hay chỉ thị của Chính phủ và các cơ quan nhà nước, người có thẩm quyền khác hoặc các sự kiện có tính chất tương tự.',
            '6.1.4. Quyết định phạm vi, loại thẻ ngân hàng và / hoặc tài khoản ngân hàng mà Người dùng có thể sử dụng để liên kết với Tài khoản Wee.',
            '6.1.5. Công ty có quyền thay đổi, ngừng hoặc chấm dứt cung cấp Dịch vụ vào bất kỳ thời điểm nào mà không cần có sự đồng ý của Người dùng. Tuy nhiên, Công ty có trách nhiệm thông báo cho Người dùng về việc thay đổi, ngừng hoặc chấm dứt cung cấp Dịch vụ.',
            '6.1.6. Công ty có thể từ chối không thực hiện chỉ dẫn thanh toán của Người dùng trong trường hợp trong các phương tiện thanh toán có kết nối với Tài khoản Wee mà Người dùng lựa chọn (thẻ tín dụng, thẻ ghi nợ, tài khoản ngân hàng, thẻ trả trước v.v...) không đủ số dư; hoặc do Tài khoản Wee của Người dùng đã đóng hoặc không hợp lệ; hoặc do Nhà cung cấp không cho phép thực hiện thanh toán bằng phương tiện điện tử, và trong trường hợp như vậy, Công ty sẽ gửi thông báo cho Người dùng.',
            '6.1.7. Công ty không chấp nhận việc hủy bỏ chỉ dẫn thanh toán mà Người dùng đã thực hiện thành công. Tất cả các chỉ dẫn thanh toán do Người dùng đưa ra khi sử dụng Dịch vụ được coi là không hủy ngang, cho dù chỉ dẫn thanh toán này do Người dùng hay một người khác được hiểu là Người dùng thực hiện. Việc hủy bỏ phải từ Nhà cung cấp và phải được Công ty đồng ý.',
            '6.1.8. Công ty được quyền sử dụng thông tin cá nhân và dữ liệu về giao dịch của Người dùng để tùy biến và cải tiến nhằm nâng cao chất lượng Dịch vụ. Công ty sẽ không sử dụng những dữ liệu này vào bất kỳ mục đích bất hợp pháp nào. Công ty được quyền cung cấp dữ liệu của Người dùng cho bên thứ ba trong các trường hợp nhưng không giới hạn:',
            'a) Công ty được Người dùng chấp thuận; hoặc',
            'b) Dịch vụ yêu cầu sự tương tác với một bên thứ ba; hoặc',
            'c) Theo quy định của pháp luật.',
            '6.1.9. Nếu Người dùng vi phạm các cam kết về sử dụng Dịch vụ hoặc sử dụng Dịch vụ vào mục đích vi phạm pháp luật (như các trường hợp gian lận thẻ hoặc tài khoản ngân hàng để giao dịch trên hệ thống Công ty, gian lận trong mua / bán hàng hóa, mua / bán hàng hóa cấm tiêu thụ tại Việt Nam…), Công ty có quyền ngay lập tức chấm dứt việc sử dụng Dịch vụ của Người dùng, chuyển giao cho các cơ quan có thẩm quyền xử lý.',
            '6.1.10. Công ty bảo lưu quyền thay đổi, chỉnh sửa, tạm ngưng hoặc chấm dứt tất cả hoặc bất kỳ phần nào của Dịch vụ vào bất cứ thời điểm nào theo quy định pháp luật. Công ty có thể áp dụng một số Dịch vụ hoặc tính năng của Dịch vụ với phiên bản thử nghiệm mà phiên bản này có thể không đúng hoặc không hoàn toàn giống với phiên bản cuối cùng, và Công ty không chịu trách nhiệm đối với phiên bản này. Công ty có toàn quyền giới hạn một số tính năng hoặc phạm vi sử dụng của Người dùng đối với một phần hoặc toàn bộ Dịch vụ mà không cần thông báo hay chịu trách nhiệm.',
            '6.1.11. Các website (bao gồm và không giới hạn các tên miền), ứng dụng, giải pháp, các sản phẩm, dịch vụ, logo và những nội dung khác liên quan đến Dịch vụ đều thuộc quyền quản lý, sở hữu và sử dụng hợp pháp, toàn vẹn, duy nhất và không chuyển giao của Công ty. Mọi sự sao chép, thay đổi, sửa chữa mà không được phép bằng văn bản của Công ty đều bị coi là vi phạm Thỏa thuận này và quy định pháp luật.',
            '6.2. Nghĩa vụ của Công ty',
            '6.2.1. Công ty đảm bảo rằng hệ thống Dịch vụ chạy ổn định, an toàn và tuân thủ các quy định của pháp luật liên quan đến việc cung ứng Dịch vụ.',
            '6.2.2. Hỗ trợ Người dùng, tiếp nhận và giải quyết khiếu nại của Người dùng trong quá trình sử dụng Dịch vụ.',
            '6.2.3. Bảo mật thông tin cá nhân và dữ liệu về giao dịch của Người dùng, Công ty không được bán hoặc trao đổi những thông tin này với bên thứ ba, trừ trường hợp theo quy định của pháp luật hoặc được Người dùng cho phép.',
        ],
    },
    {
        title: 'Điều 7: Các hành vi cấm',
        content: [
            'Khi sử dụng Dịch vụ, Người dùng không được:',
            '7.1. Vi phạm Thỏa thuận này và / hoặc tất cả các chính sách, quy định khác mà Người dùng đã thỏa thuận, xác nhận hoặc đồng ý với Công ty.',
            '7.2. Vi phạm quyền tác giả, bằng sáng chế, bí mật kinh doanh hoặc quyền sở hữu trí tuệ khác của Công ty và của bất kỳ bên thứ ba có liên quan.',
            '7.3. Bán hàng giả, hàng nhái, hàng cấm kinh doanh, cấm lưu hành, hàng có nguồn gốc bất hợp pháp qua Ứng dụng.',
            '7.4. Hành động có tính chất làm mất uy tín, phỉ báng, bôi nhọ, đe dọa hoặc quấy rối Công ty, bất cứ Nhà cung cấp hoặc bất cứ bên nào khác có hoặc không có liên quan đến Dịch vụ.',
            '7.5. Hành động có tính chất bạo lực, hành hung và / hoặc sử dụng ngôn từ tục tĩu, bôi nhọ, phỉ báng đối với nhân viên Công ty.',
            '7.6. Cung cấp thông tin sai sự thật, gây nhầm lẫn, sử dụng từ ngữ không phù hợp với thuần phong mỹ tục Việt Nam.',
            '7.7. Gửi hoặc nhận các khoản tiền mà theo Công ty thì đó là các khoản tiền bất hợp pháp hoặc các khoản tiền vi phạm các quy định về phòng chống rửa tiền của pháp luật Việt Nam và / hoặc các quy định quốc tế mà Việt Nam phải tuân thủ trong từng thời điểm.',
            '7.8. Từ chối hợp tác điều tra hoặc không cung cấp thông tin theo yêu cầu của Công ty.',
            '7.9. Quản lý một tài khoản kết nối tới một tài khoản khác vi phạm một trong những hành vi bị cấm quy định tại Điều này.',
            '7.10. Sử dụng Dịch vụ theo cách để cố ý tạo ra các tranh chấp, khiếu nại, đòi bồi thường.',
            '7.11. Tiết lộ thông tin của Người dùng khác cho bên thứ ba hoặc sử dụng thông tin vào những mục đích tiếp thị/quảng cáo mà Người dùng chưa được cho phép.',
            '7.12. Có những hành động vượt quá giới hạn hoặc chuyển các lệnh không hợp lệ lên hệ thống của Công ty.',
            '7.13. Phát tán virus, trojan, worms hoặc các chương trình máy tính có hại gây trở ngại, gian lận hoặc ảnh hưởng tới hệ thống, dữ liệu hoặc thông tin của Người dùng khác và/hoặc của Công ty.',
            '7.14. Sử dụng bất kỳ các loại robot, spider, các thiết bị tự động khác hoặc thực hiện thủ công để giám sát hoặc sao chép website, các ứng dụng, giải pháp của Công ty mà không được Công ty cho phép.',
            '7.15. Sử dụng các thiết bị, phần mềm để vượt qua (hoặc phá hủy, làm suy yếu) các biện pháp bảo vệ mà Công ty thiết lập nhằm đảm bảo an toàn hệ thống của Công ty.',
            '7.16. Hành động làm mất kết nối giữa Công ty đến Nhà cung cấp và/hoặc đối tác, đại lý… hoặc ngược lại từ Nhà cung cấp đến Công ty.',
            '7.17. Cố tình giả mạo địa chỉ IP, giả mạo thông tin Người dùng khi đăng nhập vào Tài khoản ứng dụng Wee.',
            '7.18. Cố tình thực hiện các hành vi spam tin nhắn, email liên tiếp qua các Dịch vụ của Công ty.',
            '7.19. Mở các Tài khoản Wee ảo để trục lợi. Tài khoản Wee ảo được định nghĩa bao gồm nhưng không giới hạn:',
            'a) Tài khoản tự tạo bằng cách cung cấp các thông tin cá nhân giả mạo, không chính xác với các thông tin về tên họ, địa chỉ liên lạc, giấy tờ tùy thân …; hoặc',
            'b) Tài khoản đăng ký nhiều lần với cùng một thông tin được tạo ra vì mục đích trục lợi; hoặc',
            'c) Tài khoản Wee chưa định danh theo quy định của Công ty.',
        ],
    },
    {
        title: 'Điều 8: Tích hợp với nền tảng của bên thứ ba',
        content: [
            '8.1. Dịch vụ của Công ty có thể được tích hợp trên nền tảng hoặc trang web của bên thứ ba. Khi sử dụng Dịch vụ của Công ty trên nền tảng hoặc trang web của bên thứ ba, Người dùng đồng thời đồng ý và sẽ tuân thủ các điều khoản dịch vụ của bên thứ ba đó. Người dùng cũng đồng ý rằng Công ty không chịu trách nhiệm về dịch vụ của bên thứ ba và / hoặc tính chính xác của hàng hóa, dịch vụ, bao gồm các chức năng, độ tin cậy, an ninh, chính sách bảo mật hoặc các hoạt động khác của các nền tảng hoặc trang web bên thứ ba đó.',
            '8.2. Khi Người dùng sử dụng Dịch vụ của Công ty trên nền tảng hoặc trang web của bên thứ ba, Công ty sẽ xử lý yêu cầu của Người dùng, bao gồm nhưng không giới hạn: yêu cầu đăng ký và yêu cầu hướng dẫn thanh toán, trên hệ thống của Công ty. Chúng tôi tuyệt đối không tiết lộ dữ liệu cá nhân, mà Người dùng đã cung cấp cho Công ty, cho bên thứ ba trừ khi việc tiết lộ đó là bắt buộc để xử lý yêu cầu của chính Người dùng và trong trường hợp đó, các nền tảng hoặc trang web của bên thứ ba cũng sẽ phải tuân thủ Chính sách bảo mật của chúng tôi.',
            '8.3. Trong trường hợp Người dùng tự cung cấp dữ liệu cá nhân của mình cho các nền tảng hoặc trang web của bên thứ ba trong quá trình bạn sử dụng Dịch vụ của Công ty, thông qua các cổng thông tin được phát triển bởi bên thứ ba, nghĩa là Người dùng đồng ý rằng mọi tiết lộ, việc sử dụng, thu thập và / hoặc xử lý dữ liệu cá nhân của bạn sẽ phải tuân theo chính sách bảo mật của bên thứ ba đó.',
            '8.4. Công ty có thể, theo quyết định riêng của mình, áp đặt các giới hạn đối với một số tính năng nhất định hoặc hạn chế Người dùng sử dụng một phần hoặc toàn bộ Dịch vụ của Công ty trên nền tảng hoặc trang web của bên thứ ba.',
        ],
    },
    {
        title: 'Điều 9: Các nguyên tắc xử lý khiếu nại',
        content: [
            '9.1. Việc tiếp nhận và xử lý khiếu nại của Công ty tuân thủ quy định của Ngân hàng Nhà nước và các quy định luật pháp liên quan.',
            '9.2. Công ty chỉ tiến hành xử lý khiếu nại khi nhận được thông tin yêu cầu từ Người dùng và / hoặc một bên thứ ba khi cung cấp các thông tin hợp lý, hợp lệ và có liên quan đến hoạt động và Dịch vụ Wee.',
            '9.3. Nhằm tránh các thất thoát tiếp tục xảy ra, khi tiếp nhận khiếu nại, Công ty sẽ thực hiện việc phong tỏa các tài khoản, các giao dịch có liên quan đến khiếu nại và tài khoản bị khiếu nại.',
            '9.4. Công ty tiến hành kiểm tra thông tin và giải quyết khiếu nại trên cơ sở công bằng, minh bạch với Người dùng và các bên.',
            '9.5. Trong trường hợp vi phạm pháp luật và / hoặc hành vi lừa đảo có tổ chức, Công ty có trách nhiệm và sẽ phối hợp với các cơ quan chức năng để cùng giải quyết.',
            '9.6. Các khiếu nại chỉ được chấp thuận là phù hợp khi các giao dịch khiếu nại phát sinh trong vòng 60 ngày kể từ ngày thực hiện Giao dịch, trừ trường hợp Người dùng chứng minh không thể thực hiện việc khiếu nại do các yếu tố bất khả kháng, sau thời hạn này, Công ty có quyền từ chối giải quyết khiếu nại.',
            '9.7. Các đối tác của Công ty đều có quy trình xử lý khiếu nại với thời hạn đã được thiết lập và Công ty phải tuân thủ vấn đề này. Vì vậy, Công ty có thể mất tối đa đến 180 ngày để xử lý khiếu nại tùy theo tính chất của các khiếu nại và Công ty khuyến khích Người dùng hợp tác và giữ liên lạc với chúng tôi đồng thời tuân thủ thời gian phản hồi thông tin trong toàn thời gian phối hợp xử lý khiếu nại để Công ty có thể thực hiện tốt việc xử lý khiếu nại này.',
            '9.8. Trong quá trình xử lý khiếu nại, Công ty có quyền đề nghị Người dùng cung cấp các bằng chứng xác minh tính trung thực của khiếu nại, chúng tôi mong nhận được sự hợp tác của Người dùng để tiến hành xử lý khiếu nại. Các thông tin Người dùng cung cấp chỉ được trao đổi với mục đích xử lý khiếu nại.',
        ],
    },
    {
        title: 'Điều 10: Các quy định khác',
        content: [
            '10.1. Liên lạc với Công ty',
            'Người dùng có thể liên lạc với bộ phận hỗ trợ của Công ty TNHH Wee Digital theo một trong các phương thức sau:',
            'a) Gọi tới số điện thoại: 079 810 1010',
            'b) Gửi email tới địa chỉ: hello@wee.vn',
            'c) Trao đổi trực tuyến với nhân viên hỗ trợ thông qua fanpage chính thức của sản phẩm https://www.facebook.com/WEEMegaApp/',
            '10.2. Cam kết thực hiện và giải quyết tranh chấp',
            'Công ty và Người dùng đồng ý nghiêm chỉnh thực hiện các điều khoản của Thỏa thuận này và các bản sửa đổi, bổ sung, phụ lục đính kèm (nếu có). Nếu phát sinh tranh chấp trong quá trình thực hiện, hai bên sẽ cùng nhau giải quyết bằng thương lượng hoặc hòa giải. Trường hợp thương lượng hoặc hòa giải không thành, thì một trong các bên có quyền đưa vụ tranh chấp ra Tòa án có thẩm quyền để giải quyết.',
            '10.3. Tất cả các điều khoản và điều kiện của Thỏa thuận này vẫn có hiệu lực sau khi Người dùng ngừng hoặc chấm dứt Dịch vụ. Ngay cả khi ngừng, chấm dứt sử dụng Dịch vụ, Người dùng vẫn bị ràng buộc bởi các điều khoản và điều kiện này ở chừng mực liên quan đến quyền và nghĩa vụ của Người dùng trong thời gian sử dụng Dịch vụ.',
        ],
    },
]