import React from 'react'

const IcDownLoad = (props) => {
    return <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 10L14 12.6667C14 13.0203 13.8595 13.3594 13.6095 13.6095C13.3594 13.8595 13.0203 14 12.6667 14L3.33333 14C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667L2 10" stroke="#051321" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.53499 6.52539L7.86833 9.85872L11.2017 6.52539" stroke="#051321" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.86865 9.85864L7.86865 1.85864" stroke="#051321" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export default IcDownLoad
