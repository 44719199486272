import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// styles
import {
    Bound,
    stylesDateFirst,
    stylesDateSecond,
    BlockItemDatePicker
} from "./index.styles";

// images
import IcReloadPage from "../../../../images/component/IcReloadPage";

// components
import DatePickerControl from "../../../Control/DatePickerControl";
// action
import {
    // <<<<<<< HEAD
    //     changeTimeFiler,
    //     reloadAllFilter,
    //     getDropdownShopFilterStatistic
    // =======
    changeTimeFiler,
    reloadAllFilter,
    getDropdownShopFilterStatistic,
    changeFieldsFilter
    // >>>>>>> 6672e4ac724614f566bddaf600cd7ecb00cd1cf4
} from "../../../../redux/actions/statisticAction";
import DropdownFilter from "../../../Control/DropdownFilter";

import * as keys from "../../../../constant/key";

const formatDate = "DD/MM/YYYY";

const timeDefault = {
    timeBefore: moment().startOf("month"),
    timeAfter: moment().endOf("day")
};

const StatisticFilter = () => {
    const { statisticReducer, shopReducer } = useSelector(state => ({
        statisticReducer: state.statisticReducer,
        shopReducer: state.shopReducer
    }));
    const [dropdownFilterData, setDropdownFilterData] = useState([]);
    const [dropdownListSelect, setDropdownListSelect] = useState({});

    useEffect(() => {
        const { fieldsFilter } = statisticReducer;
        if (Object.keys(dropdownListSelect).length === 0) return;
        dispatch(getDropdownShopFilterStatistic(dropdownListSelect));
        const billNumber = fieldsFilter.billCode;
        const facepayNumber = fieldsFilter.facepayNumber;
        const compareAmount = fieldsFilter.compareAmount;
        dispatch(
            changeFieldsFilter(billNumber, facepayNumber, compareAmount, 1)
        );
    }, [JSON.stringify(dropdownListSelect)]);

    useEffect(() => {
        if (!shopReducer.stores) return;
        const arr = [];
        shopReducer.stores.forEach(shop => {
            arr.push({
                id: shop.id,
                name: shop.AreaName,
                children: shop.Shops.slice().map(merchance => ({
                    id: merchance.ID,
                    name: merchance.Name,
                    address: merchance.Address
                }))
            });
            return shop;
        });
        setDropdownFilterData(arr);
    }, [shopReducer.stores]);

    let userDataLocal = JSON.parse(
        localStorage.getItem(keys.FACEPAY_USER_DATA)
    );

    const timeBeforeRedux = statisticReducer.timeFilter.timeBefore;
    const timeAfterRedux = statisticReducer.timeFilter.timeAfter;

    const dispatch = useDispatch();

    const [timeBefore, setTimeBefore] = useState(
        timeBeforeRedux ? timeBeforeRedux : timeDefault.timeBefore
    );
    const [timeAfter, setTimeAfter] = useState(
        timeAfterRedux ? timeAfterRedux : timeDefault.timeAfter
    );

    const [timeFirstTrans, setTimeFirstTrans] = useState(
        shopReducer.data && 
        // userDataLocal.Role === keys.STORE_MANAGER
        [keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role)
            ? moment(shopReducer.data.Shops[0].FirstTrans)
            : shopReducer.data && userDataLocal.Role === keys.CHAIN_MANAGER
            ? moment(shopReducer.data.Chain.FirstTrans)
            : moment()
    );

    const [openTimeBefore, setOpenTimeBefore] = useState(false);
    const [openTimeAfter, setOpenTimeAfter] = useState(false);

    useEffect(() => {
        setTimeBefore(timeBeforeRedux);
        setTimeAfter(timeAfterRedux);
    }, [statisticReducer.timeFilter]);

    useEffect(() => {
        if (!shopReducer.data) return;

        // if (shopReducer.data && userDataLocal.Role === keys.STORE_MANAGER)
        if (shopReducer.data && [keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role))
            setTimeFirstTrans(moment(shopReducer.data.Shops[0].FirstTrans));
        else if (shopReducer.data && userDataLocal.Role === keys.CHAIN_MANAGER)
            setTimeFirstTrans(moment(shopReducer.data.Chain.FirstTrans));
    }, [JSON.stringify(shopReducer.data)]);

    const _onChangeTimeBefore = date => {
        if (!date) return;

        if (date > timeAfter) {
            const timeAfterClone = timeAfter.clone();
            const newTimebefore = timeAfterClone.startOf("days");
            setTimeBefore(newTimebefore);
            dispatch(changeTimeFiler(newTimebefore, timeAfter));
            return;
        }

        if (date < timeFirstTrans) {
            setTimeBefore(timeFirstTrans);
            dispatch(changeTimeFiler(timeFirstTrans, timeAfter));
            return;
        }

        // if (!openTimeAfter) setOpenTimeAfter(!openTimeAfter);

        const timeBefore = date.startOf("day");
        setTimeBefore(timeBefore);
        dispatch(changeTimeFiler(timeBefore, timeAfter));
    };
    const _onChangeTimeAfter = date => {
        if (!date) return;

        if (date < timeFirstTrans || date < timeBefore) {
            const timeBeforeClone = timeBefore.clone();
            const newTimeAfter = timeBeforeClone.endOf("days");
            setTimeAfter(newTimeAfter);
            dispatch(changeTimeFiler(timeBefore, newTimeAfter));
            return;
        }

        if (date > timeAfter && date > moment().endOf("days")) {
            const newTimeAfter = moment().endOf("days");
            setTimeAfter(newTimeAfter);
            dispatch(changeTimeFiler(timeBefore, newTimeAfter));
            // alert(1)
            return;
        }

        if (openTimeAfter) setOpenTimeAfter(!openTimeAfter);

        const newTimeAfter = date.endOf("day");
        setTimeAfter(newTimeAfter);
        dispatch(changeTimeFiler(timeBefore, newTimeAfter));
    };

    const _dateRenderTimeBefore = current => {
        const style = {};
        if (current < timeFirstTrans.startOf("days") || current > timeAfter) {
            style.color = "rgba(0, 0, 0, 0.25)";
            style.background = "#fafafa";
            style.width = "36px";
            style.zIndex = "8";
            return (
                <BlockItemDatePicker>
                    {/* <div className="ant-picker-cell-inner" style={{ ...style, cursor: "default" }}> */}
                    <div
                        className="ant-calendar-date"
                        style={{
                            ...style,
                            cursor: "default"
                        }}
                    >
                        {current.date()}
                    </div>
                </BlockItemDatePicker>
            );
        } else {
            // return <div className="ant-picker-cell-inner" style={{ ...style, cursor: "pointer" }}>
            return (
                <div
                    className="ant-calendar-date"
                    style={{
                        ...style,
                        cursor: "pointer"
                    }}
                >
                    {current.date()}
                </div>
            );
        }
    };

    const _dateRenderTimeAfter = current => {
        const style = {};
        if (current < timeBefore || current > moment().endOf("days")) {
            style.color = "rgba(0, 0, 0, 0.25)";
            style.background = "#fafafa";
            style.width = "36px";
            style.zIndex = "8";
            return (
                <BlockItemDatePicker>
                    {/* <div className="ant-picker-cell-inner" style={{ ...style, cursor: "default" }}> */}
                    <div
                        className="ant-calendar-date"
                        style={{ ...style, cursor: "default" }}
                    >
                        {current.date()}
                    </div>
                </BlockItemDatePicker>
            );
        } else {
            // return <div className="ant-picker-cell-inner" style={{ ...style, cursor: "pointer" }}>
            return (
                <div
                    className="ant-calendar-date"
                    style={{ ...style, cursor: "pointer" }}
                >
                    {current.date()}
                </div>
            );
        }
    };

    const _reloadAllFilter = () => {
        dispatch(reloadAllFilter());
    };

    return (
        <Bound>
            <div className="wrapper">
                <p className="text-date-before">Xem ngày</p>
                <DatePickerControl
                    allowClear={false}
                    format={formatDate}
                    value={timeBefore}
                    extendStyles={stylesDateFirst}
                    // disabledDate={_disableTimeBefore}
                    onChange={_onChangeTimeBefore}
                    placeholder="Chọn ngày"
                    dateRender={_dateRenderTimeBefore}
                    open={openTimeBefore}
                    onOpenChange={() => setOpenTimeBefore(!openTimeBefore)}
                />
                <p className="text-date-after">đến ngày</p>
                <DatePickerControl
                    allowClear={false}
                    format={formatDate}
                    value={timeAfter}
                    extendStyles={stylesDateSecond}
                    // disabledDate={_disableTimeAfter}
                    onChange={_onChangeTimeAfter}
                    placeholder="Chọn ngày"
                    dateRender={_dateRenderTimeAfter}
                    open={openTimeAfter}
                    onOpenChange={() => setOpenTimeAfter(!openTimeAfter)}
                />
            </div>
            <div className="content-right">
                {userDataLocal && userDataLocal.Role === keys.CHAIN_MANAGER && (
                    <DropdownFilter
                        inputPlaceHolder="Tìm kiếm tên cửa hàng"
                        preText={"Cửa hàng đang xem"}
                        placeholder={"Chọn cửa hàng"}
                        dataDropdown={dropdownFilterData}
                        dispatchListSelect={setDropdownListSelect}
                    />
                )}

                <div
                    className="btn-reload"
                    onClick={_reloadAllFilter}
                    data-toggle="tooltip"
                    title="Reload"
                >
                    <IcReloadPage />
                </div>
            </div>
        </Bound>
    );
    // >>>>>>> 6672e4ac724614f566bddaf600cd7ecb00cd1cf4
};

export default React.memo(StatisticFilter);
