import React, { Component, useState, useCallback } from 'react'
import * as style from './index.style'
import { useEffect } from 'react'

const InputText = ({ 
  titleInput, 
  defaultValue, 
  placeholder,
  disabled=false,
  onChange,
  icon,
  contentTooltip,
  maxLength,
}) => {
    const [value, setValue] = useState(defaultValue)

    useEffect(() => {
      setValue(defaultValue)
    }, [defaultValue])

    const _handleOnChange = useCallback(
      (e) => {
        const { value } = e.target
        setValue(value)
        onChange(value)
      },
      [value, onChange, setValue],
    )

    return (
        <style.Bound>
            
            <p className="title">
              {titleInput}
              {
                icon && <img data-toggle="tooltip"
                  title={contentTooltip ? contentTooltip : ""}
                  className="icon"
                  src={icon} alt="icon"
                />
              }
            </p>            
            <input
              className = {disabled ? "value disabled" : "value"}
              onChange={_handleOnChange}
              value={value}
              maxLength={maxLength ? maxLength : ""}
              type="text"
              disabled={disabled}
              placeholder={placeholder}
            />

    </style.Bound>
  )
}

export default React.memo(InputText)
