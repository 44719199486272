import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    /* overflow: auto; */
    /* margin-top: 30px; */
    flex-direction:column;
    width: 100%;
    .header-list-container{
        display: grid;
        /* grid-template-columns: 160px 1fr 190px 185px 145px 160px; */
        grid-template-columns: ${props => props.gridTemplateColumnsHeader ? props.gridTemplateColumnsHeader : "160px 160px 110px 160px 165px 1fr"};
        width: 1110px;
        height: 48px;
        background-color: #1279DA;
        border-radius: 10px 10px 0px 0px;
        .title{
            font-family: "UTM-Avo";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            text-align: center;
            letter-spacing: 0.05px;
            color: #FFFFFF;
            padding: 0 10px;
            &.code-bill{
                margin: 0 0 0 27px;
            }
            &.last-activity{
                justify-content: flex-end;
                margin-right: 25px
            }
            &.last-activity-shop{
                margin-right: 25px
            }
        }

    }
    .content-list-container{
        width: calc(100% + 11px);
        height: 100%;
        overflow-y: scroll;
        padding-right: 6px;
        position: relative;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            width: 6px;
            background-color:transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #E2E2E2;
            background-clip: padding-box;
            border-radius: 20px;
        }
        .pos-list-scroll{
            /* background-color: rgba(18,121,218,0.1); */
            background-color: #ECF4FF;
            height: fit-content;
            min-height: 100%;
        }
        .no-data{
            font-family: "UTM-Avo";
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 170%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.05px;
            color: rgba(5, 19, 33, 0.4);
            position: absolute;
            z-index:1;
            top: 0;
            left: 0;
        }
    }

`
export { Bound }