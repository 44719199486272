import React, {
  useReducer,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import * as styles from "./index.style";
import IcDown from "../../../images/ic_down.svg";
import IcComplete from "../../../images/ic_completed.svg";
import IcArrow from "../../../images/ic_arrow.svg";
import IcSticky from "../../../images/ic_sticky.svg";

// action
import { getFilterShop } from "../../../redux/actions/shopAction";

// CUSTOM HOOKS. DEBOUNDCE A VALID TIME BEFORE UPDATE STATE
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

// INIT CONTEXT API
const ContextAPI = React.createContext();

const { Provider } = ContextAPI;

const StateProvider = ({ reducer, initialState, children }) => {
  return (
    <Provider value={useReducer(reducer, initialState)}>{children}</Provider>
  );
};

const useStateValue = () => useContext(ContextAPI);
// FINISH INIT CONTEXT API

// INITIAL
const ContextWrapper = (props) => {
  const reducer = (state, action) => {
    const { type, payload, override } = action;
    if (!type && !override) {
      return state;
    }
    if (override) {
      return { ...state, ...override };
    }
    return {
      ...state,
      [type]: payload,
    };
  };

  const initialState = {
    expandDropdown: false,
    data: [],
    selectAll: true,
    stickySelectAll: false,
    listSelect: {},
    maxWidthChild: 0,
    render: {},
    searchValue: "",
    searchResult: [],
    tooltip: {
      visible: false,
      position: {
        x: 0,
        y: 0,
      },
      content: "",
    },
  };

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <DropdownFilter {...props} />
    </StateProvider>
  );
};

// THE IDEA TO USE CONTEXT API IN THIS COMPONENT:
// EACH CHILD COMPONENT USE THE SAME STATE

// COMPONENT
export const DropdownFilter = (props) => {
  const dispatchAction = useDispatch()

  const { dispatchListSelect = () => {} } = props;
  const [
    { expandDropdown, render, searchValue, searchResult, listSelect },
    dispatch,
  ] = useStateValue();
  const {
    dataDropdown = [],
    preText = "",
    placeholder = "",
    inputPlaceHolder = "",
  } = props;
  const dropdownRef = useRef();

  useEffect(() => {
    if (Object.keys(listSelect).length === 0) return;
    dispatchListSelect(JSON.parse(JSON.stringify(listSelect)));
  }, [JSON.stringify(listSelect), dispatchListSelect]);

  useOnClickOutside(dropdownRef, () => {
    if (expandDropdown)
      dispatch({
        type: "expandDropdown",
        payload: !expandDropdown,
      });
  });

  useEffect(() => {
    dispatch({
      type: "data",
      payload: searchResult,
    });
  }, [searchResult, dispatch]);

  useEffect(() => {
    dispatch({
      type: "render",
      payload: {
        preText: preText,
        placeholder: placeholder,
        inputPlaceHolder: inputPlaceHolder,
      },
    });
    dispatch({
      type: "data",
      payload: dataDropdown,
    });
  }, [dataDropdown, preText, placeholder, inputPlaceHolder, dispatch]);

  useEffect(() => {
    const searchDeepArray = (dt, st) => {
      const result = [];
      for (let i = 0; i < dt.length; i++) {
        if (dt[i].children) {
          const resultChildren = searchDeepArray(dt[i].children, st);
          if (resultChildren.length === 0) continue;
          result.push({
            ...dt[i],
            children: resultChildren,
          });
        } else {
          for (let key in dt[i]) {
            if (
              key === "id" ||
              key !== "name" ||
              typeof dt[i][key] !== "string"
            )
              continue;
            if (
              dt[i][key]
                .normalize("NFD")
                .replace(/đ/g, "d")
                .replace(/Đ/g, "D")
                .replace(/[\u00c0-\u036f]/g, "")
                .toLowerCase()
                .includes(st)
            ) {
              result.push(dt[i]);
              break;
            }
          }
        }
      }
      return result;
    };
    const transformSearchValue = searchValue
      .trim()
      .normalize("NFD")
      .replace(/đ/g, "d")
      .replace(/Đ/g, "D")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    dispatch({
      type: "searchResult",
      payload: searchDeepArray(dataDropdown, transformSearchValue),
    });
  }, [searchValue]);

  return (
    <styles.Wrapper ref={dropdownRef}>
      {render.preText}
      <styles.Dropdown
        onClick={() => {
          !expandDropdown && dispatchAction(getFilterShop())
          dispatch({
            type: "expandDropdown",
            payload: !expandDropdown,
          })
        }}
        expand={expandDropdown}
      >
        {render.placeholder}
        <img className="down" src={IcDown} />
      </styles.Dropdown>
      <DropdownContent />
      <Tooltip />
    </styles.Wrapper>
  );
};

export const Tooltip = () => {
  const [{ tooltip }] = useStateValue();
  const { position, visible, content } = tooltip;
  return (
    <styles.Tooltip position={position} visible={visible}>
      {content}
    </styles.Tooltip>
  );
};

// WHICH WILL EXPAND WHEN TRIGGER CLICK ON COMPONENT
export const DropdownContent = () => {
  const [
    { data, expandDropdown, selectAll, maxWidthChild, listSelect, render },
    dispatch,
  ] = useStateValue();

  const [currentSticky, setCurrentSticky] = useState(false);
  const [inputText, setInputText] = useState("");
  const searchText = useDebounce(inputText, 500);

  useEffect(() => {
    dispatch({
      type: "searchValue",
      payload: searchText,
    });
  }, [searchText]);

  const onClickSelectAll = () => {
    let isHasCheck = false;
    let isAllCheck = true;
    Object.keys(listSelect).map((key) => {
      Object.keys(listSelect[key]).map((childKey) => {
        if (listSelect[key][childKey].checked) {
          isHasCheck = true;
        } else {
          isAllCheck = false;
        }
      });
      return key;
    });
    Object.keys(listSelect).map((key) => {
      Object.keys(listSelect[key]).map((childKey) => {
        let result = false;
        data.map((item) => {
          const findId = item.children.find((dt) => dt.id === childKey);
          if (findId) result = true;
          return item;
        });
        if (listSelect[key][childKey].checked) {
          isHasCheck = true;
        }
        if (result)
          listSelect[key][childKey].checked =
            isHasCheck && !isAllCheck ? isHasCheck : !selectAll;
      });
      return key;
    });
    dispatch({
      type: "listSelect",
      payload: listSelect,
    });
    dispatch({
      type: "selectAll",
      payload: isHasCheck && !isAllCheck ? isHasCheck : !selectAll,
    });
  };

  useEffect(() => {
    let isAllCheck = true;
    if (Object.keys(listSelect).length === 0) return;
    Object.keys(listSelect).map((key) => {
      const obj = listSelect[key];
      if (Object.keys(obj).length === 0) return key;
      if (Object.keys(obj).some((item) => !obj[item].checked)) {
        isAllCheck = false;
      }
      return key;
    });
    dispatch({
      type: "selectAll",
      payload: isAllCheck,
    });
  }, [data]);

  const onChangeCheckbox = (params) => {
    const { id, listChildChecked } = params;
    listSelect[id] = { ...listSelect[id], ...listChildChecked };
    dispatch({
      type: "listSelect",
      payload: listSelect,
    });
  };

  useEffect(() => {
    let isAllCheck = true;
    let isHasCheck = false;
    if (Object.keys(listSelect).length === 0) return;
    Object.keys(listSelect).map((key) => {
      const obj = listSelect[key];
      if (Object.keys(obj).length === 0) return key;
      if (Object.keys(obj).some((childKey) => obj[childKey].checked === true)) {
        isHasCheck = true;
      }
      if (Object.keys(obj).some((item) => !obj[item].checked)) {
        isAllCheck = false;
      }
      return key;
    });
    if (isHasCheck && isAllCheck) {
      setCurrentSticky(false);
      dispatch({
        type: "selectAll",
        payload: true,
      });
      return;
    }
    if (isHasCheck && !isAllCheck) {
      setCurrentSticky(true);
      return;
    }
    if (!isHasCheck) {
      setCurrentSticky(false);
      dispatch({
        type: "selectAll",
        payload: false,
      });
      return;
    }
  }, [JSON.stringify(listSelect)]);

  const onChangeTextInput = (e) => {
    if (e.target.value[0] === " ") {
      e.target.value = e.target.value.trim();
      setInputText(e.target.value.trim());
    }
    e.target.value.replace(/\s+/g, " ");
    setInputText(e.target.value.replace(/\s+/g, " "));
  };

  return (
    <styles.DropdownContent
      expand={expandDropdown}
      maxWidthChild={maxWidthChild}
    >
      <label className="label" />
      <input
        className="searchInput"
        onChange={onChangeTextInput}
        placeholder={render.inputPlaceHolder}
        value={inputText}
        onKeyPress={(e) =>
          e.key === "Enter" && setInputText(e.target.value.trim())
        }
      />
      <div className="selectAll">
        <div
          className={`checkbox checkbox-${
            selectAll && !currentSticky ? "checked" : currentSticky && "sticky"
          }`}
          onClick={onClickSelectAll}
        >
          {selectAll && !currentSticky && <img src={IcComplete} />}
          {currentSticky && <img src={IcSticky} />}
        </div>
        Chọn tất cả
      </div>
      <div className="scroll-content">
        {data.map((area) => (
          <Checkbox
            dataChildren={area.children}
            key={area.id}
            forceChecked={selectAll}
            id={area.id}
            onChange={(params) => onChangeCheckbox(params)}
          >
            {Object.keys(area).map(
              (item, indexArera) =>
                typeof area[item] === "string" &&
                item !== "id" && (
                  <span key={indexArera} title={area[item]}>
                    {area[item]}
                  </span>
                )
            )}
          </Checkbox>
        ))}
      </div>
    </styles.DropdownContent>
  );
};

// CHECKBOX N TIMES
export const Checkbox = ({
  dataChildren,
  children,
  forceChecked,
  dispatchParentCheck,
  id,
  parentId,
  onChange,
}) => {
  const [checked, setChecked] = useState(forceChecked);
  const [expand, setExpand] = useState(false);
  const [sticky, setSticky] = useState(false);
  const [listChildChecked, setListChildChecked] = useState();
  const [
    { expandDropdown, searchValue, listSelect },
    dispatch,
  ] = useStateValue();
  const selfRef = useRef();

  useEffect(() => {
    if (searchValue.length > 0) {
      setExpand(true);
      return;
    }
  }, [searchValue]);

  //device width
  useEffect(() => {
    if (expandDropdown && expand) {
      dispatch({
        type: "maxWidthChild",
        payload: selfRef.current.clientWidth,
      });
    }
  }, [expandDropdown, expand]);

  useEffect(() => {
    setChecked(forceChecked);
  }, [forceChecked]);

  // PARENT
  useEffect(() => {
    if (!dataChildren) return;
    const dto = {};
    dataChildren.map((child) => {
      dto[child.id] = {
        checked: listSelect[id] ? listSelect[id][child.id].checked : checked,
        id: child.id,
      };
      return child;
    });

    setListChildChecked(dto);
  }, []);

  // PARENT
  useEffect(() => {
    if (!listChildChecked) return;
    const isAllCheck = Object.keys(listChildChecked).every(
      (key) => listChildChecked[key].checked === true
    );
    const isHasCheck = Object.keys(listChildChecked).some(
      (key) => listChildChecked[key].checked === true
    );
    // WRONG HERE
    onChange({ id: id, listChildChecked: listChildChecked });

    if (isAllCheck) {
      setSticky(false);
      setChecked(true);
      return;
    }
    if (isHasCheck) {
      setSticky(true);
      return;
    }
    if (!isHasCheck) {
      setSticky(false);
      setChecked(false);
      return;
    }
  }, [JSON.stringify(listChildChecked)]);

  //PARENT
  useEffect(() => {
    if (!listChildChecked) return;
    if (!dataChildren) return;
    if (
      listSelect[id] &&
      Object.keys(listChildChecked).length !==
        Object.keys(listSelect[id]).length
    ) {
      const isAllCheckParent = Object.keys(listSelect[id]).every(
        (key) => listSelect[id][key].checked === true
      );
      const isHasCheckParent = Object.keys(listSelect[id]).some(
        (key) => listSelect[id][key].checked === true
      );
      const dto = {};
      dataChildren.map((child) => {
        dto[child.id] = {
          checked: listSelect[id][child.id].checked,
          id: child.id,
        };
        return child;
      });
      setListChildChecked(dto);
      if (isAllCheckParent) {
        setSticky(false);
        setChecked(true);
        return;
      }
      if (isHasCheckParent) {
        setSticky(true);
        return;
      }
      if (!isHasCheckParent) {
        setSticky(false);
        setChecked(false);
        return;
      }
    }
  }, [JSON.stringify(dataChildren)]);

  const [mouseEnterCheckbox, setMouseEnterCheckbox] = useState(false);

  if (dataChildren) {
    return (
      <styles.CheckboxWrapper
        ref={selfRef}
        expand={expand}
        hasChild={dataChildren}
      >
        <styles.Checkbox
          expand={expand}
          hasChild={dataChildren}
          listChildChecked={listChildChecked}
          onClick={() => {
            if (mouseEnterCheckbox) return;
            setExpand(!expand);
          }}
        >
          <div className="arrow">
            <img src={IcArrow} />
          </div>
          <div
            onClick={() => {
              if (!dataChildren) return;
              const dto = {};
              dataChildren.map((child) => {
                dto[child.id] = {
                  checked: !checked,
                  id: child.id,
                };
                return child;
              });
              setChecked(!checked);
              setListChildChecked(dto);
            }}
            onMouseEnter={() => setMouseEnterCheckbox(true)}
            onMouseLeave={() => setMouseEnterCheckbox(false)}
            className={`checkbox checkbox-${
              checked && !sticky ? "checked" : sticky && "sticky"
            }`}
          >
            {checked && !sticky && <img src={IcComplete} />}
            {sticky && <img src={IcSticky} />}
          </div>
          <div 
            onClick={() => {
              setExpand(!expand)
            }} 
            className="wrapper"
          >
            {children}
          </div>
        </styles.Checkbox>
        <div className="childWrapper">
          {dataChildren.map((child) => (
            <Checkbox
              forceChecked={checked}
              key={child.id}
              id={child.id}
              dispatchParentCheck={[listChildChecked, setListChildChecked]}
              parentId={id}
            >
              <p className="childContent">
                {Object.keys(child).map(
                  (item, index) =>
                    typeof child[item] === "string" &&
                    item !== "id" && (
                      <span
                        className={`childText ${item === "name" &&
                          "childText-bold"}`}
                        key={index}
                        title={child[item]}
                      >
                        {child[item]}
                        <br />
                      </span>
                    )
                )}
              </p>
            </Checkbox>
          ))}
        </div>
      </styles.CheckboxWrapper>
    );
  }

  const [listParentCheck, dispatchListParentCheck] = dispatchParentCheck;

  return (
    <styles.CheckboxWrapper ref={selfRef}>
      <styles.Checkbox expand={expand} hasChild={dataChildren}>
        <div
          onClick={() => {
            if (!listSelect[parentId] || !listSelect[parentId][id]) return;
            setChecked(!listSelect[parentId][id].checked);
            const obj = JSON.parse(JSON.stringify(listParentCheck));
            if (!obj[id]) {
              obj[id] = {
                checked: !listSelect[parentId][id].checked,
                id: id,
              };
            } else obj[id].checked = !listSelect[parentId][id].checked;
            dispatchListParentCheck(obj);
          }}
          className={`checkbox checkbox-${listSelect[parentId] &&
            listSelect[parentId][id].checked &&
            "checked"}`}
        >
          {listSelect[parentId] && listSelect[parentId][id].checked && (
            <React.Fragment>
              <img src={IcComplete} />
            </React.Fragment>
          )}
        </div>
        <div className="wrapper">{children}</div>
      </styles.Checkbox>
    </styles.CheckboxWrapper>
  );
};
DropdownFilter.propTypes = {
  dispatchListSelect: PropTypes.func,
  preText: PropTypes.string,
  data: PropTypes.array,
  placeholder: PropTypes.string,
  inputPlaceHolder: PropTypes.string,
};

Checkbox.propTypes = {
  id: PropTypes.any,
  dispatchParentCheck: PropTypes.array,
  forceChecked: PropTypes.bool,
  dataChildren: PropTypes.array,
  onChange: PropTypes.func,
  parentId: PropTypes.any,
  children: PropTypes.any,
};

StateProvider.propTypes = {
  children: PropTypes.node,
  reducer: PropTypes.func,
  initialState: PropTypes.object,
};
export default ContextWrapper;
