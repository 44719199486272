import React, { useEffect, useState, useCallback } from 'react'

// component
import AlertPopup from '../../../../Popup/AlertPopup'
import ErrorConnect from '../../ErrorConnect'

const alertPopupInvalidMoneyStyles = `
    margin: 33px auto 0 auto;
    left: 50%;
    transform: translate(-50%, 0);
    width: unset;
    max-width: unset;
`

const ErrorPaymentFollowActionType = ({ error, closePopup, autoClosePopup }) => {
    const [isAutoClosePopup, setIsAutoClosePopup] = useState(false)
    const [component, setComponent] = useState(null);
    
    useEffect(() => {
        _handleRender();
    }, [])
    
    // useEffect(() => {
    //     if (isAutoClosePopup){
    //         autoClosePopup(3000);
    //         setIsAutoClosePopup(false)
    //     }
    // }, [isAutoClosePopup])

    const _handleRenderFollowCode = useCallback(
        () => {
            switch (error.Code) {
                case 1:
                    setComponent(
                        <AlertPopup
                            type="ERROR"
                            handleClickBtn={closePopup}
                            extendStyles={alertPopupInvalidMoneyStyles}
                        >
                            <div> Số tiền không hợp lệ. </div>
                        </AlertPopup>
                    );
                    break;
                case 2:
                    setComponent(
                        <AlertPopup
                            type="ERROR"
                            handleClickBtn={closePopup}
                            extendStyles={alertPopupInvalidMoneyStyles}
                        >
                            <div> Số tiền vượt quá hạn mức cho phép. </div>
                        </AlertPopup>
                    );
                    break;
                case 3:
                    // setIsAutoClosePopup(true);
                    setComponent(
                        <AlertPopup
                            type="ERROR"
                            handleClickBtn={closePopup}
                            extendStyles={alertPopupInvalidMoneyStyles}
                        >
                            <div> Thiết bị chưa được bật hoặc chưa kết nối với hệ thống. </div>
                            <div>Vui lòng bật hoặc kết nối thiết bị và thử lại!</div>
                        </AlertPopup>
                    );
                    break;
                default:
                    // setIsAutoClosePopup(true);
                    setComponent(
                        <AlertPopup
                            type="ERROR"
                            handleClickBtn={closePopup}
                            extendStyles={alertPopupInvalidMoneyStyles}
                        >
                            Lỗi hệ thống. Code {error.Code}
                        </AlertPopup>
                    );
                    break;
            }
        },
        [],
    )

    const _handleRenderFollowTypeError = useCallback(
        () => {
            switch (error.type) {
                case "EMPTY_MONEY_REQUEST":
                    // setIsAutoClosePopup(true)
                    setComponent(
                        <AlertPopup
                            type="ERROR"
                            handleClickBtn={closePopup}
                            extendStyles={alertPopupInvalidMoneyStyles}
                        >
                            Vui lòng nhập số tiền
                        </AlertPopup>
                    )
                    break;
                case "INVALID_MONEY_REQUEST":
                    // setIsAutoClosePopup(true)
                    setComponent(
                        <AlertPopup
                            type="ERROR"
                            handleClickBtn={closePopup}
                            extendStyles={alertPopupInvalidMoneyStyles}
                        >
                            Số tiền không được dưới {error.data.min}
                        </AlertPopup>
                    )
                    break;
                default:
                    if(process.env.NODE_ENV === "development")
                        throw Error('Invalid type or type is not defined to use this modal!')
                    else {
                        setComponent(
                            <ErrorConnect closePopup={closePopup}>
                                <div>Không thể kết nối được với hệ thống.</div>
                                <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                            </ErrorConnect>
                        )
                        break;
                    }
            }
        },
        [],
    )

    const _handleRender = useCallback(() => {
        if(error.Code){
            _handleRenderFollowCode();
            return;
        }
        _handleRenderFollowTypeError()
    }, [])

    return component
}

export default ErrorPaymentFollowActionType
