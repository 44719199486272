export const MATCH_ALL = "*";

const menuData = [
    { 
        title: "Thống kê", 
        path: "/main/statistic",
        permission: [MATCH_ALL]
    },
    { 
        title: "Thiết bị POS", 
        path: "/main/pos",
        permission: [MATCH_ALL]
    }
];

export default menuData