import Axios from "axios";

const setAuth = (isAuth) => {
    if(isAuth){
        Axios.defaults.withCredentials = process.env.NODE_ENV === 'production'?false:true;
        Axios.defaults.headers.post["CSRF-TOKEN"] = isAuth.CsrfToken;
        return;
    }
    Axios.defaults.withCredentials = false;
    delete Axios.defaults.headers.common["CSRF-TOKEN"];
}

export default setAuth;