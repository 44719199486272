import * as types from "../../constant/typeAction";

export const initialState = {
    data: null,
    type: ""
};

export default function paymentReducer(state = initialState, action) {
    switch (action.type) {
        
        case types.PAYMENT_REQUEST:
            return {
                ...state,
                data: action.data,
                type: action.type,
                err: ''
            };
        case types.PAYMENT_REQUEST_ERROR:
            return {
                ...state,
                data: {},
                err: action.err,
                type: action.type
            };
        case types.CLEAR_PAYMENT_REQUEST:
            return {
                ...state,
                err: '',
                type: action.type,
                data: null
            };
        default:
            return state;
    }
}
