import React, { useRef, useEffect, useState, useCallback } from 'react'

// styles
import { Bound } from './index.styles'

// component
import OptionList from './OptionList';
import DeleteConfirm from './DeleteConfirm';
import RequestPay from './RequestPay';

const steps = {
    LIST: "LIST",
    REQUEST_PAY: "REQUEST_PAY",
    DEL_DEVICE: "DEL_DEVICE"
}

const widthPopupFollowStep = {
    [steps.LIST]: 174,
    [steps.REQUEST_PAY]: 305,
    [steps.DEL_DEVICE]: 328,
}

const MoreOptionPopUp = ({ deviceItem, btnMoreRef, handleBlur }) => {
    const [step, setStep] = useState(steps.LIST);
    const [contentFollowStep, setContentFollowStep] = useState(null);

    useEffect(() => {
        const btnMore = btnMoreRef.current;
        if (!btnMore) return;

        const extendStyles = _genExtenStylesFollowStep(step, btnMore)
        let content = null;
        switch (step) {
            case steps.LIST: {
                content = <OptionList
                    extendStyles={extendStyles}
                    handleBlur={handleBlur}
                    steps={steps}
                    setStep={setStep}
                />
                break;
            }
            case steps.REQUEST_PAY: {
                content = <RequestPay 
                    deviceItem={deviceItem}
                    extendStyles={extendStyles}
                    handleBlur={handleBlur}
                />
                break;
            }
            case steps.DEL_DEVICE: {
                content = <DeleteConfirm 
                    deviceItem={deviceItem}
                    extendStyles={extendStyles}
                    handleBlur={handleBlur}
                />
                break;
            }
            default:
                break;
        }
        setContentFollowStep(content);
    }, [step, btnMoreRef])

    const _genExtenStylesFollowStep = useCallback(
        (step, btnMore) => {
            const styles = {}

            const dimensions = btnMore.getBoundingClientRect();

            styles.left = dimensions.left + (dimensions.width / 2) - widthPopupFollowStep[step] - 10;
            styles.left = Math.min(styles.left, document.body.clientWidth);
            styles.left += "px";

            styles.top = dimensions.top + dimensions.height - 20 + "px";
            const stylesToString = Object.keys(styles)
                .reduce((str, item) =>
                    `${str}
                ${item}: ${styles[item]};
                `, ``)

            // console.log(stylesToString);
            
            return stylesToString
        },
        [btnMoreRef],
    )

    
    
    return contentFollowStep;
}
export default MoreOptionPopUp;
