import React from 'react'
import PropTypes from 'prop-types'

// styles
import { Bound } from './index.styles'

const optionInput = {
    HISTORY: "HISTORY",
    CUSTOM: "CUSTOM",
}

const stylesOption = {
    [optionInput.HISTORY]: `
        background-color: #FFFFFF;
        border: 1px solid #C6D5EB;
        box-sizing: border-box;
        border-radius: 6px;
    `,
    [optionInput.CUSTOM]: ``
}

const InputTextHistoryControl = ({ option, extendStyles, value, placeholder, onChange, type, onKeyDown }) => {
    return (
        <Bound
            specificStyles={stylesOption[option]}
            extendStyles={extendStyles}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            type={type}
            onKeyDown={onKeyDown}
        >
        </Bound>
    )
}

InputTextHistoryControl.defaultProps = {
    option: optionInput.CUSTOM
}

InputTextHistoryControl.propTypes = {
    option: PropTypes.oneOf(Object.values(optionInput)),
    extendStyles: PropTypes.any,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    type: PropTypes.string,
    onKeyDown: PropTypes.func,

}

export default InputTextHistoryControl
