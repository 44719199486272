import styled from 'styled-components';

const Input = styled.input`
    border: 1px solid #C6D5EB;
    box-sizing: border-box;
    border-radius: 3px;
    background-color: #FFFFFF;
    font-family: "UTM-Avo";
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.05px;
    font-size: 14px;
    line-height: 170%;
    color: #435A6F;
    padding: 9px 12px 8px 12px;
    width: 265px;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
    -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }

    ${props => props.extendStyles}
`;

export {
    Input
}