import React from 'react'
import styled from 'styled-components'

// import img_spinner from '../../../images/img_spinner.gif'

const Bound = styled.div`
    width : ${props => props.width || "100%"};
    height: ${props => props.height || "100%"};
    z-index:9999;
    border-radius: ${props => props.borderRad || "0"};
    background-color:rgba(255, 255, 255, 0.5);
    position:absolute;
    top:0px;
    left:0px;
    display:flex;
    justify-content:center;
    align-items:center;
    
    /* ------ SPINNER ------ */
    /* border-radius:20px; */
    /* transition-duration: 0.5s; */
    /* animation-name: isSpinner; */
    /* transition-delay: .5s; */
    /* @keyframes isSpinner {
        from {
            opacity: 0;
            top: -50px;
        }
        to {
            opacity: 1;
            top: 0px;
        }
    } */

    /* ------ PRELOADER ------ */
    .preloader {
        position: relative;
        width: 65px;
        margin: 6em auto;

        span {
            position: absolute;
            display: block;
            bottom: 0;
            width: 9px;
            height: 5px;
            border-radius: 5px;
            background: rgba(0,0,0,0.1);
            animation: preloader 2s infinite ease-in-out;
        }
    }
    @keyframes preloader {
        0% {
            height: 5px;
            transform: translateY(0);
            background: rgba(0,0,0,0.1);
        }
        25% {
            height: 30px;
            transform: translateY(15px);
            background: #3498db;
        }
        50%,100% {
            height: 5px;
            transform: translateY(0);
            background: rgba(0,0,0,0.1);
        }
    }
`

const Spinner = (props) => {

    const renderPreloader = () => {
        let arr = new Array(6).fill(undefined)
        return arr.map((item, i) => {
            let count = i + 1;
            return <span key={i} style={{ left: count * 11 + 'px', animationDelay: 200 * count + 'ms' }} />
        })
    }

    return (
        <Bound borderRad={props.borderRad}>
            {/* <img src={img_spinner} alt="ic_spinner" /> */}
            <div className='preloader'>
                {renderPreloader()}
            </div>
        </Bound>
    )
}

export default Spinner;