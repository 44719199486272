import { useEffect, useState } from 'react'

const useBlur = (handleBlur, [targetRef]) => {

    useEffect(() => {
        const target = targetRef.current;
        if(!target) return;
        target.tabIndex = "1";
        target.focus();
        target.addEventListener('blur', _onBlur)
        window.addEventListener('click', _onClick, true)
        return () => {
            target.removeEventListener('blur', _onBlur)
        }
    }, [targetRef])

    const _onBlur = (e) => {
        handleBlur(e)
        window.removeEventListener('click', _onClick, true)
    }

    const _onClick = (e) => {
        const isClickTarget = e.target === targetRef.current;
        const isClickChildOfTarget = targetRef.current &&
            targetRef.current.contains(e.target);
        if (isClickTarget || isClickChildOfTarget) {
            window.removeEventListener('click', _onClick, true)
            return;
        }
        e.preventDefault();
        e.stopPropagation();
        console.log('remove click')
        window.removeEventListener('click', _onClick, true)
    }
}

export default useBlur
