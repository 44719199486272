
/**
 * format number from 12450121 to 12.450.121
 * @param {number} num 
 */
const formatNumber = (num) => {
    if(!num){ 
        if(num !== 0)
            return
    }
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export {
    formatNumber
};
