import styled, {css} from 'styled-components'

//image 
import ic_previous from '../../../images/ic_previous.svg'
import ic_next from '../../../images/ic_next.svg'

const Bound = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 36px;
    font-family: 'UTM-Avo';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.5px;
    color: #000000;
    .btn-previous, .btn-next{
        display: flex;
        width: 30px;
        height: 30px;
        background-size: 6px 12px;
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    .btn-previous{
        background-image: url(${ic_previous});
    }
    .btn-next{
        background-image: url(${ic_next});
    }
    .paging-container{
        text-align: right;
        margin: 0 20px;
        /* width: 160px; */
    }
`

const styleDropdownParent = css`
    width: 50px;
    height: 36px;
    background-color: #F7F7F7;
    border: 1px solid #CDCDCD;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
    margin-left: 9px;
    .default-value{
        font-weight: normal;
        font-size: 13px;
        color: #000000;
    }
    .icon{
        position: absolute;
        top: 55%;
        right: 0;
        transform:translate(-10px, -55%);
    }
    
`

const stylesListDropdown = css`
    &.listDropdown{
        width: 93px;
        height: max-content;
        /* height: ${props => props.heightListDropdown || "108px"}; */
        /* overflow-x: hidden; */
        /* overflow-y: auto; */
        position: absolute;
        /* top: 38px; */
        right: 0;
        bottom: 41px;
        background-color: #FFFFFF;
        /* border: 1px solid #C6D5EB; */
        box-sizing: border-box;
        border-radius: 6px;
        z-index: 10;
        &::-webkit-scrollbar {
            width: 9px;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #E2E2E2;
            background-clip: padding-box;
            border-radius: 20px;
            border: 3px solid white;
        }
        .itemDropdown{
            width: 100%;
            height: 36px;
            /* border-radius: 6px; */
            background-color: #F3F3F3;
            display: flex;
            justify-content: center;
            align-items: center;
            /* padding: 0 0 0 20px; */
            transition: .1s all ease-in-out;
            &:hover{
                background-color: #f1f5fa;
            }
            &.active{
                color: #0075FF;
            }
        }
    }
`
export {
    Bound,
    styleDropdownParent,
    stylesListDropdown
}