import { createStore, applyMiddleware, compose } from 'redux';
import mainReducer from './redux/reducers/mainReducer';
import thunk from 'redux-thunk';

export default function configureStore(initialState={}) {
    const composeEnhancers = typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            trace: true,
            traceLimit: 25 
        }) : compose;

    let store = createStore(
        mainReducer,
        initialState,
        composeEnhancers(applyMiddleware(thunk))
    )
    return store
}
