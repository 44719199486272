import React from 'react'

const IcGridOff = (props) => {
    return <svg {...props} width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.1" width={36} height={36} rx={6} fill="#959595" />
    <path d="M16 9H9V16H16V9Z" stroke="#959595" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M27 9H20V16H27V9Z" stroke="#959595" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M27 20H20V27H27V20Z" stroke="#959595" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16 20H9V27H16V20Z" stroke="#959595" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>    
}

export default IcGridOff