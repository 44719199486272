import { useEffect, useState } from "react";

const KEY_ENTER = 13;

const usePressEnter = (targetRef) => {
    const [isEnter, setIsEnter] = useState(false);

    useEffect(() => {
        const target = targetRef ? targetRef.current : window;
        target.addEventListener('keypress', _onKeyPress)
        return () => {
            target.removeEventListener('keypress', _onKeyPress)
        }
    }, [targetRef])

    const _onKeyPress = (e) => {
        const key = e.keyCode || e.which;
        if (key === KEY_ENTER){
            setIsEnter(true)
        }
    }

    return [isEnter, setIsEnter]
}

export default usePressEnter;