import styled, { css } from 'styled-components';

const ButtonStyles = styled.button`
    outline: none;
    font-family: "UTM-Avo";
    :hover {
        cursor: pointer;
    }
    
    ${props => css`${props.specificStyles}`}
    ${props => props.extendStyles}
`;

export {
    ButtonStyles
}