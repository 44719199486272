import styled from "styled-components";

const Bound = styled.div`
    font-family: "UTM-Avo";
    font-style: normal;
    /* margin: 18px 0 44px 0; */
    width: 100%;
    .header-table {
        width: 100%;
        display: grid;
        /* grid-template-columns: 210px 180px 180px 160px 140px 100px 1fr; */
        grid-template-columns: ${props => props.gridTemplateColumnsHeader ? props.gridTemplateColumnsHeader: "160px 140px 130px 130px 130px 180px 100px 1fr"};
        height: 48px;
        background-color: rgba(18,121,218,0.1);
        background: #1279DA;
        /* border-radius: 10px 10px 0px 0px; */
        /* margin-top: 18px; */
        /* border-bottom: 2.5px solid rgba(18,121,218,0.15); */
        .title{
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-weight: bold;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            text-align: center;
            letter-spacing: 0.05px;
            color: #FFFFFF;
            padding: 0 10px;
            &:nth-child(1){
                padding:  0 10px 0 25px;
                justify-content: flex-start;
            }
            &:nth-child(3){
                justify-content: flex-start;
                /* padding: 0 0 0 20px; */
            }
            &:nth-child(4){
                justify-content: flex-start;
                /* padding: 0; */
            }
            &:nth-child(5){
                justify-content: flex-start;
                /* padding: 0; */
            }
            &:nth-child(6){
                /* justify-content: flex-end;
                padding: 0 25px 0 0; */
            }
            &:nth-child(7){
                justify-content: flex-end;
                padding: 0 18px 0 0;
            }
        }
    }

    .content-table {
        width: calc(100% + 12px);
        padding-right: 6px;
        overflow-x: hidden;
        overflow-y: scroll;
        position:relative;
        scroll-behavior: smooth;
        &::-webkit-scrollbar {
            width: 6px;
            background-color:transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #E2E2E2;
            background-clip: padding-box;
            border-radius: 20px;
        }
        .history-list-scroll{
            background-color: rgba(18,121,218,0.1);
            height: fit-content;
            min-height: 100%;
            
        }
        .title-no-data {        
            font-family: 'UTM-Avo';
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 150%;
            display: flex;
            justify-content: center;
            text-align: center;
            /* background-color: #ffffff; */
            color: rgba(5, 19, 33, 0.4);       
            padding: 40px 0 0 0;
            height: 100%;
            width: 100%;
            position:absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }

    
`

export {
    Bound
}