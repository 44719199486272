import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch } from 'react-redux';
//styles
import { Bound } from './index.styles'
//image
import ic_more_option from '../../../../../images/ic_more_option.svg'
//tools
import { convertDateTime } from '../../../../../helper/tools';
//component
import MoreOptionPopUp from '../../../../Popup/MoreOptionPopUp';
import ModalControl from '../../../../Control/ModalControl';
import IcMoreOption from '../../../../../images/component/IcMoreOption';
//constant
import * as keys from "../../../../../constant/key"
const extendStylesModal = `
    width: 100%;
    height: 100%;
`;

const ItemUser = (props) => {

    // const data = props.data
    const [isShowMoreOptionPopup, setIsShowMoreOptionPopup] = useState(false);
    const btnMoreRef = useRef(null);

    let userDataLocal = JSON.parse(localStorage.getItem(keys.FACEPAY_USER_DATA));

    const { data } = props

    const toggleMoreOption = useCallback(() => {

        setIsShowMoreOptionPopup(!isShowMoreOptionPopup)

    }, [btnMoreRef, isShowMoreOptionPopup])

    const _handleClickMore = (e) => {
        toggleMoreOption()
    }

    const _handleBlurPopup = (e) => {
        // e.preventDefault();
        // e.stopImmediatePropagation();
        toggleMoreOption()
    }


    return (
        <Bound
            gridTemplateColumnsContent={
                userDataLocal && 
                // userDataLocal.Role === keys.STORE_MANAGER && 
                [keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role) && 
                "160px 1fr 190px 185px 145px 160px"
            }>
            <div><span className="content-code-bill" data-toggle="tooltip" title={data && data.DeviceCode ? data.DeviceCode : "Invalid Identity"}>{data && data.DeviceCode ? data.DeviceCode : "-"}</span></div>
            <div><span className="content-name-device" data-toggle="tooltip" title={data && data.Name ? data.Name : "Invalid Identity"}>{data && data.Name ? data.Name : "-"}</span></div>
            <div>
                {
                    data && data.IsOnline && data.IsOnline === true ?
                        <span style={{ color: "#10C543" }}>Online</span>
                        :
                        <span style={{ color: "#EA3939" }}>Offline</span>
                }
            </div>
            <div>
                <span>{data && data.CreateTime ?
                    convertDateTime(data.CreateTime, "DD/MM/YYYY, HH:mm") : "-"}
                </span>
            </div>
            <div>
                <span>{data && data.UpdateTime ?
                    convertDateTime(data.UpdateTime, "DD/MM/YYYY, HH:mm") : "-"}
                </span>
            </div>
            {
                userDataLocal && 
                // userDataLocal.Role === keys.STORE_MANAGER ?
                [keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role) ?
                    <div>
                        <div
                            className="more-option-button"
                            onClick={_handleClickMore}
                            ref={btnMoreRef}
                        >
                            {/* <img className="content-last-activity" src={ic_more_option} alt="more-option" /> */}
                            <IcMoreOption className="content-last-activity" />
                        </div>
                        {
                            isShowMoreOptionPopup &&
                            <ModalControl extendStyles={extendStylesModal}>
                                <MoreOptionPopUp
                                    deviceItem={data}
                                    btnMoreRef={btnMoreRef}
                                    handleBlur={_handleBlurPopup}
                                />
                            </ModalControl>
                        }
                    </div>
                    :
                    <div className="name-store" data-toggle="tooltip" title={data && data.ShopName ? data.ShopName : "Invalid Identity"}>
                        {data && data.ShopName ? data.ShopName : "-"}
                    </div>
            }

        </Bound>
    )
}

export default ItemUser
