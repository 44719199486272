import styled from "styled-components";
import IcSearch from "../../../images/search.svg";
export const Dropdown = styled.div`
  margin-right: 10px;
  height: 36px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #1279da;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: UTM-Avo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 170%;
  display: flex;
  align-items: center;
  letter-spacing: 0.05px;
  color: #051321;
  padding: 6px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
  .down {
    transition: all 0.3s ease-in-out;
  }
  ${(props) => {
    const { expand } = props;
    if (expand) {
      return `.down {
                transform: rotate(180deg);
            }`;
    }
  }}
`;
export const Wrapper = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;
export const DropdownContent = styled.div`
  box-shadow: 0px 5px 48px rgba(0, 0, 0, 0.1);
  position: absolute;
  right: 10px;
  top: calc(100% + 10px);
  z-index: 100;
  width: max-content;
  max-width: 300px;
  background: #f3f3f3;
  border-radius: 6px;
  box-sizing: border-box;
  min-height: 0;
  max-height: 0;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  cursor: default;
  .scroll-content {
    max-height: ${330 - 36 - 30 - 12}px;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      border-radius: 9px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: rgba(18, 121, 218, 0.1);
      border-radius: 9px;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: rgba(18, 121, 218, 1);
      }
    }
  }
  ${(props) => {
    const { expand } = props;
    let height = 36 + 30 + 12;

    if (expand) {
      return `
                min-height: ${height}px;
                max-height: max-content;
                max-height: 330px;
            `;
    }
  }}
  .label {
    ::before {
      position: absolute;
      left: 18px;
      z-index: 1000;
      content: "";
      width: 16px;
      height: 16px;
      top: 16px;
      ${() => {
        return `content: url(${IcSearch});`;
      }}
    }
  }
  .searchInput {
    height: 36px;
    background: #ffffff;
    border: 1px solid #c6d5eb;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 6px;
    width: calc(100% - 12px);
    min-width: calc(300px - 12px);
    padding: 0px 10px 0px 36px;
    &:focus {
      outline: none;
    }
    ::placeholder {
      font-family: UTM-Avo;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 170%;
      display: flex;
      align-items: center;
      letter-spacing: 0.05px;
      color: #cdcdcd;
    }
    position: relative;
    ::before {
      position: absolute;
      left: 0;
      z-index: 1000;
      content: "";
      width: 30px;
      height: 36px;
      ${() => {
        return `background-image: url(${IcSearch});`;
      }}
    }
  }
  .selectAll {
    min-width: 100%;
    height: 30px;
    background: rgba(18, 121, 218, 0.1);
    display: flex;
    align-items: center;
    padding: 6px;
    box-sizing: border-box;
    font-family: UTM-Avo;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 170%;
    letter-spacing: 0.05px;
    color: #212121;
    .checkbox {
      border: 1px solid #1279da;
      box-sizing: border-box;
      border-radius: 3px;
      width: 16px;
      height: 16px;
      margin-right: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      &-checked {
        background: #1279da;
      }
      &-sticky {
        background-color: #fff;
      }
    }
  }
`;
export const CheckboxWrapper = styled.div`
  width: 300px;
  .childWrapper {
    height: 0;
    width: max-content;
    min-width: 100%;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    ${(props) => {
      const { expand, hasChild } = props;
      let height = 0;
      if (hasChild) {
        // 42 is height of child
        height = hasChild.length * 42;
      }
      if (expand) {
        return `height: ${height}px; overflow: visible; opacity: 1;`;
      }
    }}
    .childContent {
      width: ${300 - 40 - 16 - 12}px;
      text-overflow: ellipsis;
      box-sizing: border-box;
      overflow: hidden;
      position: relative;
      display: block;
      .childText {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        &-bold {
          color: #212121;
        }
      }
    }
  }
`;
export const Checkbox = styled.div`
  background-color: #fff;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 6px;
  box-sizing: border-box;
  font-family: UTM-Avo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 170%;
  letter-spacing: 0.05px;
  color: #212121;
  box-shadow: inset 0px -1px 0px #e3eaf5;
  min-width: 100%;
  width: max-content;
  transition: all 0.3s ease-in-out;
  .wrapper {
    p {
      max-width: 211px;
    }
    max-width: calc(100% - 12px - 16px - 6px - 3px - 6px);
    overflow: hidden;
    text-overflow: ellipsis;
    display: block !important;
  }
  ${(props) =>
    !props.hasChild &&
    `
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 14px;
        display: flex;
        align-items: center;
        letter-spacing: 0.05px;
        color: #959595;
        padding-left: 40px; 
        height: 42px;`}
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
    img {
      width: 3px;
      height: 6px;
      transition: all 0.3s ease-in-out;
      ${(props) => props.expand && `transform: rotate(90deg);`}
    }
  }
  .checkbox {
    border: 1px solid #1279da;
    box-sizing: border-box;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: false;
    &-checked {
      background: #1279da;
    }
    &-sticky {
      background-color: #fff;
    }
  }
`;
export const Tooltip = styled.div`
  position: fixed;
  z-index: 200000;
  display: none;
  background-color: #212121;
  color: #fff;
  font-family: UTM-Avo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 170%;
  letter-spacing: 0.05px;
  padding: 0px 6px;
  ${(props) => {
    const { visible, position } = props;
    if (visible) {
      return `display: block; top: ${position.y - 17}px; left: ${position.x}px`;
    }
  }}
`;
