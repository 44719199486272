import styled from 'styled-components'

const OptionListStyles = styled.div`    
    display:flex;
    flex-direction:column;
    width:174px;
    height:73px;
    background-color: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    position: fixed;
    z-index: 99;
    outline: none;
    /* position: absolute;
    top:0;
    right:calc(100% + 5px); */
    .option-line{
        display:flex;
        flex:1;
        border-bottom:0.5px solid #F8F8F8;
        align-items:center;
        cursor: pointer;
        padding-left:10px;
        :last-child{
            border-bottom: none;
        }
        p{
            font-family: "UTM-Avo";
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            letter-spacing: 0.05px;
            color: #222222;
        }
        .send-money{
            color: #222222;
        }
        .remove-device{
            color: #FF3B30;
        }
    }  

    ${props => props.extendStyles}  
`;

export { OptionListStyles };