import React, { Fragment } from 'react'
import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom'
//styles
import { Bound } from './index.styles'
//component
import Header from '../Header';
import TermsAndCondition from './TermsAndCondition';
import Help from './Help';
// import Contacts from './Contacts';

const Services = () => {

    const { path } = useRouteMatch();


    return (
        <Bound>

            <div className='service-container'>
                <Header />
                <Switch>
                    <Route path={`${path}/terms-and-condition`}>
                        <TermsAndCondition />
                    </Route>
                    {/* <Route path={`${path}/contact`}>
                        <Contacts />
                    </Route> */}
                    <Route path={`${path}/support`}>
                        <Help />
                    </Route>
                    <Redirect to="/" />
                </Switch>
            </div>

        </Bound>
    )
}

export default Services
