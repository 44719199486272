import React from "react";
import { Bound } from "./index.styles"
//data
import { data as introductionData } from "../../../data/introductionData";
//component
import IcLogoFacePay from "../../../images/component/IcLogoFacepay";

const Introduction = () => {

    const _renderIntro = (data) => {
        return data.map((item, i) => {
            return (
                <div className="intro-items" key={i}>
                    <div
                        className="intro-items-icon-bound"
                        style={{ backgroundColor: item.bgColor }}
                    >
                        {item.img}                        
                    </div>
                    <div className="intro-items-content">
                        <p className="intro-items-content-title">
                            {item.title}
                        </p>
                        <p className="intro-items-content-content">
                            {item.content}
                        </p>
                    </div>
                </div>
            );
        });
    }

    return (
        <Bound className="introduction-container">
            <div className="block-image">
                <IcLogoFacePay />
            </div>
            <div className='intro-container'>
                <p className="introduction">Giới thiệu</p>
                <div className="block-intro">
                    <p className="features">Các chức năng chính:</p>
                    {_renderIntro(introductionData)}
                </div>
            </div>
        </Bound>
    )
}

export default React.memo(Introduction);
