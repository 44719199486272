import * as types from "../../constant/typeAction"

export const initialState = {
    data: [],
    type: "",
    isBlocked: false,
    reasonBlocked: {
        lockReasonID: '',
        lockedReason: ''
    },
    listBlockedReason: []
}

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_LIST_REASON_LOCK: {
            return {
                ...state,
                listBlockedReason: action.payload.listReasons
            }
        }
        case types.ACCOUNT_BLOCKED: {
            return {
                ...state,
                type: action.type,
                ...action.payload,
            }
        }
        case types.LOGIN_USER:
            return {
                ...state,
                loginResult: action.data,
                type: action.type
            }
        case types.LOGIN_USER_NULL:
            return {
                ...state,
                loginResult: null,
                type: action.type
            }
        case types.LOGIN_USER_ERROR:
            return {
                ...state,
                err: action.err,
                type: action.type
            }
        case types.GENERATE_MPOS_QR:
            return {
                ...state,
                generateMPOSQR: action.data,
                type: action.type
            }
        case types.GENERATE_MPOS_QR_ERROR:
            return {
                ...state,
                err: action.err,
                type: action.type
            }
        case types.GET_USER:
            return {
                ...state,
                data: action.data,
                type: action.type
            }
        case types.GET_USER_ERROR: {
            return {
                ...state,
                err: action.err,
                type: action.type
            }
        }
        case types.GET_USER_NULL:
            return {
                ...state,
                data: {},
                type: action.type
            }
        case types.LOGOUT_USER:
            return {
                ...state,
                logoutResult: {},
                type: action.type
            }
        default:
            return state
    }
}