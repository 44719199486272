import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    /* overflow: hidden; */
    /* padding-top: 58px; */
    .headTitle_addnew{
        display: flex;
        align-items: baseline;
        font-family: 'SVN-Gilroy';
        font-style: normal;
        font-weight: bold;
        font-size: 32px;
        line-height: 115%;
        color: #051321;
        user-select: none;
        .ic-arrow{
            margin: 0 15px 0 15px;
        }
        p:nth-child(1){
            cursor: pointer;
            :hover{
                text-decoration: underline;
            }
        }
    }
    .no-data{
        width:100%;
        height: 100%;
        display:flex;
        justify-content:center;
        align-items:center;
    }
`

const styleButton = `
    width: 160px;
    height: 40px;
    background-color: #1279DA;
    border-radius: 50px;
    font-family: "UTM-Avo";
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 170%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05px;
    color: #FFFFFF;
    outline: none;
    border: none;
    cursor: pointer;
    .ic-white{
        margin-right: 15px;
    }    

`

export {
    Bound, styleButton
}