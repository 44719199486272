import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// styles
import { Bound } from "./index.styles"

//component
import HeaderHistory from './HeaderHistory';
import TableHistory from './TableHistory';

//action
import {
    getFilterStatistic, getSummary,
    changeFieldsFilter,
    getTotal
} from '../../../../redux/actions/statisticAction';

import * as types from '../../../../constant/typeAction';
import { FACEPAY_USER_DATA, STORE_MANAGER, CHAIN_MANAGER, WUM } from '../../../../constant/key';

const History = () => {

    const userDataLocal = JSON.parse(localStorage.getItem(FACEPAY_USER_DATA));

    const { statisticReducer, pagingReducer, shopReducer, systemReducer } = useSelector(state => ({
        statisticReducer: state.statisticReducer,
        pagingReducer: state.pagingReducer,
        shopReducer: state.shopReducer,
        systemReducer: state.systemReducer,
    }));

    const dispatch = useDispatch();

    const fetchFilterStatistic = useCallback(() => {
        if (
            !shopReducer.data ||
            !statisticReducer.fieldsFilter ||
            !statisticReducer.timeFilter ||
            !pagingReducer.history_list ||
            statisticReducer.fieldsFilter.countChange === 0
        ) return;

        const { fieldsFilter, timeFilter } = statisticReducer;
        const fromDate = timeFilter.timeBefore;
        const toDate = timeFilter.timeAfter;
        const pageSize = pagingReducer.history_list.pageSize;
        const pageNumber = fieldsFilter.pageNum;
        const billNumber = fieldsFilter.billCode;
        const facepayNumber = fieldsFilter.facepayNumber;

        let listShopID = [];
        // if (userDataLocal.Role === STORE_MANAGER) {
        if ([WUM, STORE_MANAGER].includes(userDataLocal.Role)) {
            listShopID = [shopReducer.data.Shops[0].ID]
        } else if (userDataLocal.Role === CHAIN_MANAGER) {
            if (!statisticReducer.shopFilter.listShopID) return;
            listShopID = statisticReducer.shopFilter.listShopID ? statisticReducer.shopFilter.listShopID : shopReducer.data.Shops.map(item => item.ID)
        }

        dispatch(getFilterStatistic(
            fromDate, toDate, pageSize, pageNumber, billNumber, facepayNumber, listShopID
        ))
        // }, [JSON.stringify(statisticReducer.fieldsFilter), JSON.stringify(statisticReducer.timeFilter), JSON.stringify(pagingReducer.history_list), JSON.stringify(statisticReducer.shopFilter)])
    }, [statisticReducer.fieldsFilter, statisticReducer.timeFilter, pagingReducer.history_list, statisticReducer.shopFilter])
    
    useEffect(() => {
        fetchFilterStatistic();
    }, [fetchFilterStatistic])

    const fetchChangeFielsFilter = useCallback(() => {
        if (statisticReducer.fieldsFilter) {
            const { fieldsFilter } = statisticReducer;
            if (fieldsFilter.pageNum === 1) return;
            const billNumber = fieldsFilter.billCode;
            const facepayNumber = fieldsFilter.facepayNumber;
            const compareAmount = fieldsFilter.compareAmount;
            dispatch(changeFieldsFilter(billNumber, facepayNumber, compareAmount, 1));
        }
    }, [JSON.stringify(statisticReducer.timeFilter)])

    useEffect(() => {
        fetchChangeFielsFilter();
    }, [fetchChangeFielsFilter])

    const refreshData = useCallback(() => {
        if (!shopReducer.data) return
        if (statisticReducer.type === types.RELOAD_GET_ALL_FILTER) {
            const { fieldsFilter, timeFilter } = statisticReducer;
            const fromDate = timeFilter.timeBefore;
            const toDate = timeFilter.timeAfter;
            const billNumber = fieldsFilter.billCode;
            const facepayNumber = fieldsFilter.facepayNumber;
            const compareAmount = fieldsFilter.compareAmount;
            const pageSize = pagingReducer.history_list.pageSize;
            const pageNumber = 1;

            let listShopID = [];
            // if (userDataLocal.Role === STORE_MANAGER) {
            if ([WUM, STORE_MANAGER].includes(userDataLocal.Role)) {
                listShopID = [shopReducer.data.Shops[0].ID]
            } else if (userDataLocal.Role === CHAIN_MANAGER) {
                listShopID = statisticReducer.shopFilter.listShopID ? statisticReducer.shopFilter.listShopID : shopReducer.data.Shops.map(item => item.ID)
            }
            dispatch(changeFieldsFilter(billNumber, facepayNumber, compareAmount, pageNumber));
            dispatch(getFilterStatistic(
                fromDate, toDate, pageSize, pageNumber, billNumber, facepayNumber, listShopID
            ))
            dispatch(getSummary(fromDate, toDate, listShopID));
            dispatch(getTotal(fromDate, toDate, listShopID));
        }
    },
        [statisticReducer.type, shopReducer.data],
    )
    useEffect(() => {
        refreshData();
    }, [refreshData])


    const auto_HotReload_Offline_To_Online = useCallback(
        () => {
            const { status, countSwitch } = systemReducer;

            if (status === types.WEB_IS_OFFLINE) {
                dispatch({
                    type: types.STATISTIC_PAGE_OFFLINE,
                });
                return;
            }

            if (status === types.WEB_IS_ONLINE && countSwitch > 0 && statisticReducer.type === types.STATISTIC_PAGE_OFFLINE) {
                if (
                    !shopReducer.data ||
                    !statisticReducer.fieldsFilter ||
                    !statisticReducer.timeFilter ||
                    !pagingReducer.history_list
                ) return;
                const { fieldsFilter, timeFilter } = statisticReducer;
                const fromDate = timeFilter.timeBefore;
                const toDate = timeFilter.timeAfter;
                const pageSize = pagingReducer.history_list.pageSize;
                const pageNumber = fieldsFilter.pageNum;
                const billNumber = fieldsFilter.billCode;
                const facepayNumber = fieldsFilter.facepayNumber;

                let listShopID = [];
                // if (userDataLocal.Role === STORE_MANAGER) {
                if ([WUM, STORE_MANAGER].includes(userDataLocal.Role)) {
                    listShopID = [shopReducer.data.Shops[0].ID]
                } else if (userDataLocal.Role === CHAIN_MANAGER) {
                    listShopID = statisticReducer.shopFilter.listShopID ? statisticReducer.shopFilter.listShopID : shopReducer.data.Shops.map(item => item.ID)
                }

                dispatch(getFilterStatistic(
                    fromDate, toDate, pageSize, pageNumber, billNumber, facepayNumber, listShopID
                ))
                dispatch(getSummary(fromDate, toDate, listShopID));
                dispatch(getTotal(fromDate, toDate, listShopID));
            }
        },
        [systemReducer],
    )
    useEffect(() => {
        auto_HotReload_Offline_To_Online()
    }, [auto_HotReload_Offline_To_Online])


    return (
        <Bound>
            <HeaderHistory />
            <TableHistory />
        </Bound>
    );
}

export default React.memo(History);
