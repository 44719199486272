import React from 'react'

const IcPhoneMerchant = (props) => {
    return <svg {...props} width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.13808 6.86126C4.03068 5.75316 2.97088 4.46936 3.47838 3.96186C4.20428 3.23596 4.83708 2.78796 3.54978 1.18636C2.26318 -0.41454 1.40498 0.815359 0.702178 1.51886C-0.109821 2.33086 0.658779 5.35766 3.64988 8.34946C6.64168 11.3406 9.66848 12.1071 10.4805 11.2944C11.1847 10.5909 12.4139 9.73686 10.813 8.45026C9.21208 7.16296 8.76408 7.79506 8.03748 8.52236C7.52998 9.02776 6.24618 7.96866 5.13808 6.86126Z" fill="#BFDDF6" />
  </svg>   
}

export default IcPhoneMerchant
    







