import React from 'react'
import PropTypes from 'prop-types'
import viVN from 'antd/es/date-picker/locale/vi_VN';

// styles
import { Bound } from "./index.styles"

//// Ant Design
import { DatePicker } from "antd";


const DatePickerControl = ({ firstContent, lastContent, extendStyles,...props}) => {

    return (
        <Bound extendStyles={extendStyles}>
            {firstContent}
            <DatePicker locale={viVN} {...props} />
            {lastContent}
        </Bound>
    )
}

DatePickerControl.propTypes = {
    firstContent: PropTypes.any,
    lastContent: PropTypes.any,
    extendStyles: PropTypes.any,
}

export default React.memo(DatePickerControl);


