import styled, { css } from "styled-components";

const Bound = styled.div`
    font-family: "UTM-Avo";
    font-style: normal;
    grid-column: 1 / span 2;
    display: flex;
    flex: 1;
    background: #ecf4ff;
    border-radius: 20px 20px 0px 0px;
    height: 76px;
    align-items: center;
    justify-content: space-between;
    .wrapper {
        display: flex;
        width: max-content;
        .text-date-before,
        .text-date-after {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            letter-spacing: 0.05px;
            color: #051321;
        }
        .text-date-before {
            margin: 0 0 0 20px;
        }
        .text-date-after {
            margin: 0 0 0 40px;
        }
    }

    .content-right{
        display: flex;
        justify-content: center;
        align-items: center;
        .btn-reload{
            margin: 0 20px 0 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border: 1px solid #1279DA;
            box-sizing: border-box;
            border-radius: 6px;
            cursor: pointer;
            img{
                width: 16px;
                object-fit: contain;
            }
            &:hover{                
                background-color: #ffffff;        
            }
        }
        .icon-reload{
            
        }
    }
`;

const stylesDateFirst = css`
    display: flex;
    align-items: center;
    /* .ant-picker{ */
    .ant-calendar-picker {
        margin: 0 0 0 11px;
        /* border: 1px solid #1279DA; */
        box-sizing: border-box;
        border-radius: 6px;
        width: 120px;
        height: 36px;
        /* .ant-picker-input{ */
        .ant-calendar-picker-input {
            width: 120px;
            height: 36px;
            border: 1px solid #1279da;
            input {
                font-weight: normal;
                font-size: 14px;
                line-height: 170%;
                display: flex;
                align-items: center;
                letter-spacing: 0.05px;
                color: #051321;
                width: 120px;
                height: 36px;
                border: 1px solid #1279da;
            }
            .ant-picker-suffix {
                display: flex;
                opacity: 0.6;
            }
        }
        .ant-calendar-picker-clear {
            display: flex;
            opacity: 0.6;
        }
    }
`;

const stylesDateSecond = css`
    display: flex;
    align-items: center;
    /* .ant-picker{ */
    .ant-calendar-picker {
        margin: 0 0 0 11px;
        /* border: 1px solid #1279DA; */
        box-sizing: border-box;
        border-radius: 6px;
        /* width: 120px;
        height: 36px; */
        /* .ant-picker-input{ */
        .ant-calendar-picker-input {
            width: 120px;
            height: 36px;
            border: 1px solid #1279da;
            input {
                font-weight: normal;
                font-size: 14px;
                line-height: 170%;
                display: flex;
                align-items: center;
                letter-spacing: 0.05px;
                color: #051321;
                width: 120px;
                height: 36px;
                border: 1px solid #1279da;
            }
            .ant-picker-suffix {
                display: flex;
                opacity: 0.6;
            }
        }
        .ant-calendar-picker-clear {
            display: flex;
            opacity: 0.6;
        }
    }
`;

const BlockItemDatePicker = styled.div`
    background: transparent;
    width: 36px;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
`;

export { Bound, stylesDateFirst, stylesDateSecond, BlockItemDatePicker };
