import styled, { keyframes } from "styled-components";


const animateShow = keyframes`
    from {
        width: 0;
        opacity: 0;
    }
    
    to {
        width: 2px;
        opacity: 1;
    }
`
const animateTab = keyframes`
    from {
        opacity: 0;
    }
    
    to {
        opacity: 1;
    }
`


const BoundContainer = styled.div`
    display: flex;
    flex:1;
    color: white;
    .login-container{
        display: flex;
        flex: 1;
        flex-direction: column;
        .header{
            display: flex;
            /* width: fit-content; */
            /* margin: 0 0 0 60px; */
            .shape{
                display:flex;
                width:122px;
                height:48px;
                background-color:#E9EEF3;
                position: relative;
                justify-content:center;
                align-items:center;
                cursor: pointer;
                font-family: 'UTM-Avo';
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 17px;
                color: #959595;
                transition-property:color background-color;
                transition-duration:500ms;
            }
            .left{
                border-top-left-radius:10px;
                border-top-right-radius: 10px 5px;
                span{
                    width: 134px;
                    height: 1px;
                    background-color: #47A5F2;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transition: .2s all ease-in-out;
                    animation-name: ${animateShow};
                    animation-duration: .2s;
                }
                /* :hover{
                    :before{
                        z-index: 1;
                    }
                } */
            }
            .left:before {
                content: "";
                position: absolute;
                right: -12px;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 12px solid #E9EEF3;
                border-top: 44px solid transparent;
                transition-property:border-left-color;
                transition-duration:500ms;
            }
            .right{
                border-radius: 0 23px 0 0;
                /* border-top-right-radius:10px; */
                /* border-bottom-left-radius: 10px 5px; */
                span{
                    width: 122px;
                    height: 1px;
                    background-color: #47A5F2;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    transition: .2s all ease-in-out;
                    animation-name: ${animateShow};
                    animation-duration: .2s;
                }
                /* :hover{
                    :before{
                        z-index: 1;
                    }
                } */
            }
            .right:before {
                content: "";
                position: absolute;
                left: -14px;
                top: 0;
                width: 0;
                height: 0;
                border-right: 14px solid #E9EEF3;
                border-bottom: 44px solid transparent;
                transition-property:border-right-color;
                transition-duration:500ms;
            }
            .right:after {
                content: "";
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 0;
                    height: 0;
                    border-left: 12px solid transparent;
                    border-top: 45px solid #051321;
                    transition-duration: 500ms;
            }
            .create-shop{
                color: #212121;
                width: 218px;
            }
            .active{
                background-color:#FFFFFF;
                color:#2D98F0;
            }
            .active:before{
                border-right-color:#FFFFFF;
                border-left-color:#FFFFFF;
            }
            /* &__account{
                cursor: pointer;
                position: relative;
                background-image:${props => props.imageTabAccount && `url(${props.imageTabAccount})`};
                z-index: ${props => props.tab === "ACCOUNT" ? "1" : "2"};
                width: 148px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .2s all ease-in-out;
                animation-name: ${animateTab};
                animation-duration: .2s;
                p{
                    font-family: UTM-Avo;
                    font-style: normal;
                    font-weight: ${props => props.tab === "ACCOUNT" ? "bold" : "normal"};
                    font-size: 14px;
                    line-height: 122.34%;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    text-transform: uppercase;
                    color: ${props => props.tab === "ACCOUNT" ? "#2D98F0" : "#999999"};
                }
                span{
                    width: 2px;
                    height: 30px;
                    background-color: #2579EA;
                    position: absolute;
                    left: 0;
                    transition: .2s all ease-in-out;
                    animation-name: ${animateShow};
                    animation-duration: .2s;
                }
            }
            &__qrCode{
                margin-left: -13px;
                cursor: pointer;
                position: relative;
                background-image:${props => props.imageTabQRCODE && `url(${props.imageTabQRCODE})`};
                z-index: ${props => props.tab === "QRCODE" ? "1" : "2"};
                width: 148px;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .2s all ease-in-out;
                animation-name: ${animateTab};
                animation-duration: .2s;
              
                p{
                    font-family: UTM-Avo;
                    font-style: normal;
                    font-weight: ${props => props.tab === "QRCODE" ? "bold" : "normal"};
                    font-size: 14px;
                    line-height: 122.34%;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    text-transform: uppercase;
                    color: ${props => props.tab === "QRCODE" ? "#2D98F0" : "#999999"};
                    position: absolute;
                }
                span{
                    width: 2px;
                    height: 30px;
                    background-color: #2579EA;
                    position: absolute;
                    right: 0;
                    transition: .2s all ease-in-out;
                    animation-name: ${animateShow};
                    animation-duration: .2s;
                }
            } */
        }
        .content-login{
            width: 685px;
            height: 100%;
            background-color: #FFFFFF;
            border-radius: 0 20px 20px 20px;
        }
    }
`


export { BoundContainer }