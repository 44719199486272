import React from 'react'

const IcMoreOption = (props) => {
    return <svg {...props} width={3} height={17} viewBox="0 0 3 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="1.5" cy="1.5" r="1.5" fill="#C6D5EB" />
    <circle cx="1.5" cy="8.5" r="1.5" fill="#C6D5EB" />
    <circle cx="1.5" cy="15.5" r="1.5" fill="#C6D5EB" />
  </svg>
}

export default IcMoreOption
