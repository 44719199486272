import { combineReducers } from 'redux';

// type action
import { LOGOUT_USER, LOGIN_USER } from '../../constant/typeAction';

import userReducer, { initialState as initialStateUserReducer } from './userReducer';
import posReducer, { initialState as initialStatePosReducer } from './posReducer';
import statisticReducer, { initialState as initialStateStatisticReducer } from './statisticReducer';
import notifyReducer, { initialState as initialStateNotifyReducer } from './notifyReducer';
import shopReducer, { initialState as initialStateShopReducer } from './shopReducer';
import pagingReducer, { initialState as initialStatePagingReducer } from './pagingReducer';
import paymentReducer, { initialState as initialStatePaymentReducer } from './paymentReducer';
import systemReducer, { initialState as initialStateSystemReducer } from './systemReducer';

const initialState = {
    userReducer: initialStateUserReducer,
    posReducer: initialStatePosReducer,
    statisticReducer: initialStateStatisticReducer,
    notifyReducer: initialStateNotifyReducer,
    shopReducer: initialStateShopReducer,
    pagingReducer: initialStatePagingReducer,
    paymentReducer: initialStatePaymentReducer,
    systemReducer: initialStateSystemReducer,
}

const appReducer = combineReducers({
    userReducer,
    posReducer,
    statisticReducer,
    notifyReducer,
    shopReducer,
    pagingReducer,
    paymentReducer,
    systemReducer
})

const mainReducer = (state, action) => {

    if (action.type === LOGIN_USER) {
        state = {
            ...state,
            notifyReducer: initialStateNotifyReducer
        }
    }

    if (action.type === LOGOUT_USER) {
        const { pagingReducer, notifyReducer } = state;
        state = {
            ...initialState,
            // pagingReducer,
            // notifyReducer
        };
    }

    return appReducer(state, action)
}

export default mainReducer