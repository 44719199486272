import { useEffect } from "react";
export const useClickOutside = (ref, fn, overrideOpts = {}) => {
    const defaultOpts = {
        root: { current: null },
        except: [],
        listenerType: "click",
        listenerOpts: { passive: true }
    };
    const opts = Object.assign(defaultOpts, overrideOpts);
    useEffect(() => {
        const currentRoot = opts.root.current || document;
        const listener = e => {
            const isBetweenRootAndTarget = [ref, ...opts.except].every(
                element => {
                    const isInsideRoot = !isOutside(e.target, currentRoot);
                    const isOutsideOfTarget = isOutside(
                        e.target,
                        element.current
                    );
                    return isInsideRoot && isOutsideOfTarget;
                }
            );
            if (isBetweenRootAndTarget) {
                fn(e);
            }
        };
        // const _onClick = (e) => {
        //     const isClickTarget = e.target === ref.current;
        //     const isClickChildOfTarget = ref.current &&
        //         ref.current.contains(e.target);
        //     if (isClickTarget || isClickChildOfTarget) {
        //         // window.removeEventListener('click', _onClick, true)
        //         return;
        //     }
        //     e.preventDefault();
        //     e.stopPropagation();
        //     fn(e);
        //     window.removeEventListener('click', _onClick, true)
        // }
        // window.addEventListener('click', _onClick, true)
        currentRoot.addEventListener(
            opts.listenerType,
            listener,
            opts.listenerOpts
        );
        return () => {
            currentRoot.removeEventListener(
                opts.listenerType,
                listener,
                opts.listenerOpts
            );
        };
    }, [fn, opts, ref]);
};

const isOutside = (target, source) => {
    if (!(target instanceof Node)) return false;
    if (!(source instanceof Node)) return false;
    if (source.isEqualNode(target)) return false;
    if (source.contains(target)) return false;
    return true;
};
