import * as types from "../../constant/typeAction";

export const initialState = {
    data: null,
    stores: [],
    type: "",
    err: "",
    dataCreateShop: {}
};

export default function shopReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_DATA_CREATE_SHOP:
            return {
                ...state,
                dataCreateShop: {
                    ...state.dataCreateShop,
                    ...action.payload.dataUpdate
                }
            }
        case types.GET_SHOP_START:
            return {
                ...state,
                type: action.type,
                err: ""
            };
        case types.GET_SHOP_SUCCESS:
            return {
                ...state,
                data: action.data,
                type: action.type,
                err: ""
            };
        case types.GET_SHOP_ERROR: {
            return {
                ...state,
                type: action.type,
                err: action.error,
            };
        }
        case types.GET_FILTER_SHOP_START:
            return {
                ...state,
                type: action.type,
                err: action.error,
            };
        case types.GET_FILTER_SHOP_SUCCESS:
            return {
                ...state,
                type: action.type,
                stores: action.data,
                err: action.error,
            };
        case types.GET_FILTER_SHOP_ERROR: {
            return {
                ...state,
                err: action.err,
                type: action.type,
            };
        }
        default:
            return state;
    }
}
