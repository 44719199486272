import React from 'react'

const IcPlus = (props) => {
    return <svg {...props} width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1={1} y1="8.5" x2={17} y2="8.5" stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    <line x1={9} y1={1} x2={9} y2={17} stroke="black" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
}

export default IcPlus
