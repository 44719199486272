import React, { useEffect, useState } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// component
// import Spinner from '../ui/Control/Spinner';

// constant
import { FACEPAY_USER_DATA, CHAIN_MANAGER } from '../constant/key';

// action
import { pushError } from '../redux/actions/notifyAction';
import { logout, getReasonsLock } from '../redux/actions/userAction';

export const validateTypes = {
    NOT_AUTH: "NOT_AUTH",
    LOGED_IN: "LOGED_IN",
    NOT_LINK_SHOP: "NOT_LINK_SHOP",
    ADDED_DEVICE: "ADDED_DEVICE",
    BLOCKED_ACCOUNT: 'BLOCKED_ACCOUNT'
}

const PrivateRoute = ({ isAuth = null, children, ...rest }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(isAuth)
    const dispatch = useDispatch();
    const history = useHistory();

    const { userReducer } = useSelector(state => ({
        userReducer: state.userReducer
    }))

    useEffect(() => {
        if(userReducer.isBlocked){
            dispatch(getReasonsLock())
        }
    }, [userReducer.isBlocked])

    useEffect(() => {
        const allTypesValidate = Object.keys(validateTypes)
        if (allTypesValidate.includes(isAuth)) {return;}

        const facePayLoginData = localStorage.getItem(FACEPAY_USER_DATA);
        if (!facePayLoginData) {
            setIsAuthenticated(validateTypes.NOT_AUTH)
            return;
        }

        const loginResult = JSON.parse(facePayLoginData)

        const { LinkPos, LinkedStore, Role } = loginResult;
        if (!LinkedStore) {
            dispatch(pushError({
                type: validateTypes.NOT_LINK_SHOP,
                error: {}
            }))
            history.push("/login")
            // dispatch(logout(history))

            // localStorage.removeItem(FACEPAY_USER_DATA);
            // setIsAuthenticated(validateTypes.NOT_LINK_SHOP)
            return;
        }

        if(userReducer.isBlocked){
            setIsAuthenticated(validateTypes.BLOCKED_ACCOUNT)
            return
        }

        // if ([CHAIN_MANAGER].includes(Role)) {

        // }

        if ([CHAIN_MANAGER].includes(Role) || LinkPos) {
            setIsAuthenticated(validateTypes.ADDED_DEVICE)
            return;
        }
        
        setIsAuthenticated(validateTypes.LOGED_IN)

    })

    const _renderRoute = (location) => {
        switch (isAuthenticated) {
            case validateTypes.NOT_AUTH:
                return <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location }
                    }}
                />
            case validateTypes.LOGED_IN: {
                const { pathname } = location;
                if (pathname === "/main/pos/add")
                    return children;
                return <Redirect
                    to={{
                        pathname: "/main/pos/add",
                        state: { from: location }
                    }}
                />
            }
            // case validateTypes.NOT_LINK_SHOP: {
            //     return <Redirect
            //         to={{
            //             pathname: "/login",
            //             state: { from: location }
            //         }}
            //     />
            // }
            case validateTypes.BLOCKED_ACCOUNT: {
                const { pathname } = location;
                if (pathname === "/main/locked-account")
                    return children;
                return <Redirect
                    to={{
                        pathname: "/main/locked-account",
                        state: { from: location }
                    }}
                />
            }
            case validateTypes.ADDED_DEVICE:
                return children;
            default:
                // return <Spinner />
                return null;
        }
    }

    return (
        <Route
            {...rest}
            render={({ location }) =>
                _renderRoute(location, isAuthenticated)
            }
        />
    );
}

export default PrivateRoute