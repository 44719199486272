import Axios from "axios";
import moment from "moment";

import * as types from "../../constant/typeAction";

import { host } from "../../host/host";
import * as keys from "../../constant/key";
// action
import { pushError } from "./notifyAction";
import { dispatchLogin } from "./userAction";
// tools
import getError from "../../helper/getErrorServices";

export const getShop = () => async dispatch => {
    const hostGetShop = host + "shop/get";
    dispatch({
        type: types.GET_SHOP_START
    });
    dispatch({
        type: types.ACTIVE_LOADING_PAGE,
    })
    try {
        const res = await Axios.get(hostGetShop);
        const { data } = res;
        if (data && data.Code === 0) {
            const dataUserLocal = JSON.parse(
                localStorage.getItem(keys.FACEPAY_USER_DATA)
            );
            let newDataUserLocal = null;
            // if (dataUserLocal.Role === keys.STORE_MANAGER) {
            if ([keys.WUM, keys.STORE_MANAGER].includes(dataUserLocal.Role)) {
                dispatch({
                    type: "CHANGE_TIME_FILTER",
                    data: {
                        // timeBefore: moment("2020-06-15T08:41:08.815Z"),
                        timeBefore: data ? moment(data.Shops[0].FirstTrans) : moment().startOf("month"),
                        timeAfter: moment().endOf("day")
                    }
                })
                // data.data.Shops[0].FirstTrans = "2020-06-15T08:41:08.815Z"
                newDataUserLocal = {
                    ...dataUserLocal,
                    Store: data.Shops[0]
                };
            } else if (dataUserLocal.Role === keys.CHAIN_MANAGER) {
                dispatch({
                    type: "CHANGE_TIME_FILTER",
                    data: {
                        // timeBefore: moment("2020-06-15T08:41:08.815Z"),
                        timeBefore: data ? moment(data.Chain.FirstTrans) : moment().startOf("month"),
                        timeAfter: moment().endOf("day")
                    }
                })
                // data.Chain.FirstTrans = "2020-06-15T08:41:08.815Z"
                newDataUserLocal = {
                    ...dataUserLocal,
                    Chain: data.Chain
                };
            }
            localStorage.setItem(
                keys.FACEPAY_USER_DATA,
                JSON.stringify(newDataUserLocal)
            );
            dispatch({
                type: types.GET_SHOP_SUCCESS,
                data
            });
        } else {
            dispatch({
                type: types.GET_SHOP_ERROR,
                error: data
            });
            dispatch(
                pushError({
                    type: types.GET_SHOP_ERROR,
                    error: data
                })
            );
        }

        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })

    } catch (err) {
        dispatch({
            type: types.GET_SHOP_ERROR,
            error: getError(err)
        });
        dispatch(
            pushError({
                type: types.GET_SHOP_ERROR,
                error: getError(err)
            })
        );

        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })
    }
};

export const getFilterShop = () => async dispatch => {
    const hostGetFitlerShop = host + "shop/filter";
    dispatch({
        type: types.GET_FILTER_SHOP_START
    });
    try {
        const res = await Axios.get(hostGetFitlerShop);
        const { data } = res;
        const arr = [];

        if (data && data.Code === 0 && data.Filter) {
            Object.keys(data.Filter).map(key => {
                arr.push({
                    id: key,
                    ...data.Filter[key]
                });
                return key;
            });
            dispatch({
                type: types.GET_FILTER_SHOP_SUCCESS,
                data: arr
            });
        } else {
            dispatch(
                pushError({
                    type: types.GET_FILTER_SHOP_ERROR,
                    error: data
                })
            );
        }

    } catch (err) {
        dispatch(
            pushError({
                type: types.GET_FILTER_SHOP_ERROR,
                error: getError(err)
            })
        );
    }
};

export const createStore = 
    (shopAvatar, shopName, category, email, address, longitude, latitude, history, callbackSuccess, callbackError) => 
    async (dispatch, getState) => {
    const store = getState();

    const { userReducer } = store
    const { loginResult } = userReducer
    
    const queryDataFormat = JSON.stringify({
        ShopAvatar: shopAvatar,
        ShopName:   shopName,
        Category:   category,
        Email:      email,
        Address:    address,
        Lng:  longitude,
        Lat:   latitude
    })
    const hostCreateStore = `${host}web-mpos/create-new-store`
    // debugger
    try {
        const res = await Axios.post(hostCreateStore, queryDataFormat)
        console.log(res);
        const {data} = res;
        if(data && data.Code === 0){
            callbackSuccess && callbackSuccess()
            const dataLogin = {
                ...loginResult,
                LinkedStore: true
            }
            localStorage.setItem(keys.FACEPAY_USER_DATA, JSON.stringify(dataLogin))
            dispatch(dispatchLogin(dataLogin))
            history.push("/")
            // dispatch(dispatchLogin(data))
        }
        else{
            dispatch(
                pushError({
                    type: types.CREATE_STORE_ERR,
                    error: data
                })
            );
            callbackError && callbackError()
        }
    }
    catch(err){
        dispatch(pushError({
            type: types.CREATE_STORE_ERR,
            error: getError(err)
        }))
        callbackError && callbackError()
        // dispatch({
        //     type: types.LOGIN_USER_ERROR,
        //     err
        // })
    }
}

export const setDataCreateShop = (dataUpdate) => ({
    type: types.SET_DATA_CREATE_SHOP,
    payload: {
        dataUpdate
    }
})