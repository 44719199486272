import styled, { css } from "styled-components";

const Bound = styled.div`
    width: 100vw;
    .popup-container {
        width: 540px;
        height: 64px;
        background: #FFFAE1;
        border: 1px solid #FFD000;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        align-items: center;
        position: absolute;
        top: 50px;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 0 35px 0 25px;
        p{
            width: 314px;
            margin: 0 0 0 17px;
            font-family: UTM-Avo;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 128.91%;
            color: #051321;
        }
        transition-duration: 0.5s;
        animation-name: isShowWarning;
        transition-delay: .5s;
        @keyframes isShowWarning {
            from {
                opacity: 0;
                top: 0;
            }
            to {
                opacity: 1;
                top: 50px;
            }
        }
    }
`

const styleButton = css`
    background-color: #1279DA;
    border-radius: 6px;
    border: none;
    width: 86px;
    height: 26px;
    outline:none;
    font-family: "UTM-Avo";
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 128.91%;
    text-align: center;
    color: #FFFFFF;
    margin: 0 0 0 40px;
`

export { Bound, styleButton }