import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex-direction: row;
    height: 195px;
    max-width: 545px;
    position: relative;
    background-color: #ffffff;
    border: 1px solid #c6d5eb;
    box-sizing: border-box;
    border-radius: 20px;
    font-family: "UTM-Avo";
    .pos-img-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* .pos-img {
            width: 41px;
            height: 136px;
            margin-bottom: 2px;
        } */
        .pos-status {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 90px;
            height: 28px;
            border-width: 1px;
            border-style: solid;
            box-sizing: border-box;
            border-radius: 5px;
            margin-top: 12px;
            .light {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                margin-right: 10px;
            }
            .on {
                background-color: #34c759;
            }
            .off {
                background-color: #ff3b30;
            }
            p {
                font-family: "UTM-Avo";
                font-style: normal;
                font-weight: normal;
                font-size: 11px;
                line-height: 150%;
                display: flex;
                align-items: center;
                letter-spacing: 0.02em;
                color: #FF3B30;
            }
        }
        .status-on {
            border-color: #34c759;
            p {
                color: #34c759;
            }
        }
        .status-off {
            border-color: #ff3b30;
            p {
                color: #ff3b30;
            }
        }
    }
    .pos-content-container {
        display: flex;
        flex: 2;
        flex-direction: column;
        padding: 20px 20px 20px 0;
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .pos-name {
            font-family: "UTM-Avo";
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 330px;
            height: 27px;
            font-weight: bold;
            font-size: ${props => props.fz_posName || "16px"};
            line-height: 27px;
            letter-spacing: 0.05px;
            color: #051321;
            margin-bottom: 20px;
        }
        .pos-line {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 11px;
            .icon-image {
                display: flex;
                width: 14px;
                height: 14px;
                margin-right: 10px;
            }
            .title,
            .content,
            .location {
                display: flex;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.05px;
            }
            .title {
                color: #859cbe;
                margin-right: 5px;
            }
            .content {
                color: #051321;
            }
            .location {
                color: #1279da;
            }
        }
    }
    .more-option-button {
        width: 17px;
        height: 17px;
        cursor: pointer;
        justify-content: center;
        display: flex;
        position: absolute;
        top: 20px;
        right: 20px;
        img {
            height: 17px;
        }        
    }
`


export {
    Bound
}