import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

//image
import ic_question from '../../../../images/ic_question.svg'

// styles
import { DeleteConfirmStyles } from './index.styles'

// hooks
import useBlur from '../../../../helper/useBlur'
import useScrollWindow from '../../../../helper/useScrollWindow'

// action
import { removePOS } from '../../../../redux/actions/posActions'
import IcQuestion from '../../../../images/component/IcQuestion'

const DeleteConfirm = ({ 
    deviceItem,
    extendStyles,
    handleBlur,
}) => {
    const dispatch = useDispatch();
    const selfRef = useRef(null);
    useBlur(handleBlur, [selfRef])
    useScrollWindow([selfRef])

    const _handleCancel = (e) => {
        handleBlur();
    }

    const _handleSubmit = (e) => {
        dispatch(removePOS(deviceItem.ID))
        handleBlur();
    }

    return (
        <DeleteConfirmStyles 
            extendStyles={extendStyles}
            ref={selfRef}
        >
            <div className="confirm-popup-head">
                {/* <img src={ic_question} alt='question' /> */}
                <IcQuestion />
                <div className='confirm-popup-title'>Xóa thiết bị</div>
            </div>
            <p className='confirm-popup-content'>Thiết bị sẽ ngắt kết nối với hệ thống</p>
            <div className='confirm-popup-button-container'>
                <div className='cancel-button'
                    onClick={_handleCancel}>
                    Không
                    </div>
                <div className='submit-button'
                    onClick={_handleSubmit}>
                    Đồng ý
                    </div>
            </div>
        </DeleteConfirmStyles>
    )
}

export default DeleteConfirm
