import styled from "styled-components";

const Bound = styled.div`
    padding: 67px 145px 60px 95px;
    height: 100%;
    position: relative;
    font-family: 'UTM-Avo';
    font-style: normal;
    font-weight: normal;
    transition-duration: 0.5s;
    animation-name: isShowTabAccount;
    @keyframes isShowTabAccount {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    .title{
        font-size: 14px;
        color: #051321;
        line-height: 170%;
        letter-spacing: 0.05px;
    }
    form{
        margin: 60px 0 0 0;
        color: #999999;
       .input-email, .input-password{
            background-color: #FFFFFF;
            border: 1px solid #C6D5EB;
            box-sizing: border-box;
            border-radius: 3px;
            padding: 0 10px;
            /* width: calc(445px - 20px); */
            width: 445px;
            height: 42px;
            letter-spacing: 0.09em;
            font-size: 14px;
            line-height: 19px;
            color: #212121;
            /* transition: .2s all ease-in-out; */
           &:focus{
                border: 2px solid #1279DA;
                outline:none;
           }
           &::placeholder{
            opacity: .5;
           }
       } 
       .input-email{
            margin: 0 0 20px 0;
       }
       .label-email, .label-password{
            margin: 0 0 10px 10px;
            font-size: 12px;
       }

       .block-pass{
            position: relative;
            width: fit-content;
            .hint-pass{
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translate(-50%, -10px);
                cursor: pointer;
            }
            .input-password{
                padding-right: 50px ;
            }
       }
    }
    .err-content{
        margin: 25px 0 0 0;
        font-family: UTM-Avo;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 132%;
        color: #F03B3B;
    }
`

const styleButton = `
    background-color: #1279DA;
    border-radius: 50px;
    width: 445px;
    height:60px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 170%;
    letter-spacing: 0.05px;
    color: #FFFFFF;
    outline:none;
    border: none;
    position: absolute;
    bottom: 60px;
    left: 95px;
`

export { Bound, styleButton }