import React from 'react'

const IcDelRedBgPink = (props) => {
    return (
        <svg {...props} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle opacity="0.7" cx={12} cy={12} r={12} fill="#FA8C8C" />
            <g clipPath="url(#ic-del-red-bg-pink)">
                <path d="M8.47803 9.65234H9.26064H15.5215" stroke="#E43A3A" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.4347 9.65213V8.86952C10.4347 8.66196 10.5171 8.4629 10.6639 8.31613C10.8106 8.16937 11.0097 8.08691 11.2173 8.08691H12.7825C12.99 8.08691 13.1891 8.16937 13.3359 8.31613C13.4826 8.4629 13.5651 8.66196 13.5651 8.86952V9.65213M14.739 9.65213V15.1304C14.739 15.338 14.6565 15.537 14.5098 15.6838C14.363 15.8305 14.164 15.913 13.9564 15.913H10.0434C9.83579 15.913 9.63673 15.8305 9.48996 15.6838C9.3432 15.537 9.26074 15.338 9.26074 15.1304V9.65213H14.739Z" stroke="#E43A3A" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="ic-del-red-bg-pink">
                    <rect width="9.3913" height="9.3913" fill="white" transform="translate(7.3042 7.30469)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default IcDelRedBgPink
