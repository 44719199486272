import React from 'react'

const IcAdsManage = (props) => {
    return <svg {...props} width={27} height={47} viewBox="0 0 27 47" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24.432 0H2.78783C1.65528 0 0.774414 0.880869 0.774414 2.01341V30.8933C0.774414 32.0259 1.65528 32.9067 2.78783 32.9067H12.5403V44.1063H7.31801C7.12925 44.1063 7.00341 44.2322 7.00341 44.4209V45.8052C7.00341 45.9939 7.12925 46.1198 7.31801 46.1198H19.8389C20.0277 46.1198 20.1535 45.9939 20.1535 45.8052V44.4209C20.1535 44.2322 20.0277 44.1063 19.8389 44.1063H14.6166V32.9067H24.3691C25.5016 32.9067 26.3825 32.0259 26.3825 30.8933V2.01341C26.4454 0.880869 25.5016 0 24.432 0ZM24.5579 30.8933C24.5579 30.9562 24.4949 31.0192 24.432 31.0192H2.78783C2.72491 31.0192 2.66199 30.9562 2.66199 30.8933V2.01341C2.66199 1.95049 2.72491 1.88758 2.78783 1.88758H24.432C24.4949 1.88758 24.5579 1.95049 24.5579 2.01341V30.8933Z" fill="white" />
    <rect x="4.06885" y="3.29395" width="19.1068" height="26.3541" fill="white" />
  </svg>
}

export default IcAdsManage
