import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'styled-components';


// styles
import { ButtonStyles } from './index.styles'

const types = {
    PRIMARY: "PRIMARY",
    WARNING: "WARNING",
    ERROR: "ERROR",
    OPTIONAL: "OPTIONAL"
}

const stylesFollowTypes = {
    [types.PRIMARY]: `
        color: #FFFFFF;
        background-color: #1279DA;
        border: none;
    `,
    [types.WARNING]: `
        color: #FFFFFF;
        background-color: #FF9500;
        border: none;
    `,
    [types.ERROR]: `
        color: #FFFFFF;
        background-color: #FF3B30;
        border: none;
    `,
    [types.OPTIONAL]: ``
}

const ButtonControl = ({ type, children, handleClick, extendStyles }) => {
    return (
        <ButtonStyles 
            onClick={handleClick || (() => {})} 
            specificStyles={stylesFollowTypes[type]}
            extendStyles={extendStyles}
        >
            {children}
        </ButtonStyles>
    )
}

ButtonControl.defaultProps = {
    type: types.OPTIONAL
}

ButtonControl.propTypes = {
    type: PropTypes.oneOf(Object.values(types)),
    children: PropTypes.any.isRequired,
    handleClick: PropTypes.func,
    extendStyles: PropTypes.any
}

export default ButtonControl
