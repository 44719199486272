import styled from 'styled-components'



const ListDropdown = styled.div`
    font-family: "UTM-Avo";
    outline: none;
    ${props => props.stylesListDropdown}
    ${props => props.extendStyles}
`

const ItemDropdown = styled.div`
    font-family: "UTM-Avo";
    ${props => props.stylesItemDropdown}

    ${ListDropdown} > &:not(:last-child) {
        border-bottom: 1px solid #F8F8F8;
    }

    ${ListDropdown} > &:first-child {
        border-radius: 6px 6px 0 0;
    }

    ${ListDropdown} > &:last-child {
        border-radius: 0 0 6px 6px;
    }
`

export {
    ListDropdown,
    ItemDropdown
}