import React, { useState } from 'react'
//styles
import { Bound } from './index.styles'
//data
import { TermsAnConditionData } from '../../../data/termAnsConditionData'

const TermsAndCondition = () => {

    const [data, setData] = useState(TermsAnConditionData)

    const showContent = () => {
        
        return data.map((item, i) => {
            return (
                <div key={i} className='content'>
                    <p>{item.title}</p>
                    {
                        item.content.map((itemcontent, j) => {
                            return (
                                <p key={j}>{itemcontent}</p>
                            )
                        })
                    }
                </div>
            )
        })
    }

    return (
        <Bound>
            <div className='title-wrapper'>
                <h1 className='title'>Điều khoản & điều kiện sử dụng</h1>
                <h3>Realease Patch Note v1.32</h3>
                <h4>27/08/2019</h4>
            </div>
            <div className='content-wrapper'>
                {showContent()}
            </div>
        </Bound>
    )
} 

export default TermsAndCondition
