import React from 'react'
import PropTypes from 'prop-types'

// styles
import { Bound, styleButton } from "./index.styles"

// images
import icon_warning from "./../../../../images/ic_warning.svg"
import { titleWarningChangeDevice } from '../../../../data/dataUI'
import ButtonControl from '../../../Control/ButtonControl'


const WarningChangeDevice = ({ closePopup, content }) => {

    const _closeWarning = () => {
        closePopup();
    }

    return (
        <Bound>
            <div className="popup-container">
                <img src={icon_warning} alt="icon" />
                <p>{titleWarningChangeDevice}</p>
                <ButtonControl
                    extendStyles={styleButton}
                    children={content}
                    handleClick={_closeWarning}
                />
            </div>
        </Bound>
    )
}

WarningChangeDevice.propTypes = {
    closePopup: PropTypes.func.isRequired,
    content: PropTypes.any
}

export default WarningChangeDevice

