import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'

import { Bound, TooltipDefault, TooltipBankCode } from "./index.styles"
import { _toolGetPosition } from '../../../helper/tools';

const type = {
    DEFAULT: "DEFAULT", // only text
    BANKCODE: "BANKCODE", // contain image
}

const TooltipCustomControl = ({ BoundStyles, typeTooltip, dataTooltip, refTooltip }) => {
    const [contentType, setContentType] = useState(null);

    useEffect(() => {
        const currentRef = refTooltip.current;
        if (!currentRef) return;

        const defaultPosition = _toolGetPosition(refTooltip);
        console.log(defaultPosition);

        let content;
        switch (typeTooltip) {

            case type.DEFAULT: {
                let newPosition = {
                    top: `${defaultPosition.top - 70}px`,
                    left: `${defaultPosition.left - 80}px`
                }

                content = < TooltipDefault positionShowTooltip={newPosition} >
                    {dataTooltip}
                </TooltipDefault >
                break;
            }

            case type.BANKCODE: {
                let newPosition = {
                    top: `${defaultPosition.top + 15}px`,
                    left: `${defaultPosition.left + 20}px`
                }
                content = <TooltipBankCode
                    positionShowTooltip={newPosition}
                >
                    <img src={dataTooltip} alt="ic_bank" />
                </TooltipBankCode>
                break;
            }


            default:
                break;
        }

        setContentType(content);
    }, [refTooltip])

    return (
        <Bound BoundStyles={BoundStyles} >
            {contentType}
        </Bound>
    );
}

TooltipCustomControl.propTypes = {
    BoundStyles: PropTypes.any,
    typeTooltip: PropTypes.string.isRequired,
    dataTooltip: PropTypes.any.isRequired,
    refTooltip: PropTypes.any.isRequired,
}


export default React.memo(TooltipCustomControl);


