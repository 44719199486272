import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from 'react-router-dom'

// styles
import { Bound, styleButton } from "./index.styles"

// tools
import { checkMail } from '../../../helper/tools'

// actions
import { login } from '../../../redux/actions/userAction'
import { getShop } from '../../../redux/actions/shopAction'

// control
import ButtonControl from '../../Control/ButtonControl'
import { titleWrongLogin } from '../../../data/dataUI'
import { LOGIN_USER_ERROR, WEB_IS_OFFLINE } from '../../../constant/typeAction'
//component
import HidePasswordHide from '../../../images/component/HidePasswordHide'
import HidePasswordShow from '../../../images/component/HidePasswordShow'
import { FACEPAY_USER_DATA, FACEPAY_DISPLAY_CONFIG } from '../../../constant/key'


const TabAccount = () => {

    const history = useHistory()

    const [errContent, setErrContent] = useState("");
    const [showPass, setShowPass] = useState(true);

    const [emailValue, setEmailValue] = useState("");
    const [passwordlValue, setPasswordValue] = useState("");

    const { userReducer, shopReducer, systemReducer } = useSelector(state => ({
        userReducer: state.userReducer,
        shopReducer: state.shopReducer,
        systemReducer: state.systemReducer
    }))

    const dispatch = useDispatch();

    useEffect(() => {
        if (userReducer.type === LOGIN_USER_ERROR && systemReducer.status !== WEB_IS_OFFLINE) {
            setErrContent(titleWrongLogin);
            return;
        }

        if (userReducer.loginResult) {
            if (userReducer.loginResult.Code === 0 && userReducer.loginResult.LinkedStore) {
                dispatch(getShop())
            }
            else
                setErrContent(titleWrongLogin);
        }
    }, [userReducer])

    useEffect(() => {
        if (shopReducer.data) {
            if (shopReducer.data.Code === 0) {
                history.push("/")
            }
        } else if (shopReducer.err && shopReducer.err.Code !== 0) {
            localStorage.removeItem(FACEPAY_USER_DATA)
            localStorage.removeItem(FACEPAY_DISPLAY_CONFIG)
        }
    }, [shopReducer])

    const _changeInputEmail = e => {
        const { value } = e.target;
        
        // Check prevent space
        if (value.includes(" ")) return

        setEmailValue(value)
        setErrContent(" ");
    }

    const _changeInputPassword = e => {
        const { value } = e.target;

        // Check prevent space
        if (value.includes(' ')) return

        setPasswordValue(value)
        setErrContent("");
    }

    const _handleSubmit = (e) => {
        e.preventDefault();
        const checkEmail = checkMail(emailValue);

        if (!checkEmail || !emailValue || !passwordlValue) {
            setErrContent(titleWrongLogin);
            return;
        }
        dispatch(login(emailValue.trim(), passwordlValue.trim()))
    }


    return (
        <Bound>
            <p className="title">
                Sử dụng thông tin tài khoản chúng tôi đã cung cấp qua email
            </p>
            <form onSubmit={_handleSubmit}>
                <p className="label-email">Email</p>
                <input className="input-email"
                    value={emailValue}
                    onChange={_changeInputEmail}
                    type="text" 
                    placeholder="Vui lòng nhập email" />
                <p className="label-password">Mật khẩu</p>
                <div className="block-pass">
                    <input
                        value={passwordlValue}
                        onChange={_changeInputPassword}
                        className="input-password"
                        type={showPass ? "password" : "text"}
                        placeholder="Vui lòng nhập mật khẩu"
                    />
                    <div className="hint-pass" onClick={() => setShowPass(!showPass)}>
                        {
                            showPass ?
                                <HidePasswordHide />
                                :
                                <HidePasswordShow />
                        }
                    </div>

                </div>
                <ButtonControl
                    extendStyles={styleButton}
                    children="ĐĂNG NHẬP"
                />
            </form>
            <p className="err-content">{errContent}</p>
        </Bound>
    )
}

TabAccount.propTypes = {

}

export default React.memo(TabAccount);

