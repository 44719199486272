import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BoundContainer } from "./index.styles";

// images
import reg_account_show from "../../images/reg_tabAccount_show.svg"
import reg_account_hide from "../../images/reg_tabAccount_hide.svg"
import reg_qrcode_show from "../../images/reg_tabQRCode_show.svg"
import reg_qrcode_hide from "../../images/reg_tabQRCode_hide.svg"

// components
import Introduction from './Introduction';
import TabAccount from './TabAccount';
import TabQRCode from './TabQRCode';
import TabCreateShop from './TabCreateShop'
import Spinner from '../Control/Spinner';

// constant
import { FACEPAY_USER_DATA } from '../../constant/key';
//action
import { generate_mPOS_qr } from '../../redux/actions/userAction'

const ACCOUNT = "ACCOUNT";
const QRCODE = "QRCODE";

const LoginPage = () => {
    const dispatch = useDispatch()

    const { userReducer } = useSelector(state => ({
        userReducer: state.userReducer
    }))

    const [tab, setTab] = useState(ACCOUNT)
    // const [tab, setTab] = useState(QRCODE)

    const [isAuth, setIsAuth] = useState(null);
    const [isLinkStore, setIsLinkStore] = useState(false)

    // useEffect(() => {
    //     dispatch(generate_mPOS_qr())
    // }, [])

    useEffect(() => {
        const { loginResult } = userReducer
        if (loginResult) {
            setIsAuth(true)
            const { LinkPos, LinkedStore, Role } = loginResult;
            if (LinkedStore) {
                setIsLinkStore(true)
            }
            return;
        }
        
        setIsAuth(false)
        setIsLinkStore(false)
    }, [userReducer])

    // useEffect(() => {
    //     if (isAuth) {
    //         const { loginResult } = userReducer
    //         const { LinkPos, LinkedStore, Role } = loginResult;
    //         if (LinkedStore) {
    //             setIsLinkStore(true)
    //         }
    //         return;
    //     }
    //     setIsLinkStore(false)
    // }, [isAuth, userReducer])

    const _changeTab = tab => {
        if(tab === QRCODE){
            //call api create qr code login
            dispatch(generate_mPOS_qr())
        }
        setTab(tab)
    };

    if (isAuth === null)
        return <Spinner />

    // if (isAuth)
    if (isAuth && isLinkStore)
        return <Redirect to="/main/statistic" />

    return (
        <BoundContainer
            imageTabAccount={tab === ACCOUNT ? reg_account_show : reg_account_hide}
            imageTabQRCODE={tab === QRCODE ? reg_qrcode_show : reg_qrcode_hide}
            tab={tab}
        >
            <Introduction />
            <div className="login-container">
                <div className="header">
                    {
                        isAuth ?
                        <div className={`shape left create-shop`} >
                            ĐĂNG KÝ THÔNG TIN
                        </div>
                        :
                        <React.Fragment>
                            <div className={`shape left ${tab === ACCOUNT ? "active" : ""}`} 
                                onClick={() => _changeTab(ACCOUNT)} >
                                {tab === ACCOUNT && <span />}
                                TÀI KHOẢN
                            </div>
                            {/* <div className={`shape right ${tab === QRCODE ? "active" : ""}`} 
                                onClick={() => _changeTab(QRCODE)} 
                                style={{ marginLeft: '7px' }}>
                                {tab === QRCODE && <span />}
                                QUÉT QR
                            </div> */}
                        </React.Fragment>
                    }
                </div>
                <div className="content-login">
                    {
                        isAuth ? <TabCreateShop/>
                        :
                        tab === ACCOUNT ? <TabAccount />
                            : tab === QRCODE ? <TabQRCode />
                                : null
                    }
                    {/* <TabCreateShop/> */}
                </div>
            </div>
        </BoundContainer >
    );
}

export default React.memo(LoginPage);
