import Axios from "axios"

import * as types from '../../constant/typeAction';

import {
    host
} from '../../host/host'
// action
import {
    pushError,
    pushSuccess
} from "./notifyAction";
import {
    dispatchLogin
} from "./userAction";

// tools
import getError from "../../helper/getErrorServices";
//constant
import * as keys from '../../constant/key'
import { changePageNumber } from "./pagingAction";

/**
 * 
 * @param {*Number} pageSize 
 * @param {*Number} pageNumber 
 * @param {*Array} listShopID 
 * @param {*Object} history 
 */
export const getFilterPOS = (pageSize, pageNumber, listShopID, history) => async (dispatch, getState) => {
    
    const store = getState()
    const dataUserLogin = store.userReducer.loginResult;
    if (!dataUserLogin) return;
    const { pagingReducer } = store 

    const queryDataFormat = JSON.stringify({
        PageSize: pageSize,
        PageNumber: pageNumber,
        Shops: listShopID
    })
    const hostGetPOS = host + 'pos/filter'
    dispatch({
        type: types.ACTIVE_LOADING_PAGE,
    })
    dispatch({
        type: types.GET_FILTER_POS_START,
    })
    try {
        const res = await Axios.post(hostGetPOS, queryDataFormat)
        const {
            data
        } = res;
        let dataUser = JSON.parse(localStorage.getItem(keys.FACEPAY_USER_DATA))
        if (data && data.Code === 0) {
            // const newData = {...data};
            // newData.type=types.GET_FILTER_POS
            // newData.POS.sort((a,b) => (a.Name > b.Name) ? 1:-1);
            // data.type=types.GET_FILTER_POS
            if(pageNumber !== 1) {
                if(!data.Data || data.Data.length === 0) {
                    dispatch(getFilterPOS(pageSize, pageNumber-1, listShopID, history))
                    dispatch(changePageNumber(pageNumber-1, pagingReducer.pos_grid.type));
		            dispatch(changePageNumber(pageNumber-1, pagingReducer.pos_list.type));
                    dispatch({
                        type: types.DEACTIVE_LOADING_PAGE,
                    })
                    return
                }
                dispatch({
                    type: types.GET_FILTER_POS,
                    data: data.Data,
                    total: data.Total
                })
                dispatch({
                    type: types.DEACTIVE_LOADING_PAGE,
                })
                return;
            }
            if (data.Data && data.Data.length > 0) {

                const newDataLogin = {
                    ...dataUserLogin,
                    LinkPos: true
                }
                dispatch({
                    type: types.LOGIN_USER,
                    data: newDataLogin
                })

                if (!dataUser.LinkPos)
                    dataUser.LinkPos = true
            } else {
                // if (dataUserLogin.Role === keys.STORE_MANAGER) {
                if ([keys.WUM, keys.STORE_MANAGER].includes(dataUserLogin.Role)) {
                    if (dataUser.LinkPos && pageNumber === 1) {
                        dataUser.LinkPos = false
                        dispatch(dispatchLogin(dataUser))
                        history.push('/')
                    }
                    const newDataLogin = {
                        ...dataUserLogin,
                        LinkPos: false
                    }
                    dispatch({
                        type: types.LOGIN_USER,
                        data: newDataLogin
                    })
                    localStorage.setItem(keys.FACEPAY_USER_DATA, JSON.stringify(dataUser))
                }
            }
            dispatch({
                type: types.GET_FILTER_POS,
                data: data.Data,
                total: data.Total
            })

        } else {
            // data.type = types.GET_FILTER_POS_ERROR
            // dispatch({
            //     type: types.GET_FILTER_POS_ERROR,
            //     err: ''
            // })
            dispatch(pushError({
                type: types.GET_FILTER_POS_ERROR,
                error: data
            }))
        }

        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })
    } catch (err) {
        // dispatch({
        //     type: types.GET_FILTER_POS_ERROR,
        //     err
        // })
        dispatch(pushError({
            type: types.GET_FILTER_POS_ERROR,
            error: getError(err)
        }))
        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })
    }
}

export const register = (uID, shopID) => async dispatch => {
    const queryDataFormat = JSON.stringify({
        UID: uID,
        ShopID: shopID
    })
    dispatch({
        type: types.ACTIVE_LOADING_PAGE,
    })
    dispatch({
        type: types.REGISTER_POS_START,
    })
    const hostRegisterPOS = host + 'pos/register'
    try {
        const res = await Axios.post(hostRegisterPOS, queryDataFormat)
        const {
            data
        } = res;
        if (data && data.Code === 0) {
            data.type = types.REGISTER_POS
            dispatch({
                type: types.REGISTER_POS,
                data: data
            })
        } else {
            dispatch({
                type: types.REGISTER_POS_ERROR,
                err: ''
            })
            dispatch(pushError({
                type: types.REGISTER_POS_ERROR,
                error: data
            }))
        }

        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })
    } catch (err) {
        dispatch({
            type: types.REGISTER_POS_ERROR,
            err
        })
        dispatch(pushError({
            type: types.REGISTER_POS_ERROR,
            error: getError(err)
        }))
        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })
    }
}

export const removePOS = (posID) => async dispatch => {
    const queryDataFormat = JSON.stringify({
        POSID: posID
    })
    const hostRemovePOS = host + 'pos/removePOS'
    dispatch({
        type: types.ACTIVE_LOADING_PAGE,
    })
    try {
        const res = await Axios.post(hostRemovePOS, queryDataFormat)
        const {
            data
        } = res;
        if (data && data.Code === 0) {
            data.type = types.REMOVE_POS
            data.PosID = posID
            dispatch({
                type: types.REMOVE_POS,
                data: data
            })
            dispatch(pushSuccess({
                type: types.REMOVE_POS,
            }))
        } else {
            // data.type = types.REMOVE_POS_ERROR
            // dispatch({
            //     type: types.REMOVE_POS_ERROR,
            //     err: ''
            // })
            dispatch(pushError({
                type: types.REMOVE_POS_ERROR,
                error: data
            }))
        }

        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })
    } catch (err) {
        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })
        // dispatch({
        //     type: types.REMOVE_POS_ERROR,
        //     err
        // })
        dispatch(pushError({
            type: types.REMOVE_POS_ERROR,
            error: getError(err)
        }))
    }
}

export const changeStatusPOS = objEvent => (dispatch, getState) => {
    const dataPos = JSON.parse(JSON.stringify(getState().posReducer.listPos));
    const newData = [];
    // console.log(dataPos);
    // console.log(objEvent);
    if (!dataPos) return;

    for (let i = 0; i < dataPos.length; i++) {
        const elm = dataPos[i];
        // if (elm.ID === "5e9d7a07afecf82478c3188b") {
        if (elm.ID === objEvent.PosID) {
            elm.IsOnline = objEvent.Status
        }
        newData.push(elm);
    }
    // console.log(newData);
    if (objEvent && Object.keys(objEvent).length > 0) {
        if (!objEvent.PosID && !objEvent.Status) {
            dispatch({
                type: types.CHANGE_STATUS_POS_ERR,
                err: "Event Socket response incorrect"
            })
            return;
        }
        dispatch({
            type: types.CHANGE_STATUS_POS,
            data: newData
        })
    } else {
        dispatch({
            type: types.CHANGE_STATUS_POS_ERR,
            err: "Event Socket response incorrect"
        })
    }
}


export const getDropdownShopFilterPos = (dataDropdown) => dispatch => {
    let listShopID = [];

    const arrDropdownListSelect = Object.keys(dataDropdown).map(item => {
        return dataDropdown[item]
    })

    let arrCheck = []
    for (let i = 0; i < arrDropdownListSelect.length; i++) {
        const element = arrDropdownListSelect[i];
        const arrDataShop = Object.keys(element).map(item => {
            return element[item]
        })
        for (let j = 0; j < arrDataShop.length; j++) {
            const elm = arrDataShop[j];
            arrCheck.push(elm);
        }
    }

    for (let i = 0; i < arrCheck.length; i++) {
        const element = arrCheck[i];
        if (arrCheck.every(item => item.checked === false)) {
            listShopID.push(element.id)
        } else {
            if (element.checked) {
                listShopID.push(element.id)
            }
        }
    }
    dispatch({
        type: types.DROPDOWN_SHOP_FILTER_POS,
        dataDropdown: dataDropdown,
        listShopID: listShopID,
    })
}









/**
 * 
 * @param {*string} event || ex: POSS - POSC - POSCT 
 */
export const testSocketAction = event => async dispatch => {
    const apiTestSocket = host + "pos/testSocket";
    const dataQueryFormat = JSON.stringify({
        Event: event
    })
    try {
        await Axios.post(apiTestSocket, dataQueryFormat)
        dispatch({
            type: "Test_socket",
        })
    } catch (err) {
        // console.log(err);
    }

}