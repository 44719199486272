import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types';

// styles
import { OptionListStyles } from './index.styles'

// hooks
import useBlur from '../../../../helper/useBlur';
import useScrollWindow from '../../../../helper/useScrollWindow';

const OptionList = ({ 
    extendStyles, 
    handleBlur, 
    steps, 
    setStep 
}) => {

    const selfRef = useRef(null);

    useBlur(handleBlur, [selfRef])
    useScrollWindow([selfRef])

    const _handleRequestMoney = (e) => {
        setStep(steps.REQUEST_PAY)
    }

    const _handelDeleteDevice = (e) => {
        setStep(steps.DEL_DEVICE)
    }

    return (
        <OptionListStyles
            extendStyles={extendStyles}
            ref={selfRef}
        >
            <div
                className='option-line'
                onClick={_handleRequestMoney}
            >
                <p className='send-money'>Bắn tiền *</p>
            </div>
            <div className='option-line'
                onClick={_handelDeleteDevice}
            >
                <p className='remove-device'>Xóa thiết bị</p>
            </div>

        </OptionListStyles>
    )
}

OptionList.defaultProps = {
    handleBlur: () => {}
}

OptionList.propTypes = {
    extendStyles: PropTypes.string,
    handleBlur: PropTypes.func,
    steps: PropTypes.object,
    setStep: PropTypes.func
}

export default OptionList;
