import React from 'react'

const IcFacePay = (props) => {
    return <svg {...props} width={32} height={30} viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0H9.98198L20 9.8913L0 30V20.1087L9.98198 9.8913L0 0Z" fill="white" />
    <path d="M11.1129 0H21.5753L32 9.87342L21.4624 20H11L21.5753 9.87342L11.1129 0Z" fill="white" />
  </svg>
}

export default IcFacePay
