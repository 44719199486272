import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    /* flex: 1; */
    flex-direction: column;  
    overflow: overlay;
    height: calc(100vh - 128px - 70px - 20px);
    z-index: 10;
    .title-wrapper{
        margin: 48px 0 0 0;
        h1{
            font-family: 'SVN-Gilroy';
            font-size: 32px;
            font-weight: bold;
            line-height: 115%;
            color: #051321;
            margin: 0 0 36px 0;
        }
        h3{
            font-family: 'UTM-Avo';
            font-size: 16px;
            font-weight: bold;
            line-height: 170%;
            color: #050C1D;
            margin: 0 0 5px 0;                
        }
        h4{
            font-family: 'UTM-Avo';
            font-size: 14px;
            font-weight: normal;
            line-height: 170%;
            letter-spacing: 0.05px;
            color: rgba(5,12,29,0.5);
            margin: 0 0 31px 0;
        }
    }
    .content-wrapper{
        overflow: overlay;
        margin: 0 0 40px 0;
        &::-webkit-scrollbar {
            width: 15px;
            height: 0;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #E2E2E2;
            background-clip: padding-box;
            border-radius: 20px;
            border: 5px solid white;
        }
        .content{
            width: calc(100% - 18px);
            p{
                font-family: 'UTM-Avo';
                font-size: 13px;
                font-weight: normal;
                line-height: 170%;
                letter-spacing: 0.05px;
                color: #050C1D;
                margin:0 0 10px 0;
                text-align: justify;
                &:first-child{
                    font-family: 'UTM-Avo';
                    font-size: 16px;
                    font-weight: bold;
                    margin:0 0 20px 0;
                }
            }
        }
    }
    
`;
export { Bound }