import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//constant
import * as keys from "../../../../../constant/key";

// styles
import { Bound } from "./index.styles";

// data
import {
  arrTitleTable,
  arrTitleTableStore,
} from "../../../../../data/historyData";

// control
import ItemHistoryTable from "./ItemHistoryTable";
import PagingV2Control from "../../../../Control/PagingV2Control";
//action
import { changeFieldsFilter } from "../../../../../redux/actions/statisticAction";
import {
  changePageSize,
  // changePageNumber
} from "../../../../../redux/actions/pagingAction";

const paddingTop = 20;
const heightHeader = 128;
const heightHistoryTitle = 86 + 10;
const heightFooter = 70;
const heightTableItem = 54;
const minHeightMainPage = 640;
// const heightTableTitle = 48;
// const heightPagingControl = 36 + (20*2);

const TableHistory = (props) => {
  const [dataFilter, setDataFilter] = useState([]);
  const [heightBound, setHeightBound] = useState(null);
  const [heightPos, setHeightPos] = useState(0);
  const [total, setTotal] = useState(0);
  const [pageNum, setPageNum] = useState(1);

  const { statisticReducer, pagingReducer } = useSelector((state) => ({
    statisticReducer: state.statisticReducer,
    pagingReducer: state.pagingReducer,
  }));

  let userDataLocal = JSON.parse(localStorage.getItem(keys.FACEPAY_USER_DATA));

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      statisticReducer.dataFilter &&
      Object.keys(statisticReducer.dataFilter).length > 0 &&
      statisticReducer.dataFilter.Code === 0
    ) {
      const data = statisticReducer.dataFilter.Data;
      setDataFilter(data);
      setTotal(statisticReducer.dataFilter.Total);
    }
  }, [statisticReducer.dataFilter]);

  useEffect(() => {
    const { fieldsFilter } = statisticReducer;
    if (pageNum !== fieldsFilter.pageNum) setPageNum(fieldsFilter.pageNum);
  }, [statisticReducer.fieldsFilter]);

  useEffect(() => {
    let vh = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    if (vh < minHeightMainPage) vh = minHeightMainPage;
    let availableHISTORYHeight =
      vh - paddingTop - heightHeader - heightHistoryTitle - heightFooter - 35;
    // console.log(`${availableHISTORYHeight} = (${vh} - ${paddingTop} - ${heightHeader} - ${heightHistoryTitle} - ${heightFooter}  - 35)`)
    if (availableHISTORYHeight !== heightBound) {
      setHeightBound(availableHISTORYHeight);
    }
    if (!pagingReducer) return;
    if (!pagingReducer.history_list) return;
    let height = pagingReducer.history_list.itemDisplay * heightTableItem;
    if (height > pagingReducer.history_list.pageSize * heightTableItem) {
      height = pagingReducer.history_list.pageSize * heightTableItem;
    }
    if (height !== heightPos) setHeightPos(height);
    // if(pagingReducer.history_list.pageNumber !== pageNum){
    //     setPageNum(pagingReducer.history_list.pageNumber)
    // }
  }, [pagingReducer]);

  const _renderHeader = (data) => {
    return data.map((item, index) => {
      return (
        <div className="title" key={index}>
          {item}
        </div>
      );
    });
  };

  const onChangePage = (page) => {
    if (!page) return;
    const { fieldsFilter } = statisticReducer;
    const billNumber = fieldsFilter.billCode;
    const facepayNumber = fieldsFilter.facepayNumber;
    const compareAmount = fieldsFilter.compareAmount;
    dispatch(
      changeFieldsFilter(billNumber, facepayNumber, compareAmount, page)
    );
    // setPageNum(page)
  };

  const onChangePageSize = (size) => {
    if (!size) return;
    dispatch(changePageSize(size.type, pagingReducer.history_list.type));
    const { fieldsFilter } = statisticReducer;
    const billNumber = fieldsFilter.billCode;
    const facepayNumber = fieldsFilter.facepayNumber;
    const compareAmount = fieldsFilter.compareAmount;
    dispatch(changeFieldsFilter(billNumber, facepayNumber, compareAmount, 1));
  };

  const _renderContent = (data) => {
    if (Array.isArray(data) && data && data.length > 0) {
      return data.map((item, index) => {
        return <ItemHistoryTable data={item} key={index} />;
      });
    }
  };

  return (
    <Bound
      style={{ height: heightBound ? `${heightBound}px` : "unset" }}
      gridTemplateColumnsHeader={
        userDataLocal &&
        // userDataLocal.Role === keys.STORE_MANAGER &&
        [keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role) &&
        "1fr 260px 220px 160px 190px"
      }
    >
      <div className="header-table">
        {userDataLocal && 
        // userDataLocal.Role === keys.STORE_MANAGER
        [keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role)
          ? _renderHeader(arrTitleTableStore)
          : _renderHeader(arrTitleTable)}
      </div>
      <div
        className="content-table"
        style={{ height: `${heightPos}px` }}
        id="table"
      >
        <div className="history-list-scroll">{_renderContent(dataFilter)}</div>
        {!dataFilter ||
          (dataFilter.length === 0 && (
            <div className="title-no-data">Chưa có thông tin dữ liệu...</div>
          ))}
      </div>
      <PagingV2Control
        margin="20px 0"
        total={total}
        pageNum={pageNum}
        size={pagingReducer.history_list.pageSize}
        onChangePage={(page) => onChangePage(page)}
        onChangePageSize={(size) => onChangePageSize(size)}
      />
    </Bound>
  );
};

export default React.memo(TableHistory);
