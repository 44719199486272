import React, { useRef } from 'react'

// tools
import useScrollWindow from '../../../../helper/useScrollWindow'

// styles
import { ListDropdown, ItemDropdown } from './index.styles';
import useBlur from '../../../../helper/useBlur';

const LstDropDown = ({
    stylesListDropdown,
    data,
    extendStyles,
    handleChoice,
    stylesItemDropdown,
    handleBlur,
    valueDefault
}) => {
    const lstDropDownRef = useRef(null);

    // useBlur(handleBlur, [lstDropDownRef])
    // useScrollWindow([lstDropDownRef])

    const _renderDropdown = data => {
        return data.map((item, index) => {
            return <ItemDropdown 
                // className="itemDropdown" 
                className= {valueDefault === item.name ? "itemDropdown active" : "itemDropdown"}
                stylesItemDropdown={stylesItemDropdown} 
                key={index} 
                onClick={() => handleChoice(item)}
            >
                {item.name || "item"}
            </ItemDropdown>
        })
    }
    
    return (
        <ListDropdown
            className="listDropdown"
            stylesListDropdown={stylesListDropdown}
            extendStyles={extendStyles}
            ref={lstDropDownRef}
        >
            {_renderDropdown(data)}
        </ListDropdown>
    )
}

export default LstDropDown
