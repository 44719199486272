import React from 'react'
import PropTypes from 'prop-types'

// styles
import { Input } from './index.styles';

const inputSupports = {
    TEXT: "text",
    NUMBER: "number"
}

const InputControl = (props) => {
    
    return (
        <Input {...props} />
    )
}

InputControl.defaultProps = {
    type: inputSupports.TEXT
}

InputControl.propTypes = {
    type: PropTypes.oneOf(Object.values(inputSupports)),
    extendStyles: PropTypes.string
}

export default InputControl
