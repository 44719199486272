import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';

// component
import ModalControl from '../Control/ModalControl'
import AlertPopup from '../Popup/AlertPopup'

// error modal
import ErrorLogin400Code2 from './ErrorModal/ErrorLogin/ErrorLogin400Code2';
import ErrorPaymentFollowActionType from './ErrorModal/ErrorPaymentRequest/FollowActionType';
import ErrorConnect from './ErrorModal/ErrorConnect';
import ErrorRemoveDeviceFollowActionType from './ErrorModal/ErrorRemoveDevice';
import ErrorNotLinkShop from './ErrorModal/ErrorNotLinkShop';
import Error403 from "./ErrorModal/Error403";
import ErrorGetListPosFollowActionType from "./ErrorModal/ErrorGetListPos";
import ErrorGetShopFollowActionType from "./ErrorModal/ErrorGetShop";
import ErrorStringDefault from "./ErrorModal/ErrorStringDefault";
import ErrorLoginFollowActionType from './ErrorModal/ErrorLogin/ErrorLoginFollowActionType';
import ErrorCreateStore from './ErrorModal/ErrorCreateStore';

// success modal
import SuccessPaymentRequest from './SuccessModal/SuccessPaymentRequest';
import SuccessRemoveDevice from './SuccessModal/SuccessRemoveDevice';

// warning modal
import WarningStringDefault from './WarningModal/WarningStringDefault';
import WarningChangeDevice from './WarningModal/WarningChangeDevice';

// action types
import * as typesAction from "../../constant/typeAction";

// action
import { popNotify } from '../../redux/actions/notifyAction';

const extendStylesModal = `
`;

const NotifyModal = () => {
    const dispatch = useDispatch();
    const timeRef = useRef(null);

    const { notifyReducer } = useSelector((state) => ({
        notifyReducer: state.notifyReducer
    }))

    const [notifies, setNotifies] = useState([]);
    const [notifyShow, setNotifyShow] = useState(null);

    useEffect(() => {
        setNotifies(notifyReducer.listOfNotify)
    }, [notifyReducer])

    useEffect(() => {
        // debugger;
        if (notifies.length && notifyShow === null /* && !isLoadedError.current */) {
            const notifyItem = notifies[notifies.length - 1];
            // dispatch(popNotify());
            // _showError(errorItem)
            _handleShowNotify(notifyItem)
        }
    }, [notifies, notifyShow])

    useEffect(() => {
        return () => {
            dispatch(popNotify());
            clearTimeout(timeRef.current);
        }
    }, [])

    useEffect(() => {
        if (notifies.length === 0) {
            setNotifyShow(null);
        }
    }, [notifies]);

    const _closePopup = useCallback(
        () => {
            dispatch(popNotify());
            setNotifyShow(null);
            clearTimeout(timeRef.current);
        },
        []
    )

    const _autoClosePopup = useCallback(
        (miliseconds = 7000) => {
            timeRef.current = setTimeout(() => {
                _closePopup()
            }, miliseconds)
        },
        []
    )

    //#region _handleErrorFollowErrorType

    //#region _handleErrorResponse
    const _handleErrorLogin400FollowCode = useCallback(
        (error) => {
            switch (error.data.Code) {
                case 2:
                    setNotifyShow(
                        <ErrorLogin400Code2 closePopup={_closePopup} />
                    )
                    _autoClosePopup();
                    // _autoClosePopup()
                    break;
                default:
                    // Code have not defined yet
                    setNotifyShow(
                        <ErrorConnect closePopup={_closePopup}>
                            <div>Lỗi hệ thống. Code {error.data.Code}</div>
                        </ErrorConnect>
                    )
                    _autoClosePopup();
                    break;
            }
        },
        [setNotifyShow]
    )

    const _handleErrorResponse400 = useCallback(
        (errorItem) => {
            if (!errorItem.error.data) {
                // server not response data
                setNotifyShow(
                    <ErrorConnect closePopup={_closePopup}>
                        <div>Không thể kết nối được với hệ thống.</div>
                        <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                    </ErrorConnect>
                )
                _autoClosePopup();
                return;
            }
            switch (errorItem.type) {
                case typesAction.LOGIN_USER_ERROR:
                    _handleErrorLogin400FollowCode(errorItem.error)
                    break;
                default:
                    // Type have not defined error data yet
                    if (process.env.NODE_ENV === "development")
                        throw Error('Type have not defined error data yet');
                    else {
                        setNotifyShow(
                            <ErrorConnect closePopup={_closePopup}>
                                <div>Không thể kết nối được với hệ thống.</div>
                                <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                            </ErrorConnect>
                        )
                        _autoClosePopup();
                        break;
                    }
            }
        },
        [],
    )

    const _handleErrorResponse403 = useCallback(
        () => {
            setNotifyShow(
                <Error403
                    closePopup={_closePopup}
                    autoClosePopup={_autoClosePopup}
                />
            )
        },
        []
    )

    const _handleErrorResponse = useCallback(
        (errorItem) => {
            switch (errorItem.error.status) {
                case 400:
                    _handleErrorResponse400(errorItem)
                    break;
                case 403:
                    _handleErrorResponse403()
                    break;
                default:
                    // error connect server
                    setNotifyShow(
                        <ErrorConnect closePopup={_closePopup}>
                            <div>Không thể kết nối được với hệ thống.</div>
                            <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                        </ErrorConnect>
                    )
                    _autoClosePopup();
                    break;
            }
        },
        [],
    )
    //#endregion _handleErrorResponse

    const _handleErrorRequest = useCallback(
        () => {
            setNotifyShow(
                <ErrorConnect closePopup={_closePopup}>
                    Lỗi kết nối mạng
                </ErrorConnect>
            )
            _autoClosePopup();
        },
        [setNotifyShow],
    )

    const _handleErrorSystem = useCallback(
        () => {
            setNotifyShow(
                <ErrorConnect closePopup={_closePopup}>
                    Lỗi kết nối mạng
                </ErrorConnect>
            )
            _autoClosePopup();
        },
        [setNotifyShow],
    )

    const _handleErrorFollowErrorType = useCallback(
        (errorItem) => {
            switch (errorItem.error.errorType) {
                case 'ERROR_RESPONSE':
                    // debugger;
                    _handleErrorResponse(errorItem)
                    break;
                case 'ERROR_REQUEST':
                    _handleErrorRequest()
                    break;
                default:
                    _handleErrorSystem()
                    break;
            }
        },
        [],
    )
    //#endregion _handleErrorFollowErrorType

    //#region _handleErrorFollowActionType
    const _handleErrorFollowActionType = useCallback(
        (errorItem) => {
            if (typeof (errorItem.error) === 'string') {
                setNotifyShow(
                    <ErrorStringDefault closePopup={_closePopup}>{errorItem.error}</ErrorStringDefault>
                );
                _autoClosePopup();
                return
            }
            switch (errorItem.type) {
                case typesAction.CREATE_STORE_ERR: {
                    setNotifyShow(
                        <ErrorCreateStore
                            closePopup={_closePopup}
                            autoClosePopup={_autoClosePopup}
                            error={errorItem.error}
                        />
                    )
                    _autoClosePopup();
                    break;
                }
                case typesAction.LOGIN_USER_ERROR:
                    setNotifyShow(
                        <ErrorLoginFollowActionType
                            closePopup={_closePopup}
                            autoClosePopup={_autoClosePopup}
                            error={errorItem.error}
                        />
                    )
                    _autoClosePopup();
                    break;
                case typesAction.PAYMENT_REQUEST_ERROR:
                    setNotifyShow(
                        <ErrorPaymentFollowActionType
                            closePopup={_closePopup}
                            autoClosePopup={_autoClosePopup}
                            error={errorItem.error}
                        />
                    )
                    _autoClosePopup();
                    break;
                case typesAction.REMOVE_POS_ERROR:
                    setNotifyShow(
                        <ErrorRemoveDeviceFollowActionType
                            closePopup={_closePopup}
                            autoClosePopup={_autoClosePopup}
                            error={errorItem.error}
                        />
                    )
                    _autoClosePopup();
                    break;
                case typesAction.GET_FILTER_POS_ERROR:
                    setNotifyShow(
                        <ErrorGetListPosFollowActionType
                            closePopup={_closePopup}
                            autoClosePopup={_autoClosePopup}
                            error={errorItem.error}
                        />
                    );
                    _autoClosePopup();
                    break;
                case typesAction.GET_SHOP_ERROR:
                    setNotifyShow(
                        <ErrorGetShopFollowActionType
                            closePopup={_closePopup}
                            autoClosePopup={_autoClosePopup}
                            error={errorItem.error}
                        />
                    );
                    _autoClosePopup();
                    break;
                case "NOT_LINK_SHOP":
                    setNotifyShow(
                        <ErrorNotLinkShop
                            closePopup={_closePopup}
                            autoClosePopup={_autoClosePopup}
                        />
                    )
                    _autoClosePopup();
                    break;
                case 'ACCOUNT_WAS_BLOCKED':
                    setNotifyShow(
                        <ErrorConnect closePopup={_closePopup}>
                            {/* <div>Tài khoản bị khóa vì ...!</div> */}
                            <div>Tài khoản đã bị khóa</div>
                        </ErrorConnect>
                    )
                    _autoClosePopup();
                    break;
                default:
                    if (process.env.NODE_ENV === "development")
                        throw Error("Invalid type to use modal!");
                    else {
                        setNotifyShow(
                            <ErrorConnect closePopup={_closePopup}>
                                <div>Không thể kết nối được với hệ thống.</div>
                                <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                            </ErrorConnect>
                        )
                        _autoClosePopup();
                        break;
                    }
            }
        },
        [],
    )
    //#endregion _handleErrorFollowActionType

    //#region _showError
    const _showError = useCallback(
        (errorItem) => {
            const { error } = errorItem;
            // debugger
            if (error.errorType) {
                _handleErrorFollowErrorType(errorItem)
                return;
            }
            _handleErrorFollowActionType(errorItem)
        },
        [],
    )
    //#endregion _showError

    //#region _showSuccess
    const _showSuccess = useCallback(
        (successItem) => {
            const { type } = successItem;
            switch (type) {
                case typesAction.PAYMENT_REQUEST:
                    setNotifyShow(
                        <SuccessPaymentRequest
                            closePopup={_closePopup}
                        />
                    )
                    _autoClosePopup();
                    break;
                case typesAction.REMOVE_POS:
                    setNotifyShow(
                        <SuccessRemoveDevice
                            closePopup={_closePopup}
                        />
                    )
                    _autoClosePopup();
                    break;
                default:
                    if (process.env.NODE_ENV === "development")
                        throw Error('Invalid type or type is not defined to show success');
                    else {
                        setNotifyShow(
                            <ErrorConnect closePopup={_closePopup}>
                                <div>Không thể kết nối được với hệ thống.</div>
                                <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                            </ErrorConnect>
                        )
                        _autoClosePopup();
                        break;
                    }
            }
        },
        [],
    )
    //#endregion _showSuccess

    //#region _showInfo
    const _showInfo = useCallback(
        (warningItem) => {
            const { type } = warningItem;
            switch (type) {
                default:
                    if (process.env.NODE_ENV === "development")
                        throw Error('Invalid type or type is not defined to show info');
                    else {
                        setNotifyShow(
                            <ErrorConnect closePopup={_closePopup}>
                                <div>Không thể kết nối được với hệ thống.</div>
                                <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                            </ErrorConnect>
                        )
                        _autoClosePopup();
                        break;
                    }
            }
        },
        [],
    )
    //#endregion _showInfo

    //#region _showWarning
    const _showWarning = useCallback(
        (infoItem) => {
            const { type, data } = infoItem;
            if (typeof (data) === "string") {
                setNotifyShow(
                    <WarningStringDefault closePopup={_closePopup}>{data}</WarningStringDefault>
                )
                _autoClosePopup();
                return;
            }
            switch (type) {

                case typesAction.POSCT_HAS_CHANGE_DEVICE: {
                    setNotifyShow(
                        <WarningChangeDevice
                            content="Đồng Ý"
                            closePopup={_closePopup}
                        />
                    )
                    _autoClosePopup();
                    break;
                }

                default:
                    if (process.env.NODE_ENV === "development")
                        throw Error('Invalid type or type is not defined to show info');
                    else {
                        setNotifyShow(
                            <ErrorConnect closePopup={_closePopup}>
                                <div>Không thể kết nối được với hệ thống.</div>
                                <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                            </ErrorConnect>
                        )
                        _autoClosePopup();
                        break;
                    }
            }
        },
        [],
    )
    //#endregion _showWarning

    const _handleShowNotify = useCallback(
        (notifyItem) => {
            const { type } = notifyItem;
            if (!type) {
                if (process.env.NODE_ENV === "development")
                    throw Error("Invalid format to use modal!");
                else {
                    setNotifyShow(
                        <ErrorConnect closePopup={_closePopup}>
                            <div>Không thể kết nối được với hệ thống.</div>
                            <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                        </ErrorConnect>
                    )
                    _autoClosePopup();
                }
            }
            switch (type) {
                case 'ERROR':
                    _showError(notifyItem.dataError)
                    break;
                case 'SUCCESS':
                    _showSuccess(notifyItem.dataSuccess)
                    break;
                case 'INFO':
                    _showInfo(notifyItem.dataInfo)
                    break;
                case 'WARNING':
                    _showWarning(notifyItem.dataWarning)
                    break;
                default:
                    if (process.env.NODE_ENV === "development")
                        throw Error("Invalid format type to use modal!");
                    else {
                        setNotifyShow(
                            <ErrorConnect closePopup={_closePopup}>
                                <div>Không thể kết nối được với hệ thống.</div>
                                <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>
                            </ErrorConnect>
                        )
                        _autoClosePopup();
                        break;
                    }
            }
        },
        []
    )

    if (!notifyShow) return null;

    return (
        <ModalControl extendStyles={extendStylesModal}>
            {notifyShow}
        </ModalControl>
    )
}

const MemoNotifyModal = React.memo(NotifyModal)
MemoNotifyModal.displayName = 'MemoNotifyModal';

export default MemoNotifyModal
