import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import * as styles from './index.styles'
import { Link } from 'react-router-dom'

const BlockedPage = () => {

    const [data, setData] = useState({})
    const [reason, setReason] = useState("")

    const history = useHistory();

    const { userReducer } = useSelector(state => ({
        userReducer: state.userReducer        
    }))

    useEffect(() => {
        if (!userReducer.reasonBlocked || !userReducer.listBlockedReason) return;  
        let ID = userReducer.reasonBlocked.lockReasonID
        if (ID) {
            let result = userReducer.listBlockedReason.find(item => item.ID === ID)
            if(result){
                setReason(result.Reason)
            }
        }
        setData(userReducer.reasonBlocked)
    }, [userReducer])

    useEffect(() => {
        if(userReducer.isBlocked === false){
            history.push('/main')
        }
    }, [userReducer.isBlocked])

    return (
        <styles.Bound>
            <div className="wrapper-block-page">
                <div className="title-block-page">Tài khoản đã bị khóa</div>
                <div className="content-block-page">
                    <div>
                        Tài khoản bị khóa vì<span>{reason}:</span>
                        <span>{data.lockedReason}</span>!
                        Nếu có bất kỳ câu hỏi, thắc mắc nào cần trợ giúp hãy truy cập vào
                        <Link>Trung tâm hỗ trợ</Link>{}
                        của chúng tôi!
                    </div>
                </div>
            </div>
        </styles.Bound>
    )
}

export default BlockedPage
