import React, { useCallback, useReducer, useState, useEffect } from 'react'
import { Bound, styleButtonCancel, styleButtonRegister, styleDropdownParent, stylesListDropdown } from './index.styles'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory ,Link } from 'react-router-dom';
//imgae
import icon_dropdown from '../../../images/ic_arrow_down.svg'

// helper
import fileToBase64 from '../../../helper/fileToBase64';

import ic_note from '../../../images/ic_note.svg'
//action 
import { createStore, setDataCreateShop } from '../../../redux/actions/shopAction'
import { logout } from '../../../redux/actions/userAction';
//component
import InputText from '../../Control/InputText';
import ButtonControl from '../../Control/ButtonControl'
import DropdownControl from '../../Control/DropdownControl';
import DropOrChooseLogo from '../../Control/DropOrChooseLogo';
// import IcNote from '../../../images/component/IcNote'

const dataCategory = [
    {
        key: 1,
        name: "Cửa hàng tạp hóa",
        type: "GROCERY",
    },
    {
        key: 2,
        name: "Thời trang",
        type: "CLOTHING",
    }, 
    {
        key: 3,
        name: "Nhà hàng/ Ăn uống",
        type: "FOOD_AND_BEVERAGES",
    },   
    {
        key: 4,
        name: "Cà phê",
        type: "MEDIA",
    },
    {
        key: 5,
        name: "Tiêu dùng khác",
        type: "FINANCE",
    },
]

const TabCreateShop = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const { userReducer, shopReducer } = useSelector(state => ({
        userReducer: state.userReducer,
        shopReducer: state.shopReducer,
    }))

    const [itemDropDown, setItemDropDown] = useState(dataCategory[0])
    const [isAcceptedCondition, setIsAcceptedCondition] = useState(false)
    const [isPassCondSubmit, setIsPassCondSubmit] = useState(false)
    const [error, setError] = useState('')

    const [state, dispatchState] = useReducer(
        (state, action) => ({ ...state, ...action }),
        {
            shopAvatar: '',
            shopName: '',
            category: '',
            email: '',
            address: '',
            longitude: '106.667829',
            latitude: '10.814782'
        }
    )

    const [infosUser, setInfosUser] = useState({
        FullName: '',
        PhoneNumber: '',
        IdentityNumber: ''
    })

    const [imageShow, setImageShow] = useState('')

    const _handleCancel = useCallback(
        () => {
            dispatch(logout(history))
        },
        [history]
    )

    const _handleRegShop = useCallback(
        () => {
            if(!isPassCondSubmit) 
                return;
            setIsPassCondSubmit(false)
            const data = {
                ...state,
                shopName: state.shopName.trim().replace(/\s+/g, ' '),
                email: state.email.trim().replace(/\s+/g, ' '),
                address: state.address.trim().replace(/\s+/g, ' '),
            }
            dispatch(createStore(...Object.values(data), history, null, () => setIsPassCondSubmit(true)))
        },
        [state, history, isPassCondSubmit]
    )
    const _onClickHandleChoice = (item) => {
        setItemDropDown(item)
        dispatch(setDataCreateShop({ itemDropDown: item }))
    }

    const _handleDropFiles = useCallback(
        async (files) => {
            setError('')
            // dispatch(setDataCreateShop({ error: '' }))
            if (imageShow) URL.revokeObjectURL(imageShow);
            try {
                const url = URL.createObjectURL(files[0]);
                setImageShow(url);
                dispatch(setDataCreateShop({ imageShow: url }))
                const imgBase64 = await fileToBase64(files[0])
                dispatchState({
                    shopAvatar: imgBase64.split(',')[1]
                });
                dispatch(setDataCreateShop({ shopAvatar: imgBase64.split(',')[1] }))
            } catch (err) {
                setImageShow("");
                dispatch(setDataCreateShop({ imageShow: '' }))
                dispatchState({ shopAvatar: '' });
                dispatch(setDataCreateShop({ shopAvatar: '' }))
            }
        },
        [imageShow],
    )

    const _handleCheckCondition = useCallback(
        () => {
            setIsAcceptedCondition(!isAcceptedCondition)
            dispatch(setDataCreateShop({ isAcceptedCondition: !isAcceptedCondition }))
        },
        [isAcceptedCondition],
    )

    const _handleErrorGetImg = useCallback(
        (err) => {
            // console.log(err)
            setError(err)
            // dispatch(setDataCreateShop({ error: err }))
        },
        [],
    )

    useEffect(() => {
        const { dataCreateShop } = shopReducer;
        setItemDropDown(dataCreateShop.itemDropDown || dataCategory[0])
        setIsAcceptedCondition(dataCreateShop.isAcceptedCondition || false)
        
        dispatchState({
            shopAvatar: dataCreateShop.shopAvatar || '',
            shopName: dataCreateShop.shopName || '',
            // category: dataCreateShop.category || '',
            email: dataCreateShop.email || '',
            address: dataCreateShop.address || '',
        })
        setImageShow(dataCreateShop.imageShow || '')
        // setError(dataCreateShop.error || '')
    }, [])

    useEffect(() => {
        if (userReducer.loginResult) {
            setInfosUser(userReducer.loginResult)
            return;
        }

        setInfosUser({
            FullName: '',
            PhoneNumber: '',
            IdentityNumber: ''
        })
    }, [userReducer])

    useEffect(() => {
        dispatchState({
            category: itemDropDown.type
        })
    }, [itemDropDown])

    useEffect(() => {
        const obligateField = [
            "shopAvatar",
            "shopName",
            "category",
            "address",
        ]
        const data = {
            ...state,
            shopName: state.shopName.trim().replace(/\s+/g, ' '),
            email: state.email.trim().replace(/\s+/g, ' '),
            address: state.address.trim().replace(/\s+/g, ' '),
        }
        const isFilledAllField = obligateField.every(item => !!data[item])
        if(!isFilledAllField) {
            setIsPassCondSubmit(false);
            return;
        }

        setIsPassCondSubmit(isAcceptedCondition)
    }, [isAcceptedCondition, state])

    return (
        <Bound>
            <div className='logo-shop'>
                <DropOrChooseLogo
                    file={imageShow}
                    handleDropFiles={_handleDropFiles}
                    handleReturnError={_handleErrorGetImg}
                />
            </div>
            <div className='empty'></div>
            <InputText
                titleInput="Tên shop*"
                placeholder="Nhập tên shop"
                maxLength={20}
                defaultValue={shopReducer.dataCreateShop.shopName || ''}
                onChange={(val) => {
                    dispatchState({ shopName: val })
                    dispatch(setDataCreateShop({ shopName: val }))
                    setError('')
                    // dispatch(setDataCreateShop({ error: '' }))
                }}
                disabled={false}
            />
            <InputText
                titleInput="Tên người đại diện"
                defaultValue={infosUser.FullName}
                disabled={true}
            />
            <DropdownControl
                isTitle={true}
                titleDropDown="Loại chuỗi*"
                data={dataCategory}
                handleChoice={_onClickHandleChoice}
                stylesParent={styleDropdownParent}
                stylesListDropdown={stylesListDropdown}
                icon={icon_dropdown}
                value={itemDropDown.name}
                width={307}
            />
            <InputText
                titleInput="Số điện thoại đại diện"
                defaultValue={infosUser.PhoneNumber}
                disabled={true}
            />
            <InputText
                titleInput="Số GTTT người đại diện"
                defaultValue={infosUser.IdentityNumber}
                icon={ic_note}
                contentTooltip="Giấy tờ tùy thân bao gồm Chứng minh nhân dân, Căn cước công dân, hoặc hộ chiếu"
                disabled={true}
            />
            <InputText
                titleInput="Email đại diện"
                placeholder="infofacepay@gmail.com"
                defaultValue={shopReducer.dataCreateShop.email || ''}
                onChange={(val) =>  {
                    dispatchState({ email: val })
                    dispatch(setDataCreateShop({ email: val }))
                    setError('')
                    // dispatch(setDataCreateShop({ error: '' }))
                }}
            />
            <div className="shop-address">
                <InputText
                    titleInput="Địa chỉ shop*"
                    placeholder="Nhập địa chỉ"
                    defaultValue={shopReducer.dataCreateShop.address || ''}
                    onChange={(val) => {
                        dispatchState({ address: val })
                        dispatch(setDataCreateShop({ address: val }))
                        setError('')
                        // dispatch(setDataCreateShop({ error: '' }))
                    }}
                />
            </div>
            <div className="errors">
                {error}
            </div>
            <div className='terms'>
                <div className="input-wrapper">
                    <input 
                        type="checkbox" 
                        onChange={_handleCheckCondition}
                        checked={isAcceptedCondition}
                    />
                </div>
                <div className="terms-and-condition">
                    Tôi đồng ý với
                    <Link to="/services/terms-and-condition">điều khoản và điều kiện</Link>
                    sử dụng Facepay
                </div>
            </div>
            <div className="button-container">
                <ButtonControl
                    handleClick={() => _handleCancel()}
                    extendStyles={styleButtonCancel}
                    children="HỦY"
                />
            </div>
            <div className="button-container">
                <div className={isPassCondSubmit?"btn-reg":"btn-reg disabled"}>
                    <ButtonControl
                        handleClick={() => _handleRegShop()}
                        extendStyles={styleButtonRegister}
                        children="ĐĂNG KÝ SHOP"
                    />
                </div>
            </div>
        </Bound>
    )
}

TabCreateShop.propTypes = {

}

export default React.memo(TabCreateShop);

