const getError = (error) => {
    if (error.response) {
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        return {
            errorType: 'ERROR_RESPONSE',
            ...error.response
        };
    } else if (error.request) {
        // console.log(error.request);
        // return error.request;
        return {
            errorType: 'ERROR_REQUEST'
        }
    } 
    console.log('Error', error.message);
    return {
        errorType: 'ERROR_SYSTEM'
    };
}

export default getError;