import styled from 'styled-components'

const Bound = styled.div`
    display: grid;
    /* grid-template-columns: 160px 1fr 190px 185px 145px 160px; */
    grid-template-columns: ${props => props.gridTemplateColumnsContent ? props.gridTemplateColumnsContent : "160px 160px 110px 160px 165px 1fr"};
    width: 1110px;
    height: 54px;
    background-color: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: inset 0px -0.5px 0px #E2E2E2;
    &:nth-child(odd) {
      background-color: rgba(255, 255, 255, 0.6);
      /* border-radius: 3px; */
    }
    &:hover{
        /* box-shadow: inset 0px -2px 0px #0075FF; */
        background-color: rgba(149, 203, 255, 0.3);
        box-shadow: inset 0px -0.5px 0px #E2E2E2;
    }
    div{
        font-family: "UTM-Avo";
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        display: flex;
        align-items: center;
        color: #051321;
        position: relative;
        span{
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: start;
            padding: 0 10px;
            &.content-code-bill{
                margin: 0 0 0 27px
            }
            &.content-name-device{
                font-size: 16px;
            }            
        }
    }
    .more-option-button {
        width: 17px;
        height: 17px;
        cursor: pointer;
        justify-content: center;
        display: flex;
        position: absolute;
        top: 20px;
        right: 25px;
    }
    .name-store{
        display: flex;
        justify-content: flex-start;
        /* text-align: justify; */
        margin-right: 25px;
        padding: 0 10px;
    }

`
export { Bound }