import React, {
    useEffect,
    useReducer,
    useRef,
    useCallback,
    useState
} from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { useChartData, useDimension } from "./chartUtil";
import { TYPEDATE, FACEPAY_USER_DATA, STORE_MANAGER, CHAIN_MANAGER, WUM } from "../../../../constant/key";
import TypeDatePicker from "./typeDatePicker";
import Plotly from "plotly.js";
import { formatNumber } from "../../../../helper/formatNumber";
import {
    getSummary
    // changeTimeFiler
} from "../../../../redux/actions/statisticAction";

const Bound = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid #f7f7f7;
    border-radius: 20px;
    margin-right: 10px;
    border: 1px solid #cdcdcd;
`;
const StyledTooltip = styled.div`
    display: ${props => (props.visible ? "flex" : "none")};
    flex-direction: column;
    position: fixed;
    z-index: 100;
    background-color: #051321;
    border-radius: 10px;
    min-width: 163px;
    min-height: 136px;
    padding: 6px 12px 12px 12px;
    box-sizing: border-box;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    ${props => {
        const { coords } = props;
        const tooltipHeight = 136;
        let left = coords[0] ? coords[0] + 10 : 0;
        const top = coords[1] ? coords[1] - tooltipHeight - 20 : 0;
        if (window.innerWidth - left < 156) {
            left = left - 156 - 30;
        }
        return `left: ${left}px; top: ${top}px;`;
    }}
    .tooltip {
        &-total {
            margin: 6px 0px 10px 0px;
            font-family: UTM-Avo;
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 170%;
            display: flex;
            align-items: center;
            letter-spacing: 0.05px;
            color: #ffffff;
        }
        &-date {
            margin: 0;
            padding: 0;
            color: #c6d5eb;
            font-family: UTM-Avo;
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 170%;
            display: flex;
            align-items: center;
            letter-spacing: 0.05px;
        }
        &-datasets {
            display: grid;
            grid-row-gap: 10px;
            grid-auto-rows: max-content;
            &-section {
                &-block {
                    &-0,
                    &-1,
                    &-2 {
                        width: 18px;
                        height: 18px;
                        position: relative;
                        z-index: 100;
                        background-color: #fff;
                        ::before {
                            content: "";
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            z-index: 2;
                            ${props => {
        const { isToday } = props;
        return isToday
            ? `background-color: rgba(18, 121, 218, 1);`
            : `background-color: rgba(191, 221, 247, 1);`;
    }}
                        }
                    }
                    &-0 {
                        ::before {
                            ${props => {
        const { isToday } = props;
        return isToday
            ? `background-color: rgba(18, 121, 218, .4);`
            : `background-color: rgba(191, 221, 247, .4);`;
    }}
                        }
                    }
                    &-1 {
                        ::before {
                            ${props => {
        const { isToday } = props;
        return isToday
            ? `background-color: rgba(18, 121, 218, .6);`
            : `background-color: rgba(191, 221, 247, .6);`;
    }}
                        }
                    }
                }
                display: flex;
                &-content {
                    margin-left: 13px;
                    font-family: UTM-Avo;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 10px;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.05px;
                    color: #c6d5eb;
                }
            }
        }
    }
`;
const ChartWrapper = styled.div`
    height: 100%;
    .no-data {
        z-index: 99;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        font-family: UTM-Avo;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 150%;
        display: flex;
        align-items: center;
        text-align: center;
        color: #051321;
        opacity: 0.4;
    }
    .ewdrag {
        position: absolute;
        height: 55px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 999;
    }
    .wdrag {
        display: none;
    }
    .edrag {
        display: none;
    }
    width: ${props => props.width}px;
`;
const reducer = (state, action) => {
    const { type, payload, override } = action;
    return override
        ? {
            ...state,
            ...override
        }
        : {
            ...state,
            [type]: payload
        };
};

const Chart = () => {
    const userDataLocal = JSON.parse(localStorage.getItem(FACEPAY_USER_DATA));

    const { statisticReducer, shopReducer } = useSelector(state => ({
        statisticReducer: state.statisticReducer,
        shopReducer: state.shopReducer
    }));

    const [chartWidth, chartHeight, containerRef] = useDimension(-10, 0);
    const [cursorHover, setCursorHover] = useState();
    // const [needRefetch, setNeedRefetch] = useState(false);
    // const [refetchData, setRefetchData] = useState();

    const [
        labels,
        datasets,
        maxTotal,
        typeDate,
        setData,
        setTypeDate,
        firstRange,
        isEmpty,
        columnColors
    ] = useChartData(
        [],
        {
            timeBegin: statisticReducer.timeFilter.timeBefore.format(
                "YYYY/MM/DD"
            ),
            timeEnd: statisticReducer.timeFilter.timeAfter.format("YYYY/MM/DD")
        },
        TYPEDATE.DAY
    );
    const chartRef = useRef();
    const [hoverData, setHoverData] = useReducer(reducer, {
        data: {},
        coords: []
    });
    const dispatch = useDispatch();

    const fetchData = useCallback(() => {
        if (!shopReducer.data || !statisticReducer.timeFilter) return;

        const timeBegin = statisticReducer.timeFilter.timeBefore;
        const timeEnd = statisticReducer.timeFilter.timeAfter;

        let listShopID = [];
        // if (userDataLocal.Role === STORE_MANAGER) {
        if ([WUM, STORE_MANAGER].includes(userDataLocal.Role)) {
            listShopID = [shopReducer.data.Shops[0].ID]
        } else if (userDataLocal.Role === CHAIN_MANAGER) {
            if (!statisticReducer.shopFilter.listShopID) return;
            listShopID = statisticReducer.shopFilter.listShopID ? statisticReducer.shopFilter.listShopID : shopReducer.data.Shops.map(item => item.ID)
        }
        if (!timeBegin || !timeEnd || !listShopID || listShopID.length === 0) return;
        dispatch(getSummary(timeBegin, timeEnd, listShopID));
    }, [JSON.stringify(statisticReducer.timeFilter), JSON.stringify(shopReducer.data), JSON.stringify(statisticReducer.shopFilter)]);

    const oldRange = useRef({
        begin: undefined,
        end: undefined,
        typeDate: undefined
    });

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const dataSummary = statisticReducer.dataSummary;
        if (!dataSummary) return;
        setData(dataSummary);
    }, [statisticReducer.dataSummary]);

    useEffect(() => {
        const arrTrace = document.getElementsByClassName("points");
        if (arrTrace.length === 0) return;
        if (cursorHover !== undefined) {
            let index = 0;
            for (let trace of arrTrace) {
                const path = trace.childNodes[cursorHover].children[0];
                const dimension = path.getBoundingClientRect();
                const style = path.style;
                const height = -dimension.top + dimension.bottom;
                const width = -dimension.left + dimension.right;
                if (index !== 2)
                    style.setProperty("stroke-dasharray", `${height} ${width}`);
                else {
                    style.setProperty(
                        "stroke-dasharray",
                        `${height} 0 ${width} 0 ${height}`
                    );
                }
                style.setProperty("stroke-opacity", 1, "important");
                style.setProperty("stroke", "#1279da", "important");
                style.setProperty("stroke-width", "1", "important");

                index++;
            }
        } else {
            for (let trace of arrTrace) {
                for (let point of trace.children) {
                    const path = point.children[0];
                    const style = path.style;
                    style.setProperty("stroke-opacity", 0, "important");
                }
            }
        }
    }, [cursorHover]);

    useEffect(() => {
        if (firstRange.length <= 1) return;
        if (!datasets || !labels) return;
        if (!chartHeight || !chartWidth) return;
        if (!columnColors) return;

        chartRef.current = Plotly.newPlot(
            "graph",
            [
                {
                    y: datasets[2].slice().map(item => item / 1000000),
                    name: "> 1 triệu",
                    hoverinfo: "none",
                    marker: {
                        color: columnColors[0]
                    },
                    type: "bar",
                    x: labels
                },
                {
                    x: labels,
                    y: datasets[1].slice().map(item => item / 1000000),
                    name: "0.5 triệu đến 1 triệu",
                    type: "bar",
                    hoverinfo: "none",
                    marker: {
                        color: columnColors[1]
                    }
                },
                {
                    x: labels,
                    y: datasets[0].slice().map(item => item / 1000000),
                    name: "< 0.5 triệu",
                    type: "bar",
                    hoverinfo: "none",
                    marker: {
                        color: columnColors[2]
                    }
                }
            ],
            {
                barmode: "stack",
                xaxis: {
                    type: "date",
                    tickmode:
                        typeDate === TYPEDATE.DAY && labels.length > 5
                            ? "array"
                            : "linear",
                    dtick:
                        typeDate === TYPEDATE.DAY
                            ? "d"
                            : typeDate === TYPEDATE.MONTH
                                ? "M1"
                                : "M3",
                    tickangle: 80,
                    tickformat: "%d/%m/%y",
                    tickfont: {
                        family: "UTM-Avo",
                        size: 9,
                        color: "#051321"
                    },
                    //                    range: setRange()
                    autorange: true,
                    range: [
                        moment(statisticReducer.timeFilter.timeBefore).toDate(),
                        moment(statisticReducer.timeFilter.timeAfter).toDate()
                    ]
                },
                yaxis: {
                    rangemode: "nonnegative",
                    exponentformat: "none",
                    ticksuffix: " Tr",
                    fixedrange: true,
                    showticksuffix: "all",
                    autorange: true,
                    showexponent: "none",
                    tickfont: {
                        family: "UTM-Avo",
                        size: 9,
                        color: "#051321"
                    },
                    zerolinecolor: "rgba(198, 213, 235, .5)"
                },
                showlegend: false,
                width: chartWidth,
                height: chartHeight,
                autosize: false,
                tracetoggle: false,
                margin: {
                    l: 50,
                    r: 0,
                    t: 10,
                    b: 70,
                    pad: 6
                },
                dragmode: "zoom",
                hovermode: "closest",
                separators: ",."
            },
            {
                displayModeBar: false,
                displaylogo: false,
                responsive: true,
                locale: "vi"
            }
        );
        // const graphSlider = document.getElementsByClassName("nsewdrag")[0];

        // const onMouseEnterGraphSlider = () => {
        //     setNeedRefetch(true);
        // };
        // const onMouseLeaveGraphSlider = () => {
        //     setNeedRefetch(false);
        // };

        // graphSlider.addEventListener("mouseenter", onMouseEnterGraphSlider);
        // graphSlider.addEventListener("mouseleave", onMouseLeaveGraphSlider);

        const graphDOM = document.getElementById("graph");

        graphDOM.on("plotly_doubleclick", () => {
            let xRange = graphDOM.layout.xaxis.range;
            const currentLayout = graphDOM.layout;
            const rangeWidth = moment(xRange[1]).diff(
                moment(xRange[0]),
                typeDate === TYPEDATE.DAY
                    ? "day"
                    : typeDate === TYPEDATE.MONTH
                        ? "month"
                        : "quarter"
            );
            const currentRangeWidth = Math.abs(
                moment(statisticReducer.timeFilter.timeBefore).diff(
                    moment(statisticReducer.timeFilter.timeAfter),
                    typeDate === TYPEDATE.DAY
                        ? "day"
                        : typeDate === TYPEDATE.MONTH
                            ? "month"
                            : "quarter"
                )
            );
            const currentTickmode = graphDOM.layout.xaxis.tickmode;
            if (typeDate !== TYPEDATE.DAY) {
                if (currentTickmode === "array" && rangeWidth > 5) {
                    currentLayout.xaxis.tickmode = "linear";
                    Plotly.relayout("graph", currentLayout);
                    return;
                }
            } else {
                if (
                    currentTickmode === "linear" &&
                    rangeWidth <= 5 &&
                    currentRangeWidth >= 9
                ) {
                    currentLayout.xaxis.tickmode = "array";
                    Plotly.relayout("graph", currentLayout);
                    return;
                }
            }
        });
        graphDOM.on("plotly_relayout", () => {
            let xRange = graphDOM.layout.xaxis.range;
            const currentLayout = graphDOM.layout;
            const currentTickmode = graphDOM.layout.xaxis.tickmode;
            oldRange.current = {
                begin: moment(xRange[0]).add(
                    typeDate !== TYPEDATE.QUARTER ? 1 : 3,
                    typeDate !== TYPEDATE.DAY ? "months" : "days"
                ),
                end: moment(xRange[1]),
                typeDate: typeDate
            };

            // const switchDates = oldRange.current.begin.diff(
            //     oldRange.current.end
            // );
            // setRefetchData(
            //     switchDates > 0
            //         ? {
            //               begin: oldRange.current.end.startOf("day"),
            //               end: oldRange.current.begin.endOf("day")
            //           }
            //         : {
            //               begin: oldRange.current.begin.startOf("day"),
            //               end: oldRange.current.end.endOf("day")
            //           }
            // );
            const rangeWidth = moment(xRange[1]).diff(
                moment(xRange[0]),
                typeDate === TYPEDATE.DAY
                    ? "days"
                    : typeDate === TYPEDATE.MONTH
                        ? "months"
                        : "quarters"
            );
            if (
                rangeWidth <= 5 &&
                typeDate === TYPEDATE.DAY &&
                currentLayout.xaxis.tickmode === "array"
            ) {
                currentLayout.xaxis.tickmode = "linear";
                Plotly.relayout("graph", currentLayout);
                return;
            }
            if (
                currentLayout > 5 &&
                typeDate === TYPEDATE.DAY &&
                currentLayout.xaxis.tickmode === "linear"
            ) {
                currentLayout.xaxis.tickmode = "array";
                Plotly.relayout("graph", currentLayout);
                return;
            }
            if (rangeWidth > 35 && currentTickmode === "linear") {
                currentLayout.xaxis.tickmode = "array";
                Plotly.relayout("graph", currentLayout);
                return;
            }
            if (rangeWidth < 35 && currentLayout === "array") {
                currentLayout.xaxis.tickmode = "linear";
                Plotly.relayout("graph", currentLayout);
                return;
            }
        });
        graphDOM.on("plotly_hover", plotData => {
            const arr = [];
            let pointNumber;
            let total = 0;
            let currentDate = "";
            if (!plotData || !plotData.points) return;
            plotData.points.forEach(dataset => {
                pointNumber = dataset.pointNumber;
                currentDate = dataset.label;
            });
            // debugger
            datasets.forEach(dataset => {
                const pointData = dataset.find(
                    (item, index) => item && index === pointNumber
                );
                total += pointData ? +pointData : 0;
                arr.push(
                    pointData
                        // ? Math.round(
                        //     (pointData / 1000000) * 100 + Number.EPSILON
                        // ) / 100
                        ? Math.round(((pointData / 1000000) * 10))/10
                        // ? pointData / 1000000
                        : 0
                );
                // debugger
            });
            setCursorHover(pointNumber);

            setHoverData({
                override: {
                    data: { date: currentDate, total: total, datasets: arr },
                    coords: [plotData.event.clientX, plotData.event.clientY],
                    visible: true,
                    isToday:
                        typeDate === TYPEDATE.DAY
                            ? moment().format("YYYY-MM-DD") === currentDate
                            : typeDate === TYPEDATE.MONTH
                                ? moment().format("YYYY-MM") ===
                                moment(currentDate).format("YYYY-MM")
                                : moment().quarter() ===
                                moment(currentDate).quarter()
                }
            });
        });
        const removeEvent = () => {
            setCursorHover(undefined);
            setHoverData({
                type: "visible",
                payload: false
            });
        };
        graphDOM.on("plotly_unhover", removeEvent);
        document.addEventListener("mousedown", removeEvent);
        return () => {
            Plotly.purge("graph");
            document.removeEventListener("mousedown", removeEvent);
            // graphSlider.removeEventListener(
            //     "mouseenter",
            //     onMouseEnterGraphSlider
            // );
            // graphSlider.removeEventListener(
            //     "mouseleave",
            //     onMouseLeaveGraphSlider
            // );
        };
    }, [
        datasets,
        labels,
        firstRange,
        typeDate,
        maxTotal,
        columnColors,
        chartHeight,
        chartWidth,
        statisticReducer.timeFilter
    ]);

    return (
        <Bound ref={containerRef}>
            <StyledTooltip
                coords={hoverData.coords}
                visible={hoverData.visible}
                isToday={hoverData.isToday}
            >
                {hoverData && hoverData.data && (
                    <React.Fragment>
                        <div className="tooltip-date">
                            {hoverData.data.date
                                ? typeDate === TYPEDATE.DAY
                                    ? ` ${
                                    moment(
                                        hoverData.data.date,
                                        "YYYY-MM-DD"
                                    ).isoWeekday() === 7
                                        ? "Chủ nhật"
                                        : `Thứ  ${moment(
                                            hoverData.data.date,
                                            "YYYY-MM-DD"
                                        ).isoWeekday() + 1}`
                                    }, ${moment(
                                        hoverData.data.date,
                                        "YYYY-MM-DD"
                                    ).format("DD/MM/YYYY")}`
                                    : typeDate === TYPEDATE.MONTH
                                        ? `Tháng ${moment(
                                            hoverData.data.date,
                                            "YYYY-MM-DD"
                                        ).format("MM/YYYY")}`
                                        : `Quý ${moment(
                                            hoverData.data.date,
                                            "YYYY-MM-DD"
                                        ).quarter()}/${moment(
                                            hoverData.data.date
                                        ).format("YYYY")}`
                                : "Unknown"}
                        </div>
                        <div className="tooltip-total">
                            {hoverData.data.total
                                ? formatNumber(hoverData.data.total)
                                : "Unknown"}
                            &nbsp;vnd
                        </div>
                        <div className="tooltip-datasets">
                            {hoverData.data.datasets &&
                                hoverData.data.datasets.map((item, index) => (
                                    <div
                                        key={index}
                                        className="tooltip-datasets-section"
                                    >
                                        <div
                                            className={`tooltip-datasets-section-block-${index}`}
                                        ></div>
                                        <div className="tooltip-datasets-section-content">
                                            {index === 2
                                                ? "1 - 2 triệu:"
                                                : index === 1
                                                    ? "0,5 ~ 1 triệu:"
                                                    : "< 0,5 triệu:"}
                                            &nbsp;
                                            {/* {new Intl.NumberFormat(
                                                        "vi-VN"
                                                    ).format(item)} */}
                                            {String(item).replace('.', ',')}
                                            &nbsp;{item !== 0 && "triệu"}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </React.Fragment>
                )}
            </StyledTooltip>
            <TypeDatePicker
                dataDropdown={[
                    { key: TYPEDATE.DAY, value: "Ngày" },
                    { key: TYPEDATE.MONTH, value: "Tháng" },
                    { key: TYPEDATE.QUARTER, value: "Quý" }
                ]}
                onChange={({ e, data }) => setTypeDate(data.key)}
            />
            <ChartWrapper>
                {isEmpty && (
                    <p className="no-data">Chưa có thông tin dữ liệu...</p>
                )}
                <div ref={chartRef} id="graph" />
            </ChartWrapper>
        </Bound>
    );
};

export default Chart;
