
import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    flex:1;
    border-radius: 20px 20px 0px 0px;
    background-color: #FFFFFF;
    position: relative;
    width: 100%;
    
    
    /* ::-webkit-scrollbar{
        width: 0;
        position: absolute;
    } */
    .statistic-container{
        position: absolute;
        display: grid;
        grid-template-columns:350px 1fr;
        grid-template-rows: 76px 320px 1fr;
        /* padding-top: 30px; */
        flex: 1;
        width: 100%;
        grid-gap: 10px;
        /* grid-gap: 50px; */
        &:nth-child(1){
            /* grid-gap: 50px; */
        }

        .no-data-chart{
            overflow: hidden;
            box-sizing: border-box;
            border: 1px solid #f7f7f7;
            border-radius: 20px;
            margin-right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            p{
                font-family: UTM-Avo;
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 150%;
                color: #051321;
                opacity: 0.4;
            }
        }
    }
`

export {
    Bound
}
