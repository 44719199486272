import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"

// styles
import { Bound } from "./index.styles"

// tools
import { convertNumber } from "../../../../helper/tools"

// controls
import ModalControl from '../../../Control/ModalControl';
import TooltipCustomControl from '../../../Control/TooltipCustomControl';

// actions
import { getTotal } from '../../../../redux/actions/statisticAction';
// import { FACEPAY_USER_DATA } from '../../../../constant/key';

//component
import IcLogoShop from '../../../../images/component/IcLogoShop';

import { STORE_MANAGER, CHAIN_MANAGER, FACEPAY_USER_DATA, WUM } from '../../../../constant/key';
import { host } from '../../../../host/host';

const billion = 99999999999
// const billion = 1

const Merchant = () => {
    // const userDataLocal = JSON.parse(localStorage.getItem(FACEPAY_USER_DATA));

    const refTooltipMoney = useRef(null)

    const [dataTotal, setDataTotal] = useState(null);
    const [showMoneyTooltip, setShowMoneyTooltip] = useState(false);
    const [userDataLocal, setUserDataLocal] = useState({})

    const { statisticReducer, shopReducer, userReducer } = useSelector(state => ({
        statisticReducer: state.statisticReducer,
        shopReducer: state.shopReducer,
        userReducer: state.userReducer,
    }))

    const dispatch = useDispatch();

    const fetchDataTotal = useCallback(() => {
        if (!shopReducer.data || !statisticReducer.timeFilter) return;
        if (!Object.keys(userDataLocal).length) return;
        const { timeFilter } = statisticReducer;

        let listShopID = [];
        // if (userDataLocal.Role === STORE_MANAGER) {
        if ([WUM, STORE_MANAGER].includes(userDataLocal.Role)) {
            listShopID = [shopReducer.data.Shops[0].ID]
        } else if (userDataLocal.Role === CHAIN_MANAGER) {
            if (!statisticReducer.shopFilter.listShopID) return;
            listShopID = statisticReducer.shopFilter.listShopID ? statisticReducer.shopFilter.listShopID : shopReducer.data.Shops.map(item => item.ID)
        }
        dispatch(getTotal(
            timeFilter.timeBefore,
            timeFilter.timeAfter,
            listShopID
        ))
    }, [JSON.stringify(statisticReducer.timeFilter), JSON.stringify(shopReducer.data), JSON.stringify(statisticReducer.shopFilter), userDataLocal.Role])

    useEffect(() => {
        fetchDataTotal();
    }, [fetchDataTotal])


    useEffect(() => {
        if (statisticReducer.dataTotal && Object.keys(statisticReducer.dataTotal).length > 0 && statisticReducer.dataTotal.Code === 0) {
            setDataTotal(statisticReducer.dataTotal)
        }
    }, [statisticReducer.dataTotal])

    useEffect(() => {
        if(!userReducer.loginResult) return;
        setUserDataLocal(userReducer.loginResult)
    }, [userReducer.loginResult])

    return (
        <Bound>
            <div className="shop-info">
                {
                    // userDataLocal.Role === STORE_MANAGER && 
                    [WUM, STORE_MANAGER].includes(userDataLocal.Role) && 
                    shopReducer.data && shopReducer.data.Shops[0].ID ?
                        <img src={host + `shop/avatar?id=${shopReducer.data.Shops[0].ID}&t=${moment().unix()}`} /> :
                        userDataLocal.Role === CHAIN_MANAGER && shopReducer.data && shopReducer.data.Chain.ID ?
                            <img src={host + `chain/avatar?id=${shopReducer.data.Chain.ID}&t=${moment().unix()}`} /> :
                            "-"
                }
                {/* <IcLogoShop /> */}
                <p data-toggle="tooltip" title={
                    // userDataLocal.Role === STORE_MANAGER && 
                    [WUM, STORE_MANAGER].includes(userDataLocal.Role) && 
                    shopReducer.data && shopReducer.data.Shops[0].Address ?
                        shopReducer.data.Shops[0].Name :
                        userDataLocal.Role === CHAIN_MANAGER && shopReducer.data && shopReducer.data.Chain.Name ?
                            shopReducer.data.Chain.Name :
                            "-"
                }>
                    {
                        // userDataLocal.Role === STORE_MANAGER && 
                        [WUM, STORE_MANAGER].includes(userDataLocal.Role) && 
                        shopReducer.data && shopReducer.data.Shops[0].Address ?
                            shopReducer.data.Shops[0].Name :
                            userDataLocal.Role === CHAIN_MANAGER && shopReducer.data && shopReducer.data.Chain.Name ?
                                shopReducer.data.Chain.Name :
                                "-"
                    }
                </p>
            </div>

            {/* <div className='shop-address'>
                <IcLocation />
                <p className='address' data-toggle="tooltip" title={shopReducer.data && shopReducer.data.Address}>
                    {shopReducer.data && shopReducer.data.Address}
                </p>
            </div>
            <div className='shop-phone'>
                <IcPhoneMerchant />
                <p className='phone'>
                    {shopReducer.data && shopReducer.data.PhoneNumber}
                </p>
            </div> */}

            {/* <span className="line" /> */}

            <div className="shop-payment">
                <p className="shop-payment__title">{"Lượt thanh toán"}</p>
                <div className="shop-payment__value">
                    <b>{dataTotal && dataTotal.Count || 0}</b>
                    <p>{"lượt"}</p>
                </div>
            </div>
            <div className="shop-revenue">
                <p className="shop-revenue__title">{"Doanh thu"}</p>
                <div className="shop-revenue__value"
                    ref={refTooltipMoney}
                    onMouseEnter={() => { setShowMoneyTooltip(true) }}
                    onMouseLeave={() => { setShowMoneyTooltip(false) }}
                    data-toogle="tooltip" title={`${convertNumber(dataTotal ? dataTotal.Total : 0)} VND`}

                >
                    <b className='money_toolTip'>{
                        convertNumber(dataTotal ? dataTotal.Total : 0)}
                    </b>
                    <p>{"VND"}</p>
                </div>
            </div>
            {
                showMoneyTooltip && dataTotal.Total >= billion &&
                <ModalControl >
                    <TooltipCustomControl
                        typeTooltip="DEFAULT"
                        dataTooltip={
                            `${convertNumber(dataTotal ? dataTotal.Total : 0)} VND`
                        }
                        refTooltip={refTooltipMoney}
                    />
                </ModalControl>
            }
        </Bound>
    );
}

export default React.memo(Merchant);
