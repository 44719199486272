import React from 'react'

const IcLocation = (props) => {
    return <svg {...props} width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.4676 1.43613C9.54151 0.510072 8.31017 0 7.00061 0C5.69078 0 4.45971 0.510072 3.53365 1.43613C1.81986 3.14966 1.60689 6.37372 3.07242 8.32708L7.00061 14L10.9229 8.33501C12.3943 6.37372 12.1814 3.14966 10.4676 1.43613ZM7.04586 6.64755C6.05921 6.64755 5.25614 5.84448 5.25614 4.85783C5.25614 3.87118 6.05921 3.0681 7.04586 3.0681C8.03251 3.0681 8.83559 3.87118 8.83559 4.85783C8.83559 5.84448 8.03251 6.64755 7.04586 6.64755Z" fill="#BFDDF6" />
  </svg>
}
export default IcLocation
    







