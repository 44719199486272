import styled from 'styled-components'

const Bound = styled.div`    
    display: flex;
    flex: 1;
    flex-direction:column;
    /* margin-top: 30px; */
    /* width: calc(100% + 15px); */
    position: relative;
    .pos-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: max-content;
        grid-gap: 30px;
        /* width: 100%; */
        height: fit-content;
        /* margin: 0 0 20px 0; */
        width: calc(100% + 11px);
        overflow-y: scroll;
        padding-right: 6px;
        scroll-behavior: smooth;
        
        &::-webkit-scrollbar {
            width: 6px;
            background-color:transparent;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #E2E2E2;
            background-clip: padding-box;
            border-radius: 20px;
        }
    }
    .no-data{
        font-family: "UTM-Avo";
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 170%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.05px;
        color: rgba(5, 19, 33, 0.4);
    }
`;

export { Bound };