import * as types from '../../constant/typeAction';

// export const changeAllPageSize = (pageSize) => async dispatch => {
//     dispatch({
//         type: types.CHANGE_ALL_PAGE_SIZE,
//         pageSize
//     })
// }

export const changePageSize = (pageSize, type) => async dispatch => {
    //type: HISTORY_LIST || POS_GRID || POS_LIST
    dispatch({
        type: types.CHANGE_PAGE_SIZE,
        payload: {
            pageSize,
            type
        }
    })
}

export const changePageDisplayItem = (itemDisplay, type) => async dispatch => {
    //type: HISTORY_LIST || POS_GRID || POS_LIST
    dispatch({
        type: types.CHANGE_PAGE_DISPLAY_ITEM,
        payload: {
            itemDisplay,
            type
        }
    })
}

export const changePageNumber = (pageNumber, type) => async dispatch => {
    //type: HISTORY_LIST || POS_GRID || POS_LIST
    dispatch({
        type: types.CHANGE_PAGE_NUMBER,
        payload: {
            pageNumber,
            type
        }
    })
}