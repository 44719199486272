import styled from "styled-components"

const Bound = styled.div`
    font-family: UTM-AVO;
    display: flex;
    flex:1;
    grid-column: 1 / span 2;
    flex-direction: column;
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    margin-bottom: 35px;
`

export { Bound };