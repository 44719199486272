import styled from 'styled-components'
const Bound = styled.div`
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    width: 100%;
    /* height: 100%; */
    background-color: #fff;
    border-radius: 20px;
    overflow: hidden;
    .service-container{
        grid-column: 2 / span 1;
        width: 1110px;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
    }
`;
export { Bound }