import styled from 'styled-components'


const Bound = styled.div`
    display: flex;
    width: 100%;
    min-width: 1110px;
    max-width: 1440px;
    height: 70px;
    margin: 0 20px;   
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    a,p{
        font-family: "UTM-Avo";
        font-size: 15px;
        line-height: 22px;
        color:#fff;
    }
    #menu-list{
        display:flex;
        flex-direction:row;
        margin-left:165px;
        a{
            text-decoration:none;
            margin-right:60px;
            :last-child{
                margin-right:0;
            }
        }
    }
    #wee-product{
        display:flex;
        flex-direction:row;
        margin-right:165px;
        p{
            width: 150px;
            /* margin-right: 10px; */
        }
        img{

        }
    }
`
export { Bound }