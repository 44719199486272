import React, { useEffect, useState, useCallback, useRef } from 'react'
import { Bound, StyleShowZoomQRCode } from './index.styles'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux';
import QRCode from "qrcode.react";

// images
import ic_union_more from "../../../images/ic_union_more.svg";
import ic_QR_scan from "../../../images/ic_QR_scan.svg";
import ic_refresh from "../../../images/ic_refresh.svg";
import ic_qr_fail from "../../../images/ic_qr_fail.svg";
import ic_zoom from '../../../images/ic_zoom.svg'
import ic_close from '../../../images/ic_close.svg'
//component
import ModalControl from '../../Control/ModalControl';
//host
import { hostWsLoginStore } from '../../../host/hostWebSocket'
//action 
import { generate_mPOS_qr, login_mPOS } from '../../../redux/actions/userAction'
import { createStore } from '../../../redux/actions/shopAction'

var webSocketLogin;
var timeoutSocket;
var expireTime;
var timer;
let tokenKey;

let variableTimeout = null;

const TabQRCODE = (props) => {
    const dispatch = useDispatch()

    const [showZoom, setShowZoom] = useState(false);
    const [countDownTimeExpired, setCountDownTimeExpired] = useState(0)
    const [dataRegisterPOS, setDataRegisterPOS] = useState(null);
    const isClickOutsiteRef = useRef(false);
    const [checkError, setCheckError] = useState(false)

    const [startWS, setStartWS] = useState(true);
    const [sessionKey, setSessionKey] = useState(null)
    const { userReducer } = useSelector(state => ({
        userReducer: state.userReducer
    }));

    useEffect(() => {
        dispatch(generate_mPOS_qr())
    }, [])

    useEffect(() => {
        return () => {
            clearTimeout(timeoutSocket)
            clearInterval(timer)
            if (webSocketLogin) {
                webSocketLogin.close();
            }
        }
    }, [])

    useEffect(() => {
        timeoutSocket = null
        if (!startWS && webSocketLogin) {
            _closeConnectWebSocket()
            _startConnectWebSocket()
        }

        return () => {
            clearTimeout(timeoutSocket)
            clearInterval(timer)
        }
    }, [startWS])

    useEffect(() => {
        if (!userReducer.generateMPOSQR) return
        let token = userReducer.generateMPOSQR.SessionKey
        expireTime = userReducer.generateMPOSQR.ExpireTime
        if (!token) return
        if (token !== tokenKey) tokenKey = token
        else return

        _startConnectWebSocket()
        setSessionKey(token)
        setCountDownTimeExpired(expireTime)
        _startTimer()
        
        return () => {
            clearTimeout(timeoutSocket)
            clearInterval(timer)
        }
    }, [userReducer.generateMPOSQR])

    const _startTimer = () => {
        timer = setInterval(() => {
            expireTime--
            setCountDownTimeExpired(expireTime)
            if(expireTime === 0){
                clearInterval(timer)
                timer = null
            }
        }, 1000); 
    }

    /**
     * @description:  Connect WebSocket
     */
    const _startConnectWebSocket = () => {
        let hostWs = `${hostWsLoginStore}${tokenKey}`
        let websocket = new WebSocket(hostWs);
        websocket.onopen = async event => {
            console.log("ws login open", event);
            // await setStartWS(true);
        };
        websocket.onmessage = event => {
            console.log("ws login message ", event);
            const data = JSON.parse(event.data);
            console.log(data)
            if(!data) return
            if(!data.ClientID || !data.ClientSecret) return
            dispatch(login_mPOS(data.ClientID, data.ClientSecret))
            _closeConnectWebSocket()
        };
        websocket.onclose = async event => {
            console.log("ws login close", event);
            // await setStartWS(false);
        };
        websocket.onerror = async (event) => {
            console.log("ws login onerror ", event);
            await setStartWS(false);
        };

        webSocketLogin = websocket;
        timeoutSocket = setTimeout(() => {
            _closeConnectWebSocket()
        }, expireTime * 1000);
    };

    const _closeConnectWebSocket = () => {
        webSocketLogin.close()
        setSessionKey(null)
    }

    const handleClick = useCallback(() => {
        if (isClickOutsiteRef.current === false)
            setTimeout(() => {
                setShowZoom(false)
            }, 0);
    }, []);


    useEffect(() => {
        if (checkError) return;

        if (showZoom)
            document.addEventListener("click", handleClick, false);
        return () => {
            if (checkError) return;
            document.removeEventListener("click", handleClick, false);
        }
    }, [handleClick, showZoom, checkError]);

    // useEffect(() => {
    //     let intervalExpired = null;
    //     if (countDownTimeExpired > 0) {
    //         intervalExpired = setInterval(async () => {
    //             await setCountDownTimeExpired(second => second - 1000);
    //         }, 1000);
    //     } else if (countDownTimeExpired === 0) {
    //         setExpiredKeyQR(true);
    //     }
    //     return () => clearInterval(intervalExpired);
    // }, [countDownTimeExpired])


    // useEffect(() => {
    //     if (dataRegisterPOS) {
    //         setCountDownTimeExpired(dataRegisterPOS.TimeOut * 1000)
    //     }
    //     return () => clearTimeout(variableTimeout)

    // }, [dataRegisterPOS])

    // useEffect(() => {
    //     return () => clearTimeout(variableTimeout)
    // }, [])

    return (
        <Bound>
            <div className="wrap-qrcode">
                <div className="qr-container">
                    {
                        sessionKey && sessionKey.length > 0 ?
                            <React.Fragment>
                                <QRCode
                                    value={sessionKey}
                                    id="qr-code-id"
                                    className="qr-container__block-qr"
                                    renderAs="svg"
                                    size={288}
                                    level="Q"
                                    onClick={() => setShowZoom(true)}
                                />
                                <div className="qr-container__zoom-qr"
                                    onClick={() => setShowZoom(true)}>
                                    <img src={ic_zoom} alt="ic_zoom" />
                                    <span>Phóng lớn</span>
                                </div>
                            </React.Fragment>
                            :
                            <div className="qr-code-fail">
                                <img src={ic_qr_fail} alt="icon-qr-code" />
                                <p>Mã QR đã hết hạn</p>
                                <div className="button-reload" onClick={() => { dispatch(generate_mPOS_qr()) }}>
                                    <img src={ic_refresh} alt="icon-reload" />
                                    <span>TẢI LẠI</span>
                                </div>
                            </div>
                    }
                </div>
            </div>
            <div className="content-container">
                {
                    sessionKey && sessionKey.length > 0 &&
                    <div className="countdown-timer first">
                        <p>
                            Mã QR sẽ hết hạn sau:
                        </p>
                        <span>
                            {countDownTimeExpired}s
                        </span>
                    </div>

                }
                <div className="title-qr-code">Quét mã QR để đăng nhập</div>
                <p><b>Bước 1: </b>Đăng nhập vào ứng dụng Facepay trên điện thoại của bạn</p>
                <p>
                    <b>Bước 2: </b>Trên giao diện trang chủ, mở menu &nbsp;<img src={ic_union_more} alt="more" />
                    {/* &nbsp; Chọn biểu tượng &nbsp;<img src={ic_QR_scan} alt="scan" />&nbsp; quét mã để đăng nhập. */}
                </p>
            </div>

            {
                showZoom &&
                <ModalControl>
                    <StyleShowZoomQRCode>
                        <div className="ic_close" onClick={() => setShowZoom(false)}>
                            <img src={ic_close} alt="ic_close" />
                        </div>
                        {
                            sessionKey && sessionKey.length > 0 ?
                                <div className="qr-code-zoom">
                                    <QRCode
                                        value={sessionKey}
                                        id="qr-code-id"
                                        className="qr-container__block-qr"
                                        renderAs="svg"
                                        size={288}
                                        level="Q"
                                        onMouseEnter={() => isClickOutsiteRef.current = true}
                                        onMouseLeave={() => isClickOutsiteRef.current = false}
                                    />
                                </div>
                                :
                                <div className="qr-container__expired"
                                    onMouseEnter={() => checkError ? null : isClickOutsiteRef.current = true}
                                    onMouseLeave={() => checkError ? null : isClickOutsiteRef.current = false}
                                >
                                    <div className="background">
                                        <img src={ic_qr_fail} alt="icon-qr-code" />
                                        <p className="title">Mã đã hết hạn</p>
                                        <p className="reload" onClick={() => { dispatch(generate_mPOS_qr()) }}>
                                            <img src={ic_refresh} alt="icon-reload" />
                                            <span>TẢI LẠI</span>
                                        </p>
                                    </div>
                                </div>
                        }
                        <div className="countdown-timer last">
                            <p>
                                Mã QR sẽ hết hạn sau:
                            </p>
                            <span>
                                {countDownTimeExpired}s
                            </span>
                        </div>
                    </StyleShowZoomQRCode>
                </ModalControl>
            }
        </Bound>
    )
}

TabQRCODE.propTypes = {

}

export default React.memo(TabQRCODE);

