import * as types from "../../constant/typeAction";

export const initialState = {
    status: types.WEB_IS_ONLINE,
    countSwitch: 0,
    isLoading: {
        mountPage: false,
        active: -1
    }
};

export default function systemReducer(state = initialState, action) {
    switch (action.type) {
        case types.WEB_IS_ONLINE:
        case types.WEB_IS_OFFLINE:
            return {
                ...state,
                status: action.status,
                countSwitch: state.countSwitch + 1
            };
        case types.ACTIVE_LOADING_PAGE:
            return {
                ...state,
                isLoading: {
                    mountPage: window.location.pathname,
                    active: state.isLoading.active < 0 ? 1 : state.isLoading.active + 1
                }
            };
        case types.DEACTIVE_LOADING_PAGE:
            return {
                ...state,
                isLoading: {
                    mountPage: window.location.pathname,
                    active: state.isLoading.active < 0 ? 0 : state.isLoading.active - 1
                }
            };

        default:
            return state;
    }
}
