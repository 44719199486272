import * as types from "../../constant/typeAction";

export const initialState = {
    total: 0,
    listPos: null,
    data: null,
    shopFilter: {
        dataDropdown: null,
        listShopID: null
    },
    type: ""
};

export default function posReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_FILTER_POS:
            return {
                ...state,
                listPos: action.data,
                total: action.total,
                type: action.type
            };
        case types.REGISTER_POS:
            return {
                ...state,
                data: action.data,
                type: action.type
            };
        case types.GET_FILTER_POS_START:
        case types.REGISTER_POS_START:
        case types.POS_PAGE_ADD_NEW_OFFLINE:
        case types.POS_PAGE_GRID_OFFLINE:
        case types.POS_PAGE_LIST_OFFLINE:
            return {
                ...state,
                type: action.type
            };
        case types.REMOVE_POS:
            return {
                ...state,
                data: action.data,
                type: action.type
            };
        case types.CHANGE_STATUS_POS:
            return {
                ...state,
                listPos: action.data,
                type: action.type
            }
        case types.GET_FILTER_POS_ERROR:
            return {
                ...state,
                type: action.type,
                err: action.err
            }
        case types.REMOVE_POS_ERROR:
        case types.REGISTER_POS_ERROR:
        case types.PAYMENT_REQUEST_ERROR:
        case types.CHANGE_STATUS_POS_ERR:
            return {
                ...state,
                err: action.err,
                type: action.type
            };

        case types.DROPDOWN_SHOP_FILTER_POS:
            return {
                ...state,
                shopFilter: {
                    ...state.shopFilter,
                    dataDropdown: action.dataDropdown ? action.dataDropdown : null,
                    listShopID: action.listShopID ? action.listShopID : null
                },
                type: action.type
            }

        default:
            return state;
    }
}