import styled from 'styled-components'

const RequestPayStyles = styled.div`
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 305px;
    height: fit-content;
    background-color: #FFFFFF;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    padding: 26px 20px 10px 20px;
    position: fixed;
    outline: none;
    p{
        font-family: "UTM-Avo";
    }
    .request-payment-title{
        font-weight: bold;
        font-size: 17px;
        line-height: 22px;
        color: #222222;
        margin-bottom: 9px;
    }
    .request-payment-note {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 128%;
        color: #051321;
        opacity: 0.5;
        margin: 0 0 10px 6px;
    }

    .request-payment-submit{
        width:105px;
        height:36px;
        background-color: #FF9500;
        border-radius: 4px;
        font-family: "Roboto-medium";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        color: #FFFFFF;
        display:flex;
        justify-content:center;
        align-items:center;
        align-self:flex-end;
        margin-top: 9px;

        &:hover {
            cursor: pointer;
        }
    }

    ${props => props.extendStyles}
`

export {
    RequestPayStyles
}