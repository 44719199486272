import moment from "moment";

export const convertDateTime = (time, format) => {
  return moment(time).format(format);
};

export const buildValueScope = value => {
  if (value < 500000) {
    return "< 0,5 triệu";
  } else if (value >= 500000 && value <= 1000000) {
    return "0,5 triệu ~ 1 triệu";
  } else if (value > 1000000) {
    return "> 1 triệu";
  }
};

export const buildMoney = value => {
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const deg_to_dms = (deg, isLongitude) => {
  var de = deg < 0 ? (isLongitude ? "W" : "S") : isLongitude ? "E" : "N";

  var d = Math.floor(deg);
  var minfloat = (deg - d) * 60;
  var m = Math.floor(minfloat);
  var secfloat = (minfloat - m) * 60;
  var s = Math.round(secfloat);
  if (s === 60) {
    m++;
    s = 0;
  }
  if (m === 60) {
    d++;
    m = 0;
  }
  return "" + d + "°" + m + "'" + s + '"' + de;
};

// Random number from 0 to length
export const randomNumber = length => {
  return Math.floor(Math.random() * length);
};

// Generate Pseudo Random String, if safety is important use dedicated crypto/math library for less possible collisions!

export const generateID = length => {
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let text = "";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(randomNumber(possible.length));
  }

  return text;
};

// ===================== Format image base64 to request sever ===================== //
export const formatBase64 = imageBase64 => {
  let newBase = "";
  newBase = imageBase64.split(",")[1];
  return newBase;
};


export const convertNumber = (number, type = ".") => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, type);
};


export const checkMail = email => {
  var filter = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  if (!filter.test(email)) {
    return false;
  } else {
    return true;
  }
};

export const isEven = (value) => {
  if (value % 2 === 0)
    return true;
  else
    return false;
}

export const roundNumberInteger = number => {
  if (number < 100) return number;
  const numberToString = String(number); /* .split("").map(Number) */
  if (numberToString[1] < 3)
    return Number(numberToString[0] + "2".repeat(numberToString.length - 1));
  if (numberToString[1] >= 3 && numberToString[1] < 7)
    return Number(
      numberToString[0] + "5" + "0".repeat(numberToString.length - 2)
    );
  return Number(
    Number(numberToString[0]) + 1 + "0".repeat(numberToString.length - 1)
  );
};

export const RemoveAccents = strAccents => {
  if (!strAccents) {
    return "";
  }
  let strAccentsCheck = strAccents.split("");
  let strAccentsOut = [];
  let strAccentsLen = strAccentsCheck.length;
  let accents =
    "ẢẠÀÁÂÃÄÅĂẤẨẪẬĂẮẰẲẴẶàáảạâãäåỒỔỖỐÒÓỎỌÔÕÕÖØỏọơớờởỡợốồổỗộậăấầẩẫắằẳẵặỪỬỮỰƯưứừửữựòóôõöøỢỞỠƠỜỚỎÕỌểềếễệỄỀẾỂỆÈÉÊËẺẼẸèéẻẽẹêëðÇçĐđÌÍÎÏỈĨỊìíîïỉĩịũủụưứừửữựuỨỤƯỦŨÙÚÛÜùúûüÑñŠšýỳỷỹỵŸÿýÝỶỸỴỲŽžĐđ";

  let accentsOut =
    "AAAAAAAAAAAAAAAAAAAaaaaaaaaOOOOOOOOOOOOOoooooooooooooaaaaaaaaaaaUUUUUuuuuuuooooooOOOOOOOOOeeeeeeEEEEEEEEEEEEeeeeeeeeCcDdIIIIIIIiiiiiiiuuuuuuuuuUUUUUUUUUuuuuNnSsyyyyyYyyYYYYYZzDd";
  for (let y = 0; y < strAccentsLen; y++) {
    if (accents.indexOf(strAccents[y]) !== -1) {

      strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
    } else {
      strAccentsOut[y] = strAccents[y];
    }
  }

  strAccentsOut = strAccentsOut.join("");
  return strAccentsOut;
};

export const reverseString = (str) => {
  let splitString = str.split("");
  let reverseArray = splitString.reverse();
  let joinArray = reverseArray.join("");
  return joinArray;
}

export const enCodeSTK = value => {
  let result = "";
  let newValue = reverseString(value);
  for (let i = 0; i < newValue.length; i++) {
    let element = newValue[i];
    if (i < 4)
      result += newValue[i]
    else {
      element = "*"
      result += element;
    }
  }
  return reverseString(result)
}

/**
 *
 * @param {*ref} ref
 * @description: return object contain top and left follow ref
 */
export const _toolGetPosition = (ref) => {
  let position;
  if (ref) {
    let bound = ref.current.getBoundingClientRect();
    let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    let topBound = bound.top + scrollTop;
    let leftBound = bound.left + scrollLeft;
    let bottomBound = window.innerHeight - bound.bottom;
    let rightBound = window.innerWidth - bound.right;

    position = {
      top: topBound + bound.height / 2,
      left: leftBound + bound.width / 2,
      bottom: bottomBound + bound.height / 2,
      right: rightBound + bound.width / 2,
    };
  }
  return position;
};

/**
 * @param {object} currentPosition : current position is left top
 * @param {ref} ref
 */
export const _calcNewPositionBounding = (currentPosition, ref) => {
  let newPosition = currentPosition;
  if (
    ref.offsetTop + ref.offsetHeight >
    window.innerHeight - window.screenTop
  ) {
    newPosition.top = ref.offsetTop - ref.offsetHeight;
  }
  return newPosition;
};


export const clearFirstString = (value, charAtCheck) => {
  if (value.charAt(0) === charAtCheck)
    return value.substr(1);
  else return value
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key =>
    object[key] === value);
} 
