import Axios from "axios"

import * as types from '../../constant/typeAction';

import {host} from '../../host/host'

// action
import { pushError, pushSuccess } from "./notifyAction";

// tools
import getError from "../../helper/getErrorServices";

export const paymentRequest = (posID, amount) => async dispatch => {
    const queryDataFormat = JSON.stringify({
        POSID:      posID,
        Amount:     amount
    })
    const hostPaymentRequest = host + 'pos/paymentRequest'
    try {
        const res = await Axios.post(hostPaymentRequest, queryDataFormat)
        const {data} = res;
        if(data && data.Code === 0){
            data.type=types.PAYMENT_REQUEST
            dispatch({
                type: types.PAYMENT_REQUEST,
                data: data
            })
            // push success
            dispatch(pushSuccess({
                type: types.PAYMENT_REQUEST,
                // data: 
            }))
        }
        else{
            // data.type=types.PAYMENT_REQUEST_ERROR
            // dispatch({
            //     type: types.PAYMENT_REQUEST_ERROR,
            //     err: ''
            // })
            dispatch(pushError({
                type: types.PAYMENT_REQUEST_ERROR,
                error: data
            }))
        }
    }
    catch(err){
        // dispatch({
        //     type: types.PAYMENT_REQUEST_ERROR,
        //     err
        // })
        dispatch(pushError({
            type: types.PAYMENT_REQUEST_ERROR,
            error: getError(err)
        }))
    }
}

export const clearPaymentRequest = (data) => async dispatch => {
    dispatch({
        type: types.CLEAR_PAYMENT_REQUEST,
        data: data
    })
}