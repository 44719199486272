import styled from 'styled-components'

const DeleteConfirmStyles = styled.div`
    box-sizing: border-box;
    display:flex;
    flex-direction:column;
    padding: 18px 20px 31px 18px;
    width: 328px;
    height:fit-content;
    position: fixed;
    background-color: #FFFFFF;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    outline: none;

    .confirm-popup-head {
        display: flex;
        align-items: center;

        img{
            /* position: absolute; */
            width:22px;
            height:22px !important;
            /* top:18px; */
            /* left:18px; */
        }

        .confirm-popup-title{
            font-size: 17px;
            line-height: 22px;
            color: #222222;
            margin-left: 27px;
            line-height: 128.91%;
            /* margin-bottom: 14px; */
        }
    }
    
    /* p{
        font-family: "UTM-Avo";
        font-style: normal;
        font-weight: normal;
    } */
    
    .confirm-popup-content{
        font-size: 13px;
        line-height: 18px;
        color: #484848;
        margin: 14px 0 0 49px;
    }
    .confirm-popup-button-container{
        display:flex;
        flex-direction:row;
        justify-content:flex-end;
        margin-top: 49px;
        .cancel-button,.submit-button{
            display:flex;
            align-items:center;
            justify-content:center;
            width:104px;
            height:35px;
            border: 0.5px solid #E43A3A;
            box-sizing: border-box;
            border-radius: 4px;
            font-family: "UTM-Avo";
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;

            &:hover {
                cursor: pointer;
            }
        }
        .cancel-button{
            background-color:#fff;
            color:#E43A3A;
            margin-right:10px;
        }
        .submit-button{
            background-color:#E43A3A;
            color:#fff;
        }
    }

    ${props => props.extendStyles}
`

export {
    DeleteConfirmStyles
}