export const footerData=[
    {
        value:'Trợ giúp',
        path:'/services/support'
    },
    // {
    //     value:'Liên hệ với chúng tôi',
    //     path:'/services/contact'
    // },
    {
        value:'Điều khoản sử dụng',
        path:'/services/terms-and-condition'
    },
]