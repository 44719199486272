import React from 'react'

const IcQrFail = (props) => {
    return <svg {...props} width={132} height={80} viewBox="0 0 132 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <path d="M20.1228 28.41L15.0589 23.3461L9.9971 28.4079C7.71366 30.6978 4.00416 30.6978 1.71638 28.4079C-0.571404 26.1201 -0.571402 22.4084 1.71421 20.125L6.77597 15.0632L1.71421 10.0014C-0.571402 7.71149 -0.571402 4.00199 1.71421 1.71855C4.00633 -0.571401 7.71583 -0.571402 9.9971 1.71638L15.061 6.78032L20.1271 1.71421C22.4128 -0.571402 26.1201 -0.571402 28.41 1.71421C30.6956 4.00416 30.6956 7.71366 28.41 9.99927L23.3461 15.061L28.41 20.125C30.6956 22.4128 30.6956 26.1223 28.4057 28.4079C26.1201 30.6978 22.4128 30.6978 20.1228 28.41ZM124.267 15.061L129.331 9.99927C131.616 7.71149 131.616 4.00199 129.331 1.71421C127.039 -0.571402 123.329 -0.571402 121.048 1.71421L115.982 6.78032L110.918 1.71638C108.632 -0.571402 104.923 -0.571401 102.635 1.71855C100.349 4.00416 100.349 7.71366 102.635 10.0014L107.697 15.0632L102.635 20.125C100.349 22.4106 100.349 26.1223 102.637 28.4079C104.925 30.6978 108.634 30.6978 110.918 28.4079L115.979 23.3461L121.043 28.41C123.331 30.6978 127.041 30.6978 129.326 28.4079C131.616 26.1201 131.616 22.4106 129.331 20.125L124.267 15.061Z" fill="url(#paint0_linear)" />
      <path d="M87.3803 61.8595C85.2473 59.9024 82.048 60.053 80.0674 62.0101L74.735 67.2791L68.7933 61.8595C66.6604 59.9024 63.4609 60.053 61.4803 62.0101L55.9956 67.4296L50.0538 62.0101C47.9209 60.053 44.7215 60.2036 42.7409 62.1606L33.5997 71.0427C31.4668 73.1503 31.4668 76.4622 33.5997 78.4193C35.7326 80.5269 39.0844 80.5269 41.2174 78.4193L46.7021 73.1503L52.6438 78.5699C53.7103 79.4731 54.9292 79.9247 56.3003 79.9247C57.6715 79.9247 59.0427 79.4731 60.1092 78.4193L65.5938 73.1503L70.7739 77.9677C70.9262 78.1182 71.0786 78.2687 71.2309 78.4193C72.2974 79.4731 73.6686 79.9247 75.0397 79.9247C75.0397 79.9247 75.0397 79.9247 75.1921 79.9247C76.5632 79.9247 78.0868 79.3226 79.1533 78.2688C79.3056 78.1182 79.458 77.9676 79.6103 77.6665L84.3332 72.9997L90.275 78.4193C91.3415 79.3225 92.5604 79.7742 93.9315 79.7742C95.3027 79.7742 96.8262 79.172 97.8927 78.1182C99.8733 76.0106 99.7209 72.6987 97.588 70.7416L87.3803 61.8595Z" fill="url(#paint1_linear)" />
    </g>
    <defs>
      <linearGradient id="paint0_linear" x1="65.5224" y1={0} x2="65.5224" y2="30.1256" gradientUnits="userSpaceOnUse">
        <stop stopColor="#07295E" />
        <stop offset={1} stopColor="#02182D" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="65.6438" y1="60.4648" x2="65.6438" y2={80} gradientUnits="userSpaceOnUse">
        <stop stopColor="#07295E" />
        <stop offset={1} stopColor="#02182D" />
      </linearGradient>
    </defs>
  </svg>
}

export default IcQrFail