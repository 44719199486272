import React from 'react'
//image
import ic_facepay from '../images/ic_facepay.svg'
import ic_ads_manage from '../images/ic_ads_manage.svg'
import ic_sale_manage from '../images/ic_sale_manage.svg'
//component
import IcFacePay from '../images/component/IcFacePay'
import IcAdsManage from '../images/component/IcAdsManage'
import IcSaleManage from '../images/component/IcSaleManage'
export const data = [
    {
        title: 'Thanh toán bằng khuôn mặt',
        content: 'Với thuật toán nhận diện khuôn mặt độc quyền bởi Wee Digital.',
        img: <IcFacePay/>,
        bgColor: '#34C759',
        key: 'face pay'
    },
    {
        title: 'Quản lý thiết bị',
        content: 'Thông tin và tình trạng hoạt động của các máy FacePay POS (POS Face Payment Terminal).',
        img: <IcAdsManage />,
        bgColor: '#FF9500',
        key: 'ads manage'
    },
    {
        title: 'Quản lý doanh số',
        content: 'Các giao dịch được thực hiện bằng Facepay.',
        img: <IcSaleManage />,
        bgColor: '#FF3B30',
        key: 'sale manage'
    }
]
export default React