import React from 'react'
import ErrorConnect from '../../ErrorConnect';

const ErrorLoginFollowActionType = ({ closePopup, error, autoClosePopup }) => {
    const { Code } = error;
    if(Code !== 6) {
        if (process.env.NODE_ENV === "development")
            throw Error("Invalid type to use modal!");
        else {
            return <ErrorConnect closePopup={closePopup}>
                <div>Không thể kết nối được với hệ thống.</div>
                <div>Vui lòng kết nối mạng hoặc thử lại sau ít phút</div>s
            </ErrorConnect>
        }
    }
    return (
        <ErrorConnect closePopup={closePopup}>
            {/* <div>Tài khoản bị khóa vì ...!</div> */}
            <div>Tài khoản đã bị khóa</div>
        </ErrorConnect>
    )
}

export default ErrorLoginFollowActionType
