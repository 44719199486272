import React from 'react'

const IcListOn = (props) => {
    return <svg {...props} width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.1" width={36} height={36} rx={6} fill="#0075FF" />
    <path d="M8.93262 18H26.9326" stroke="#0075FF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.93262 12H26.9326" stroke="#0075FF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.93262 24H26.9326" stroke="#0075FF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>  
}

export default IcListOn