import * as types from "../../constant/typeAction";
import * as keys from '../../constant/key'

export const initialState = {
    history_list:{
        pageSize: 10,
        pageNumber: 1,
        itemDisplay: 10,
        type: keys.HISTORY_LIST
    },
    pos_list:{
        pageSize: 10,
        pageNumber: 1,
        itemDisplay: 10,
        type: keys.POS_LIST
    },
    pos_grid:{
        pageSize: 10,
        pageNumber: 1,
        itemDisplay: 10,
        type: keys.POS_GRID
    },
};

export default function pagingReducer(state = initialState, action) {
    switch (action.type) {
        case types.CHANGE_PAGE_SIZE:{
            switch (action.payload.type) {
                case keys.HISTORY_LIST:
                    return {
                        ...state,
                        history_list: {
                            ...state.history_list,
                            pageSize: action.payload.pageSize
                        }
                    }
                case keys.POS_LIST:
                    return {
                        ...state,
                        pos_list: {
                            ...state.pos_list,
                            pageSize: action.payload.pageSize
                        }
                    }
                case keys.POS_GRID:{
                    return {
                        ...state,
                        pos_grid: {
                            ...state.pos_grid,
                            pageSize: action.payload.pageSize
                        }
                    }}
                default:
                    break;
            }
        }
        case types.CHANGE_PAGE_DISPLAY_ITEM:{
            switch (action.payload.type) {
                case keys.HISTORY_LIST:
                    return {
                        ...state,
                        history_list: {
                            ...state.history_list,
                            itemDisplay: action.payload.itemDisplay
                        }
                    }
                case keys.POS_LIST:
                    return {
                        ...state,
                        pos_list: {
                            ...state.pos_list,
                            itemDisplay: action.payload.itemDisplay
                        }
                    }
                case keys.POS_GRID:{
                    return {
                        ...state,
                        pos_grid: {
                            ...state.pos_grid,
                            itemDisplay: action.payload.itemDisplay
                        }
                    }}
                default:
                    break;
            }
        }
        case types.CHANGE_PAGE_NUMBER:{
            switch (action.payload.type) {
                case keys.HISTORY_LIST:
                    return {
                        ...state,
                        history_list: {
                            ...state.history_list,
                            pageNumber: action.payload.pageNumber
                        }
                    }
                case keys.POS_LIST:
                    return {
                        ...state,
                        pos_list: {
                            ...state.pos_list,
                            pageNumber: action.payload.pageNumber
                        }
                    }
                case keys.POS_GRID:{
                    return {
                        ...state,
                        pos_grid: {
                            ...state.pos_grid,
                            pageNumber: action.payload.pageNumber
                        }
                    }}
                default:
                    break;
            }
        }
        default:
            return state;
    }
}
