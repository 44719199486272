import { useState, useEffect, useRef } from "react";
import { TYPEDATE } from "../../../../constant/key";
import moment from "moment";

const setColors = (dates, typeDate) => {
    const today = moment();
    const currentQuarter = moment(today)
        .startOf("quarter")
        .format("DD/MM/YYYY");
    const arrColors = [];
    for (let i = 0; i < 3; i++) {
        const colors = [];
        dates.forEach(date => {
            switch (typeDate) {
                case TYPEDATE.DAY:
                    colors.push(
                        moment(date, "YYYY-MM-DD").format("DD/MM/YYYY") ===
                            today.format("DD/MM/YYYY")
                            ? `rgba(18, 121, 218, ${
                                  i === 0 ? "1" : i === 1 ? ".6" : ".4"
                              })`
                            : `rgba(191, 221, 247,${
                                  i === 0 ? "1" : i === 1 ? ".6" : ".4"
                              })`
                    );
                    break;
                case TYPEDATE.MONTH:
                    colors.push(
                        moment(date, "YYYY-MM-DD").format("MM/YYYY") ===
                            today.format("MM/YYYY")
                            ? `rgba(18, 121, 218, ${
                                  i === 0 ? "1" : i === 1 ? ".6" : ".4"
                              })`
                            : `rgba(191, 221, 247,${
                                  i === 0 ? "1" : i === 1 ? ".6" : ".4"
                              })`
                    );
                    break;
                case TYPEDATE.QUARTER:
                    colors.push(
                        moment(date, "YYYY-MM-DD").format("DD/MM/YYYY") ===
                            currentQuarter
                            ? `rgba(18, 121, 218, ${
                                  i === 0 ? "1" : i === 1 ? ".6" : ".4"
                              }`
                            : `rgba(191, 221, 247,${
                                  i === 0 ? "1" : i === 1 ? ".6" : ".4"
                              })`
                    );
                    break;
                default:
                    break;
            }
            return date;
        });
        arrColors.push(colors);
    }
    return arrColors;
};

const transformTimeArray = dates => {
    const time = [];
    const currentFormat = "YYYY-MM-DD";
    dates.forEach(date => {
        time.push(moment(date, currentFormat).toDate());
        return date;
    });
    return time;
};

export const useChartData = (dt, { timeBegin, timeEnd }, initTypeDate) => {
    const [data, setData] = useState(dt);
    const [title, setTitles] = useState();
    const [datasets, setDatasets] = useState();
    const [typeDate, setTypeDate] = useState(initTypeDate);
    const [firstRange, setFirstRange] = useState([]);
    const [maxTotal, setMaxTotal] = useState(0);
    const [isEmpty, setIsEmpty] = useState(true);
    const [columnColors, setColumnColors] = useState();
    useEffect(() => {
        if (!data || (data && data.length === 0)) return;
        if (!typeDate || !timeBegin || !timeEnd) return;
        switch (typeDate) {
            case TYPEDATE.DAY:
                setTitles(transformTimeArray(data.Day.Date));
                setDatasets([
                    data.Day.TotalOne,
                    data.Day.TotalTwo,
                    data.Day.TotalThree
                ]);
                setColumnColors(setColors(data.Day.Date, typeDate));
                setMaxTotal(Math.max.apply(null, data.Day.Total));
                break;
            case TYPEDATE.MONTH:
                setTitles(transformTimeArray(data.Month.Date));
                setDatasets([
                    data.Month.TotalOne,
                    data.Month.TotalTwo,
                    data.Month.TotalThree
                ]);
                setColumnColors(setColors(data.Month.Date, typeDate));
                setMaxTotal(Math.max.apply(null, data.Month.Total));
                break;
            case TYPEDATE.QUARTER:
                setTitles(transformTimeArray(data.Quarter.Date));
                setDatasets([
                    data.Quarter.TotalOne,
                    data.Quarter.TotalTwo,
                    data.Quarter.TotalThree
                ]);
                setColumnColors(setColors(data.Quarter.Date, typeDate));
                setMaxTotal(Math.max.apply(null, data.Quarter.Total));
                break;
            default:
                break;
        }
    }, [data, typeDate, timeBegin, timeEnd]);

    useEffect(() => {
        if (!datasets || (datasets && datasets[0].length === 0)) {
            setIsEmpty(true);
            return;
        }
        setIsEmpty(
            !datasets.some(dataset => dataset.some(data => data > 0) === true)
        );
    }, [datasets]);

    useEffect(() => {
        if (!title || title.length <= 0) return;
        let firstDate, lastDate;
        firstDate = title[0];
        lastDate = title.length > 31 ? title[31] : title[title.length - 1];
        if (!data || data.length === 0) {
            const arr = [];
            const traces = 3;
            for (let i = 0; i < traces; i++) {
                const fakeTrace = [];
                title.map(label => {
                    fakeTrace.push(0);
                    return label;
                });
                arr.push(fakeTrace);
            }
            setDatasets(arr);
        }
        setFirstRange([firstDate, lastDate]);
    }, [title]);

    return [
        title,
        datasets,
        maxTotal,
        typeDate,
        setData,
        setTypeDate,
        firstRange,
        isEmpty,
        columnColors
    ];
};

export const useDimension = (x, y) => {
    const deviceRef = useRef();
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    useEffect(() => {
        setWidth(deviceRef.current.clientWidth + x);
        setHeight(deviceRef.current.clientHeight + y);
    }, [x, y]);
    return [width, height, deviceRef];
};
