import logoABBank from "./../images/bankImage/logo_ABBank.svg"
import logoSacomBank from "./../images/bankImage/logo_SacomBank.svg"
import logoVietinBank from "./../images/bankImage/logo_VietinBank.svg"
import logoMSBank from "./../images/bankImage/logo_MSBank.svg"
import logoTechComBank from "./../images/bankImage/logo_TechComBank.svg"
// import ic_citad_01313007_baca_big from "./../images/bankImage/ic_citad_01313007_baca_big.png"
// import ic_citad_01359001_baovietbank_big from "./../images/bankImage/ic_citad_01359001_baovietbank_big.png"
// import ic_citad_01501001_vid_big from "./../images/bankImage/ic_citad_01501001_vid_big.png"
// import ic_citad_01317001_seabank_big from "./../images/bankImage/ic_citad_01317001_seabank_big.png"
// import ic_citad_79334001_scb_big from "./../images/bankImage/ic_citad_79334001_scb_big.png"
// import ic_citad_79304001_donga_big from "./../images/bankImage/ic_citad_79304001_donga_big.png"
// import ic_citad_79308001_saigonbank_big from "./../images/bankImage/ic_citad_79308001_saigonbank_big.png"
// import ic_citad_01355002_vieta_big from "./../images/bankImage/ic_citad_01355002_vieta_big.png"
// import ic_citad_01202001_bidv_big from "./../images/bankImage/ic_citad_01202001_bidv_big.png"
// import ic_citad_79356001_vietbank_big from "./../images/bankImage/ic_citad_79356001_vietbank_big.png"
// import ic_citad_01203001_vcb_big from "./../images/bankImage/ic_citad_01203001_vcb_big.png"

export const dataHeader = [
    {
        name: "Mặc định",
        type: 0
    },
    {
        name: "< 0,5 triệu",
        type: 1
    },
    {
        name: "0,5 ~ 1 triệu",
        type: 2
    },
    {
        name: "> 1 triệu",
        type: 3
    }
];

export const arrTitleTable = ["Mã đơn hàng", "Thời gian", "Mã giao dịch", "Số tiền", "Khung giá trị", "Cửa hàng", "", "Tài khoản nhận"]
export const arrTitleTableStore = ["Mã đơn hàng", "Thời gian", "Mã giao dịch", "Số tiền", "Khung giá trị",]

export const dataBank = [
    {
        BankCode: "ABBANK",
        BankImage: logoABBank
    },
    {
        BankCode: "SACOMBANK",
        BankImage: logoSacomBank
    },
    {
        BankCode: "VIETINBANK",
        BankImage: logoVietinBank
    },
    {
        BankCode: "MSBANK",
        BankImage: logoMSBank
    },
    {
        BankCode: "TECHCOMBANK",
        BankImage: logoTechComBank,
    }
]

export const dataTestTableHistory = [
    {
        A: "FP001234",
        B: new Date(2020, 23, 2),
        C: "VH02020202",
        D: 100000,
        E: "9791445527372272"
    },
    {
        A: "FP001235",
        B: new Date(2020, 5, 3),
        C: "VH02020203",
        D: 50000,
        E: "9791445527372273"
    },
    {
        A: "FP001236",
        B: new Date(2020, 6, 3),
        C: "VH02020204",
        D: 635000,
        E: "9791445527372274"
    },
    {
        A: "FP001237",
        B: new Date(2020, 14, 3),
        C: "VH02020205",
        D: 1125000,
        E: "9791445527372275"
    },
    {
        A: "FP001238",
        B: new Date(2020, 19, 3),
        C: "VH02020206",
        D: 835000,
        E: "9791445527372276"
    },
    {
        A: "FP001239",
        B: new Date(2020, 22, 3),
        C: "VH02020206",
        D: 75000,
        E: "9791445527372278"
    },
    {
        A: "FP001240",
        B: new Date(2020, 23, 3),
        C: "VH02020207",
        D: 342500,
        E: "9791445527372279"
    },
    {
        A: "FP001241",
        B: new Date(2020, 26, 3),
        C: "VH02020208",
        D: 2800000,
        E: "9791445527372280"
    },
    {
        A: "FP001242",
        B: new Date(2020, 30, 3),
        C: "VH02020209",
        D: 694000,
        E: "9791445527372281"
    },
    {
        A: "FP001243",
        B: new Date(2020, 2, 4),
        C: "VH02020210",
        D: 933000,
        E: "9791445527372282"
    },
    {
        A: "FP001244",
        B: new Date(2020, 4, 4),
        C: "VH02020211",
        D: 5355000,
        E: "9791445527372283"
    },
    {
        A: "FP001245",
        B: new Date(2020, 10, 4),
        C: "VH02020212",
        D: 655000,
        E: "9791445527372284"
    },
    {
        A: "FP001246",
        B: new Date(2020, 21, 4),
        C: "VH02020213",
        D: 3450000,
        E: "9791445527372285"
    },
]
