import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components'
import { useSelector, useDispatch } from "react-redux";
import { Switch, useRouteMatch, Redirect, useHistory, Route } from 'react-router-dom';

import PrivateRoute from '../../helper/PrivateRouter';

//host
import { hostWsNewPOS } from "../../host/hostWebSocket";


//component
import Header from '../Header'
import POSPage from './POSPage' //Thiết bị
import StatisticPage from './Statistic' //Thống kê
import Spinner from '../Control/Spinner';
import BlockedPage from './BlockedPage';
// import { FACEPAY_USER_DATA } from '../../constant/key';

//constant
import * as types from '../../constant/typeAction'
import * as keys from '../../constant/key'
//action
import { logout, checkStatus } from '../../redux/actions/userAction'
import { getShop, getFilterShop } from '../../redux/actions/shopAction';
import { getFilterPOS, changeStatusPOS } from '../../redux/actions/posActions';
import { pushWarning, pushError } from '../../redux/actions/notifyAction';

const Bound = styled.div`
    display: grid;
    position: relative;
    grid-template-columns: 1fr auto 1fr;
    grid-template-rows: 128px 1fr;
    width: 100%;
    min-height: 100%;
    background-color: #FAFAFA;
    border-radius: 20px;
    flex-direction: column;
`

const MainContainer = styled.div`
    display: grid;
    grid-column: 1 / span 3;
    grid-template-columns: 1fr auto 1fr;
    width: calc(100% + 11px);
    overflow-y: scroll;
    padding-right: 6px;
    
    &::-webkit-scrollbar {
        width: 6px;
        background-color:transparent;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #E2E2E2;
        background-clip: padding-box;
        border-radius: 20px;
    }
    .main-bound{
        display: flex;
        width: 1110px;
        grid-column: 2 / span 1;
        flex-direction: column;
        flex:1;
    }

`
let webSocketNewPOS = null;
let isRestartSocket = false;
let timeoutRestartSocket = null;

const type = {
    POSC: "POSC",
    POSS: "POSS",
    POSCT: "POSCT",
    POSD: "POSD",
    POSL: "POSL"
};

// const defaultLoading = {
//     count: 0,
//     active: true
// }

const defaultLoading = {
    count: 0,
    isActive: false
  }

const MainPage = () => {
    const restartSocketRef = useRef()

    const [startWS, setStartWS] = useState(true);
    const [loading, setLoading] = useState(defaultLoading);

    const history = useHistory();
    const userDataLocal = JSON.parse(localStorage.getItem(keys.FACEPAY_USER_DATA));

    const { path } = useRouteMatch();

    const { shopReducer, notifyReducer, userReducer, pagingReducer, posReducer, systemReducer, statisticReducer } = useSelector(state => ({
        shopReducer: state.shopReducer,
        notifyReducer: state.notifyReducer,
        userReducer: state.userReducer,
        pagingReducer: state.pagingReducer,
        posReducer: state.posReducer,
        statisticReducer: state.statisticReducer,
        systemReducer: state.systemReducer
    }))

    const dispatch = useDispatch();

    const refNotifyReducer = useRef();

    refNotifyReducer.current = notifyReducer || null;

    useEffect(() => {
        dispatch(checkStatus(history))
    }, [history])

    useEffect(() => {
        if (!shopReducer.data) dispatch(getShop())

        //chain manager
        dispatch(getFilterShop())
        window.addEventListener('storage', onStorageChange);

        _startConnectWebSocket();

        return () => {
            window.removeEventListener('storage', onStorageChange)
            clearTimeout(timeoutRestartSocket)
            if (webSocketNewPOS) {
                webSocketNewPOS.close();
            }
        }
    }, [])


    const onStorageChange = () => {
        let storage = (JSON.parse(window.localStorage.getItem(keys.FACEPAY_USER_DATA)));
        if (!storage)
            dispatch(logout(history))
    }

    const _pushWarningDevice = useCallback(() => {
        if (
            Array.isArray(refNotifyReducer.current.listOfNotify) &&
            refNotifyReducer.current.listOfNotify.length === 0
        ) {
            dispatch(
                pushWarning({
                    type: types.POSCT_HAS_CHANGE_DEVICE
                })
            );
        }
    }, []);

    
    useEffect(() => {
        return () => {
            clearTimeout(restartSocketRef.current)
        }
    }, [restartSocketRef])

    // useEffect(() => {
    //     let timeoutSocket = null;
    //     if (!startWS && webSocketNewPOS && !!userReducer.loginResult) {
    //         webSocketNewPOS.close();
    //         setStartWS(true);
    //         timeoutSocket = setTimeout(async () => {
    //             await _startConnectWebSocket()
    //         }, 5000)
    //     }

    //     return () => {
    //         clearTimeout(timeoutSocket)
    //     }
    // }, [startWS, JSON.stringify(userReducer.loginResult)])

    /**
     * @description:  Connect WebSocket
     */
    const _startConnectWebSocket = () => {
        let websocket = new WebSocket(hostWsNewPOS);
        websocket.onopen = async event => {
            // console.log("ws open", event);
            // await setStartWS(true);
        };
        websocket.onmessage = event => {
            // console.log("ws message ", event);
            const data = JSON.parse(event.data);
            if (data.Event === type.POSC) {
                // Add new pos
                if (window.location.pathname === "/main/pos/add") {
                    if (!userDataLocal.LinkPos) {
                        let newUserDataLocal = { ...userDataLocal, LinkPos: true }
                        localStorage.setItem(keys.FACEPAY_USER_DATA, JSON.stringify(newUserDataLocal))
                        const newDataLogin = { ...userReducer.loginResult, LinkPos: true }
                        dispatch({
                            type: types.LOGIN_USER,
                            data: newDataLogin
                        })
                    }
                    history.push("/main/pos");
                } else {
                    let listShopID = [];
                    // if (userDataLocal.Role === keys.STORE_MANAGER) {
                    if ([keys.WUM, keys.STORE_MANAGER].includes(userDataLocal.Role)) {
                        listShopID = [shopReducer.data.Shops[0].ID]
                    } else if (userDataLocal.Role === keys.CHAIN_MANAGER) {
                        // listShopID = shopReducer.data.Shops.map(item => item.ID)
                        listShopID = posReducer.shopFilter.listShopID
                    }
                    dispatch(
                        getFilterPOS(
                            pagingReducer.pos_grid.pageSize,
                            1,
                            listShopID,
                            history
                        )
                    );
                }
            }
            if (data.Event === type.POSS) {
                // change status pos
                if (window.location.pathname === "/main/pos")
                    dispatch(changeStatusPOS(data));
            }
            if (data.Event === type.POSCT || data.Event === type.POSD) {
                // change device when another account
                if (!userDataLocal.LinkPos) {
                    let newUserDataLocal = { ...userDataLocal, LinkPos: true }
                    localStorage.setItem(keys.FACEPAY_USER_DATA, JSON.stringify(newUserDataLocal))
                    const newDataLogin = { ...userReducer.loginResult, LinkPos: true }
                    dispatch({
                        type: types.LOGIN_USER,
                        data: newDataLogin
                    })
                }
                if (window.location.pathname === "/main/pos")
                    _pushWarningDevice();
            }
            if (data.Event === type.POSL) {
                dispatch(checkStatus(history))
                // console.log(data)
                // if(data.LockStatus)
                    // dispatch(pushError({
                    //     type: 'ACCOUNT_WAS_BLOCKED',
                    //     error: {
                    //         data: {
                                
                    //         }
                    //     }
                    // }))
                return;
            }
        };
        websocket.onclose = async event => {
            console.log("ws close", event);
            // await setStartWS(false);
            restartSocket()
        };
        websocket.onerror = async (event) => {
            console.log("ws onerror ", event);
            // await setStartWS(false);
            // restartSocket()
        };

        webSocketNewPOS = websocket;
    };

    const restartSocket = useCallback(
        () => {
            if(!userReducer.loginResult) return;
            if(!Object.keys(userReducer.loginResult).length) return;

            restartSocketRef.current = setTimeout(async () => {
                _startConnectWebSocket()
            }, 5000)
        },
        [restartSocketRef, JSON.stringify(userReducer.loginResult), _startConnectWebSocket],
    )

    /**
     * @description: loading in statistic page
     */
    // useEffect(() => {
    //     // if (loading.count > 0) return;
    //     if (systemReducer.isLoading.active === 0)
    //         setTimeout(() => {
    //             setLoading({
    //                 ...loading,
    //                 active: false
    //             })
    //         }, 800);
    //     else
    //         setLoading(defaultLoading)
    // }, [systemReducer])


    useEffect(() => {
        if (systemReducer.isLoading.active === 0)
          setTimeout(() => {
            setLoading(defaultLoading);
          }, 600);
        else if (systemReducer.isLoading.active > 0){
            setLoading({
                ...loading,
                isActive: true
            });
        }
      }, [systemReducer])


    return (
        <Bound>
            {
                loading.isActive &&
                <Spinner borderRad="20px" />
            }
            <React.Fragment>
                <Header />
                <MainContainer>
                    <div className='main-bound'>
                        <Switch>
                            <PrivateRoute path={`${path}/pos`}>
                                <POSPage />
                            </PrivateRoute>
                            <PrivateRoute path={`${path}/statistic`}>
                                <StatisticPage />
                            </PrivateRoute>
                            <Route path={`${path}/locked-account`}>
                                <BlockedPage />
                            </Route>
                            <Redirect to={`${path}/statistic`} />
                        </Switch>
                    </div>
                </MainContainer>
            </React.Fragment>
        </Bound>
    );
}

export default MainPage;
