import React from 'react'

// component
import AlertPopup from '../../../Popup/AlertPopup'

const alertPopupExtendStyles = `
    margin: 33px auto 0 auto;
    left: 50%;
    transform: translate(-50%, 0);
`

const SuccessPaymentRequest = ({ closePopup }) => {
    return (
        <AlertPopup
            type="SUCCESS"
            handleClickBtn={closePopup}
            extendStyles={alertPopupExtendStyles}
        >
            Bắn tiền thành công!
        </AlertPopup>
    )
}

export default SuccessPaymentRequest
