import styled from "styled-components";

const Bound = styled.div`
    width: 100%;
    height: 54px;
    display: grid;
    /* grid-template-columns: 210px 180px 180px 160px 140px 100px 1fr; */
    grid-template-columns: ${props => props.gridTemplateColumnsContent ? props.gridTemplateColumnsContent : "160px 140px 130px 130px 130px 180px 100px 1fr"};
    background-color: #FFFFFF;
    mix-blend-mode: normal;
    box-shadow: inset 0px -0.5px 0px #E2E2E2;
    transition: .1s all ease-in-out;
    align-items: center;

    &:nth-child(odd) {
        background-color: rgba(255, 255, 255, 0.6);
        align-items: center;
    }

    &:hover{
        background-color: rgba(149, 203, 255, 0.3);
        box-shadow: inset 0px -0.5px 0px #E2E2E2;
    }

    .item{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        display: flex;
        /* text-align: center; */
        letter-spacing: 0.05px;
        color: #484848;
        padding: 0 10px;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;

        &:nth-child(1){
            padding:  0 10px 0 25px;
            text-align: start;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }
        &:nth-child(2){
            /* padding: 0 10px 0 15px; */
            justify-content: flex-start;
        }
        &:nth-child(3){
            text-align: start;
            /* padding: 0 10px 0 20px; */
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
        }
        &:nth-child(4){
            justify-content: flex-start;
            /* padding: 0; */
        }
        &:nth-child(5){
            justify-content: flex-start;
            /* padding: 0; */
        }       
    }
    .name-store{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 10px;
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
    .icon-bank {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img{
            width: 85px;
            object-fit: contain;
            /* margin: 0 10px 0 0; */
        }
    }

    .block-bankcode{
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        display: flex;
        text-align: center;
        letter-spacing: 0.05px;
        color: #484848;
        padding: 0 10px;
        justify-content: flex-end;
        padding: 0 18px 0 0;
        height: 100%;

        &__stk-tooltip{
            cursor:default;
            width:fit-content;
        }
    }
`

export { Bound };