import React, { useEffect } from 'react';
import styled from 'styled-components'
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import PrivateRoute from './helper/PrivateRouter';

//image
import web_background from './images/web_background.svg'
//component
import MainPage from './ui/MainPage'
import LoginPage from './ui/LoginPage'
import Footer from './ui/Footer'
// import Lobby from './ui/Lobby';
import ServicesPage from './ui/ServicesPage';
import NotifyModal from './ui/NotifyModal';

//action
import { changePageSize, changePageDisplayItem } from './redux/actions/pagingAction'
// constant
import * as keys from './constant/key';
import { useDispatch } from 'react-redux';

const Theme = styled.div`
  display: flex;
  flex-direction:column;
  align-items:center;
  width: 100%;
  /* height: 100%; */
  min-height: 100%;
  background-image: url(${web_background});
  background-position: top left;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #051321;
  .version{
    font-family: "UTM-Avo";
    font-size: 12px;
    color: #fff;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`

const MainBound = styled.div`
  display: flex;
  width: 100%;
  min-width: 1070px;
  max-width: 1440px;
  /* height: 100%; */
  flex: 1;
  /* min-height: calc(640px - 20px - 70px); */
  padding: 20px 20px 0 20px;
  
  .head-container{
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    padding: 33px 0 30px;
    height: calc(40px + 30px + 33px);
    .head-title{
      font-family: 'SVN-Gilroy';
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 115%;
      color: #051321;
      user-select: none;
    }
    .button-control{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: max-content;
      .icon-grid, .icon-list{
        cursor: pointer;
      }
      .icon-grid{
        margin: 0 20px;
      }
      a{
        text-decoration: none;
      }
    }
  }
`

const paddingTop = 20;
const heightHeader = 128;
const heightPOSTitle = 33 + 40 + 30;
const heightHistoryTitle = 86 + 10;
const heightFooter = 70;
const heightTableTitle = 48;
const heightTableItem = 54;
const heightPagingControl = 36 + (20 * 2);

var rtime;
var timeout = false;
var delta = 500;

const App = () => {
  // const [isLogin, setIsLogin] = useState(false) 
  const dispatch = useDispatch()
  const history = useHistory();

  useEffect(() => {
    loadPagingConfig()
    window.addEventListener('resize', onResize)
    window.addEventListener('storage', onStorageChange);
    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('storage', onStorageChange)
      // if (resizeTimeout) {
      //     clearTimeout(resizeTimeout);
      // }
    }
  }, [])

  const onStorageChange = () => {
    let storage = (JSON.parse(localStorage.getItem(keys.FACEPAY_USER_DATA)));
    console.log(storage)
    if (storage)
      window.location.reload()
  }

  const onResize = () => {
    rtime = new Date();
    if (timeout === false) {
      timeout = true;
      setTimeout(resizeend, delta);
    }
  }

  const resizeend = () => {
    if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
    } else {
      timeout = false;
      loadPagingConfig()
    }
  }

  const loadPagingConfig = () => {
    let vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    if (vh < 640) vh = 640
    //POS
    let availablePOSHeight = (vh - paddingTop - heightHeader - heightPOSTitle - heightFooter - heightTableTitle - heightPagingControl)
    // console.log(`${availablePOSHeight} = (${vh} - ${paddingTop} - ${heightHeader} - ${heightPOSTitle} - ${heightFooter} - ${heightTableTitle} - ${heightPagingControl})`)
    let availablePOSItem = Math.floor(availablePOSHeight / heightTableItem)
    // console.log(availablePOSItem)
    let pageSizePOS = 10;
    if (availablePOSItem <= 10) {
      // availablePOSItem = 10
      pageSizePOS = 10
    }
    else if (availablePOSItem > 10 && availablePOSItem <= 30) {
      pageSizePOS = 30
    }
    else {
      pageSizePOS = 60
    }
    dispatch(changePageSize(pageSizePOS, keys.POS_LIST))
    dispatch(changePageDisplayItem(availablePOSItem, keys.POS_LIST))
    dispatch(changePageSize(pageSizePOS, keys.POS_GRID))
    dispatch(changePageDisplayItem(availablePOSItem, keys.POS_GRID))
    //history
    let availableHISTORYHeight = (vh - paddingTop - heightHeader - heightHistoryTitle - heightFooter - 35 - heightTableTitle - heightPagingControl)
    // console.log(`${availableHISTORYHeight} = (${vh} - ${paddingTop} - ${heightHeader} - ${heightHistoryTitle} - ${heightFooter} - ${heightTableTitle} - ${heightPagingControl} - 35)`)
    let availableHISTORYItem = Math.floor(availableHISTORYHeight / heightTableItem)
    // console.log(availableHISTORYItem)
    let pageSizeHISTORY = 10;
    if (availableHISTORYItem <= 10) {
      // availableHISTORYItem = 10
      pageSizeHISTORY = 10
    }
    else if (availableHISTORYItem > 10 && availableHISTORYItem <= 30) {
      pageSizeHISTORY = 30
    }
    else {
      pageSizeHISTORY = 60
    }
    dispatch(changePageSize(pageSizeHISTORY, keys.HISTORY_LIST))
    dispatch(changePageDisplayItem(availableHISTORYItem, keys.HISTORY_LIST))
  }

  // console.log(process.env.REACT_APP_WEB_VERSION, process.env.REACT_APP_WEB_BUILD)
  return (
    <Theme>
      <MainBound>
        {/* Router */}
        {/* {
          isLogin?
          <MainPage/>
          :
          <LoginPage/>
        } */}
        <Switch>
          <Route exact path="/">
            {/* <Lobby /> */}
            {/* <RegisterPage /> */}
            <Redirect to="/main" />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/services">
            <ServicesPage />
          </Route>
          <PrivateRoute path="/main">
            <MainPage />
          </PrivateRoute>
          <Redirect to="/" />
        </Switch>
        <NotifyModal />
      </MainBound>
      <Footer />
      {
        process.env.REACT_APP_WEB_BUILD !== 'production' &&
        <p className='version'>Version {process.env.REACT_APP_WEB_VERSION}</p>
      }

    </Theme>
  );
}

export default App;
