import styled from "styled-components";

const Bound = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 40px 0 0 100px;
    p {
        font-family: UTM-Avo;
        font-size: 15px;
        line-height: 22px;
        color: #c6d5eb;
    }
    .block-image {
        display: flex;
        justify-content: flex-start;
    }
    .intro-container{
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-evenly;
        .introduction{
            /* margin: 100px 0 0 0; */
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 52px;
            line-height: 115%;
            display: flex;
            align-items: center;
            color: #FFFFFF;
        }
        
        .block-intro{
            display: flex;
            /* flex: 1; */
            flex-direction: column;
            /* margin: 55px 0 0 0; */
            .features{
                margin-bottom: 50px;
                font-family: SVN-Gilroy;
                font-style: normal;
                font-weight: bold;
                font-size: 22px;
                line-height: 115%;
                display: flex;
                align-items: center;
                color: #FFFFFF;
            }
            .intro-items {
                display: flex;
                flex-direction: row;
                margin-bottom: 30px;
                :last-child {
                    margin-bottom: 0;
                }
                &-icon-bound {
                    display: flex;
                    width: 65px;
                    height: 65px;
                    border-radius: 20px;
                    justify-content: center;
                    align-items: center;
                }
                &-content {
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    padding-left: 30px;
                    &-facepay-icon-bound,
                    &-title,
                    &-content {
                        display: flex;
                        flex: 1;
                        align-items: center;
                        letter-spacing: 0.05px;
                    }
                    &-title {
                        font-size: 16px;
                        line-height: 27px;
                        font-weight: bold;
                    }
                    &-content {
                        font-size: 13px;
                        line-height: 22px;
                        padding-right: 100px;
                    }
                }
            }
        }
    }
    

`;

export { Bound }