import React, { useState, useEffect } from 'react'
//styles
import { Bound } from './index.styles'
//data
import { HelpsData } from '../../../data/helpData'
//component
import IcPlus from '../../../images/component/IcPlus'
import IcLine from '../../../images/component/IcLine'

const Help = () => {

    const [isSelected, setIsSelected] = useState([])

    const [data, setData] = useState(HelpsData)

    useEffect(() => {
        buildLink()
    })

    const buildLink = () => {
        var parserRules = [
            { pattern: 'http://pos.facepay.vn', replacement: '<a target="_blank" href=\'http://pos.facepay.vn\'>http://pos.facepay.vn</a>' },
            { pattern: 'http://wee.vn', replacement: '<a target="_blank" href=\'http://wee.vn\'>Tìm hiểu thêm</a>' }
        ];

        document.querySelectorAll('.anser-item').forEach(function (tag) {
            var inner = tag.innerHTML;
            parserRules.forEach(function (rule) {
                inner = inner.replace(rule.pattern, rule.replacement)
            });
            tag.innerHTML = inner;
        });
    }

    const _handleToggle = (i) => {
        if (isSelected.includes(i)) {
            let positon = isSelected.filter(item => item !== i)
            setIsSelected(positon)
        }
        else {
            setIsSelected([...isSelected, i])
        }
    }

    const showQuestion = () => {

        return data.map((item, i) => {
            return (
                <div key={i} className='help-wrapper'>
                    <div className='question' onClick={() => _handleToggle(i)}>
                        {
                            isSelected.includes(i) ?
                                <IcLine />
                                :
                                <IcPlus />
                        }
                        <h4 >{item.question}</h4>
                    </div>
                    {
                        isSelected.includes(i) &&
                        item.answer.map((itemanswer, j) => {
                            return (
                                <p key={j} className='anser-item'>{itemanswer}</p>
                            )
                        })
                    }
                </div>
            )
        })
    }

    return (
        <Bound>
            <div className='title-wrapper'>
                <h1>Trợ giúp</h1>
            </div>
            <div className='content-wrapper'>
                {showQuestion()}
            </div>
        </Bound>
    )
}

export default Help
