import styled from "styled-components";

const Bound = styled.div`
    display: flex;
    flex:1;
    flex-direction:column;
    background-color: #FFFFFF;
    /* border: 1px solid #C6D5EB; */
    border: 1px solid #F7F7F7;
    margin: 0 0 0 10px;
    box-sizing: border-box;
    border-radius: 20px;   
    padding: 20px 18px 0 20px;
    font-family: "UTM-Avo";
    font-style: normal;
    border: 1px solid #CDCDCD;
    .shop-info {
        display: flex;
        align-items: center;
        height: 70px;
        width: 100%;
        img{
            width: 64px;
            height: 64px;
            object-fit:contain;
            border-radius: 5px;
        }
        p{
            margin: 0 0 0 20px;
            font-weight: bold;
            font-size: 16px;
            line-height: 170%;
            display: flex;
            align-items: center;
            letter-spacing: 0.05px;
            color: #051321;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    .shop-address, .shop-phone {
        display: flex;
        align-items: center;
        margin: 10px 0 0 0;
        img{
            width: 14px;
            height: 14px;
            object-fit:contain;
        }
        p{
            margin: 0 0 0 10px;
            font-weight: normal;
            font-size: 12px;
            line-height: 170%;
            display: flex;
            align-items: center;
            letter-spacing: 0.05px;
            color: #051321;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

        }
    }

    .line {
        margin: 15px 0 0 0;
        width: 275px;
        border: .5px solid #F7F7F7;
    }

    .shop-payment, .shop-revenue {
        margin: 35px 0 0 0;
        &__title{
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
            display: flex;
            align-items: center;
            color: #BABABA;
        }
        &__value{
            display: flex;
            height: 43px;
            b{
                margin: 0 10px 0 0;
                font-weight: bold;
                font-size: 30px;
                line-height: 53px;
                display: flex;
                align-items: center;
                color: #051321;
            }
            p{
                font-weight: normal;
                font-size: 12px;
                color: #051321;
                padding: 20px 0 0 0;
            }
        }
    }
    .shop-revenue{
        margin: 10px 0 0 0;
        cursor: default;
        &__value{
            width: fit-content;
            b{
                white-space: nowrap;
                text-overflow: ellipsis;
                max-width: 275px;
                display: block;
                overflow: hidden;
            }
            p{
                font-weight: normal;
                font-size: 12px;
                color: #051321;
                padding: 24px 0 0 0;
            }
        }
        .money_toolTip {
            width: ${props => props.widthMoney || "auto"};
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            position: relative;
            font-size: ${props => props.sizeMoney || "35px"};
            font-weight: bold;
            margin-right: 5px;
            font-size: 30px;
            &:hover{
                .tooltiptext{
                    visibility: visible;
                }
            }
            .tooltiptext {
                visibility: hidden;
                width: 120px;
                background-color: black;
                color: #fff;
                text-align: center;
                border-radius: 6px;
                padding: 5px 0;
                position: absolute;
                z-index: 1;
                bottom: 100%;
                left: 50%;
                margin-left: -60px;
            }
        }
    }
    
`

export { Bound }