import React from 'react'
import PropTypes from 'prop-types'

// styles
import { Bound } from "./index.styles"

// image
// import icSuccess from "../../../images/ic_success.svg"
// import icInfo from "../../../images/ic_info.svg"
// import icWarning from "../../../images/ic_warning.svg"
// import icError from "../../../images/ic_error.svg";
// import icClose from "../../../images/ic_close.svg"
import IcSuccess from "../../../images/component/IcSuccess"
import IcInfo from "../../../images/component/IcInfo"
import IcWarning from "../../../images/component/IcWarning"
import IcError from "../../../images/component/IcError"
import IcClose from "../../../images/component/IcClose"

const types = {
    SUCCESS: "SUCCESS",
    INFO: "INFO",
    WARNING: "WARNING",
    ERROR: "ERROR"
}

const icFollowTypes = {
    [types.SUCCESS]: <IcSuccess className="ic-detail" />,
    [types.INFO]: <IcInfo className="ic-detail" />,
    [types.WARNING]: <IcWarning className="ic-detail" />,
    [types.ERROR]: <IcError className="ic-detail" />
}

const stylesFollowTypes = {
    [types.SUCCESS]: `
        background-color: #F6FFED;
        border: 1px solid #B7EB8F;
    `,
    [types.INFO]: `
        background-color: #E6F7FF;
        border: 1px solid #91D5FF;
    `,
    [types.WARNING]: `
        background-color: #FFFBE6;
        border: 1px solid #FFE58F;
    `,
    [types.ERROR]: `
        background-color: #FFF1F0;
        border: 1px solid #FFA39E;
    `
}



const AlertPopup = ({ 
    type, 
    children, 
    btn, 
    handleClickBtn,
    extendStyles
}) => {
    return (
        <Bound 
            specificStyles={stylesFollowTypes[type]}
            extendStyles={extendStyles}
        >
            {icFollowTypes[type]}

            <div className="content">{children}</div>
            {
                btn?
                    btn
                    :
                    <IcClose onClick={handleClickBtn || (() => {})} />
                    
            }
        </Bound>
    )
}



AlertPopup.propTypes = {
    type: PropTypes.oneOf(Object.values(types)).isRequired,
    children: PropTypes.any.isRequired,
    btn: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.element
    ]),
    handleClickBtn: PropTypes.func,
    extendStyles: PropTypes.string
}

export default AlertPopup
