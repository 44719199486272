import styled, { keyframes } from "styled-components";

const animateShowQR = keyframes`
    from {
      opacity: 0.5;
      transform: scale(0.5);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
`

const Bound = styled.div`
    font-family: 'UTM-AVO';
    /* margin: 25px 0 0 0; */
    display: flex;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0;  
        display: none;          
    }       
    &::-webkit-scrollbar-thumb {
        background: #E2E2E2;
        border-radius: 0;
    }
    .device{
        margin: 0 0 0 80px;
        width: max-content;
        height: 584px;
        object-fit: contain;
    }
    .wrap-qrcode{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .qr-container{
            width: 338px;
            height: 360px;
            border-radius: 3px;
            background-color: #F4F7FB;
            display: flex;
            flex-direction: column;
            &__block-qr{
                margin: 25px 25px 0 25px;
                cursor: pointer;
            }
            &__zoom-qr{
                width: 100%;
                margin: 15px 0 0 0;
                display: flex;
                align-items: center;
                justify-content: center;
                p{
                    width:fit-content;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: UTM-Avo;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 170%;
                    letter-spacing: 0.05px;
                    color: linear-gradient(180deg, #07295E 0%, #02182D 100%);
                    opacity: 0.5;
                    cursor: pointer;
                }
            }

            &__expired {
                background-color:rgba(5, 19, 33, 0.1);
                width: 288px;
                height: 288px;
                margin: 25px 25px 0 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                .title{
                    margin: 43px 0 0 0;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 170%;
                    display: flex;
                    align-items: center;
                    text-align: right;
                    letter-spacing: 0.05px;
                    color: rgba(0, 0, 0, .8);
                }
                .reload{
                    margin: 20px 0 0 0;
                    display: flex;
                    align-items: center;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 152.34%;
                    letter-spacing: 0.001em;
                    text-transform: uppercase;
                    color: rgba(18, 121, 218, 1);
                    cursor: pointer;
                }
            }
        }
        .title {
            margin: 35px 0 0 0;
            font-family: SVN-Gilroy;
            font-style: normal;
            font-weight: bold;
            font-size: 32px;
            line-height: 115%;
            display: flex;
            align-items: center;
            text-align: center;
            color: #051321;
        }
        .countdown-timer{
            font-size: 15px;
            line-height: 150%;
            display: flex;
            justify-content: center;
            align-items: center;
            p{
                font-weight: normal;
            }
            span{
                font-weight: bold;
            }

            &.first {
                margin: 40px 0 0 0;
                color: #000000;
            }
        }
    }
`;

const StyleShowZoomQRCode = styled.div`
    font-family: 'UTM-Avo';
    font-style: normal;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(5, 19, 33, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .ic_close{
        position: absolute;
        top: 81px;
        /* right: 176px; */
        right: 12%;
        width: 42px;
        height: 42px;
        background-color: #5f6870;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img{
            width: 16px;
            object-fit: contain;
        }
    }

    .qr-container__expired {
        background-color: #ffffff;
        width: 635px;
        height: 635px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 15px;
        padding: 20px;
        .background{
            background-color: #E6E7E9;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .fail{
                width: 250px;
            }
            .title{
                margin: 90px 0 0 0;
                font-weight: normal;
                font-size: 14px;
                line-height: 170%;
                display: flex;
                align-items: center;
                text-align: right;
                letter-spacing: 0.05px;
                color: rgba(0, 0, 0, .8);
            }
            .reload{
                margin: 25px 0 0 0;
                display: flex;
                align-items: center;
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 152.34%;
                letter-spacing: 0.001em;
                text-transform: uppercase;
                color: rgba(18, 121, 218, 1);
                cursor: pointer;
            }
        }
    }

    .qr-container {
        width: 635px;
        height: 635px;
        padding: 20px;
        background-color: #ffffff;
        border-radius: 10px;
        transition: .3s all ease-in-out;
        animation-name: ${animateShowQR};
        animation-duration: .3s;
    }
    .countdown-timer{
        font-size: 15px;
        line-height: 150%;
        color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 25px 0 0 0;
        p{
            font-weight: normal;
        }
        span{
            font-weight: bold;
        }
    }
`

export { Bound, StyleShowZoomQRCode }