import * as types from '../../constant/typeAction';

/**
 * 
 * @param {{
 * type: string, 
 * error: Object
 * }} dataError 
 */
export const pushError = (dataError) => {
    return ({
        type: types.PUSH_ERROR,
        payload: {
            error: {
                type: 'ERROR',
                dataError
            }
        }
    })
}

/**
 * 
 * @param {{type: string, data: any}} dataSuccess 
 */
export const pushSuccess = (dataSuccess) => {
    return ({
        type: types.PUSH_SUCCESS,
        payload: {
            success: {
                type: 'SUCCESS',
                dataSuccess
            }
        }
    })
}

/**
 * 
 * @param {{type: string, data: any}} dataInfo
 */
export const pushInfo = (dataInfo) => {
    return ({
        type: types.PUSH_INFO,
        payload: {
            info: {
                type: 'INFO',
                dataInfo
            }
        }
    })
}

/**
 * 
 * @param {{type: string, data: any}} dataWarning
 */
export const pushWarning = (dataWarning) => {
    return ({
        type: types.PUSH_WARNING,
        payload: {
            warning: {
                type: 'WARNING',
                dataWarning
            }
        }
    })
}


/**
 * 
 */
export const popNotify = () => {
    return ({
        type: types.POP_NOTIFY
    })
}