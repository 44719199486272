import styled from 'styled-components'

const Bound = styled.div`
    display: flex;
    /* flex: 1; */
    flex-direction: column;
    overflow: overlay;
    height: calc(100vh - 128px - 70px - 20px);
    z-index: 10;
    .title-wrapper{
        margin: 48px 0 0 0;
        h1{
            font-family: 'SVN-Gilroy';
            font-size: 32px;
            font-weight: bold;
            line-height: 115%;
            color:#051321;
        }
    }
    .content-wrapper{
        overflow: overlay;
        margin: 40px 0;
        &::-webkit-scrollbar {
            width: 15px;
            height: 0;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #E2E2E2;
            background-clip: padding-box;
            border-radius: 20px;
            border: 5px solid white;
        }
        .help-wrapper{
            transition: all .5s ease;
            margin-top: 40px;
            :first-child{
                margin-top: 0;
            }
            .question{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0 0 0 95px;
                cursor: pointer;
                img{
                    height: 14px;
                    width: 14px;
                }
                h4{
                    font-family: 'UTM-Avo';
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 170%;
                    letter-spacing: 0.05px;
                    color: #050C1D;
                    margin: 0 0 0 15px
                }            
            }
            p{
                font-family: 'UTM-Avo';
                font-size: 15px;
                line-height: 150%;
                color: #050C1D;
                text-align: justify;
                transition: all 100ms ease;
                padding: 7px 80px 0 124px;
            }
            a{
                font-family: 'UTM-Avo';
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 150%;
                color: #1279DA;
                text-decoration: none;
            }
        }
    }
    
`
export { Bound }