import React from "react";

// component
import AlertPopup from "../../../Popup/AlertPopup";
// import AlertPopup from "../../../AlertPopup";

const alertPopupExtendStyles = `
    margin: 33px auto 0 auto;
    left: 50%;
    transform: translate(-50%, 0);
    width: unset;
    max-width: unset;
`;

const WarningStringDefault = ({ closePopup, children }) => {
    return (
        <AlertPopup
            type="WARNING"
            handleClickBtn={closePopup}
            extendStyles={alertPopupExtendStyles}
        >
            {children}
        </AlertPopup>
    );
};

export default WarningStringDefault;
