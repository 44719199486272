import Axios from "axios"
import moment from 'moment'

import * as types from '../../constant/typeAction';
import {
    host
} from '../../host/host'
// action
import {
    pushError
} from "./notifyAction";
// tools
import getError from "../../helper/getErrorServices";

const SuccessApi = 0;

/**
 * 
 * @param {*String} fromDate 
 * @param {*String} toDate 
 * @param {*Array} listShopID 
 */
export const getTotal = (fromDate, toDate, listShopID) => async dispatch => {
    const hostTotal = host + 'trans/total';
    const queryDataFormat = JSON.stringify({
        FromDate: fromDate,
        ToDate: toDate,
        Shops: listShopID
    })
    dispatch({
        type: types.GET_STATISTIC_TRANSACTION_TOTAL_START,
    })
    dispatch({
        type: types.ACTIVE_LOADING_PAGE,
    })
    try {
        const res = await Axios.post(hostTotal, queryDataFormat)
        const {
            data
        } = res;
        const Success = data.Code === SuccessApi && true;
        if (Success && data) {
            const newData = {
                ...data
            }
            newData.type = types.GET_STATISTIC_TRANSACTION_TOTAL_SUCCESS
            dispatch({
                type: types.GET_STATISTIC_TRANSACTION_TOTAL_SUCCESS,
                data: newData
            })
        } else {
            // data.type = types.GET_STATISTIC_TRANSACTION_TOTAL_ERROR
            // dispatch({
            //     type: types.GET_STATISTIC_TRANSACTION_TOTAL_ERROR,
            //     err: ''
            // })
            dispatch(pushError({
                type: types.GET_STATISTIC_TRANSACTION_TOTAL_ERROR,
                error: data
            }))

        }

        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })

    } catch (err) {
        // dispatch({
        //     type: types.GET_STATISTIC_TRANSACTION_TOTAL_ERROR,
        //     err
        // })
        dispatch(pushError({
            type: types.GET_STATISTIC_TRANSACTION_TOTAL_ERROR,
            error: getError(err)
        }))
        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })
    }
}
/**
 * 
 * @param {*string} fromDate 
 * @param {*string} toDate 
 * @param {*array} shopID 
 */
export const getSummary = (fromDate, toDate, listShopID) => async dispatch => {
    const hostSummary = host + 'trans/getSumary';
    const queryDataFormat = JSON.stringify({
        FromDate: moment(fromDate).unix(),
        ToDate: moment(toDate).unix(),
        Shops: listShopID
    })
    dispatch({
        type: types.GET_STATISTIC_TRANSACTION_SUMMARY_START,
    })
    dispatch({
        type: types.ACTIVE_LOADING_PAGE,
    })
    try {
        const res = await Axios.post(hostSummary, queryDataFormat)
        const {
            data
        } = res;
        const Success = data.Code === SuccessApi && true;

        if (Success && data) {
            const newData = {
                ...data
            }
            newData.type = types.GET_STATISTIC_TRANSACTION_SUMMARY_SUCCESS
            dispatch({
                type: types.GET_STATISTIC_TRANSACTION_SUMMARY_SUCCESS,
                data: newData
            })

        } else {
            // data.type = types.GET_STATISTIC_TRANSACTION_SUMMARY_ERROR
            // dispatch({
            //     type: types.GET_STATISTIC_TRANSACTION_SUMMARY_ERROR,
            //     err: ''
            // })
            dispatch(pushError({
                type: types.GET_STATISTIC_TRANSACTION_SUMMARY_ERROR,
                error: data
            }))
        }

        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })

    } catch (err) {
        // dispatch({
        //     type: types.GET_STATISTIC_TRANSACTION_SUMMARY_ERROR,
        //     err
        // })
        dispatch(pushError({
            type: types.GET_STATISTIC_TRANSACTION_SUMMARY_ERROR,
            error: getError(err)
        }))
        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })
    }
}

/**
 * 
 * @param {*string} fromDate 
 * @param {*string} toDate 
 * @param {*number} pageSize 
 * @param {*number} pageNumber 
 * @param {*string} billNumber 
 * @param {*string} facepayNumber 
 * @param {*number} compareAmount
 * compareAmount: {
        // 0 - default
        // 1 - <500
        // 2 - >500 <1000
        // 3 - >1000
 * }
 * @param {*array} shopID 
 */
export const getFilterStatistic = (fromDate, toDate, pageSize, pageNumber, billNumber, facepayNumber, listShopID) => async dispatch => {
    const hostFilter = host + 'trans/filter'
    const queryDataFormat = JSON.stringify({
        FromDate: fromDate,
        ToDate: toDate,
        PageSize: pageSize,
        PageNumber: pageNumber,
        BillNumber: billNumber,
        FacepayNumber: facepayNumber,
        Shops: listShopID,
        // CompareAmount: compareAmount
        // 0 - default
        // 1 - <500
        // 2 - >500 <1000
        // 3 - >1000
    })

    dispatch({
        type: types.GET_STATISTIC_TRANSACTION_FILTER_START,
    })
    dispatch({
        type: types.ACTIVE_LOADING_PAGE,
    })

    try {
        const res = await Axios.post(hostFilter, queryDataFormat)
        const {
            data
        } = res;
        const Success = data.Code === SuccessApi && true;
        if (Success && data) {
            const newData = {
                ...data
            };

            // if (newData.Data.length === 0) {
            //     dispatch(disPatchTestDataFilter)
            //     return;
            // }

            newData.type = types.GET_STATISTIC_TRANSACTION_FILTER_SUCCESS
            dispatch({
                type: types.GET_STATISTIC_TRANSACTION_FILTER_SUCCESS,
                data: newData
            })

        } else {
            // data.type = types.GET_STATISTIC_TRANSACTION_FILTER_ERROR
            // dispatch({
            //     type: types.GET_STATISTIC_TRANSACTION_FILTER_ERROR,
            //     err: ''
            // })
            dispatch(pushError({
                type: types.GET_STATISTIC_TRANSACTION_FILTER_ERROR,
                error: data
            }))
        }

        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })

    } catch (err) {
        // dispatch({
        //     type: types.GET_STATISTIC_TRANSACTION_FILTER_ERROR,
        //     err
        // })
        dispatch(pushError({
            type: types.GET_STATISTIC_TRANSACTION_FILTER_ERROR,
            error: getError(err)
        }))
        dispatch({
            type: types.DEACTIVE_LOADING_PAGE,
        })
    }
}

export const changeTimeFiler = (timeBefore, timeAfter) => dispatch => {
    // console.log(timeBefore, timeAfter);
    if (!timeBefore || !timeAfter) {
        return;
    }
    const data = {
        timeBefore,
        timeAfter
    }
    dispatch({
        type: types.CHANGE_TIME_FILTER,
        data
    })
}

export const changeFieldsFilter = (billCode, facepayNumber, compareAmount, pageNum, countChange = null) => dispatch => {
    const data = {
        billCode,
        facepayNumber,
        compareAmount,
        pageNum,
        countChange
    }
    dispatch({
        type: types.CHANGE_FIELDS_FILTER,
        data
    })
}

export const reloadAllFilter = () => dispatch => {
    dispatch({
        type: types.RELOAD_GET_ALL_FILTER,
    })
}

export const getDropdownShopFilterStatistic = (dataDropdown) => dispatch => {
    let listShopID = [];

    const arrDropdownListSelect = Object.keys(dataDropdown).map(item => {
        return dataDropdown[item]
    })

    let arrCheck = []
    for (let i = 0; i < arrDropdownListSelect.length; i++) {
        const element = arrDropdownListSelect[i];
        const arrDataShop = Object.keys(element).map(item => {
            return element[item]
        })
        for (let j = 0; j < arrDataShop.length; j++) {
            const elm = arrDataShop[j];
            arrCheck.push(elm);
        }
    }

    for (let i = 0; i < arrCheck.length; i++) {
        const element = arrCheck[i];
        if (arrCheck.every(item => item.checked === false)) {
            listShopID.push(element.id)
        } else {
            if (element.checked) {
                listShopID.push(element.id)
            }
        }
    }
    dispatch({
        type: types.DROPDOWN_SHOP_FILTER_STATISTIC,
        dataDropdown: dataDropdown,
        listShopID: listShopID,
    })
}







// ================ TEST ================ //
// const disPatchTestDataFilter = {
//     type: "GET_STATISTIC_TRANSACTION_FILTER_SUCCESS",
//     data: {
//         Code: 0,
//         ErrCode: "",
//         Data: [
//             {
//                 BillNumber: "test redux test redux",
//                 CreateTime: "2020-04-26T16:55:23.458Z",
//                 FacepayNumber: "test redux test redux",
//                 Amount: 600000,
//                 ToBankAccount: "2340018273173test",
//                 ToBankCode: "ABBANK"
//             }
//         ],
//         Total: 30,
//         type: "GET_STATISTIC_TRANSACTION_FILTER_SUCCESS"

//     }
// }