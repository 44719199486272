import styled from 'styled-components'
const Bound = styled.div`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 180px 0 0 0;
    .wrapper-block-page{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 921px;
        height: 182px;
        background: #FFFFFF;
        border: 1px solid #BFBFBF;
        box-sizing: border-box;
        border-radius: 20px;
        .title-block-page{
            height: 72px;
            display: flex;
            align-items: center;
            font-family: 'UTM-Avo';
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 170%;
            letter-spacing: 0.05px;
            color: #051321;
            padding: 0px 28px 0 20px;
            border-bottom:  1px solid #BFBFBF;
        }
        .content-block-page{
            height: 110px;
            display: flex;
            flex-direction: row;   
            align-items: center;
            padding: 0 28px 0px 20px;       
            font-family: 'UTM-Avo';
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;        
            letter-spacing: 0.05px; 
            color: #051321;       
            span{
                font-weight: bold;
                padding: 0 3px 0 3px;
            }
            a{
                text-decoration:none;
                color: #1279DA;
                padding: 0 3px;
            }
        }
    }

`
export { Bound }