import { useRef, useEffect, useCallback } from 'react'

const useScrollWindow = ([targetRef]) => {
    const lastScrollTopRef = useRef(window.pageYOffset);
    const initialPosition = useRef(null);

    useEffect(() => {
        const target = targetRef.current;
        if (!target) return;
        initialPosition.current = target.offsetTop;
        window.addEventListener('scroll', _handleScrollPage);

        return () => {
            window.removeEventListener('scroll', _handleScrollPage);
        }
    }, [])

    const _handleScrollPage = useCallback(
        (e) => {
            const target = targetRef.current;
            if (!target) return;
            const currentOffset = target.offsetTop;
            const pageYOffSet = window.pageYOffset;
            const lastScrollTop = lastScrollTopRef.current;
            lastScrollTopRef.current = pageYOffSet;

            if (lastScrollTop > pageYOffSet) {//scroll up
                target.style.top = currentOffset + lastScrollTop - pageYOffSet + "px";
                return;
            }
            target.style.top = currentOffset - (pageYOffSet - lastScrollTop) + "px";

        },
        [targetRef],
    )
}

export default useScrollWindow
