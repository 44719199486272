import React, { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

// component
import InputControl from '../../../Control/InputControl';

// tools
import { formatNumber } from '../../../../helper/formatNumber';
import useBlur from '../../../../helper/useBlur';
import { useClickOutside } from '../../../../helper/custom.hook';
import useScrollWindow from '../../../../helper/useScrollWindow';
import usePressEnter from "../../../../helper/usePressEnter";

// styles
import { RequestPayStyles } from './index.styles';

// action
import { paymentRequest, clearPaymentRequest } from '../../../../redux/actions/paymentAction';
import { pushError } from '../../../../redux/actions/notifyAction';

// type error
import { PAYMENT_REQUEST_ERROR } from '../../../../constant/typeAction';
import { Input } from '../../../Control/InputControl/index.styles';


const MIN_REQUEST = 10e3;
const MAX_REQUEST = 999999999999999;
const MinFormat = formatNumber(MIN_REQUEST)
const MaxFormat = formatNumber(MAX_REQUEST)
const MaxNum = MaxFormat.length;

// min < 10k => show popup error

const RequestPay = ({ deviceItem, extendStyles, handleBlur }) => {
    const dispatch = useDispatch();
    const selfRef = useRef(null);

    const { paymentReducer } = useSelector((state) => ({
        paymentReducer: state.paymentReducer
    }))

    const [val, setVal] = useState('')
    
    const [isEnter, setIsEnter] = usePressEnter();

    useClickOutside(selfRef, handleBlur)
    useScrollWindow([selfRef])
    useEffect(() => {
        const { data } = paymentReducer;
        if(!data) return;
        handleBlur();
        dispatch(clearPaymentRequest())
    }, [paymentReducer])

    useEffect(() => {
        if(isEnter){
            _handleSubmit()
            setIsEnter(false)
        }
    }, [isEnter]);

    const _handleSubmit = (e) => {
        if(!val){
            dispatch(pushError({
                type: PAYMENT_REQUEST_ERROR,
                error: {
                    type: 'EMPTY_MONEY_REQUEST'
                }
            }))
            return;
        }
        const moneyRequest = val;
        if (moneyRequest > MAX_REQUEST || moneyRequest < MIN_REQUEST){
            dispatch(pushError({
                type: PAYMENT_REQUEST_ERROR,
                error: {
                    type: 'INVALID_MONEY_REQUEST',
                    data: {
                        max: MaxFormat,
                        min: MinFormat
                    }
                }
            }))
            return;
        }
        dispatch(paymentRequest(deviceItem.ID, moneyRequest))
    }


    const _handleChangeVal = (values) => {
        // console.log(values)
        setVal(values.floatValue)
    }

    const _setAllowed = (values) => {
        if(values.value[0] === "0") return false;
        if(values.value > MAX_REQUEST) return false;
        return true;
    }

    return (
        <RequestPayStyles
            ref={selfRef}
            extendStyles={extendStyles}
        >
            <p className='request-payment-title'>Bắn yêu cầu thanh toán</p>
            <div className='request-payment-note'>Nhập số tiền</div>
            <NumberFormat 
                onPaste={(e) => {e.preventDefault()}}
                autoFocus="autofocus"
                isAllowed={_setAllowed}
                customInput={Input}
                thousandSeparator="." 
                decimalSeparator=","
                value={val}
                decimalScale={0}
                allowNegative={false}
                allowLeadingZeros={false}
                onValueChange={_handleChangeVal}
            />
            {/* <FieldError></FieldError> */}
            <div 
                className='request-payment-submit'
                onClick={_handleSubmit}
            >
                Gửi
            </div>
        </RequestPayStyles>
    )
}

export default RequestPay
