import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ICDown from "../../../../images/ic_down.svg";
import { useClickOutside } from "../../../../helper/custom.hook";
const StyledTypeDatePicker = styled.div`
    position: absolute;
    width: max-content;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 10;
    height: max-content;
    max-height: 30px;
    box-sizing: border-box;
    margin-top: 10px;
    ${props => props.disable && `display: none;`}
    .text {
        font-family: UTM-Avo;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 170%;
        display: flex;
        align-items: center;
        letter-spacing: 0.05px;
        color: #051321;
        margin-right: 10px;
        cursor: default;
    }
    .dropdown {
        &-selected {
            background-color: rgba(45, 152, 240, 0.2);
            border: 1px solid #2d98f0;
            box-sizing: border-box;
            border-radius: 6px;
            width: 80px;
            height: 30px;
            font-family: UTM-Avo;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 170%;
            display: flex;
            align-items: center;
            letter-spacing: 0.05px;
            color: #0075ff;
            padding: 2px 10px 4px 10px;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            &-arrow {
                width: 11.87px;
                height: 6.69px;
            }
        }
        &-content {
            margin-top: 10px;
            position: absolute;
            top: 100%;
            background-color: #e3eaf5;
            width: 80px;
            display: grid;
            grid-auto-rows: max-content;
            grid-row-gap: 2px;
            border-radius: 6px;
            overflow: hidden;
            transition: max-height 0.2s ease-in-out;
            ${props => {
                const { expand } = props;
                return expand
                    ? `max-height: 300px;`
                    : `height: 0; max-height: 0;`;
            }}
            &-item {
                background-color: #f3f3f3;
                width: 100%;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: UTM-Avo;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 170%;
                letter-spacing: 0.05px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
                :hover,
                :focus {
                    background-color: #fff;
                    outline: none;
                }
            }
            .selected {
                color: #0075ff;
            }
        }
    }
`;
const TypeDatePicker = ({ dataDropdown, onChange, disable }) => {
    const [currentSelected, setCurrentSelected] = useState(dataDropdown[0]);
    const [expand, setExpand] = useState(false);
    const pickerRef = useRef();
    useClickOutside(pickerRef, () => setExpand(false));
    return (
        <StyledTypeDatePicker ref={pickerRef} expand={expand} disable={disable}>
            <div className="text">Xem theo</div>
            <div className="dropdown">
                <div
                    className="dropdown-selected"
                    onClick={() => setExpand(!expand)}
                >
                    {currentSelected && currentSelected.value}
                    <img
                        className="dropdown-selected-arrow"
                        src={ICDown}
                        alt="down"
                    />
                </div>
                <div className="dropdown-content">
                    {dataDropdown &&
                        dataDropdown.map((item, index) => (
                            <div
                                className={`dropdown-content-item ${currentSelected.key ===
                                    item.key && "selected"}`}
                                onClick={e => {
                                    setCurrentSelected(item);
                                    onChange && onChange({ e, data: item });
                                    setExpand(false);
                                }}
                                key={index}
                            >
                                {item.value}
                            </div>
                        ))}
                </div>
            </div>
        </StyledTypeDatePicker>
    );
};
TypeDatePicker.propTypes = {
    dataDropdown: PropTypes.array,
    onChange: PropTypes.func,
    disable: PropTypes.bool
};
export default TypeDatePicker;
